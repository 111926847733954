import { createAction, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  api,
  ChatInitialMessageDto,
  ChatRoom,
  Consultation,
  ConsultationStatusEnum,
  Message,
  UpdateLastReadMessageDto,
  User,
} from 'shared/api';
import { ExtraParamsThunkType } from 'shared/common/types';
import { handleAsyncError } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';

export const createChatRoom = createAsyncThunk(
  'chatRooms/createChatRoom',
  async ({
    expert,
    client,
    initialMessage,
  }: {
    expert: User;
    client: User;
    initialMessage?: ChatInitialMessageDto;
  }) => {
    try {
      const { data } = await api.V1ChatsApi.chatRoomsControllerCreateChat({
        clientId: client.id,
        expertId: expert.id,
        initialMessage,
      });
      return { room: data, client, expert };
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const createTempChatRoom =
  createAction<{ client: User; expert: User; tempRoomId: number }>('chatRooms/createTempChatRoom');

export const replaceTempToRealChatRoom = createAsyncThunk(
  'chatRooms/replaceTempToRealChatRoom',
  async ({
    expert,
    client,
    initialMessage,
    tempRoomId,
  }: {
    expert: User;
    client: User;
    initialMessage?: ChatInitialMessageDto;
    tempRoomId: number;
  }) => {
    try {
      const { data } = await api.V1ChatsApi.chatRoomsControllerCreateChat({
        clientId: client.id,
        expertId: expert.id,
        initialMessage,
      });
      return { room: data, client, expert, tempRoomId };
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const getChatRooms = createAsyncThunk('chatRooms/getChatRooms', async ({ user }: { user: User }) => {
  try {
    const { data } = await api.V1ChatsApi.chatRoomsControllerGetMany();
    return { rooms: data, user };
  } catch (error: any) {
    captureError(error);
    handleAsyncError(error);
  }
});

export const getOneChatRoom = createAsyncThunk(
  'chatRooms/getOneChatRoom',
  async ({ chatRoomId }: { chatRoomId: number }) => {
    try {
      const { data } = await api.V1ChatsApi.chatRoomsControllerGetOne(chatRoomId);
      return data;
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const setActiveRoom = createAction<{ roomId: number | null }>('chatRooms/setActiveRoom');

export const addChatRoom = createAction<ChatRoom>('chatRooms/addChatRoom');

export const updateLastMessage = createAction<{ message: Message; user: User }>('chatRooms/updateLastMessage');

export const addUnreadMark = createAction<{ roomId: number }>('chatRoom/addUnreadMark');
export const resetUnreadMark = createAction<{ chatRoomId: number }>('chatRooms/resetUnreadMark');
export const changeLastReadMessage = createAction<{
  chatRoomId: number;
  lastMessageId?: number;
  isExpertInRoom: boolean;
}>('chatRooms/changeLastReadMessage');

export const getOnlineMembers = createAction<number[]>('chatRooms/getOnlineMembers');
export const addMember = createAction<number>('chatRooms/addOnlineMembers');
export const removeMember = createAction<number>('chatRooms/removeMember');

export const toggleChatNotification = createAction<{ roomId: number; isExpertInRoom: boolean; isMuted: boolean }>(
  'chatRoom/toggleChatNotification',
);

export const setCompanionWritten =
  createAction<{ chatRoomId: number; isWritten: boolean }>('chatRoom/setCompanionWritten');

export const addChatRoomIfNotExist = createAction<Consultation>('chatRooms/addChatRoomIfNotExist');
export const changeConsultationStatus = createAction<{
  roomId: number;
  consultationId: number;
  status: ConsultationStatusEnum;
}>('chatRooms/changeConsultationStatus');

export const resetState = createAction('chatRooms/resetState');

export const updateLastReadMessageAsync = createAsyncThunk<
  { id: number; dto: UpdateLastReadMessageDto },
  { id: number; dto: UpdateLastReadMessageDto },
  ExtraParamsThunkType<SerializedError>
>('chatRooms/update-last-read-message', async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
  try {
    await api.V1ChatsApi.chatRoomsControllerUpdateLastReadMessage(id, dto);
    return { id, dto };
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
