import * as React from 'react';

function SvgLikeBigIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={30} height={30} {...props}>
      <defs>
        <path
          id="likeBigIcon_svg__a"
          d="M16.266.508c1.884 0 3.914 1.73 3.914 5.531 0 1.296-.223 2.384-.485 3.211h4.657A5.655 5.655 0 0130 14.898c0 .075-.29 5.956-1.43 8.351l.103.017c-.44 2.7-2.892 4.734-5.704 4.734h-1.782c-4.986 0-8.348-.98-10.357-1.857A3.509 3.509 0 017.734 28H5.391a3.52 3.52 0 01-3.516-3.516V12.766A3.52 3.52 0 015.391 9.25h2.343c.782 0 1.504.256 2.089.69 1.23-.838 3.63-2.76 3.63-4.98v-.233c.008-1.513.144-4.22 2.813-4.22zM7.734 11.594H5.391c-.647 0-1.172.525-1.172 1.172v11.718c0 .647.525 1.172 1.172 1.172h2.343c.647 0 1.172-.525 1.172-1.172V12.766c0-.647-.525-1.172-1.172-1.172zm8.532-8.742c-.22.03-.47.363-.47 2.109 0 3.277-2.944 5.756-4.66 6.921.075.282.114.578.114.884v10.988c1.525.743 4.74 1.902 9.938 1.902h1.78c1.648 0 3.137-1.216 3.39-2.768.007-.037.014-.072.023-.108.14-.684 1.245-7.253 1.275-7.903 0-1.8-1.483-3.283-3.304-3.283h-6.446a1.172 1.172 0 01-.973-1.826c.003-.007.903-1.418.903-3.729 0-2.339-.94-3.187-1.57-3.187z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="likeBigIcon_svg__b" fill="#fff">
          <use xlinkHref="#likeBigIcon_svg__a" />
        </mask>
        <use fill="#24AF1E" fillRule="nonzero" xlinkHref="#likeBigIcon_svg__a" />
        <path fill="#24AF1E" d="M0 0h30v30H0z" mask="url(#likeBigIcon_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgLikeBigIcon;
