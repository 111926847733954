import * as React from 'react';

function SvgGrayWallet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14} {...props}>
      <defs>
        <path
          id="GrayWallet_svg__a"
          d="M11.76 3.92H9.8c-1.08 0-1.96.88-1.96 1.96s.88 1.96 1.96 1.96h1.96V9.8c0 .36-.293.653-.653.653H1.96a.653.653 0 01-.653-.653V1.96c0-.36.293-.653.653-.653h9.147c.36 0 .653.292.653.653v1.96zm0 2.613H9.8a.653.653 0 010-1.306h1.96v1.306zM11.107 0H1.96C.88 0 0 .88 0 1.96V9.8c0 1.08.88 1.96 1.96 1.96h9.147c1.08 0 1.96-.88 1.96-1.96V1.96c0-1.08-.88-1.96-1.96-1.96z"
        />
      </defs>
      <g transform="translate(.933 .933)" fill="none" fillRule="evenodd">
        <mask id="GrayWallet_svg__b" fill="#fff">
          <use xlinkHref="#GrayWallet_svg__a" />
        </mask>
        <use fill="#232832" fillRule="nonzero" xlinkHref="#GrayWallet_svg__a" />
        <path fill="#93969D" d="M0-.871h13.067v13.067H0z" mask="url(#GrayWallet_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgGrayWallet;
