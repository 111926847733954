import React, { FC } from 'react';
import styled from 'styled-components';

export type FieldWrapperProps = {
  label?: string;
  error?: string;
  successMessage?: string;
  required?: boolean;
  width?: string;
  centered?: boolean;
  onlyNumbers?: boolean;
};

export const FieldWrapper: FC<FieldWrapperProps> = ({
  label,
  error,
  successMessage,
  width,
  centered,
  required,
  children,
}) => (
    <Container $width={width} $centered={centered}>
      {label && (
        <Label>
          {label} {required && <Required>*</Required>}
        </Label>
      )}
      {children}
      {error && <BottomText className="error">{error}</BottomText>}
      {successMessage && <BottomText className="success">{successMessage}</BottomText>}
    </Container>
  );

const Container = styled.div<{ $width?: string; $centered?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $centered }) => ($centered ? 'center' : undefined)};
  width: ${({ $width }) => ($width || '100%')};
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 15px;
  color: var(--gray);
  margin-bottom: 10px;
`;

const Required = styled.span`
  color: var(--purple);
`;

const BottomText = styled.span`
  margin: 10px 0;
  font-weight: 500;
  font-size: 13px;
  color: var(--gray);
  min-height: 14px;
  &.error {
    color: var(--red);
  }

  &.success {
    color: var(--green);
  }
`;
