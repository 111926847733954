import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  ChatConsultationOffer,
  ChatRoom,
  Consultation,
  ConsultationInvoice,
  ConsultationInvoiceTypeEnum,
  ConsultationStatusEnum,
  InvoiceStatusEnum,
} from 'shared/api';
import { ConsultationWithOffer } from 'shared/common/types';

const initialState = {
  isReviewOpenModal: false,
  consultations: [],
} as {
  isReviewOpenModal: boolean;
  consultations: ConsultationWithOffer[];
};

const consultationsSlice = createSlice({
  name: 'consultationsChatRoom',
  initialState,
  reducers: {
    setIsReviewModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isReviewOpenModal = payload;
    },
    setConsultationsChatRoom(state, { payload }: PayloadAction<ChatRoom>) {
      const chatConsultationOfferMap: { [key: number]: ChatConsultationOffer } = {};
      payload.chatConsultationOffers &&
        payload.chatConsultationOffers.forEach((offer) => {
          chatConsultationOfferMap[offer.consultationId] = offer;
        });
      if (payload.consultations) {
        const consultationsExtends: ConsultationWithOffer[] = payload.consultations.map((consultation) => ({
          ...consultation,
          consultationOffer: chatConsultationOfferMap[consultation.id],
        }));
        state.consultations = consultationsExtends;
      }
    },
    changeConsultationByIdOrAdd(state, { payload }: PayloadAction<ConsultationWithOffer>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        state.consultations[consultationIndex] = payload;
      } else {
        state.consultations = [...state.consultations, payload];
      }
    },
    incrementExpertMessagesCount(state, { payload }: PayloadAction<Consultation['id']>) {
      state.consultations = state.consultations.map((cons) =>
        cons.id === payload ? { ...cons, expertMessagesCount: cons.expertMessagesCount + 1 } : cons,
      );
    },
    updateConsultationsStatus(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        state.consultations[consultationIndex] = {
          ...state.consultations[consultationIndex],
          status: payload.status,
          updatedAt: payload.updatedAt,
          expiresIn: payload.expiresIn || undefined,
        };
      }
    },
    updateConsultationByTimeExceeded(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        state.consultations[consultationIndex] = {
          ...state.consultations[consultationIndex],
          status: ConsultationStatusEnum.TimeLimitExceeded,
          updatedAt: dayjs().toISOString(),
        };
      }
    },
    rejectConsultationOffer(state, { payload }: PayloadAction<ChatConsultationOffer>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.consultationId);
      if (consultationIndex !== -1) {
        state.consultations[consultationIndex] = {
          ...state.consultations[consultationIndex],
          status: ConsultationStatusEnum.ClientRejectChatOffer,
          updatedAt: payload.updatedAt,
          expiresIn: payload.expiresIn || undefined,
        };
      }
    },
    addProlongationRequestToConsultation(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        state.consultations[consultationIndex] = {
          ...state.consultations[consultationIndex],
          invoices: [...payload.invoices],
        };
      }
    },
    prolongationConsultation(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);

      if (consultationIndex === -1) {
        return;
      }

      const fulfilledInvoice = state.consultations[consultationIndex].invoices?.find(
        (invoice) => invoice.invoice?.status === InvoiceStatusEnum.Pending,
      );

      if (fulfilledInvoice && fulfilledInvoice.invoice) {
        fulfilledInvoice.invoice.status = InvoiceStatusEnum.Fulfilled;
        fulfilledInvoice.invoice.updatedAt = dayjs().toISOString();
      } else {
        const prolongedInvoice = {
          id: Date.now(),
          consultationId: payload.id,
          type: ConsultationInvoiceTypeEnum.Prolongation,
          invoice: {
            id: Date.now(),
            payerId: payload.clientId,
            status: InvoiceStatusEnum.Fulfilled,
            isFree: true,
            createdAt: dayjs().format(),
            updatedAt: dayjs().format(),
          },
        } as ConsultationInvoice;
        state.consultations[consultationIndex].invoices.push(prolongedInvoice);
      }
      state.consultations[consultationIndex] = {
        ...state.consultations[consultationIndex],
        messagesLimit: payload.messagesLimit,
        expiresIn: payload.expiresIn,
      };
    },
    prolongationDecline(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        const rejectingInvoice = state.consultations[consultationIndex].invoices?.find(
          (invoice) => invoice.invoice?.status === InvoiceStatusEnum.Pending,
        );
        if (rejectingInvoice && rejectingInvoice.invoice) {
          rejectingInvoice.invoice.status = InvoiceStatusEnum.Rejected;
          rejectingInvoice.invoice.updatedAt = dayjs().toISOString();
        } else {
          const rejectedInvoice = {
            id: Date.now(),
            consultationId: payload.id,
            type: ConsultationInvoiceTypeEnum.Prolongation,
            invoice: {
              id: Date.now(),
              payerId: payload.clientId,
              status: InvoiceStatusEnum.Rejected,
              createdAt: dayjs().format(),
              updatedAt: dayjs().format(),
            },
          } as ConsultationInvoice;
          state.consultations[consultationIndex].invoices.push(rejectedInvoice);
        }
      }
    },
    createExtraService(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        if ('extraServices' in state.consultations[consultationIndex]) {
          state.consultations[consultationIndex].extraServices.push(payload.extraServices[0]);
        } else {
          state.consultations[consultationIndex].extraServices = [payload.extraServices[0]];
        }
      }
    },
    paidExtraService(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        const currentExtraService = state.consultations[consultationIndex].extraServices.find(
          (service) => service.id === payload.extraServices[0].id,
        );
        if (currentExtraService) {
          currentExtraService.invoice.status = InvoiceStatusEnum.Fulfilled;
          currentExtraService.invoice.updatedAt = dayjs().toISOString();
        }
      }
    },
    rejectExtraService(state, { payload }: PayloadAction<Consultation>) {
      const consultationIndex = state.consultations.findIndex((c) => c.id === payload.id);
      if (consultationIndex !== -1) {
        const currentExtraService = state.consultations[consultationIndex].extraServices.find(
          (service) => service.id === payload.extraServices[0].id,
        );
        if (currentExtraService) {
          currentExtraService.invoice.status = InvoiceStatusEnum.Rejected;
          currentExtraService.invoice.updatedAt = dayjs().toISOString();
        }
      }
    },
    resetState() {
      return initialState;
    },
  },
});

export const { reducer } = consultationsSlice;
export const actions = { ...consultationsSlice.actions };
