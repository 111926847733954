import { createAction, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { api, News } from 'shared/api';
import { ExtraParamsThunkType } from 'shared/common/types';
import { handleAsyncError } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';

export const getChannelNews = createAsyncThunk(
  'channelNews/getChannelNews',
  async ({ limit, page }: { limit: number; page: number }) => {
    try {
      const { data } = await api.V1NewsApi.newsControllerGetNews(limit, page);
      return data;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchMoreChannelNews = createAsyncThunk(
  'channelNews/fetchMoreChannelNews',
  async ({ limit, page }: { limit: number; page: number }) => {
    try {
      const { data } = await api.V1NewsApi.newsControllerGetNews(limit, page);
      return data;
    } catch (e: any) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const resetState = createAction('channelNews/resetState');

export const createNewsRegistrationAsync = createAsyncThunk<
  News,
  'default' | 'wellcome' | 'after_3_hours' | 'after_3_days',
  ExtraParamsThunkType<SerializedError>
>('channelNews/create-news-registration', async (type, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1NewsApi.newsControllerCreateNewsRegistration(type);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const sendEmailAfterRegistrationAsync = createAsyncThunk<
  boolean,
  'wellcome' | 'after_3_hours' | 'after_3_days',
  ExtraParamsThunkType<SerializedError>
>('channelNews/send-email-after-registration', async (type, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1EmailsApi.emailControllerSendEmailAfterRegistration(type);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
