import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ExpertService } from 'shared/api';
import { LoadingStatus } from 'shared/common/types';

import {
  createExpertsServiceAsync,
  deleteExpertsServiceAsync,
  getManyExpertsServicesAsync,
  updateExpertsServiceAsync,
} from './actions';

export interface ServicesState {
  services: ExpertService[];
  servicesLoading: LoadingStatus;
  crearteServiceLoading: LoadingStatus;
  removeServiceLoading: LoadingStatus;
  updateServiceLoading: LoadingStatus;
  error: string | null | undefined | SerializedError;
}

const initialState: ServicesState = {
  services: [],
  servicesLoading: 'idle',
  crearteServiceLoading: 'idle',
  removeServiceLoading: 'idle',
  updateServiceLoading: 'idle',
  error: null,
};

export const sericesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: {
    [getManyExpertsServicesAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertService[]>) => {
      state.services = payload;
      state.servicesLoading = 'idle';
    },
    [getManyExpertsServicesAsync.pending.type]: (state) => {
      if (state.servicesLoading === 'idle') {
        state.servicesLoading = 'pending';
      }
    },
    [getManyExpertsServicesAsync.rejected.type]: (state, { payload }) => {
      state.servicesLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [createExpertsServiceAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertService>) => {
      state.services = [...state.services, payload];
      state.crearteServiceLoading = 'idle';
    },
    [createExpertsServiceAsync.pending.type]: (state) => {
      state.crearteServiceLoading = 'pending';
    },
    [createExpertsServiceAsync.rejected.type]: (state, { payload }) => {
      state.crearteServiceLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [deleteExpertsServiceAsync.fulfilled.type]: (state, { payload }: PayloadAction<number>) => {
      state.services = state.services.filter((item) => item.id !== payload);
      state.removeServiceLoading = 'idle';
    },
    [deleteExpertsServiceAsync.pending.type]: (state) => {
      if (state.removeServiceLoading === 'idle') {
        state.removeServiceLoading = 'pending';
      }
    },
    [deleteExpertsServiceAsync.rejected.type]: (state, { payload }) => {
      state.removeServiceLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },

    [updateExpertsServiceAsync.fulfilled.type]: (state, { payload }: PayloadAction<ExpertService>) => {
      state.services = state.services.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      state.updateServiceLoading = 'idle';
    },
    [updateExpertsServiceAsync.pending.type]: (state) => {
      state.updateServiceLoading = 'pending';
    },
    [updateExpertsServiceAsync.rejected.type]: (state, { payload }) => {
      state.updateServiceLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});

export const { reducer } = sericesSlice;
export const actions = {
  ...sericesSlice.actions,
  getManyExpertsServicesAsync,
  createExpertsServiceAsync,
  deleteExpertsServiceAsync,
  updateExpertsServiceAsync,
};
