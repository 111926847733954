import { TFunction } from 'next-i18next';
import { Message } from 'shared/api';
import { AUDIO_MESSAGE_EXT } from 'shared/common/constants';

export const makeMessageContent = (message: Message, t: TFunction): string => {
  if (message.attachments && message.attachments[0].split('.').pop() === AUDIO_MESSAGE_EXT) {
    return t('messageContent.audio');
  }
  return message.content!;
};
