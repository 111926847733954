import React, { FC } from 'react';
import styled from 'styled-components';

interface StarRatingProps {
  rating: number;
  starCount?: number;
}

export const StarRating: FC<StarRatingProps> = ({ rating, starCount = 5 }) => {
  const stars = new Array(starCount).fill('');
  return (
    <Stars>
      {stars.map((v, i) => (
        <Star key={i} $rating={rating} $num={i + 1}>
          ★
        </Star>
      ))}
    </Stars>
  );
};

type StarProps = {
  $rating: number;
  $num: number;
};
export const Star = styled.div`
  color: ${({ $rating, $num }: StarProps) => ($rating >= $num ? 'var(--yellow)' : 'var(--gray9)')};
  margin: 0 1px;
`;

const Stars = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
