import * as React from 'react';

function SvgDislikeBigIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
      <g fill="#F36" fillRule="nonzero">
        <path d="M7.734 2H5.391a3.52 3.52 0 00-3.516 3.516v11.718a3.52 3.52 0 003.516 3.516h2.343a3.52 3.52 0 003.516-3.516V5.516A3.52 3.52 0 007.734 2zM5.391 18.406a1.172 1.172 0 01-1.172-1.172V5.516c0-.647.525-1.172 1.172-1.172h2.343c.647 0 1.172.525 1.172 1.172v11.718c0 .647-.525 1.172-1.172 1.172H5.391z" />
        <path d="M22.969 2h-1.782c-6.33 0-10.042 1.58-11.69 2.522a1.17 1.17 0 00-.436 1.598c.319.565 1.034.76 1.598.436 1.165-.665 4.524-2.212 10.528-2.212h1.782c1.647 0 3.136 1.216 3.389 2.768.007.037.014.072.023.108.14.684 1.245 7.253 1.275 7.903 0 1.8-1.483 3.283-3.304 3.283h-6.446a1.172 1.172 0 00-.973 1.826c.003.007.903 1.418.903 3.729 0 2.339-.94 3.187-1.57 3.187-.22-.03-.47-.363-.47-2.109 0-3.63-3.613-6.281-5.167-7.252a1.173 1.173 0 00-1.242 1.988c.954.595 4.066 2.737 4.066 5.264 0 1.446 0 4.453 2.813 4.453 1.884 0 3.914-1.73 3.914-5.531 0-1.296-.223-2.384-.485-3.211h4.657A5.655 5.655 0 0030 15.102c0-.075-.29-5.956-1.43-8.351l.103-.017C28.233 4.034 25.781 2 22.969 2z" />
      </g>
    </svg>
  );
}

export default SvgDislikeBigIcon;
