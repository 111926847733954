import * as React from 'react';

function SvgLike(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={16} height={16} {...props}>
      <defs>
        <path
          id="Like_svg__a"
          d="M8.675.27c1.005 0 2.087.923 2.087 2.95 0 .692-.118 1.272-.258 1.713h2.484A3.017 3.017 0 0116 7.946c0 .04-.155 3.176-.762 4.454l.055.008c-.236 1.44-1.543 2.525-3.043 2.525h-.95c-2.66 0-4.453-.522-5.524-.99a1.87 1.87 0 01-1.651.99h-1.25A1.876 1.876 0 011 13.058v-6.25c0-1.033.841-1.875 1.875-1.875h1.25c.417 0 .803.137 1.115.369.653-.447 1.935-1.472 1.935-2.656 0-.771 0-2.375 1.5-2.375zm-4.55 5.913h-1.25a.625.625 0 00-.625.625v6.25c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625v-6.25a.625.625 0 00-.625-.625zm4.55-4.662c-.118.016-.25.194-.25 1.125 0 1.749-1.573 3.071-2.488 3.692.042.15.063.307.063.47v5.86c.814.397 2.528 1.015 5.3 1.015h.95c.879 0 1.672-.648 1.807-1.476l.013-.057c.075-.365.664-3.87.68-4.215 0-.96-.791-1.752-1.762-1.752H9.55a.624.624 0 01-.519-.973c.002-.004.481-.757.481-1.99 0-1.247-.5-1.7-.837-1.7z"
        />
      </defs>
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <mask id="Like_svg__b" fill="#fff">
          <use xlinkHref="#Like_svg__a" />
        </mask>
        <use fill="#24AF1E" fillRule="nonzero" xlinkHref="#Like_svg__a" />
        <path fill="#24AF1E" d="M0 0h16v16H0z" mask="url(#Like_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgLike;
