import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../common/constants';

interface TipProps {
  text: string;
}

export const Tip: FC<TipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Container>
      {text && <Text $isVisible={isVisible}>{text}</Text>}
      <Content onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
        {children}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;
const Text = styled.span<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 1s ease, visibility 1s ease;
  position: absolute;
  padding: 7px 7px 8px;
  border-radius: 8px;
  background-color: #232832;
  color: var(--white);
  white-space: nowrap;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  top: -37px;
  height: 30px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  display: none;

  ${CONDITION_DESKTOP} {
    display: block;
  }

  &:before {
    content: '';
    background: transparent;
    border-top: 7px solid #232832;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    position: absolute;
    left: 48%;
    bottom: -7px;
  }
`;

const Content = styled.div`
  z-index: 10;
`;
