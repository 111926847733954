import SvgArrowBigClose from 'app/assets/svg/ArrowBigClose';
import SvgCheckOkOpacity from 'app/assets/svg/CheckOkOpacity';
import SvgCheckOkSuccess from 'app/assets/svg/CheckOkSuccess';
import SvgCloseNote from 'app/assets/svg/CloseNote';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';

export enum ActionButtonStatusNames {
  'no',
  'processing',
  'fail',
  'success',
  'expired',
}

type ActionButtonProps = {
  buttonTexts: string[];
  isSuccess?: boolean;
  isClosed?: boolean;
  isShowArrow?: boolean;
  clickHandler?: () => void;
  statusName?: ActionButtonStatusNames;
  isPadding?: boolean;
};

export const ActionButton: FC<ActionButtonProps> = ({
  buttonTexts,
  isSuccess = false,
  isClosed = false,
  isShowArrow = false,
  clickHandler,
  statusName = ActionButtonStatusNames.no,
  isPadding = false,
}) => {
  const { t } = useTranslation('profile.page');
  const [isShowButton, setIsShowButton] = useState(true);

  if (!isShowButton) {
    return null;
  }

  return (
    <ButtonContainer $pb={isClosed} onClick={clickHandler} $pt={isPadding}>
      <IconContainer>{isSuccess ? <SvgCheckOkSuccess /> : <SvgCheckOkOpacity />}</IconContainer>
      <LeftContainer>
        <ButtonWrapper>
          <ButtonText>{buttonTexts[0]}</ButtonText>
          <ButtonSubText>{buttonTexts[1]}</ButtonSubText>
        </ButtonWrapper>
        {!isClosed && !isSuccess && statusName !== ActionButtonStatusNames.no && (
          <StatusWrapper>
            {statusName === ActionButtonStatusNames.processing ? (
              <CheckStatusText>{t('actionButtonCheckStatus')}</CheckStatusText>
            ) : (
              <FailStatusText>{t('actionButtonFailStatus')}</FailStatusText>
            )}
          </StatusWrapper>
        )}
      </LeftContainer>
      <RightContainer>
        {isShowArrow && (
          <ArrowIconContainer>
            {isClosed ? (
              <CloseNoteWrapper onClick={() => setIsShowButton(false)}>
                <SvgCloseNote width="16" height="15" />
              </CloseNoteWrapper>
            ) : (
              <SvgArrowBigClose />
            )}
          </ArrowIconContainer>
        )}
      </RightContainer>
    </ButtonContainer>
  );
};

const IconContainer = styled.div`
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
`;

const ButtonSubText = styled.span`
  margin-top: 3px;
  display: block;
  ${CONDITION_DESKTOP} {
    max-width: 440px;
    align-self: flex-start;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    margin-top: 0;
    letter-spacing: normal;
    color: var(--gray);
  }
`;

const ButtonText = styled.span`
  align-self: flex-start;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--black2);
`;

const ButtonContainer = styled.div<{ $pb: boolean; $pt: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ $pb }) => ($pb ? '20px 28px 20px 20px' : '20px 28px 25px 20px')};
  padding-top: 18px;
  padding-bottom: 18px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  background-color: var(--white);
`;

const LeftContainer = styled.div`
  justify-content: space-between;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex: 1 99%;
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
  flex-basis: 100%;
  ${CONDITION_DESKTOP} {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 550px;
  }
`;

const RightContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  flex: 2 1%;
`;

const ArrowIconContainer = styled.div`
  display: flex;
`;

const StatusWrapper = styled.div`
  margin-top: 10px;
  ${CONDITION_DESKTOP} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 20px;
    margin-top: 0px;
    margin-left: 0;
    padding-top: 0;
  }
`;

const CheckStatusText = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--purple);
  border-radius: 2px;
  border: solid 1px var(--purple);
  padding: 1px 5px;
`;

const FailStatusText = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--red);
  border-radius: 2px;
  border: solid 1px var(--red);
  padding: 1px 5px;
`;

const CloseNoteWrapper = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
