import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { GetManyRubricsResponseDto, Rubric } from 'shared/api';
import { LoadingStatus } from 'shared/common/types';

import {
  createSuggestedCategoryFromUserAsync,
  getExpertsByCategoryAsync,
  getOneCategoryAsync,
  getRubricsAsync,
} from './actions';

export interface RubricsState {
  rubrics: Rubric[];
  rubricsLoading: LoadingStatus;
  error: string | null | undefined | SerializedError;
}

const initialState: RubricsState = {
  rubrics: [],
  rubricsLoading: 'idle',
  error: null,
};

export const rubricsSlice = createSlice({
  name: 'rubrics',
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: {
    [getRubricsAsync.fulfilled.type]: (state, { payload }: PayloadAction<GetManyRubricsResponseDto>) => {
      state.rubrics = payload.data;
    },
    [getRubricsAsync.pending.type]: (state) => {
      if (state.rubricsLoading === 'idle') {
        state.rubricsLoading = 'pending';
      }
    },
    [getRubricsAsync.rejected.type]: (state, { payload }) => {
      state.rubricsLoading = 'idle';
      if (payload.message && payload.statusCode) {
        state.error = { message: payload.message, code: payload.statusCode };
      } else {
        state.error = payload.error;
      }
    },
  },
});

export const { reducer } = rubricsSlice;
export const actions = {
  ...rubricsSlice.actions,
  getRubricsAsync,
  getOneCategoryAsync,
  createSuggestedCategoryFromUserAsync,
  getExpertsByCategoryAsync,
};
