import { RootState } from 'store';

export const selectChannelNews = (state: RootState) => state.channelNews.channelNews;
export const selectChannelNewsPage = (state: RootState) => state.channelNews.page;
export const selectChannelNewsPageCount = (state: RootState) => state.channelNews.pageCount;
export const selectFetchingChannelNewsStatus = (state: RootState) => state.channelNews.fetchingChannelNewsStatus;
export const selectFetchingMoreChannelNewsStatus = (state: RootState) =>
  state.channelNews.fetchingMoreChannelNewsStatus;
export const selectIsShouldFetchChannelNewsAgain = (state: RootState) =>
  state.channelNews.isShouldFetchChannelNewsAgain;
export const selectIsFirstNewsToShow = (state: RootState) => state.channelNews.isFirstNewsToShow;
export const selectIsSecondNewsToShow = (state: RootState) => state.channelNews.isSecondNewsToShow;
export const selectIsThirdNewsToShow = (state: RootState) => state.channelNews.isThirdNewsToShow;
export const selectIsFirstNewsViewed = (state: RootState) => state.channelNews.isFirstNewsViewed;
export const selectIsSecondNewsViewed = (state: RootState) => state.channelNews.isSecondNewsViewed;
export const selectIsThirdNewsViewed = (state: RootState) => state.channelNews.isThirdNewsViewed;
export const selectIsShowUnreadCount = (state: RootState) => state.channelNews.isShowUnreadCount;
