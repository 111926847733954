import * as React from 'react';

function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} {...props}>
      <defs>
        <path
          id="Copy_svg__a"
          d="M13.406 3.154c.872 0 1.577.706 1.577 1.578v11.04c0 .871-.705 1.577-1.577 1.577H4.732a1.577 1.577 0 01-1.578-1.577V4.732c0-.872.706-1.578 1.578-1.578zm0 1.578H4.732v11.04h8.674V4.732zM11.04 0v1.577H1.577v11.04H0V1.578C0 .706.706 0 1.577 0h9.463z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="Copy_svg__b" fill="#fff">
          <use xlinkHref="#Copy_svg__a" />
        </mask>
        <use fill="#79818C" xlinkHref="#Copy_svg__a" />
        <path fill="#79818C" d="M0 0h19.5v19.5H0z" mask="url(#Copy_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgCopy;
