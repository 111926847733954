import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetManyNewsResponseDto, News } from 'shared/api';
import { LoadingStatus } from 'shared/common/types';

import {
  createNewsRegistrationAsync,
  fetchMoreChannelNews,
  getChannelNews,
  resetState,
  sendEmailAfterRegistrationAsync,
} from './actions';

const initialState = {
  channelNews: [],
  page: 0,
  pageCount: null,
  fetchingChannelNewsStatus: 'idle',
  fetchingMoreChannelNewsStatus: 'idle',
  isShouldFetchChannelNewsAgain: false,
  isFirstNewsToShow: false,
  isSecondNewsToShow: false,
  isThirdNewsToShow: false,
  isFirstNewsViewed: false,
  isSecondNewsViewed: false,
  isThirdNewsViewed: false,
  isShowUnreadCount: true,
} as {
  channelNews: News[];
  page: number;
  pageCount: number | null;
  fetchingChannelNewsStatus: LoadingStatus;
  fetchingMoreChannelNewsStatus: LoadingStatus;
  isShouldFetchChannelNewsAgain: boolean;
  isFirstNewsToShow: boolean;
  isSecondNewsToShow: boolean;
  isThirdNewsToShow: boolean;
  isFirstNewsViewed: boolean;
  isSecondNewsViewed: boolean;
  isThirdNewsViewed: boolean;
  isShowUnreadCount: boolean;
};

const channelNewsSlice = createSlice({
  name: 'channelNews',
  initialState,
  reducers: {
    setShowUnreadCount: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isShowUnreadCount = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
    setFirstNewsToShow: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isFirstNewsToShow = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
    setSecondNewsToShow: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isSecondNewsToShow = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
    setThirdNewsToShow: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isThirdNewsToShow = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
    setFirstNewsViewed: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isFirstNewsViewed = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
    setSecondNewsViewed: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isSecondNewsViewed = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
    setThirdNewsViewed: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.isThirdNewsViewed = action.payload;
      },
      prepare: (payloadRequests: boolean) => ({
        payload: payloadRequests,
      }),
    },
  },
  extraReducers: {
    [getChannelNews.pending.type]: (state) => {
      state.fetchingChannelNewsStatus = 'pending';
      state.page = 0;
      state.pageCount = null;
    },
    [getChannelNews.fulfilled.type]: (state, { payload }: PayloadAction<GetManyNewsResponseDto>) => {
      state.fetchingChannelNewsStatus = 'fulfilled';
      state.channelNews = state.channelNews && state.channelNews.length > 0 ? state.channelNews : payload.data;
      state.page = payload.page;
      state.pageCount = payload.pageCount;
    },
    [getChannelNews.rejected.type]: (state) => {
      state.fetchingChannelNewsStatus = 'rejected';
    },
    [fetchMoreChannelNews.pending.type]: (state) => {
      state.fetchingMoreChannelNewsStatus = 'pending';
      state.isShouldFetchChannelNewsAgain = false;
    },
    [fetchMoreChannelNews.fulfilled.type]: (state, { payload }: PayloadAction<GetManyNewsResponseDto>) => {
      state.fetchingMoreChannelNewsStatus = 'fulfilled';
      state.page = payload.page;
      state.pageCount = payload.pageCount;

      const chatMessagesIds = state.channelNews.map((news) => news.id);
      const uniqNews = payload.data.filter((news) => !chatMessagesIds.includes(news.id));

      state.channelNews = [...state.channelNews, ...uniqNews];

      if (!uniqNews.length) {
        state.isShouldFetchChannelNewsAgain = true;
      }
    },
    [fetchMoreChannelNews.rejected.type]: (state) => {
      state.fetchingMoreChannelNewsStatus = 'rejected';
    },
    [resetState.type]: () => initialState,
  },
});

export const { reducer } = channelNewsSlice;
export const actions = {
  ...channelNewsSlice.actions,
  getChannelNews,
  fetchMoreChannelNews,
  resetState,
  createNewsRegistrationAsync,
  sendEmailAfterRegistrationAsync,
};
