import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  api,
  CreateSuggestedCategoryDto,
  GetCategoryDto,
  GetManyCategoryExpertsDto,
  GetManyRubricsResponseDto,
} from 'shared/api';
import { ExtraParamsThunkType } from 'shared/common/types';

type PaginatedSearchDto = {
  limit?: number;
  page?: number;
  filter?: 'ONLY_ACTIVE'[];
  join?: Array<'FIRST_LEVEL_OF_CATEGORIES'>;
};

export const getRubricsAsync = createAsyncThunk<GetManyRubricsResponseDto, PaginatedSearchDto>(
  'rubrics/getRubrics',
  async (dto, { rejectWithValue }) => {
    try {
      const { data } = await api.V1RubricsApi.rubricsControllerGetManyRubrics(
        dto.limit,
        dto.page,
        dto.filter,
        dto.join,
      );
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const getOneCategoryAsync = createAsyncThunk<GetCategoryDto, number, ExtraParamsThunkType<SerializedError>>(
  'rubrics/get-one-category',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.V1CategoriesApi.categoriesControllerGetOneCategory(id);
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const createSuggestedCategoryFromUserAsync = createAsyncThunk<
  boolean,
  CreateSuggestedCategoryDto,
  ExtraParamsThunkType<SerializedError>
>('rubrics/create-suggested-category-from-user', async (dto, { rejectWithValue }) => {
  try {
    const { data } = await api.V1CategoriesApi.categoriesControllerCreateSuggestedCategoryFromUser(dto);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export type GetExpertsByCategoryParams = {
  sortCriteria:
    | 'rating'
    | 'outcomingRating'
    | 'countOfConsultationExpert'
    | 'countOfConsultationsUser'
    | 'totalProceeds'
    | 'countComplaint'
    | 'userComplaintCount'
    | 'createdAt';
  direction: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
  consultationsFilter?: 'freeConsultations';
  subcategoriesFilter?: 'allCategories' | 'rootSubcategories';
};
export const getExpertsByCategoryAsync = createAsyncThunk<
  GetManyCategoryExpertsDto,
  { id: number; params: GetExpertsByCategoryParams },
  ExtraParamsThunkType<SerializedError>
>('rubrics/get-experts-by-category', async ({ id, params }, { rejectWithValue }) => {
  try {
    const { data } = await api.V1CategoriesApi.categoriesControllerGetExpertsByCategory(
      id,
      params?.sortCriteria,
      params?.direction,
      params?.limit,
      params?.page,
      params?.consultationsFilter,
      params?.subcategoriesFilter,
    );
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
