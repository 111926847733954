import * as React from 'react';

function Youtube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M18.5944,1.4056 C19.8759765,2.68790588 19.9890567,4.6818173 19.9990344,8.45394059 L19.9999642,9.20651991 C20,9.33574602 20,9.46689412 20,9.6 L20,10.4 L19.9999642,10.7934801 L19.9999642,10.7934801 L19.9990344,11.5460594 C19.9890567,15.3181827 19.8759765,17.3120941 18.5944,18.5944 C17.3120941,19.8759765 15.3181827,19.9890567 11.5460594,19.9990344 L10.7934801,19.9999642 C10.664254,20 10.5331059,20 10.4,20 L9.6,20 L9.20651991,19.9999642 L9.20651991,19.9999642 L8.45394059,19.9990344 C4.6818173,19.9890567 2.68790588,19.8759765 1.4056,18.5944 C0.124023529,17.3120941 0.0109432526,15.3181827 0.000965581111,11.5460594 L0.000965581111,8.45394059 C0.0109432526,4.6818173 0.124023529,2.68790588 1.4056,1.4056 C2.68790588,0.124023529 4.6818173,0.0109432526 8.45394059,0.000965581111 L11.5460594,0.000965581111 C15.3181827,0.0109432526 17.3120941,0.124023529 18.5944,1.4056 Z M5.62296358,5.65805056 C4.76018349,5.75518474 4.16785544,6.81128089 4.10309932,7.61406634 C3.966921,9.29963009 3.966921,10.2595444 4.10309932,11.9441558 C4.16880773,12.7478935 4.74589905,13.8097035 5.62486817,13.9211221 C8.43985485,14.1058675 11.6624242,14.1334841 14.3774198,13.9211221 C15.2297246,13.7725639 15.8325279,12.7478935 15.8982364,11.9441558 C16.035367,10.2595444 16.035367,9.19487754 15.8972841,7.50931379 C15.8306233,6.70652835 15.3544754,5.6942378 14.3755152,5.65805056 C11.6414737,5.47330515 8.40366761,5.42283347 5.62296358,5.65805056 Z M8.49984949,7.59502042 L12.2499908,9.75673212 L8.49984949,11.9184438 L8.49984949,7.59502042 Z"
          id="Combined-Shape"
          fill="#111111"
        />
      </g>
    </svg>
  );
}

export default Youtube;
