import { KeyboardEvent } from 'react';

import { numberBetween } from './numberBetween';

export const onKeyDownNumber = (e: KeyboardEvent<HTMLInputElement>) => {
  // todo enum с кодами клавишь, если не в нем то не выполнять дальше

  const { keyCode } = e;
  if (numberBetween(keyCode, 58, 47) || numberBetween(keyCode, 10, 7) || numberBetween(keyCode, 41, 36)) {
    return;
  }
  e.preventDefault();
};
