import { CSSObject } from 'styled-components';

import { ButtonColor, ButtonSize } from './button';

export const backgroundImageMap: Record<ButtonColor, string | undefined> = {
  white: undefined,
  purple: undefined,
  'purple-opacity': undefined,
  'purple-gradient': 'linear-gradient(to right, #812df6 5%, #993dfa 48%, #ae4cfe)',
  gray: undefined,
  red: undefined,
  'white-text': undefined,
};

export const colorsMap: Record<ButtonColor, string> = {
  white: 'var(--white)',
  purple: 'var(--purple)',
  'purple-opacity': 'rgba(255, 255, 255, 0.15)',
  'purple-gradient': 'var(--purple)',
  gray: '#f7f6ff',
  red: 'var(--red)',
  'white-text': 'var(--white)',
};

export const hoverColorsMap: Record<ButtonColor, string> = {
  white: 'var(--white)',
  purple: 'var(--purple2)',
  'purple-opacity': 'rgba(255, 255, 255, 0.3)',
  'purple-gradient': 'var(--purple)',
  gray: '#f7f6ff',
  red: 'var(--red2)',
  'white-text': 'var(--white)',
};

export const textColorsMap: Record<ButtonColor, string> = {
  white: 'var(--purple)',
  purple: 'var(--white)',
  'purple-opacity': 'var(--white)',
  'purple-gradient': 'var(--white)',
  gray: 'var(--purple)',
  red: 'var(--white)',
  'white-text': 'var(--white)',
};

export const hoverTextColorsMap: Record<ButtonColor, string> = {
  white: 'var(--purple)',
  purple: 'var(--white)',
  'purple-opacity': 'var(--white)',
  'purple-gradient': 'var(--white)',
  gray: 'var(--purple)',
  red: 'var(--white)',
  'white-text': 'var(--purple)',
};

export const boxShadowMap: Record<ButtonColor, string | undefined> = {
  white: '0 0 30px 0 rgba(255, 255, 255, 0.5)',
  purple: undefined,
  'purple-opacity': '0 0 15px 0 rgba(255, 51, 102, 0.2)',
  'purple-gradient': '0 0 30px 0 rgba(175, 0, 255, 0.5)',
  gray: undefined,
  red: '0 0 15px 0 rgba(255, 51, 102, 0.5)',
  'white-text': undefined,
};

export const borderColorMap: Record<ButtonColor, string> = {
  white: 'var(--white)',
  purple: 'rgba(139, 51, 255, 0.2)',
  'purple-opacity': 'rgba(255, 255, 255, 0.15)',
  'purple-gradient': 'var(--purple)',
  gray: '#f7f6ff',
  red: 'var(--red)',
  'white-text': 'var(--white)',
};

export const borderedDisabledStylesMap: Record<ButtonColor, CSSObject> = {
  white: {},
  purple: {
    color: 'var(--purple)',
    backgroundColor: 'var(--gray7)',
    borderColor: 'var(--gray7)',
    opacity: 1,
  },
  'purple-opacity': {},
  'purple-gradient': {},
  gray: {},
  red: {},
  'white-text': {},
};

export const sizeStylesMap: Record<ButtonSize, CSSObject> = {
  small: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 17,
  },
  medium: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 28,
  },
  big: {
    paddingTop: 22,
    paddingBottom: 22,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 33,
  },
};
