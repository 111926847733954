import * as React from 'react';

function SvgChatStatusErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
      <g fill="#FC0" fillRule="nonzero">
        <path d="M6 0C2.684 0 0 2.684 0 6s2.684 6 6 6 6-2.684 6-6-2.684-6-6-6zm0 11.063A5.061 5.061 0 01.937 6 5.062 5.062 0 016 .937 5.06 5.06 0 0111.063 6 5.06 5.06 0 016 11.063z" />
        <path d="M8.053 7.39L6.663 6l1.39-1.39a.469.469 0 00-.663-.663L6 5.337l-1.39-1.39a.469.469 0 00-.663.663L5.337 6l-1.39 1.39a.469.469 0 00.663.663L6 6.663l1.39 1.39a.469.469 0 00.663-.663z" />
      </g>
    </svg>
  );
}

export default SvgChatStatusErrorIcon;
