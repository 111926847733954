import SvgCheckPurple from 'app/assets/svg/CheckPurple';
import React, { FC } from 'react';
import styled from 'styled-components';

type StyledCheckboxProps = {
  checked: boolean;
  onChange: (nextValue: boolean) => void;
  children?: React.ReactNode;
};

export const StyledCheckbox: FC<StyledCheckboxProps> = ({ checked, onChange, children }) => {
  const onInputChange = () => {
    onChange(!checked);
  };

  return (
    <Container>
      <StyledInput type="checkbox" checked={checked} onChange={onInputChange} />
      <StyledBox $checked={checked}>
        {checked && (
          <CheckImgContainer>
            <SvgCheckPurple width={11} height={11} />
          </CheckImgContainer>
        )}
      </StyledBox>
      <StyledText>{children}</StyledText>
    </Container>
  );
};

const Container = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledBox = styled.div<{ $checked: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: ${({ $checked }) => ($checked ? '2px solid var(--purple)' : '1px solid var(--gray3)')};
  background-color: ${({ $checked }) => ($checked ? 'var(--white)' : 'var(--white)')};
`;

const CheckImgContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  top: -2px;
  left: -1.5px;
`;

const StyledText = styled.div`
  flex: 1;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
  color: 'var(--text)';
`;
