import * as React from 'react';

function SvgWarningGrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
      <path
        d="M9 0a9 9 0 10.001 18.001A9 9 0 009 0zm0 14.143a1.286 1.286 0 110-2.573 1.286 1.286 0 010 2.573zm1.286-4.5H7.714L7.071 4.5c0-.355.288-.643.643-.643h2.572c.355 0 .643.288.643.643l-.643 5.143z"
        fill={props.fill || '#79818C'}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgWarningGrayIcon;
