import * as React from 'react';

function SvgHeartWhiteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.484.174a4.53 4.53 0 00-2.829.978c-.804.622-1.34 1.414-1.655 1.99-.315-.576-.85-1.368-1.655-1.99A4.53 4.53 0 005.516.174C2.62.174.435 2.544.435 5.686c0 3.394 2.725 5.717 6.851 9.233.7.597 1.495 1.274 2.32 1.996a.597.597 0 00.788 0c.825-.722 1.62-1.399 2.32-1.996 4.126-3.516 6.851-5.839 6.851-9.233 0-3.143-2.184-5.512-5.081-5.512z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgHeartWhiteIcon;
