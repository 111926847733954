import React, { ForwardedRef,forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { FieldWrapper } from '../field-wrapper';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper';

type InputProps = InputHTMLAttributes<HTMLInputElement> & FieldWrapperProps;

export const Input = forwardRef(
  (
    { label, error, successMessage, width, centered, required, onlyNumbers, type = 'text', ...props }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => (
    <FieldWrapper
      label={label}
      error={error}
      successMessage={successMessage}
      width={width}
      centered={centered}
      required={required}
    >
      <StyledInput
        ref={ref}
        type={onlyNumbers ? 'number' : type}
        $centered={centered}
        onKeyDown={(e) => {
          if (onlyNumbers) ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
        }}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        {...props}
      />
    </FieldWrapper>
  ),
);

const StyledInput = styled.input<{ $centered?: boolean }>`
  width: 100%;
  height: 50px;
  outline: none;
  border: 1px solid var(--gray3);
  border-radius: 12px;
  background-color: var(--white);
  padding: 0 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.67;
  text-align: ${({ $centered }) => ($centered ? 'center' : undefined)};
  color: var(--text);
  text-overflow: ellipsis;

  &::placeholder {
    color: (--gray);
  }

  &:disabled {
    background-color: #f9f9f9;
  }
`;
