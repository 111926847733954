import { AxiosError } from 'axios';

type RequestError<T = { message: string; statusCode: number; error: string }> = AxiosError<T>;

export const handleAsyncError = (err: RequestError): never => {
  if (err.response) {
    const errorData = err.response.data;
    const errorText = Array.isArray(errorData.message)
      ? errorData.message.join(', ')
      : errorData.message || errorData.error;
    throw new Error(errorText);
  }
  if (err.message) {
    const errorText = Array.isArray(err.message) ? err.message.join(', ') : err.message;
    throw new Error(errorText);
  }
  throw err;
};
