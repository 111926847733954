import * as React from 'react';

function SvgMoreMenuButton(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} {...props}>
      <g transform="rotate(90 6.734 21.666)" fill="#8B33FF" fillRule="nonzero">
        <circle cx={2.5} cy={3.1} r={2.5} />
        <circle cx={2.5} cy={11.1} r={2.5} />
        <circle cx={2.5} cy={19.1} r={2.5} />
      </g>
    </svg>
  );
}

export default SvgMoreMenuButton;
