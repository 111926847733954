import * as React from 'react';

function SvgUnLikeSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
      <g fill="#F36" fillRule="nonzero">
        <path d="M3.867 1H2.695A1.76 1.76 0 00.938 2.758v5.86a1.76 1.76 0 001.757 1.757h1.172a1.76 1.76 0 001.758-1.758v-5.86A1.76 1.76 0 003.867 1zM2.695 9.203a.586.586 0 01-.586-.586v-5.86c0-.323.263-.585.586-.585h1.172c.324 0 .586.262.586.586v5.86a.585.585 0 01-.586.585H2.695z" />
        <path d="M11.484 1h-.89c-3.165 0-5.022.79-5.846 1.26a.588.588 0 00-.218.8c.16.283.517.38.8.218.582-.333 2.261-1.106 5.264-1.106h.89c.824 0 1.568.608 1.695 1.384a.47.47 0 00.012.054c.07.342.622 3.627.637 3.951 0 .9-.742 1.642-1.652 1.642H8.953a.587.587 0 00-.487.913c.002.004.452.709.452 1.864 0 1.17-.47 1.594-.785 1.594-.11-.015-.235-.181-.235-1.054 0-1.816-1.807-3.141-2.584-3.626a.585.585 0 10-.621.994c.477.297 2.034 1.368 2.034 2.632 0 .723 0 2.226 1.406 2.226.942 0 1.957-.865 1.957-2.766 0-.648-.111-1.191-.243-1.605h2.329A2.827 2.827 0 0015 7.551c0-.038-.145-2.978-.715-4.176l.052-.008C14.117 2.017 12.89 1 11.484 1z" />
      </g>
    </svg>
  );
}

export default SvgUnLikeSmallIcon;
