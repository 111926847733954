import * as React from 'react';

function SvgMicrophoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={15} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#microphoneIcon_svg__clip0)">
        <path
          d="M8.097 17.089v4.317a.596.596 0 01-1.194 0v-4.317a7.498 7.498 0 01-4.699-2.17A7.424 7.424 0 010 9.645a.596.596 0 011.194 0c0 1.726.71 3.296 1.853 4.434A6.3 6.3 0 007.5 15.925a6.3 6.3 0 004.453-1.846 6.249 6.249 0 001.853-4.434.596.596 0 011.194 0 7.431 7.431 0 01-2.202 5.276 7.505 7.505 0 01-4.701 2.168zM7.54 0c1.146 0 2.19.467 2.944 1.22a4.132 4.132 0 011.224 2.932v5.37c0 1.143-.469 2.182-1.224 2.934a4.166 4.166 0 01-5.89 0 4.132 4.132 0 01-1.223-2.933V4.152c0-1.142.469-2.18 1.224-2.933A4.166 4.166 0 017.54 0zm2.1 2.06a2.972 2.972 0 00-5.074 2.092v5.37c0 .816.335 1.555.874 2.092a2.972 2.972 0 005.073-2.091V4.152a2.937 2.937 0 00-.874-2.091z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="microphoneIcon_svg__clip0">
          <path fill="#fff" d="M0 0h15v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgMicrophoneIcon;
