import * as React from 'react';

function ArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m11.667 15.833-5-5c-.642-.641-.642-1.025 0-1.666l5-5"
        stroke="#1C212D"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
}

export default ArrowLeft;
