import React, { FC, ImgHTMLAttributes } from 'react';

interface ImageWithRetinaProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
}

const ImageWithRetina: FC<ImageWithRetinaProps> = ({ src, alt, ...props }) => (
  // const fullPath = src.split('.');
  // const fileExt = fullPath.pop();
  // const filePathWithName = fullPath.join('.');

  <img {...props} src={src} alt={alt} />
);
export { ImageWithRetina };
