import * as React from 'react';

function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={23} height={20} {...props}>
    //   <defs>
    //     <path
    //       id="camera_svg__a"
    //       d="M14.933 2.866l1.708 1.9h2.958c.978 0 1.786.776 1.861 1.753l.006.148V18.07c0 .995-.762 1.818-1.722 1.895l-.145.005H4.666c-.977 0-1.786-.775-1.86-1.752L2.8 18.07V8.567h2.8v-2.85h2.8V2.865h6.533zm-2.8 4.75c-2.576 0-4.667 2.13-4.667 4.752 0 2.623 2.09 4.752 4.667 4.752 2.576 0 4.666-2.129 4.666-4.752 0-2.622-2.09-4.751-4.666-4.751zm0 1.711c1.652 0 2.986 1.36 2.986 3.041 0 1.682-1.334 3.041-2.986 3.041-1.652 0-2.987-1.359-2.987-3.04l.005-.18c.091-1.598 1.389-2.862 2.982-2.862zM4.666.015v2.85h2.8v1.901h-2.8v2.85H2.8v-2.85H0v-1.9h2.8V.015h1.866z"
    //     />
    //   </defs>
    //   <g fill="none" fillRule="evenodd">
    //     <mask id="camera_svg__b" fill="#fff">
    //       <use xlinkHref="#camera_svg__a" />
    //     </mask>
    //     <use fill="#7033FF" fillRule="nonzero" xlinkHref="#camera_svg__a" />
    //     <path fill="#FFF" d="M0 0h22v20H0z" mask="url(#camera_svg__b)" />
    //   </g>
    // </svg>
    <svg width="18" height="15" viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.265 2.5h2.833c.372 0 .507.038.642.112.136.072.243.179.315.315.073.135.112.27.112.641v10.364c0 .371-.039.506-.112.641a.757.757 0 0 1-.315.315c-.135.074-.27.112-.642.112H1.568c-.371 0-.506-.038-.641-.112a.757.757 0 0 1-.315-.315c-.074-.135-.112-.27-.112-.641V3.568c0-.371.038-.506.112-.641a.758.758 0 0 1 .315-.315c.135-.074.27-.112.641-.112h2.834L5.137.54a.833.833 0 0 1 .78-.54h5.833c.347 0 .658.216.78.54l.735 1.96zm-4.432 10a4.167 4.167 0 1 1 0-8.333 4.167 4.167 0 0 1 0 8.333zm2.5-4.167a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCamera;
