import React, { FC } from 'react';
import styled from 'styled-components';

type BadgeProps = {
  label: string;
  className?: string;
};

export const Badge: FC<BadgeProps> = ({ label, className }) => <Container className={className}>{label}</Container>;

const Container = styled.div`
  display: inline-block;
  padding: 0 5px;
  border: 1px solid var(--purple);
  border-radius: 1px;
  color: var(--purple);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;
