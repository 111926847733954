import * as React from 'react';

function ReceiptIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={15} height={15} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 0h12.5v1.25h-1.25V15H10l-1.875-1.25L6.25 15l-1.875-1.25L2.5 15H1.25V1.25H0V0zm2.5 1.25v12.352l1.875-1.25 1.875 1.25 1.875-1.25L10 13.602V1.25H2.5zm1.25 2.5h5V5h-5V3.75zm0 2.5h5V7.5h-5V6.25zm0 2.5h5V10h-5V8.75z"
        fill="#8B33FF"
      />
    </svg>
  );
}

export default ReceiptIcon;
