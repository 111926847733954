import * as React from 'react';

function SvgCloseNote(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="m8.855 7.49 5.827-5.828A.968.968 0 1 0 13.312.29L7.482 6.118 1.656.29A.969.969 0 1 0 .284 1.662L6.112 7.49.284 13.317a.968.968 0 1 0 1.372 1.372L7.483 8.86l5.828 5.828a.967.967 0 0 0 1.371 0 .968.968 0 0 0 0-1.372L8.855 7.49z"
          id="hcee0eospa"
        />
      </defs>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <mask id="lmoso4dvtb" fill="#fff">
          <use xlinkHref="#hcee0eospa" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#hcee0eospa" />
        <path fill="#8B33FF" mask="url(#lmoso4dvtb)" d="M0 0h15v15H0z" />
      </g>
    </svg>
  );
}

export default SvgCloseNote;
