// api
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const CLIENT_ID = process.env.ZOOM_CLIENT_ID ?? '2sJREWQYSGSMe1Sm6d9Ulw';
export const REDIRECT_URI =
  process.env.ZOOM_REDIRECT_URI ?? 'https://consulty.online/app/profile?activeTab%3DexpertProfile';
export const ZOOM_URL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
export const ENVIRONMENT_TYPE = process.env.NEXT_PUBLIC_ENVIRONMENT as 'development' | 'staging' | 'production';
// export const API_BASE_URL = 'http://193.32.219.9';
// export const API_BASE_URL = 'http://localhost:5000';

// breakpoints
export const MOBILE = 320;
export const SCROLLABLE_DESKTOP = 600;
export const DESKTOP = 1180;

export const CONDITION_DESKTOP = `@media(min-width: ${SCROLLABLE_DESKTOP}px)`;

export const CONDITION_MOBILE = `@media(max-width: ${SCROLLABLE_DESKTOP}px)`;

// header
export const HEADER_HEIGHT_DESKTOP = '80px';
export const HEADER_HEIGHT_MOBILE = '60px';

// pusher
export const pusherEvents = {
  chatRoom: {
    created: 'CHAT_CREATED',
    companionWritingStart: 'client-COMPANION_WRITING_START',
    companionWritingEnd: 'client-COMPANION_WRITING_END',
  },
  messages: {
    received: 'MESSAGE_RECEIVED',
  },
  consultationOffers: {
    created: 'CONSULTATION_OFFER_CREATED',
  },
  consultationRequests: {
    created: 'CONSULTATION_REQUEST_CREATED',
  },
  consultation: {
    starts: 'CONSULTATION_STARTS',
    pendingStart: 'CONSULTATION_PENDING_START',
    timeExceeded: 'CONSULTATION_TIME_EXCEEDED',
    messagesLimitExceeded: 'CONSULTATION_MESSAGES_LIMIT_EXCEEDED',
    endedByClient: 'CONSULTATION_ENDED_BY_CLIENT',
    endedByExpert: 'CONSULTATION_ENDED_BY_EXPERT',
    prolongationRequest: 'CONSULTATION_PROLONGATION_REQUEST',
    prolonged: 'CONSULTATION_PROLONGED',
    prolongationRequestDeclined: 'CONSULTATION_PROLONGATION_REQUEST_DECLINED',
    paymentExpired: 'CONSULTATION_PAYMENT_EXPIRED',
  },
  chatConsultationOffer: {
    created: 'CREATED',
    rejected: 'REJECTED',
  },
  extraServices: {
    created: 'EXTRA_SERVICE_CREATED',
    paid: 'EXTRA_SERVICE_PAID',
    rejected: 'EXTRA_SERVICE_REJECTED',
  },
  blocking: {
    block: 'USER_BLOCKED',
  },
  zoom: {
    zoomConsultationPaid: 'ZOOM_CONSULTATION_PAID',
    zoomConsultationDeclined: 'ZOOM_CONSULTATION_DECLINED',
  },
};

// other
export const DEFAULT_AVATAR = '/img/default-avatar.png';
export const DEFAULT_CATEGORY = '/img/default-category.png';
export const DEFAULT_REQUEST_CHAT_TOAST_ICON = '/img/coin60@3x.png';
export const DEFAULT_RESPONSE_CHAT_TOAST_ICON = '/img/thumb-up60@3x.png';
export const DEFAULT_THINK_CHAT_TOAST_ICON = '/img/think-for-banner@3x.png';

export const BLOG_IMG_1 = '/img/bitmap@3x.png';
export const BLOG_IMG_2 = '/img/pic@3x.png';
export const BLOG_IMG_3 = '/img/pic-2@3x.png';
export const BLOG_IMG_4 = '/img/pic@3x2new.png';

export const COOKIE_KEY = {
  accessToken: 'token',
  oneStepConsultationModal: 'isOneStepModalShown',
  notCompletedProfileModal: 'isNotCompletedModalShown',
};

export const LIMIT_CHAT_MESSAGES = 35;
export const MAX_FILE_SIZE = 5242880;

export const LIMIT_CHANNEL_NEWS = 35;

export const SUPPORT_EMAIL = 'support@consulty.online';
export const SUPPORT_TELEGRAM = 'consultyonline';

export const EXPERT_PROFILE_LINK =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
    ? 'https://www.consulty.online/app/profile?activeTab=expertProfile'
    : 'https://consulty-staging.vercel.app/app/profile?activeTab=expertProfile';
export const EXPERT_PROFILE_TITLE = 'профиль эксперта';

export const FIND_EXPERT_LINK =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
    ? 'https://www.consulty.online/app/topics'
    : 'https://consulty-staging.vercel.app/app/topics';
export const FIND_EXPERT_TITLE = 'Найти эксперта';

export const APP_STORE_LINK = 'https://apps.apple.com/ru/app/consulty/id1590830075?l=en';
export const APP_STORE_TITLE = 'App Store';

export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.consulty';
export const GOOGLE_PLAY_TITLE = 'Google Play';

export const MAIN_AGREEMENT =
  'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/agreements-pdf/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80-%D0%BE%D1%84%D0%B5%D1%80%D1%82%D0%B0.pdf';
export const DOP_AGREEMENT =
  'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/agreements-pdf/%D0%94%D0%9E%D0%9F%D0%9E%D0%9B%D0%9D%D0%98%D0%A2%D0%95%D0%9B%D0%AC%D0%9D%D0%9E%D0%95%20%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A8%D0%95%D0%9D%D0%98%D0%95%20(%D0%AD%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82).pdf';
export const SAFE_DEAL =
  'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/agreements-pdf/%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0%20%D0%91%D0%A1.pdf';
export const POLICY =
  'https://storage.yandexcloud.net/consulty-stage/consulty-stage/public/agreements-pdf/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf';

export const AUDIO_MESSAGE_EXT = 'aac';

export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID!;

export const currencies = {
  ruble: '₽',
};

export const LEFT_MESSAGES_WARNING_COUNT = 5;
export const LEFT_MINUTES_WARNING_COUNT = 5;

export const OFERTA_LINK = '/oferta';
export const POLICY_LINK = '/policy';
export const POLICYWEB_LINK = '/policyweb';
export const TRADE_LINK = '/trade';

export const BLOG_LINK = '/blog';

export const AFTER_3_HOURS = 10800000;
export const AFTER_3_DAYS = 259200000;

export const XPlatformHeader = 'web';
