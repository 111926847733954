import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={15} height={15} viewBox="0 0 15 15" {...props}>
      <defs>
        <path
          id="cross_svg_1"
          d="M8.855 7.49l5.827-5.828a.968.968 0 000-1.372.967.967 0 00-1.37 0l-5.83 5.828L1.656.29A.97.97 0 10.284 1.662L6.112 7.49.284 13.317a.97.97 0 101.372 1.372L7.483 8.86l5.828 5.828a.97.97 0 101.371-1.372L8.855 7.49z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="cross_svg_2" fill="#fff">
          <use xlinkHref="#cross_svg_1" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#cross_svg_1" />
        <path fill="#93969D" d="M0 0h15v15H0z" mask="url(#cross_svg_2)" />
      </g>
    </svg>
  );
}

export default SvgComponent;
