import * as React from 'react';

function SvgUserGrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height={18} {...props}>
      <defs>
        <path
          id="userGrayIcon_svg__a"
          d="M.98 12.235c4.1-2.77 9.696-2.77 13.796 0 .608.411.972 1.105.972 1.857v3.897H.008v-3.897c0-.752.363-1.446.971-1.857zM11.057 1.317a4.498 4.498 0 11-6.361 6.36 4.498 4.498 0 016.361-6.36z"
        />
      </defs>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <mask id="userGrayIcon_svg__b" fill="#fff">
          <use xlinkHref="#userGrayIcon_svg__a" />
        </mask>
        <use fill="#79818C" fillRule="nonzero" xlinkHref="#userGrayIcon_svg__a" />
        <path fill="#79818C" fillOpacity={0.1} d="M0 0h18v18H0z" mask="url(#userGrayIcon_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgUserGrayIcon;
