import { RootState } from 'store';

export const selectChatRooms = (state: RootState) => state.chatRooms.chatRooms;
export const selectCurrentChatRoomId = (state: RootState) => state.chatRooms.currentChatRoomId;
export const selectChatRoomById = (id: number | null) => (state: RootState) =>
  state.chatRooms.chatRooms.find((room) => room.id === id);
export const selectCreatingChatRoom = (state: RootState) => state.chatRooms.creatingChatRoomStatus;
export const selectOnlineMembersIds = (state: RootState) => state.chatRooms.onlineMembersIds;
export const selectUnreadRoomsIds = (state: RootState) => state.chatRooms.unreadRoomIds;
export const selectConsultationStatusesByRoomId = (roomId: number) => (state: RootState) =>
  state.chatRooms.consultationsStatuses.find((statuses) => statuses.roomId === roomId);
