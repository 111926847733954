import * as React from 'react';

function SvgArrowBigClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M8.57 6.9 1.91.24a.813.813 0 0 0-.58-.24.813.813 0 0 0-.579.24L.26.73a.82.82 0 0 0 0 1.158l5.594 5.594-5.6 5.6a.814.814 0 0 0-.24.579c0 .22.086.425.24.58l.49.49c.154.154.36.239.58.239.219 0 .424-.085.578-.24L8.57 8.064a.814.814 0 0 0 .24-.58.814.814 0 0 0-.24-.582z"
          id="sl4zj2z7aa"
        />
      </defs>
      <g transform="translate(3)" fill="none" fillRule="evenodd">
        <mask id="8nxajkbu9b" fill="#fff">
          <use xlinkHref="#sl4zj2z7aa" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#sl4zj2z7aa" />
        <path fill="#8B33FF" mask="url(#8nxajkbu9b)" d="M-3 0h15v15H-3z" />
      </g>
    </svg>
  );
}

export default SvgArrowBigClose;
