/**
 * Множественные формы слов
 * @param  {Integer} count Количество
 * @param  {Array} words Массив слов. Пример: ['коментарий', 'коментария', 'комментариев']
 * @return {String} Множественная форма слова
 */
export const chooseEndingWord = (count: number, words: [string, string, string]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return `${words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]}`;
};
