import { ConsultationInvoice, InvoiceStatusEnum } from 'shared/api';

type AvailableFields = 'amount' | 'commission' | 'revert';

export const getConsultationFieldSum = (invoices: ConsultationInvoice[], target: AvailableFields) => {
  if (target === 'amount') {
    const sum = invoices.reduce((prev, item) => prev + (item.invoice.amount ? item.invoice.amount : 0), 0);
    return Number(sum.toFixed(2));
  }

  if (target === 'commission') {
    const sum = invoices.reduce((prev, item) => prev + (item.invoice.commission ? item.invoice.commission : 0), 0);
    return Number(sum.toFixed(2));
  }

  if (target === 'revert') {
    const reverted = invoices.filter((item) => item.invoice.status === InvoiceStatusEnum.Reversed);
    const sum = reverted.reduce((prev, item) => prev + (item.invoice.amount ? item.invoice.amount : 0), 0);
    return sum !== 0 ? Number(sum.toFixed(2)) : 0;
  }

  return 0;
};
