import * as React from 'react';

function SvgLogoMobile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 32 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#8B33FF"
          d="M10 0h12c5.523 0 10 4.477 10 10v20a2 2 0 01-2 2H10C4.477 32 0 27.523 0 22V10C0 4.477 4.477 0 10 0z"
        />
        <g stroke="#FFF" strokeWidth={1.5}>
          <path d="M16 6.4a9.6 9.6 0 019.6 9.6v7.19h0s0 0 0 0a2.41 2.41 0 01-2.41 2.41H16c-5.302 0-9.6-4.298-9.6-9.6s4.298-9.6 9.6-9.6z" />
          <path strokeLinecap="round" d="M12.4 18.4c1.09 1.333 2.474 2 4.153 2 1.68 0 3.095-.667 4.247-2" />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoMobile;
