import * as React from 'react';

function SvgChatSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={14} height={14} {...props}>
      <defs>
        <path
          id="chatSmallIcon_svg__a"
          d="M1.666.583C3.161.239 4.94.067 7 .067c2.064 0 3.84.172 5.328.518a1.999 1.999 0 011.543 1.813c.042.61.062 1.395.062 2.354 0 1.157-.03 2.1-.09 2.83a1.997 1.997 0 01-1.39 1.743c-1.854.587-3.02.939-3.497 1.055l-1.091 3.553H6.436L5.088 10.38c-.625-.147-1.81-.497-3.554-1.05a2 2 0 01-1.39-1.764 39.798 39.798 0 01-.078-2.814c0-.954.017-1.732.051-2.334A2.002 2.002 0 011.666.583z"
        />
      </defs>
      <use fill="#79818C" xlinkHref="#chatSmallIcon_svg__a" fillRule="evenodd" />
    </svg>
  );
}

export default SvgChatSmallIcon;
