import React, { FC } from 'react';
import styled from 'styled-components';

type SwitchButtonProps = {
  checked: boolean;
  onChange: (nextValue: boolean) => void;
};

export const SwitchButton: FC<SwitchButtonProps> = ({ checked, onChange }) => {
  const onInputChange = () => {
    onChange(!checked);
  };

  return (
    <Container onClick={onInputChange} $checked={checked}>
      <Circle />
    </Container>
  );
};

const Container = styled.div<{ $checked: boolean }>`
  width: 36px;
  height: 20px;
  padding: 2px;
  padding-left: ${({ $checked }) => ($checked ? '18px' : '2px')};
  border-radius: 12px;
  background-color: ${({ $checked }) => ($checked ? 'var(--purple)' : 'var(--gray3)')};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: padding 0.2s;
  flex-shrink: 0;
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--white);
  position: relative;
`;
