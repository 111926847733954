import * as React from 'react';

function SvgSuperLargeAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M6.214 14.651c.84 0 1.492-.232 1.957-.697.466-.466.698-1.007.698-1.625 0-.45-.121-.816-.364-1.098a1.668 1.668 0 00-.851-.549l1.584-1.593.189-.981H5.368l-.261 1.35h1.467c.27 0 .477-.015.621-.045v.018l-.09.072a4.99 4.99 0 00-.594.531l-.963 1.017.18.765h.531c.684 0 1.026.195 1.026.585 0 .216-.096.398-.288.544-.192.147-.465.221-.819.221-.246 0-.486-.042-.72-.126a1.37 1.37 0 01-.558-.351L4 13.805c.546.564 1.284.846 2.214.846zm5.269 0c.426 0 .813-.102 1.161-.306.348-.204.631-.463.85-.779.22-.315.404-.673.554-1.075.15-.402.255-.792.315-1.17.06-.378.09-.741.09-1.089C14.453 8.744 13.742 8 12.32 8a2.333 2.333 0 00-1.764.778c-.21.237-.394.506-.553.806-.16.3-.284.618-.374.954-.09.336-.157.659-.202.967-.045.31-.068.608-.068.896 0 1.5.708 2.25 2.124 2.25zm.054-1.44c-.366 0-.549-.294-.549-.882 0-.258.027-.546.081-.864.054-.318.13-.631.23-.94.099-.31.234-.568.405-.774.17-.207.358-.311.562-.311.366 0 .549.294.549.882 0 .264-.027.555-.081.873-.054.318-.13.63-.23.936a2.294 2.294 0 01-.404.77c-.171.207-.359.31-.563.31zm5.224 1.44c.426 0 .813-.102 1.161-.306.348-.204.631-.463.85-.779.22-.315.404-.673.554-1.075.15-.402.255-.792.315-1.17.06-.378.09-.741.09-1.089C19.731 8.744 19.02 8 17.598 8a2.325 2.325 0 00-1.764.778c-.21.237-.394.506-.553.806-.16.3-.284.618-.374.954a8.5 8.5 0 00-.203.967 6.23 6.23 0 00-.067.896c0 1.5.708 2.25 2.124 2.25zm.054-1.44c-.366 0-.549-.294-.549-.882 0-.258.027-.546.081-.864.054-.318.13-.631.23-.94.098-.31.233-.568.404-.774.171-.207.359-.311.563-.311.366 0 .549.294.549.882 0 .264-.027.555-.081.873-.054.318-.13.63-.23.936a2.312 2.312 0 01-.404.77c-.172.207-.359.31-.563.31z"
        />
      </g>
    </svg>
  );
}

export default SvgSuperLargeAchivment;
