import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message } from 'shared/api';
import { GetMessagesAxiosResponse, LoadingStatus, MessageExtended } from 'shared/common/types';

import {
  addChatMessage,
  clearChatMessages,
  fetchMoreChatMessages,
  getChatMessages,
  receiveChatMessage,
  resetState,
  sendChatMessage,
} from './actions';

const initialState = {
  chatMessages: [],
  page: 0,
  pageCount: null,
  fetchingChatMessagesStatus: 'idle',
  fetchingMoreChatMessagesStatus: 'idle',
  isShouldFetchChatMessagesAgain: false,
  sendingChatMessagesStatus: 'idle',
} as {
  chatMessages: MessageExtended[];
  page: number;
  pageCount: number | null;
  fetchingChatMessagesStatus: LoadingStatus;
  fetchingMoreChatMessagesStatus: LoadingStatus;
  isShouldFetchChatMessagesAgain: boolean;
  sendingChatMessagesStatus: LoadingStatus;
};

const chatMessagesSlice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    [addChatMessage.type]: (state, { payload }: PayloadAction<MessageExtended>) => {
      state.chatMessages = [payload, ...state.chatMessages];
    },
    [clearChatMessages.type]: (state) => {
      state.chatMessages = [];
    },
    [sendChatMessage.pending.type]: (state) => {
      state.sendingChatMessagesStatus = 'pending';
    },
    [sendChatMessage.fulfilled.type]: (state) => {
      state.sendingChatMessagesStatus = 'fulfilled';
    },
    [sendChatMessage.rejected.type]: (
      state,
      { payload }: PayloadAction<{ content: string; attachments?: string[] }>,
    ) => {
      state.sendingChatMessagesStatus = 'rejected';
      const messageIndex = state.chatMessages.findIndex(
        (m) =>
          !m.id &&
          m.content === payload.content &&
          (m.attachments && payload.attachments ? m.attachments.includes(payload.attachments[0]) : true),
      );
      if (messageIndex !== -1) {
        state.chatMessages[messageIndex].status = 'error';
      }
    },
    [receiveChatMessage.type]: (state, { payload }: PayloadAction<Message>) => {
      const messageIndex = state.chatMessages.findIndex(
        (m) =>
          !m.id &&
          m.content === payload.content &&
          (m.attachments && payload.attachments ? m.attachments.includes(payload.attachments[0]) : true),
      );
      if (messageIndex !== -1) {
        state.chatMessages[messageIndex] = { ...payload, status: 'sent' };
      } else {
        state.chatMessages = [payload, ...state.chatMessages];
      }
    },
    [getChatMessages.pending.type]: (state) => {
      state.fetchingChatMessagesStatus = 'pending';
      state.page = 0;
      state.pageCount = null;
    },
    [getChatMessages.fulfilled.type]: (state, { payload }: PayloadAction<GetMessagesAxiosResponse>) => {
      state.fetchingChatMessagesStatus = 'fulfilled';
      state.chatMessages = state.chatMessages ? [...state.chatMessages, ...payload.data] : payload.data;
      state.page = payload.page;
      state.pageCount = payload.pageCount;
    },
    [getChatMessages.rejected.type]: (state) => {
      state.fetchingChatMessagesStatus = 'rejected';
    },
    [fetchMoreChatMessages.pending.type]: (state) => {
      state.fetchingMoreChatMessagesStatus = 'pending';
      state.isShouldFetchChatMessagesAgain = false;
    },
    [fetchMoreChatMessages.fulfilled.type]: (state, { payload }: PayloadAction<GetMessagesAxiosResponse>) => {
      state.fetchingMoreChatMessagesStatus = 'fulfilled';
      state.page = payload.page;
      state.pageCount = payload.pageCount;

      const chatMessagesIds = state.chatMessages.map((message) => message.id);
      const uniqMessages = payload.data.filter((message) => !chatMessagesIds.includes(message.id));

      state.chatMessages = [...state.chatMessages, ...uniqMessages];

      if (!uniqMessages.length) {
        state.isShouldFetchChatMessagesAgain = true;
      }
    },
    [fetchMoreChatMessages.rejected.type]: (state) => {
      state.fetchingMoreChatMessagesStatus = 'rejected';
    },
    [resetState.type]: () => initialState,
  },
});

export const { reducer } = chatMessagesSlice;
export const actions = {
  addChatMessage,
  sendChatMessage,
  getChatMessages,
  clearChatMessages,
  receiveChatMessage,
  fetchMoreChatMessages,
  resetState,
};
