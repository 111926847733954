import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  currentAudioId: null,
} as {
  currentAudioId: number | string | null;
};

const audioMessagesSlice = createSlice({
  name: 'audioMessages',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setCurrentAudioId(state, { payload }: PayloadAction<{ messageId: number | string }>) {
      state.currentAudioId = payload.messageId;
    },
    clearAudio(state) {
      state.currentAudioId = null;
    },
  },
});

export const { reducer } = audioMessagesSlice;
export const actions = { ...audioMessagesSlice.actions };
