import { IncomingMessage } from 'http';
import cookie from 'js-cookie';

// resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

export const setCookie = (key: string, value: string) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 7,
      path: '/',
    });
  }
};

export const setModalShowCookie = (key: string, value: string) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
      path: '/',
    });
  }
};

export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 7,
    });
  }
};

export const getCookie = (key: string, req?: IncomingMessage) => !req ? getCookieFromBrowser(key) : getCookieFromServer(key, req);

const getCookieFromBrowser = (key: string) => cookie.get(key);

const getCookieFromServer = (key: string, req: IncomingMessage) => {
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie.split(';').find((c: any) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};
