import * as React from 'react';

function Schedule(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.75 2.8125C15.75 1.2592 14.4908 0 12.9375 0H2.8125C1.2592 0 0 1.2592 0 2.8125V12.9375C0 14.4908 1.2592 15.75 2.8125 15.75H7.42467C7.24249 15.3936 7.09384 15.0172 6.98291 14.625H2.8125C1.88052 14.625 1.125 13.8694 1.125 12.9375V4.5H14.625V6.98291C15.0172 7.09384 15.3936 7.24249 15.75 7.42467V2.8125ZM2.8125 1.125H12.9375C13.8694 1.125 14.625 1.88052 14.625 2.8125V3.375H1.125V2.8125C1.125 1.88052 1.88052 1.125 2.8125 1.125Z"
        fill="#8B33FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9375 18C15.7335 18 18 15.7335 18 12.9375C18 10.1415 15.7335 7.875 12.9375 7.875C10.1415 7.875 7.875 10.1415 7.875 12.9375C7.875 15.7335 10.1415 18 12.9375 18ZM12.9375 10.125C13.2481 10.125 13.5 10.3769 13.5 10.6875V12.375H15.1875C15.4981 12.375 15.75 12.6269 15.75 12.9375C15.75 13.2481 15.4981 13.5 15.1875 13.5H13.5V15.1875C13.5 15.4981 13.2481 15.75 12.9375 15.75C12.6269 15.75 12.375 15.4981 12.375 15.1875V13.5H10.6875C10.3769 13.5 10.125 13.2481 10.125 12.9375C10.125 12.6269 10.3769 12.375 10.6875 12.375H12.375V10.6875C12.375 10.3769 12.6269 10.125 12.9375 10.125Z"
        fill="#8B33FF"
      />
    </svg>
  );
}

export default Schedule;
