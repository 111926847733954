import axios from 'axios';
import { API_BASE_URL } from 'shared/common/constants';
import { actions, selectors } from 'store/ducks';

export const httpClient = axios.create({
  baseURL: API_BASE_URL,
});

export const AxiosInterceptors = {
  setup: (store: any) => {
    httpClient.interceptors.request.use(
      (config) => {
        const state = store?.getState();
        const accessToken = state ? selectors.profile.selectToken(state) : null;

        if (!accessToken) {
          return config;
        }

        const headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };

        return { ...config, headers };
      },
      (error) => Promise.reject(error),
    );

    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            if (store) {
              store.dispatch(actions.profile.signOut());
            }
          }
          throw error.response.data;
        }

        if (error.data) {
          throw error.data;
        }

        throw error;
      },
    );
  },
};
