// import { ChangeEvent } from 'hoist-non-react-statics/node_modules/@types/react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import React, { ForwardedRef, forwardRef, InputHTMLAttributes, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { FieldWrapper } from '../field-wrapper';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper';

type InputPhoneProps = FieldWrapperProps & InputHTMLAttributes<HTMLInputElement>;

enum CountryCodeName {
  RU = 'RU',
  KZ = 'KZ',
  UA = 'UA',
  BY = 'BY',
}

type CodeMappingValues = {
  codeName: string;
  codeText: string;
};

const CountryCodeValuesMapping: Record<CountryCodeName, CodeMappingValues> = {
  [CountryCodeName.RU]: { codeName: '+7', codeText: 'Россия (+7)' },
  [CountryCodeName.KZ]: { codeName: '+7', codeText: 'Казахстан (+7)' },
  [CountryCodeName.UA]: { codeName: '+380', codeText: 'Украина (+380)' },
  [CountryCodeName.BY]: { codeName: '+375', codeText: 'Беларусь (+375)' },
};

const codeNames = Object.keys(CountryCodeName) as Array<keyof typeof CountryCodeName>;

const getTextWidth = (text: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = getComputedStyle(document.body).font;
  return context.measureText(text).width;
};

export const InputPhoneWithCode = forwardRef(
  (
    {
      label,
      error,
      successMessage,
      width,
      centered,
      required,
      onChange,
      disabled = false,
      name,
      value,
    }: InputPhoneProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [isShow, setIsShow] = useState(false);
    const [currentCode, setCurrentCode] = useState<CountryCodeName>(CountryCodeName.RU);

    const codeLength = Math.ceil(getTextWidth(CountryCodeValuesMapping[currentCode].codeName));

    const codePickerRef = useRef<HTMLDivElement>(null);

    useOutsideClick({ ref: codePickerRef, onOutsideClick: () => setIsShow(false) });

    return (
      <FieldWrapper
        label={label}
        error={error}
        successMessage={successMessage}
        width={width}
        centered={centered}
        required={required}
      >
        <Container>
          <StyledFieldWrapper>
            <PhoneCodeContainer
              isDisabled={disabled}
              onClick={() => {
                setIsShow(true);
              }}
            >
              <PhoneCodeWrapper>{CountryCodeValuesMapping[currentCode].codeName}</PhoneCodeWrapper>
              <IconArrowDownWrapper>⌵</IconArrowDownWrapper>
            </PhoneCodeContainer>
            <StyledPhone
              placeholder={`${CountryCodeValuesMapping[currentCode].codeName} 999 991-23-45`}
              style={{ textIndent: -codeLength }}
              mask={`${CountryCodeValuesMapping[currentCode].codeName}\\   999 999-99-99`}
              alwaysShowMask={false}
              maskChar={null}
              name={name}
              value={value}
              inputRef={ref}
              disabled={disabled}
              onChange={(e: any) => {
                e.persist();
                e.target.value = e.target.value.replace(/[^\d]/g, '');
                onChange && onChange(e);
              }}
            />
          </StyledFieldWrapper>
          {isShow && !disabled && (
            <CountryCodePickerContainer ref={codePickerRef}>
              {codeNames.map((code, index) => (
                <CodeItem
                  key={index}
                  onClick={() => {
                    setIsShow(false);
                    setCurrentCode(code as CountryCodeName);
                  }}
                >
                  <CodeItemIcon>
                    <CodeItemIconWrapper>{currentCode === code && <span>✓</span>}</CodeItemIconWrapper>
                  </CodeItemIcon>
                  <CodeItemText>{CountryCodeValuesMapping[code].codeText}</CodeItemText>
                </CodeItem>
              ))}
            </CountryCodePickerContainer>
          )}
        </Container>
      </FieldWrapper>
    );
  },
);

const StyledPhone = styled(InputMask)`
  outline: none;
  border: none;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.67;
  position: relative;
  width: 100%;
  /* opacity: 0.5; */
  color: var(--text);

  &:disabled {
    opacity: 0.5;
    background-color: #f9f9f9;

    &:hover {
      opacity: 0.5;
    }
  }

  &:focus,
  &:hover {
    opacity: 1;
  }

  &::placeholder {
    opacity: 0.5;
  }
`;

const PhoneCodeContainer = styled.div<{ isDisabled: boolean }>`
  padding: 12px 20px;
  z-index: 1;
  overflow: hidden;

  border-right: 1px solid var(--gray3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  pointer-events: ${(props) => (props.isDisabled ? 'none' : '')};
  background-color: ${(props) => (props.isDisabled ? '#f9f9f9' : 'var(--white)')};

  color: var(--gray3);

  &:hover {
    color: var(--text);
    cursor: pointer;
  }
`;

const PhoneCodeWrapper = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  margin-right: 8px;
  width: 100%;
`;

const IconArrowDownWrapper = styled.div`
  font-weight: bold;
  font-size: 20px;
  max-height: 25px;
  margin-top: -7px;
`;

const CountryCodePickerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f0f1f0;
  z-index: 999;
  border-radius: 8px;
  border: 1px solid var(--gray3);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`;

const CodeItem = styled.div`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;

  &:last-of-type {
    padding-bottom: 3px;
  }

  &:first-of-type {
    padding-top: 3px;
  }

  &:hover {
    background-color: #2979ff;
    color: white;
    cursor: pointer;
  }
`;

const CodeItemIcon = styled.div`
  width: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CodeItemIconWrapper = styled.div``;

const CodeItemText = styled.div`
  padding-left: 8px;
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--gray3);
  border-radius: 12px;
  background-color: var(--white);
  outline: none;
  overflow: hidden;
  position: relative;
  max-height: 50px;
`;

const Container = styled.div`
  position: relative;
  max-height: 50px;
`;
