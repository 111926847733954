import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { User } from '@sentry/nextjs';
import { About, ConfirmVerificationEmailDto, PresignUrlDto } from 'shared/api';
import { ExtraParamsThunkType } from 'shared/common/types';
import { captureError } from 'shared/helpers/captureError';

export const signFileUrlAsync = createAsyncThunk<string, PresignUrlDto, ExtraParamsThunkType<SerializedError>>(
  'app/sign-file-url',
  async (dto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1StorageApi.storageControllerSignFileUrl(dto);
      return data;
    } catch (e: any) {
      captureError(e);
      return rejectWithValue(e);
    }
  },
);

export const checkEmailIsUniqAsync = createAsyncThunk<boolean, string, ExtraParamsThunkType<SerializedError>>(
  'app/check-email-is-uniq',
  async (email, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerEmailIsUniq(email);
      return data;
    } catch (e: any) {
      captureError(e);
      return rejectWithValue(e);
    }
  },
);

export const confirmVerificationEmailAsync = createAsyncThunk<
  User,
  ConfirmVerificationEmailDto,
  ExtraParamsThunkType<SerializedError>
>('app/confirm-verification-email', async (dto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1UsersApi.usersControllerConfirmVerificationEmail(dto);
    return data;
  } catch (e: any) {
    captureError(e);
    return rejectWithValue(e);
  }
});

export const getInfoAboutServiceAsync = createAsyncThunk<About, undefined, ExtraParamsThunkType<SerializedError>>(
  'app/get-info-about-service',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1AboutServiceApi.aboutControllerGetOne();
      return data;
    } catch (e: any) {
      captureError(e);
      return rejectWithValue(e);
    }
  },
);
