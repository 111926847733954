import * as React from 'react';

function SvgWallet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={16} height={15} {...props}>
      <defs>
        <path
          id="Wallet_svg__a"
          d="M12.6 4.2h-2.1c-1.158 0-2.1.942-2.1 2.1 0 1.158.942 2.1 2.1 2.1h2.1v2.1a.7.7 0 01-.7.7H2.1a.7.7 0 01-.7-.7V2.1a.7.7 0 01.7-.7h9.8a.7.7 0 01.7.7v2.1zm0 2.8h-2.1a.7.7 0 110-1.4h2.1V7zm-.7-7H2.1C.942 0 0 .942 0 2.1v8.4c0 1.158.942 2.1 2.1 2.1h9.8c1.158 0 2.1-.942 2.1-2.1V2.1C14 .942 13.058 0 11.9 0z"
        />
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <mask id="Wallet_svg__b" fill="#fff">
          <use xlinkHref="#Wallet_svg__a" />
        </mask>
        <use fill="#232832" fillRule="nonzero" xlinkHref="#Wallet_svg__a" />
        <path fill="#8B33FF" d="M0-.933h14v14H0z" mask="url(#Wallet_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgWallet;
