import * as React from 'react';

function SvgUserMenuBurger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={14} height={10} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <defs>
        <path
          d="M13.417 7.983c.322 0 .583.234.583.521 0 .288-.261.521-.583.521H.583c-.322 0-.583-.233-.583-.52 0-.288.261-.522.583-.522h12.834zm0-3.991c.322 0 .583.233.583.52 0 .288-.261.521-.583.521H.583C.261 5.033 0 4.8 0 4.513c0-.288.261-.521.583-.521h12.834zm0-3.992c.322 0 .583.233.583.52 0 .288-.261.522-.583.522H.583C.261 1.042 0 .808 0 .52 0 .233.261 0 .583 0h12.834z"
          id="userMenuBurger_svg__a"
        />
      </defs>
      <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
        <mask id="userMenuBurger_svg__b" fill="#fff">
          <use xlinkHref="#userMenuBurger_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#userMenuBurger_svg__a" />
        <path fill="#232832" mask="url(#userMenuBurger_svg__b)" d="M0-.5h14v10H0z" />
      </g>
    </svg>
  );
}

export default SvgUserMenuBurger;
