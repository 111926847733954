import * as React from 'react';

function SvgServiceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
      <path
        d="M10.587 0C9.294 0 8.204 1.09 8.204 2.383c0 1.292 1.09 2.383 2.383 2.383s2.344-1.09 2.344-2.383S11.88 0 10.587 0zm2.601 4.766a3.493 3.493 0 01-2.6 1.171 3.579 3.579 0 01-2.641-1.171 3.489 3.489 0 00-.915 2.343v.586c0 .324.262.586.586.586h5.899a.586.586 0 00.586-.586V7.11c0-.903-.352-1.72-.915-2.343zM5.662 17.985l-2.986-5.987a1.172 1.172 0 00-1.569-.527l-.78.387a.586.586 0 00-.264.787l3.516 7.031c.144.29.496.405.784.263l.77-.381c.581-.288.818-.993.529-1.573zM19.52 9.676a1.167 1.167 0 00-1.546.152l-3.203 3.938c-.223.234-.645.375-.867.375h-2.731a.58.58 0 01-.586-.586.58.58 0 01.586-.586h2.344c.644 0 1.172-.528 1.172-1.172 0-.645-.528-1.172-1.172-1.172h-3.07c-.293 0-.438-.185-.669-.387-.906-.816-2.263-1.209-3.637-.89-.763.177-1.277.485-1.799.898l-.017-.014-.848.746 3.33 6.678h6.71a3.529 3.529 0 002.812-1.406l3.438-4.922a1.181 1.181 0 00-.246-1.652z"
        fill="#79818C"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgServiceIcon;
