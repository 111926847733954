/* tslint:disable */
/* eslint-disable */
/**
 * NestJS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface About
 */
export interface About {
    /**
     * 
     * @type {number}
     * @memberof About
     */
    id: number;
    /**
     * 
     * @type {Content}
     * @memberof About
     */
    about: Content;
    /**
     * 
     * @type {Array<Faq>}
     * @memberof About
     */
    faq: Array<Faq>;
    /**
     * 
     * @type {Terms}
     * @memberof About
     */
    terms: Terms;
}
/**
 * 
 * @export
 * @interface AcceptConsultationOfferDto
 */
export interface AcceptConsultationOfferDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptConsultationOfferDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface AcceptPromocodeConsultationOfferDto
 */
export interface AcceptPromocodeConsultationOfferDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptPromocodeConsultationOfferDto
     */
    userPromocodeId: string;
}
/**
 * 
 * @export
 * @interface Achievement
 */
export interface Achievement {
    /**
     * 
     * @type {string}
     * @memberof Achievement
     */
    name: AchievementNameEnum;
    /**
     * 
     * @type {number}
     * @memberof Achievement
     */
    id: number;
    /**
     * 
     * @type {Array<UserAchievement>}
     * @memberof Achievement
     */
    users?: Array<UserAchievement>;
    /**
     * 
     * @type {string}
     * @memberof Achievement
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Achievement
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Achievement
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AchievementNameEnum {
    Profi = 'profi',
    RespondsQuickly = 'respondsQuickly',
    PromptConsultation = 'promptConsultation',
    SuperExpert = 'superExpert',
    OnThePositive = 'onThePositive',
    MaestroOfConsultations = 'maestroOfConsultations',
    Smartest = 'smartest'
}

/**
 * 
 * @export
 * @interface AddImageUrlsDto
 */
export interface AddImageUrlsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddImageUrlsDto
     */
    imageUrls: Array<string>;
}
/**
 * 
 * @export
 * @interface AddOneImageUrlDto
 */
export interface AddOneImageUrlDto {
    /**
     * 
     * @type {string}
     * @memberof AddOneImageUrlDto
     */
    imageUrl: string;
}
/**
 * 
 * @export
 * @interface AdminUpdateComplaintDecisionDto
 */
export interface AdminUpdateComplaintDecisionDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateComplaintDecisionDto
     */
    complaintDecision: AdminUpdateComplaintDecisionDtoComplaintDecisionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpdateComplaintDecisionDtoComplaintDecisionEnum {
    NoDecision = 'NO DECISION',
    Refund = 'REFUND',
    RefundAndWarning = 'REFUND AND WARNING',
    RefundAndBlocking = 'REFUND AND BLOCKING',
    Refusal = 'REFUSAL',
    Apology = 'APOLOGY'
}

/**
 * 
 * @export
 * @interface AdminUpdateReceiptDto
 */
export interface AdminUpdateReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateReceiptDto
     */
    status?: AdminUpdateReceiptDtoStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUpdateReceiptDto
     */
    adminComments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateReceiptDto
     */
    receiptFileUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpdateReceiptDtoStatusEnum {
    ModerationRejected = 'moderationRejected',
    WaitingModeration = 'waitingModeration',
    PassedModeration = 'passedModeration'
}

/**
 * 
 * @export
 * @interface AdminUpdateVerificationRequestDto
 */
export interface AdminUpdateVerificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentVideoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    instagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentInstagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentFacebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    vKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentVKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    telegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentTelegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    youtubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentYoutubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    odnoklassnikiUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentOdnoklassnikiUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    diplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentDiplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    reviewsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentReviewsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    personalSite?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    commentPersonalSite?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateVerificationRequestDto
     */
    status: AdminUpdateVerificationRequestDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUpdateVerificationRequestDtoStatusEnum {
    Pending = 'pending',
    Rejected = 'rejected',
    Fulfilled = 'fulfilled'
}

/**
 * 
 * @export
 * @interface AnswerNpsPoll
 */
export interface AnswerNpsPoll {
    /**
     * 
     * @type {number}
     * @memberof AnswerNpsPoll
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof AnswerNpsPoll
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof AnswerNpsPoll
     */
    npsPollId: number;
    /**
     * 
     * @type {number}
     * @memberof AnswerNpsPoll
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof AnswerNpsPoll
     */
    id: number;
    /**
     * 
     * @type {NpsPoll}
     * @memberof AnswerNpsPoll
     */
    npsPoll: NpsPoll;
    /**
     * 
     * @type {User}
     * @memberof AnswerNpsPoll
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof AnswerNpsPoll
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerNpsPoll
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerNpsPoll
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface ApplyPromocodeDto
 */
export interface ApplyPromocodeDto {
    /**
     * 
     * @type {string}
     * @memberof ApplyPromocodeDto
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ApproveContentChangeRequestDto
 */
export interface ApproveContentChangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ApproveContentChangeRequestDto
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveContentChangeRequestDto
     */
    commentAvatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveContentChangeRequestDto
     */
    aboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveContentChangeRequestDto
     */
    commentAboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveContentChangeRequestDto
     */
    status: ApproveContentChangeRequestDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApproveContentChangeRequestDtoStatusEnum {
    Pending = 'pending',
    Rejected = 'rejected',
    Fulfilled = 'fulfilled'
}

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     * 
     * @type {User}
     * @memberof AuthUser
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    token: string;
}
/**
 * 
 * @export
 * @interface BlockUserDto
 */
export interface BlockUserDto {
    /**
     * 
     * @type {number}
     * @memberof BlockUserDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BlockUserDto
     */
    blockStatus: BlockUserDtoBlockStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BlockUserDto
     */
    reasonBlock?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BlockUserDtoBlockStatusEnum {
    Full = 'full',
    Topic = 'topic',
    Partial = 'partial',
    Unblock = 'unblock'
}

/**
 * 
 * @export
 * @interface BookingConsultationDto
 */
export interface BookingConsultationDto {
    /**
     * 
     * @type {number}
     * @memberof BookingConsultationDto
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof BookingConsultationDto
     */
    expertId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingConsultationDto
     */
    dateOfTheConsultationWithZoom: string;
    /**
     * 
     * @type {string}
     * @memberof BookingConsultationDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface BulkElementUserCategoryMetaDto
 */
export interface BulkElementUserCategoryMetaDto {
    /**
     * 
     * @type {number}
     * @memberof BulkElementUserCategoryMetaDto
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkElementUserCategoryMetaDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof BulkElementUserCategoryMetaDto
     */
    lastReadTime?: string;
    /**
     * 
     * @type {number}
     * @memberof BulkElementUserCategoryMetaDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface BulkUpdateUserCategoryMetaDto
 */
export interface BulkUpdateUserCategoryMetaDto {
    /**
     * 
     * @type {Array<BulkElementUserCategoryMetaDto>}
     * @memberof BulkUpdateUserCategoryMetaDto
     */
    bulk: Array<BulkElementUserCategoryMetaDto>;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    iconUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    expertsCount: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    SEODescription?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Category
     */
    subCategories: Array<Category>;
    /**
     * 
     * @type {Category}
     * @memberof Category
     */
    rootCategory?: Category;
    /**
     * 
     * @type {Array<Rubric>}
     * @memberof Category
     */
    rubrics: Array<Rubric>;
    /**
     * 
     * @type {Array<ConsultationRequest>}
     * @memberof Category
     */
    consultationRequests?: Array<ConsultationRequest>;
    /**
     * 
     * @type {Array<UserCategoryMeta>}
     * @memberof Category
     */
    userConsultsCategories?: Array<UserCategoryMeta>;
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof Category
     */
    consultations: Array<Consultation>;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface ChatConsultationOffer
 */
export interface ChatConsultationOffer {
    /**
     * 
     * @type {number}
     * @memberof ChatConsultationOffer
     */
    authorId: number;
    /**
     * 
     * @type {number}
     * @memberof ChatConsultationOffer
     */
    chatRoomId: number;
    /**
     * 
     * @type {number}
     * @memberof ChatConsultationOffer
     */
    userCategoryMetaId: number;
    /**
     * 
     * @type {string}
     * @memberof ChatConsultationOffer
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConsultationOffer
     */
    expiresIn: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConsultationOffer
     */
    status: ChatConsultationOfferStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ChatConsultationOffer
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof ChatConsultationOffer
     */
    author: User;
    /**
     * 
     * @type {ChatRoom}
     * @memberof ChatConsultationOffer
     */
    chatRoom: ChatRoom;
    /**
     * 
     * @type {UserCategoryMeta}
     * @memberof ChatConsultationOffer
     */
    userCategoryMeta: UserCategoryMeta;
    /**
     * 
     * @type {number}
     * @memberof ChatConsultationOffer
     */
    invoiceId: number;
    /**
     * 
     * @type {Invoice}
     * @memberof ChatConsultationOffer
     */
    invoice: Invoice;
    /**
     * 
     * @type {Consultation}
     * @memberof ChatConsultationOffer
     */
    consultation: Consultation;
    /**
     * 
     * @type {number}
     * @memberof ChatConsultationOffer
     */
    consultationId: number;
    /**
     * 
     * @type {string}
     * @memberof ChatConsultationOffer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConsultationOffer
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatConsultationOffer
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChatConsultationOfferStatusEnum {
    Fulfilled = 'fulfilled',
    Expired = 'expired',
    Pending = 'pending',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface ChatInitialMessageDto
 */
export interface ChatInitialMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ChatInitialMessageDto
     */
    content?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatInitialMessageDto
     */
    attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChatRoom
 */
export interface ChatRoom {
    /**
     * 
     * @type {User}
     * @memberof ChatRoom
     */
    client: User;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    clientId: number;
    /**
     * 
     * @type {User}
     * @memberof ChatRoom
     */
    expert: User;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    expertId: number;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ChatRoom
     */
    messages: Array<Message>;
    /**
     * 
     * @type {Message}
     * @memberof ChatRoom
     */
    lastMessage?: Message;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    lastMessageId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    clientLastReadMessageId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    expertLastReadMessageId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoom
     */
    isExpertMuteChat: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatRoom
     */
    isClientMuteChat: boolean;
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof ChatRoom
     */
    consultations: Array<Consultation>;
    /**
     * 
     * @type {Array<ChatConsultationOffer>}
     * @memberof ChatRoom
     */
    chatConsultationOffers: Array<ChatConsultationOffer>;
    /**
     * 
     * @type {string}
     * @memberof ChatRoom
     */
    lastActionDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ChatRoom
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ChatRoom
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoom
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatRoom
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface ConfirmVerificationEmailDto
 */
export interface ConfirmVerificationEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmVerificationEmailDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface Consultation
 */
export interface Consultation {
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    chatRoomId: number;
    /**
     * 
     * @type {User}
     * @memberof Consultation
     */
    client: User;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    clientId: number;
    /**
     * 
     * @type {User}
     * @memberof Consultation
     */
    expert: User;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    expertId: number;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    status: ConsultationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Consultation
     */
    isBooking: boolean;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    linkForZoom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    dateOfTheConsultationWithZoom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    messagesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    expertMessagesCount: number;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    expiresIn?: string;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    completedAt: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Consultation
     */
    messages: Array<Message>;
    /**
     * 
     * @type {Message}
     * @memberof Consultation
     */
    lastMessage?: Message;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    lastMessageId?: number;
    /**
     * 
     * @type {Message}
     * @memberof Consultation
     */
    expertLastReadMessage?: Message;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    expertLastReadMessageId?: number;
    /**
     * 
     * @type {Message}
     * @memberof Consultation
     */
    clientLastReadMessage?: Message;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    clientLastReadMessageId?: number;
    /**
     * 
     * @type {any}
     * @memberof Consultation
     */
    consultationMetaData?: any;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    id: number;
    /**
     * 
     * @type {ChatRoom}
     * @memberof Consultation
     */
    chatRoom: ChatRoom;
    /**
     * 
     * @type {Category}
     * @memberof Consultation
     */
    category?: Category;
    /**
     * 
     * @type {boolean}
     * @memberof Consultation
     */
    isService: boolean;
    /**
     * 
     * @type {Array<ConsultationInvoice>}
     * @memberof Consultation
     */
    invoices: Array<ConsultationInvoice>;
    /**
     * 
     * @type {ConsultationRequest}
     * @memberof Consultation
     */
    initialConsultationRequest?: ConsultationRequest;
    /**
     * 
     * @type {number}
     * @memberof Consultation
     */
    initialConsultationRequestId?: number;
    /**
     * 
     * @type {Array<Review>}
     * @memberof Consultation
     */
    reviews: Array<Review>;
    /**
     * 
     * @type {Array<ExtraService>}
     * @memberof Consultation
     */
    extraServices: Array<ExtraService>;
    /**
     * 
     * @type {Array<Receipt>}
     * @memberof Consultation
     */
    receipts?: Array<Receipt>;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Consultation
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsultationStatusEnum {
    PendingPayment = 'pendingPayment',
    PaymentExpired = 'paymentExpired',
    PaymentFails = 'paymentFails',
    PendingStart = 'pendingStart',
    Active = 'active',
    MessagesLimitExceeded = 'messagesLimitExceeded',
    TimeLimitExceeded = 'timeLimitExceeded',
    Ended = 'ended',
    EndedByClient = 'endedByClient',
    EndedByExpert = 'endedByExpert',
    ClientRejectChatOffer = 'clientRejectChatOffer',
    ExpertRejectChatOffer = 'expertRejectChatOffer',
    CanceledPaidConsultationByClient = 'canceledPaidConsultationByClient',
    CanceledPaidConsultationByExpert = 'canceledPaidConsultationByExpert'
}

/**
 * 
 * @export
 * @interface ConsultationInvoice
 */
export interface ConsultationInvoice {
    /**
     * 
     * @type {number}
     * @memberof ConsultationInvoice
     */
    id: number;
    /**
     * 
     * @type {Invoice}
     * @memberof ConsultationInvoice
     */
    invoice: Invoice;
    /**
     * 
     * @type {number}
     * @memberof ConsultationInvoice
     */
    invoiceId: number;
    /**
     * 
     * @type {Consultation}
     * @memberof ConsultationInvoice
     */
    consultation: Consultation;
    /**
     * 
     * @type {number}
     * @memberof ConsultationInvoice
     */
    consultationId: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationInvoice
     */
    type: ConsultationInvoiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsultationInvoice
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationInvoice
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationInvoice
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsultationInvoiceTypeEnum {
    Initiation = 'initiation',
    Prolongation = 'prolongation'
}

/**
 * 
 * @export
 * @interface ConsultationOffer
 */
export interface ConsultationOffer {
    /**
     * 
     * @type {ConsultationRequest}
     * @memberof ConsultationOffer
     */
    consultationRequest: ConsultationRequest;
    /**
     * 
     * @type {User}
     * @memberof ConsultationOffer
     */
    expert: User;
    /**
     * 
     * @type {number}
     * @memberof ConsultationOffer
     */
    expertId: number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationOffer
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationOffer
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationOffer
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationOffer
     */
    consultationRequestId: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationOffer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationOffer
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationOffer
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface ConsultationRequest
 */
export interface ConsultationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationRequest
     */
    categoryId: number;
    /**
     * 
     * @type {number}
     * @memberof ConsultationRequest
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    lastReadTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    expiresIn?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationRequest
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    status: ConsultationRequestStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof ConsultationRequest
     */
    client: User;
    /**
     * 
     * @type {number}
     * @memberof ConsultationRequest
     */
    feedbackCount: number;
    /**
     * 
     * @type {Category}
     * @memberof ConsultationRequest
     */
    category: Category;
    /**
     * 
     * @type {Array<ConsultationOffer>}
     * @memberof ConsultationRequest
     */
    offers?: Array<ConsultationOffer>;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRequest
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsultationRequestStatusEnum {
    Active = 'active',
    Satisfied = 'satisfied',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    body: string;
}
/**
 * 
 * @export
 * @interface ContentChangeRequest
 */
export interface ContentChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    commentAvatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    aboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    commentAboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    status: ContentChangeRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ContentChangeRequest
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof ContentChangeRequest
     */
    expert?: User;
    /**
     * 
     * @type {number}
     * @memberof ContentChangeRequest
     */
    expertId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ContentChangeRequest
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ContentChangeRequestStatusEnum {
    Pending = 'pending',
    Rejected = 'rejected',
    Fulfilled = 'fulfilled'
}

/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    type: ContractTypeEnum;
    /**
     * 
     * @type {IEContractPayloadDto | IndividualContractPayloadDto | LLCContractPayloadDto | SelfEmployedContractPayloadDto}
     * @memberof Contract
     */
    payload: IEContractPayloadDto | IndividualContractPayloadDto | LLCContractPayloadDto | SelfEmployedContractPayloadDto;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    status: ContractStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof Contract
     */
    owner: User;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    ownerId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Contract
     */
    adminComments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    pdfUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    userIpAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    timestampSendSignSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    timestampEnterSignSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ContractTypeEnum {
    Llc = 'llc',
    Ie = 'ie',
    SelfEmployed = 'selfEmployed',
    Individual = 'individual'
}
/**
    * @export
    * @enum {string}
    */
export enum ContractStatusEnum {
    Accepted = 'accepted',
    WaitingReview = 'waitingReview',
    PassedReview = 'passedReview',
    ReviewRejection = 'reviewRejection',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface CreateAdminArticleDto
 */
export interface CreateAdminArticleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminArticleDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminArticleDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminArticleDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminArticleDto
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdminArticleDto
     */
    categoryId?: number;
}
/**
 * 
 * @export
 * @interface CreateAnswerNpsPollsDto
 */
export interface CreateAnswerNpsPollsDto {
    /**
     * 
     * @type {number}
     * @memberof CreateAnswerNpsPollsDto
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAnswerNpsPollsDto
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface CreateChatConsultationOfferDto
 */
export interface CreateChatConsultationOfferDto {
    /**
     * 
     * @type {number}
     * @memberof CreateChatConsultationOfferDto
     */
    userCategoryMetaId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateChatConsultationOfferDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChatConsultationOfferDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface CreateChatRoomDto
 */
export interface CreateChatRoomDto {
    /**
     * 
     * @type {number}
     * @memberof CreateChatRoomDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateChatRoomDto
     */
    expertId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateChatRoomDto
     */
    lastActionDate?: string;
    /**
     * 
     * @type {ChatInitialMessageDto}
     * @memberof CreateChatRoomDto
     */
    initialMessage?: ChatInitialMessageDto;
}
/**
 * 
 * @export
 * @interface CreateConsultationOfferDto
 */
export interface CreateConsultationOfferDto {
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationOfferDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationOfferDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationOfferDto
     */
    consultationRequestId: number;
}
/**
 * 
 * @export
 * @interface CreateConsultationProlongationInvoiceDto
 */
export interface CreateConsultationProlongationInvoiceDto {
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationProlongationInvoiceDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationProlongationInvoiceDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface CreateConsultationReceiptDto
 */
export interface CreateConsultationReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationReceiptDto
     */
    receiptFileUrl: string;
}
/**
 * 
 * @export
 * @interface CreateConsultationRequestDto
 */
export interface CreateConsultationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationRequestDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationRequestDto
     */
    categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationRequestDto
     */
    lastReadTime?: string;
}
/**
 * 
 * @export
 * @interface CreateConsultationThroughExpertServiceDto
 */
export interface CreateConsultationThroughExpertServiceDto {
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationThroughExpertServiceDto
     */
    expertServiceId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationThroughExpertServiceDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface CreateConsultationThroughUserCategoryMetaDto
 */
export interface CreateConsultationThroughUserCategoryMetaDto {
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationThroughUserCategoryMetaDto
     */
    userCategoryMetaId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationThroughUserCategoryMetaDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface CreateConsultationThroughUserCategoryMetaWithPromocodeDto
 */
export interface CreateConsultationThroughUserCategoryMetaWithPromocodeDto {
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationThroughUserCategoryMetaWithPromocodeDto
     */
    userCategoryMetaId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateConsultationThroughUserCategoryMetaWithPromocodeDto
     */
    userPromocodeId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsultationThroughUserCategoryMetaWithPromocodeDto
     */
    redirectUrl: string;
}
/**
 * 
 * @export
 * @interface CreateContentChangeRequestDto
 */
export interface CreateContentChangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContentChangeRequestDto
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContentChangeRequestDto
     */
    aboutSelf?: string;
}
/**
 * 
 * @export
 * @interface CreateCustomRequestDto
 */
export interface CreateCustomRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomRequestDto
     */
    type: CreateCustomRequestDtoTypeEnum;
    /**
     * 
     * @type {PersonalPromocodeRequestPayload | SpecialTariffRequestPayload}
     * @memberof CreateCustomRequestDto
     */
    payload: PersonalPromocodeRequestPayload | SpecialTariffRequestPayload;
    /**
     * 
     * @type {number}
     * @memberof CreateCustomRequestDto
     */
    promocodeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateCustomRequestDtoTypeEnum {
    PersonalPromocode = 'personalPromocode',
    SpecialTariff = 'specialTariff'
}

/**
 * 
 * @export
 * @interface CreateExpertServiceDto
 */
export interface CreateExpertServiceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateExpertServiceDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExpertServiceDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateExpertServiceDto
     */
    price: number;
}
/**
 * 
 * @export
 * @interface CreateExtraServiceDto
 */
export interface CreateExtraServiceDto {
    /**
     * 
     * @type {number}
     * @memberof CreateExtraServiceDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExtraServiceDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExtraServiceDto
     */
    redirectUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExtraServiceDto
     */
    description: string;
}
/**
 * 
 * @export
 * @interface CreateIEContractDto
 */
export interface CreateIEContractDto {
    /**
     * 
     * @type {string}
     * @memberof CreateIEContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {IEContractPayloadDto}
     * @memberof CreateIEContractDto
     */
    payload: IEContractPayloadDto;
    /**
     * 
     * @type {string}
     * @memberof CreateIEContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIEContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIEContractDto
     */
    timestampEnterSignSmsCode?: string;
}
/**
 * 
 * @export
 * @interface CreateIndividualContractDto
 */
export interface CreateIndividualContractDto {
    /**
     * 
     * @type {string}
     * @memberof CreateIndividualContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {IndividualContractPayloadDto}
     * @memberof CreateIndividualContractDto
     */
    payload: IndividualContractPayloadDto;
    /**
     * 
     * @type {string}
     * @memberof CreateIndividualContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIndividualContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIndividualContractDto
     */
    timestampEnterSignSmsCode?: string;
}
/**
 * 
 * @export
 * @interface CreateLLCContractDto
 */
export interface CreateLLCContractDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLLCContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {LLCContractPayloadDto}
     * @memberof CreateLLCContractDto
     */
    payload: LLCContractPayloadDto;
    /**
     * 
     * @type {string}
     * @memberof CreateLLCContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLLCContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLLCContractDto
     */
    timestampEnterSignSmsCode?: string;
}
/**
 * 
 * @export
 * @interface CreateManyConsultationDto
 */
export interface CreateManyConsultationDto {
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof CreateManyConsultationDto
     */
    bulk: Array<Consultation>;
}
/**
 * 
 * @export
 * @interface CreateManyUserDto
 */
export interface CreateManyUserDto {
    /**
     * 
     * @type {Array<User>}
     * @memberof CreateManyUserDto
     */
    bulk: Array<User>;
}
/**
 * 
 * @export
 * @interface CreateMessageDto
 */
export interface CreateMessageDto {
    /**
     * 
     * @type {number}
     * @memberof CreateMessageDto
     */
    chatRoomId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMessageDto
     */
    consultationId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateMessageDto
     */
    content?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMessageDto
     */
    attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateNewsDto
 */
export interface CreateNewsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    toWhom: CreateNewsDtoToWhomEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    scheduledTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateNewsDtoToWhomEnum {
    All = 'all',
    Expert = 'expert',
    Client = 'client'
}

/**
 * 
 * @export
 * @interface CreateOneCategoryDto
 */
export interface CreateOneCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOneCategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOneCategoryDto
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOneCategoryDto
     */
    iconUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOneCategoryDto
     */
    rootCategoryId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateOneCategoryDto
     */
    rubricsIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateOneCategoryDto
     */
    SEODescription?: string;
}
/**
 * 
 * @export
 * @interface CreatePromocodeDto
 */
export interface CreatePromocodeDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePromocodeDto
     */
    type: CreatePromocodeDtoTypeEnum;
    /**
     * 
     * @type {Privilege}
     * @memberof CreatePromocodeDto
     */
    privilege: Privilege;
    /**
     * 
     * @type {string}
     * @memberof CreatePromocodeDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromocodeDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromocodeDto
     */
    expiresIn: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePromocodeDto
     */
    customRequestId: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreatePromocodeDtoTypeEnum {
    FreeConsultation = 'freeConsultation'
}

/**
 * 
 * @export
 * @interface CreateReviewDto
 */
export interface CreateReviewDto {
    /**
     * 
     * @type {number}
     * @memberof CreateReviewDto
     */
    rating: number;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewDto
     */
    expertId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateReviewDto
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateReviewDto
     */
    isPromptly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateReviewDto
     */
    onPositive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateReviewDto
     */
    isSavvy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateReviewDto
     */
    wantRefund?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateReviewDto
     */
    consultationId: number;
}
/**
 * 
 * @export
 * @interface CreateSelfEmployedContractDto
 */
export interface CreateSelfEmployedContractDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSelfEmployedContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {SelfEmployedContractPayloadDto}
     * @memberof CreateSelfEmployedContractDto
     */
    payload: SelfEmployedContractPayloadDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSelfEmployedContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSelfEmployedContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSelfEmployedContractDto
     */
    timestampEnterSignSmsCode?: string;
}
/**
 * 
 * @export
 * @interface CreateSuggestedCategoryDto
 */
export interface CreateSuggestedCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSuggestedCategoryDto
     */
    suggestedCategoryName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSuggestedCategoryDto
     */
    source: CreateSuggestedCategoryDtoSourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSuggestedCategoryDtoSourceEnum {
    ExpertProfile = 'ExpertProfile',
    BecomeAnExpert = 'BecomeAnExpert',
    FindAnExpert = 'FindAnExpert'
}

/**
 * 
 * @export
 * @interface CreateTariffPlanDto
 */
export interface CreateTariffPlanDto {
    /**
     * 
     * @type {number}
     * @memberof CreateTariffPlanDto
     */
    commissionPercent: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTariffPlanDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTariffPlanDto
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTariffPlanDto
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface CreateUserCategoryMetaDto
 */
export interface CreateUserCategoryMetaDto {
    /**
     * 
     * @type {number}
     * @memberof CreateUserCategoryMetaDto
     */
    categoryId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserCategoryMetaDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCategoryMetaDto
     */
    lastReadTime?: string;
}
/**
 * 
 * @export
 * @interface CreateVerificationRequestDto
 */
export interface CreateVerificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    instagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    vKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    telegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    youtubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    odnoklassnikiUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    diplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    reviewsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerificationRequestDto
     */
    personalSite?: string;
}
/**
 * 
 * @export
 * @interface CreateWithdrawalRequestsDto
 */
export interface CreateWithdrawalRequestsDto {
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawalRequestsDto
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface CustomRequest
 */
export interface CustomRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomRequest
     */
    type: CustomRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomRequest
     */
    status: CustomRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomRequest
     */
    comment?: string;
    /**
     * 
     * @type {PersonalPromocodeRequestPayload | SpecialTariffRequestPayload}
     * @memberof CustomRequest
     */
    payload: PersonalPromocodeRequestPayload | SpecialTariffRequestPayload;
    /**
     * 
     * @type {number}
     * @memberof CustomRequest
     */
    promocodeId?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomRequest
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof CustomRequest
     */
    author: User;
    /**
     * 
     * @type {number}
     * @memberof CustomRequest
     */
    authorId: number;
    /**
     * 
     * @type {Promocode}
     * @memberof CustomRequest
     */
    promocode?: Promocode;
    /**
     * 
     * @type {string}
     * @memberof CustomRequest
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomRequest
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomRequest
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomRequestTypeEnum {
    PersonalPromocode = 'personalPromocode',
    SpecialTariff = 'specialTariff'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomRequestStatusEnum {
    Pending = 'pending',
    Fulfilled = 'fulfilled',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface ExpertRating
 */
export interface ExpertRating {
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    avatarAndAboutMe: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    contract: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    videosBusinessCard: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    telegramChannel: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    vkontakte: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    odnoklassniki: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    youTube: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    diplomasOrReviews: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    personalSite: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    socialNetworks: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    site: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    content: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    media: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    sumOfPoints: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    internalRating: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRating
     */
    expertId: number;
    /**
     * 
     * @type {User}
     * @memberof ExpertRating
     */
    expert: User;
    /**
     * 
     * @type {string}
     * @memberof ExpertRating
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertRating
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertRating
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface ExpertService
 */
export interface ExpertService {
    /**
     * 
     * @type {number}
     * @memberof ExpertService
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertService
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertService
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertService
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertService
     */
    price: number;
    /**
     * 
     * @type {User}
     * @memberof ExpertService
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof ExpertService
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertService
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertService
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface ExpertStepDto
 */
export interface ExpertStepDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertStepDto
     */
    step: ExpertStepDtoStepEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ExpertStepDtoStepEnum {
    _0checkboxexpert = 'step-0checkboxexpert',
    _1start = 'step-1start',
    _2photo = 'step-2photo',
    _3about = 'step-3about',
    _4theme = 'step-4theme',
    _5profile = 'step-5profile',
    _6doc = 'step-6doc',
    _7rek = 'step-7rek',
    _8done = 'step-8done'
}

/**
 * 
 * @export
 * @interface ExpertZoomCodeDto
 */
export interface ExpertZoomCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ExpertZoomCodeDto
     */
    zoomCode: string;
}
/**
 * 
 * @export
 * @interface ExtraService
 */
export interface ExtraService {
    /**
     * 
     * @type {number}
     * @memberof ExtraService
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExtraService
     */
    description: string;
    /**
     * 
     * @type {Consultation}
     * @memberof ExtraService
     */
    consultation: Consultation;
    /**
     * 
     * @type {number}
     * @memberof ExtraService
     */
    consultationId: number;
    /**
     * 
     * @type {Invoice}
     * @memberof ExtraService
     */
    invoice: Invoice;
    /**
     * 
     * @type {number}
     * @memberof ExtraService
     */
    invoiceId: number;
    /**
     * 
     * @type {User}
     * @memberof ExtraService
     */
    author: User;
    /**
     * 
     * @type {number}
     * @memberof ExtraService
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof ExtraService
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraService
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraService
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface Faq
 */
export interface Faq {
    /**
     * 
     * @type {string}
     * @memberof Faq
     */
    question: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Faq
     */
    answer: Array<string>;
}
/**
 * 
 * @export
 * @interface GetAttachCardUrlResponseDto
 */
export interface GetAttachCardUrlResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAttachCardUrlResponseDto
     */
    attachUrl: string;
}
/**
 * 
 * @export
 * @interface GetCategoryDto
 */
export interface GetCategoryDto {
    /**
     * 
     * @type {Category}
     * @memberof GetCategoryDto
     */
    category: Category;
    /**
     * 
     * @type {Array<User>}
     * @memberof GetCategoryDto
     */
    threeBestExpert: Array<User>;
    /**
     * 
     * @type {Array<Category>}
     * @memberof GetCategoryDto
     */
    subCategories: Array<Category>;
    /**
     * 
     * @type {Category}
     * @memberof GetCategoryDto
     */
    rootCategory: Category;
}
/**
 * 
 * @export
 * @interface GetChatRoomManyMessages
 */
export interface GetChatRoomManyMessages {
    /**
     * 
     * @type {Array<Message>}
     * @memberof GetChatRoomManyMessages
     */
    data: Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof GetChatRoomManyMessages
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetChatRoomManyMessages
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetChatRoomManyMessages
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetChatRoomManyMessages
     */
    total: number;
}
/**
 * 
 * @export
 * @interface GetContractSignSmsCodeResponseDto
 */
export interface GetContractSignSmsCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetContractSignSmsCodeResponseDto
     */
    smsCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetContractSignSmsCodeResponseDto
     */
    validUntil: string;
}
/**
 * 
 * @export
 * @interface GetManyAnswerNpsPollResponseDto
 */
export interface GetManyAnswerNpsPollResponseDto {
    /**
     * 
     * @type {Array<AnswerNpsPoll>}
     * @memberof GetManyAnswerNpsPollResponseDto
     */
    data: Array<AnswerNpsPoll>;
    /**
     * 
     * @type {number}
     * @memberof GetManyAnswerNpsPollResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyAnswerNpsPollResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyAnswerNpsPollResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyAnswerNpsPollResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyArticleResponseDto
 */
export interface GetManyArticleResponseDto {
    /**
     * 
     * @type {Array<Article>}
     * @memberof GetManyArticleResponseDto
     */
    data: Array<Article>;
    /**
     * 
     * @type {number}
     * @memberof GetManyArticleResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyArticleResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyArticleResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyArticleResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyCategoryExpertsDto
 */
export interface GetManyCategoryExpertsDto {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetManyCategoryExpertsDto
     */
    data: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryExpertsDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryExpertsDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryExpertsDto
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryExpertsDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface GetManyCategoryResponseDto
 */
export interface GetManyCategoryResponseDto {
    /**
     * 
     * @type {Array<Category>}
     * @memberof GetManyCategoryResponseDto
     */
    data: Array<Category>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyConsultationRequestResponseDto
 */
export interface GetManyConsultationRequestResponseDto {
    /**
     * 
     * @type {Array<ConsultationRequest>}
     * @memberof GetManyConsultationRequestResponseDto
     */
    data: Array<ConsultationRequest>;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationRequestResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationRequestResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationRequestResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationRequestResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyConsultationResponseDto
 */
export interface GetManyConsultationResponseDto {
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof GetManyConsultationResponseDto
     */
    data: Array<Consultation>;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyConsultationsResponseDto
 */
export interface GetManyConsultationsResponseDto {
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof GetManyConsultationsResponseDto
     */
    data: Array<Consultation>;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationsResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationsResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationsResponseDto
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyConsultationsResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface GetManyContentChangeRequestResponseDto
 */
export interface GetManyContentChangeRequestResponseDto {
    /**
     * 
     * @type {Array<ContentChangeRequest>}
     * @memberof GetManyContentChangeRequestResponseDto
     */
    data: Array<ContentChangeRequest>;
    /**
     * 
     * @type {number}
     * @memberof GetManyContentChangeRequestResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContentChangeRequestResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContentChangeRequestResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContentChangeRequestResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyContractResponseDto
 */
export interface GetManyContractResponseDto {
    /**
     * 
     * @type {Array<Contract>}
     * @memberof GetManyContractResponseDto
     */
    data: Array<Contract>;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyCustomRequestResponseDto
 */
export interface GetManyCustomRequestResponseDto {
    /**
     * 
     * @type {Array<CustomRequest>}
     * @memberof GetManyCustomRequestResponseDto
     */
    data: Array<CustomRequest>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomRequestResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomRequestResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomRequestResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomRequestResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyExpertServiceResponseDto
 */
export interface GetManyExpertServiceResponseDto {
    /**
     * 
     * @type {Array<ExpertService>}
     * @memberof GetManyExpertServiceResponseDto
     */
    data: Array<ExpertService>;
    /**
     * 
     * @type {number}
     * @memberof GetManyExpertServiceResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyExpertServiceResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyExpertServiceResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyExpertServiceResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyInvoiceResponseDto
 */
export interface GetManyInvoiceResponseDto {
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof GetManyInvoiceResponseDto
     */
    data: Array<Invoice>;
    /**
     * 
     * @type {number}
     * @memberof GetManyInvoiceResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyInvoiceResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyInvoiceResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyInvoiceResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyNewsResponseDto
 */
export interface GetManyNewsResponseDto {
    /**
     * 
     * @type {Array<News>}
     * @memberof GetManyNewsResponseDto
     */
    data: Array<News>;
    /**
     * 
     * @type {number}
     * @memberof GetManyNewsResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyNewsResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyNewsResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyNewsResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyNpsPollResponseDto
 */
export interface GetManyNpsPollResponseDto {
    /**
     * 
     * @type {Array<NpsPoll>}
     * @memberof GetManyNpsPollResponseDto
     */
    data: Array<NpsPoll>;
    /**
     * 
     * @type {number}
     * @memberof GetManyNpsPollResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyNpsPollResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyNpsPollResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyNpsPollResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyPaymentResponseDto
 */
export interface GetManyPaymentResponseDto {
    /**
     * 
     * @type {Array<Payment>}
     * @memberof GetManyPaymentResponseDto
     */
    data: Array<Payment>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyPaymentsResponseDto
 */
export interface GetManyPaymentsResponseDto {
    /**
     * 
     * @type {Array<Payment>}
     * @memberof GetManyPaymentsResponseDto
     */
    data: Array<Payment>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentsResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentsResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentsResponseDto
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPaymentsResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface GetManyPortfolioResponseDto
 */
export interface GetManyPortfolioResponseDto {
    /**
     * 
     * @type {Array<Portfolio>}
     * @memberof GetManyPortfolioResponseDto
     */
    data: Array<Portfolio>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPortfolioResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPortfolioResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPortfolioResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPortfolioResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyPromocodeResponseDto
 */
export interface GetManyPromocodeResponseDto {
    /**
     * 
     * @type {Array<Promocode>}
     * @memberof GetManyPromocodeResponseDto
     */
    data: Array<Promocode>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromocodeResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromocodeResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromocodeResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromocodeResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyReceiptResponseDto
 */
export interface GetManyReceiptResponseDto {
    /**
     * 
     * @type {Array<Receipt>}
     * @memberof GetManyReceiptResponseDto
     */
    data: Array<Receipt>;
    /**
     * 
     * @type {number}
     * @memberof GetManyReceiptResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReceiptResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReceiptResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReceiptResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyReviewResponseDto
 */
export interface GetManyReviewResponseDto {
    /**
     * 
     * @type {Array<Review>}
     * @memberof GetManyReviewResponseDto
     */
    data: Array<Review>;
    /**
     * 
     * @type {number}
     * @memberof GetManyReviewResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReviewResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReviewResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyReviewResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyRubricsResponseDto
 */
export interface GetManyRubricsResponseDto {
    /**
     * 
     * @type {Array<Rubric>}
     * @memberof GetManyRubricsResponseDto
     */
    data: Array<Rubric>;
    /**
     * 
     * @type {number}
     * @memberof GetManyRubricsResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyRubricsResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyRubricsResponseDto
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyRubricsResponseDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface GetManySuggestedCategoryResponseDto
 */
export interface GetManySuggestedCategoryResponseDto {
    /**
     * 
     * @type {Array<SuggestedCategory>}
     * @memberof GetManySuggestedCategoryResponseDto
     */
    data: Array<SuggestedCategory>;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestedCategoryResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestedCategoryResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestedCategoryResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManySuggestedCategoryResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyTariffPlanResponseDto
 */
export interface GetManyTariffPlanResponseDto {
    /**
     * 
     * @type {Array<TariffPlan>}
     * @memberof GetManyTariffPlanResponseDto
     */
    data: Array<TariffPlan>;
    /**
     * 
     * @type {number}
     * @memberof GetManyTariffPlanResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTariffPlanResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTariffPlanResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTariffPlanResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyTransactionResponseDto
 */
export interface GetManyTransactionResponseDto {
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof GetManyTransactionResponseDto
     */
    data: Array<Transaction>;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyUserResponseDto
 */
export interface GetManyUserResponseDto {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetManyUserResponseDto
     */
    data: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyUserResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyVerificationRequestResponseDto
 */
export interface GetManyVerificationRequestResponseDto {
    /**
     * 
     * @type {Array<VerificationRequest>}
     * @memberof GetManyVerificationRequestResponseDto
     */
    data: Array<VerificationRequest>;
    /**
     * 
     * @type {number}
     * @memberof GetManyVerificationRequestResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyVerificationRequestResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyVerificationRequestResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyVerificationRequestResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyWalletResponseDto
 */
export interface GetManyWalletResponseDto {
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof GetManyWalletResponseDto
     */
    data: Array<Wallet>;
    /**
     * 
     * @type {number}
     * @memberof GetManyWalletResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWalletResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWalletResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyWalletResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface IEContractPayloadDto
 */
export interface IEContractPayloadDto {
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    inn: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    ogrnip: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    ogrnipDateOfIssue: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    residencePlaceRegistrationAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    physicalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    bik: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    checkingAccount: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    passportSecondAndThirdPageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    selfieWithPassportSecondAndThirdPageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof IEContractPayloadDto
     */
    passportRegistrationPageUrl: string;
}
/**
 * 
 * @export
 * @interface IndexDto
 */
export interface IndexDto {
    /**
     * 
     * @type {number}
     * @memberof IndexDto
     */
    index: number;
}
/**
 * 
 * @export
 * @interface IndividualContractPayloadDto
 */
export interface IndividualContractPayloadDto {
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    birthPlace: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    passportSeries: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    passportNumber: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    passportWhenGiven: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    passportIssuedBy: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    residencePlaceRegistrationAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    inn?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    physicalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualContractPayloadDto
     */
    selfieWithPassportSecondAndThirdPageUrl: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof Invoice
     */
    author: User;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    authorId: number;
    /**
     * 
     * @type {User}
     * @memberof Invoice
     */
    payer: User;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    commission?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    status: InvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    checkoutUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    expiresIn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isFree?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    tinkoffSpAccumulationId?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    payerId: number;
    /**
     * 
     * @type {ConsultationInvoice}
     * @memberof Invoice
     */
    consultationInvoice?: ConsultationInvoice;
    /**
     * 
     * @type {ConsultationInvoice}
     * @memberof Invoice
     */
    extraService?: ConsultationInvoice;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceStatusEnum {
    Pending = 'pending',
    Fulfilled = 'fulfilled',
    WaitingWithdrawal = 'waitingWithdrawal',
    Withdrawled = 'withdrawled',
    Reversed = 'reversed',
    Rejected = 'rejected',
    Expired = 'expired'
}

/**
 * 
 * @export
 * @interface LLCContractPayloadDto
 */
export interface LLCContractPayloadDto {
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    inn: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    kpp: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    ceoFullNameNominative: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    ceoFullNameGenitive: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    ceoPosition: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    legalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    physicalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    bik: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    correspondentAccount: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    checkingAccount: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    certificateOfStateRegistrationUrl: string;
    /**
     * 
     * @type {string}
     * @memberof LLCContractPayloadDto
     */
    certificateEgrulUrl: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {User}
     * @memberof Message
     */
    author?: User;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    authorId?: number;
    /**
     * 
     * @type {ChatRoom}
     * @memberof Message
     */
    chatRoom: ChatRoom;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    chatRoomId: number;
    /**
     * 
     * @type {Consultation}
     * @memberof Message
     */
    consultation?: Consultation;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    consultationId?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    content?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    systemMessageType?: MessageSystemMessageTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof Message
     */
    systemMessageMetadata?: any;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type: MessageTypeEnum;
    /**
     * 
     * @type {ChatRoom}
     * @memberof Message
     */
    chatLastMessage: ChatRoom;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageSystemMessageTypeEnum {
    ConsultationOfferCreated = 'consultationOfferCreated',
    ConsultationOfferRejected = 'consultationOfferRejected',
    ConsultationInitInvoiceCreated = 'consultationInitInvoiceCreated',
    ConsultationInitInvoiceTimeExpired = 'consultationInitInvoiceTimeExpired',
    ConsultationPendingStart = 'consultationPendingStart',
    ConsultationStarted = 'consultationStarted',
    ConsultationTimeLimitExceeded = 'consultationTimeLimitExceeded',
    ConsultationMessagesLimitExceeded = 'consultationMessagesLimitExceeded',
    ConsultationEnded = 'consultationEnded',
    ConsultationProlongationRequestCreated = 'consultationProlongationRequestCreated',
    ConsultationProlongationPayed = 'consultationProlongationPayed',
    ConsultationProlongationRejected = 'consultationProlongationRejected',
    ConsultationProlongedFree = 'consultationProlongedFree',
    ConsultationExtraServiceOfferCreated = 'consultationExtraServiceOfferCreated',
    ConsultationExtraServicePayed = 'consultationExtraServicePayed',
    ConsultationExtraServiceRejected = 'consultationExtraServiceRejected',
    ConsultationExtraServiceExpired = 'consultationExtraServiceExpired',
    ConsultationsPayedWithPromocode = 'consultationsPayedWithPromocode',
    ConsultationCanceled = 'consultationCanceled'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageTypeEnum {
    System = 'system',
    Default = 'default'
}

/**
 * 
 * @export
 * @interface News
 */
export interface News {
    /**
     * 
     * @type {string}
     * @memberof News
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    type: NewsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    toWhom?: NewsToWhomEnum;
    /**
     * 
     * @type {number}
     * @memberof News
     */
    numberOfViews: number;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    status: NewsStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof News
     */
    admin?: User;
    /**
     * 
     * @type {number}
     * @memberof News
     */
    adminId?: number;
    /**
     * 
     * @type {User}
     * @memberof News
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof News
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof News
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    scheduledTime?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewsTypeEnum {
    Default = 'default',
    Wellcome = 'wellcome',
    After3Hours = 'after_3_hours',
    After3Days = 'after_3_days'
}
/**
    * @export
    * @enum {string}
    */
export enum NewsToWhomEnum {
    All = 'all',
    Expert = 'expert',
    Client = 'client'
}
/**
    * @export
    * @enum {string}
    */
export enum NewsStatusEnum {
    Planned = 'planned',
    Published = 'published'
}

/**
 * 
 * @export
 * @interface NotificationsSetting
 */
export interface NotificationsSetting {
    /**
     * 
     * @type {number}
     * @memberof NotificationsSetting
     */
    userId: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultRequestTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultRequestSMS: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultRequestPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultStartTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultStartSMS: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultStartPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultMessageTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultMessageSMS: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onConsultMessagePush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onNewCategoryTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSetting
     */
    onNewCategorySMS: boolean;
    /**
     * 
     * @type {User}
     * @memberof NotificationsSetting
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof NotificationsSetting
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsSetting
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsSetting
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface NpsPoll
 */
export interface NpsPoll {
    /**
     * 
     * @type {string}
     * @memberof NpsPoll
     */
    type: NpsPollTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NpsPoll
     */
    id: number;
    /**
     * 
     * @type {Array<AnswerNpsPoll>}
     * @memberof NpsPoll
     */
    answerNpsPolls: Array<AnswerNpsPoll>;
    /**
     * 
     * @type {string}
     * @memberof NpsPoll
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof NpsPoll
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof NpsPoll
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NpsPollTypeEnum {
    After48hours = 'after48hours',
    After2weeks = 'after2weeks',
    ForExperts = 'forExperts',
    ForClients = 'forClients',
    ForAll = 'forAll'
}

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    commission?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    status: PaymentStatusEnum;
    /**
     * 
     * @type {Invoice}
     * @memberof Payment
     */
    invoice?: Invoice;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    invoiceId?: number;
    /**
     * 
     * @type {Wallet}
     * @memberof Payment
     */
    recipientWallet?: Wallet;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    recipientWalletId?: number;
    /**
     * 
     * @type {Wallet}
     * @memberof Payment
     */
    payerWallet?: Wallet;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    payerWalletId?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    operationType: PaymentOperationTypeEnum;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Payment
     */
    paymentMethod?: PaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    paymentMethodId?: number;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof Payment
     */
    transactions?: Array<Transaction>;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentStatusEnum {
    Pending = 'pending',
    Fulfilled = 'fulfilled',
    Rejected = 'rejected'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentOperationTypeEnum {
    Transfer = 'transfer',
    Refill = 'refill',
    Withdrawal = 'withdrawal',
    Revert = 'revert'
}

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    cardId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    pan?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    expDate?: string;
    /**
     * 
     * @type {Wallet}
     * @memberof PaymentMethod
     */
    wallet: Wallet;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    walletId: number;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof PaymentMethod
     */
    payments?: Array<Payment>;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    requestKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface PersonalPromocodeRequestPayload
 */
export interface PersonalPromocodeRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof PersonalPromocodeRequestPayload
     */
    socialSiteUrl: string;
}
/**
 * 
 * @export
 * @interface Portfolio
 */
export interface Portfolio {
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    status: PortfolioStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Portfolio
     */
    id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Portfolio
     */
    imageUrls: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    adminComment: string;
    /**
     * 
     * @type {number}
     * @memberof Portfolio
     */
    expertId: number;
    /**
     * 
     * @type {User}
     * @memberof Portfolio
     */
    expert: User;
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PortfolioStatusEnum {
    InReview = 'inReview',
    Confirmed = 'confirmed',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface PresignUrlDto
 */
export interface PresignUrlDto {
    /**
     * 
     * @type {string}
     * @memberof PresignUrlDto
     */
    fileKey: string;
    /**
     * 
     * @type {string}
     * @memberof PresignUrlDto
     */
    contentType: string;
}
/**
 * 
 * @export
 * @interface Privilege
 */
export interface Privilege {
    /**
     * 
     * @type {number}
     * @memberof Privilege
     */
    freeConsultationsCount: number;
}
/**
 * 
 * @export
 * @interface ProcessContractDto
 */
export interface ProcessContractDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessContractDto
     */
    status: ProcessContractDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessContractDto
     */
    comment?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessContractDtoStatusEnum {
    Accepted = 'accepted',
    WaitingReview = 'waitingReview',
    PassedReview = 'passedReview',
    ReviewRejection = 'reviewRejection',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface ProcessCustomRequestsDto
 */
export interface ProcessCustomRequestsDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessCustomRequestsDto
     */
    status: ProcessCustomRequestsDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessCustomRequestsDto
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessCustomRequestsDto
     */
    promocodeId?: number | null;
    /**
     * 
     * @type {Promocode}
     * @memberof ProcessCustomRequestsDto
     */
    promocode?: Promocode;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessCustomRequestsDtoStatusEnum {
    Pending = 'pending',
    Fulfilled = 'fulfilled',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface ProcessPortfolioDto
 */
export interface ProcessPortfolioDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessPortfolioDto
     */
    status: ProcessPortfolioDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProcessPortfolioDto
     */
    adminComment?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessPortfolioDtoStatusEnum {
    InReview = 'inReview',
    Confirmed = 'confirmed',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @interface Promocode
 */
export interface Promocode {
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    type: PromocodeTypeEnum;
    /**
     * 
     * @type {Privilege}
     * @memberof Promocode
     */
    privilege: Privilege;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof Promocode
     */
    ownerId?: number;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    expiresIn: string;
    /**
     * 
     * @type {number}
     * @memberof Promocode
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof Promocode
     */
    owner?: User;
    /**
     * 
     * @type {boolean}
     * @memberof Promocode
     */
    isActive: boolean;
    /**
     * 
     * @type {CustomRequest}
     * @memberof Promocode
     */
    customRequest?: CustomRequest;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Promocode
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PromocodeTypeEnum {
    FreeConsultation = 'freeConsultation'
}

/**
 * 
 * @export
 * @interface PusherAuthDto
 */
export interface PusherAuthDto {
    /**
     * 
     * @type {string}
     * @memberof PusherAuthDto
     */
    socket_id: string;
    /**
     * 
     * @type {string}
     * @memberof PusherAuthDto
     */
    channel_name: string;
}
/**
 * 
 * @export
 * @interface Receipt
 */
export interface Receipt {
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    id: number;
    /**
     * 
     * @type {Consultation}
     * @memberof Receipt
     */
    consultation?: Consultation;
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    consultationId?: number;
    /**
     * 
     * @type {User}
     * @memberof Receipt
     */
    user: User;
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    status: ReceiptStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Receipt
     */
    adminComments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    receiptFileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReceiptStatusEnum {
    ModerationRejected = 'moderationRejected',
    WaitingModeration = 'waitingModeration',
    PassedModeration = 'passedModeration'
}

/**
 * 
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    rating: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    expertId: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Review
     */
    isPromptly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Review
     */
    onPositive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Review
     */
    isSavvy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Review
     */
    wantRefund?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    consultationId: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    complaintDecision: ReviewComplaintDecisionEnum;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof Review
     */
    client: User;
    /**
     * 
     * @type {User}
     * @memberof Review
     */
    expert: User;
    /**
     * 
     * @type {User}
     * @memberof Review
     */
    author: User;
    /**
     * 
     * @type {Consultation}
     * @memberof Review
     */
    consultation: Consultation;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReviewComplaintDecisionEnum {
    NoDecision = 'NO DECISION',
    Refund = 'REFUND',
    RefundAndWarning = 'REFUND AND WARNING',
    RefundAndBlocking = 'REFUND AND BLOCKING',
    Refusal = 'REFUSAL',
    Apology = 'APOLOGY'
}

/**
 * 
 * @export
 * @interface Rubric
 */
export interface Rubric {
    /**
     * 
     * @type {number}
     * @memberof Rubric
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    iconUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof Rubric
     */
    isUpcoming?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    SEODescription?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Rubric
     */
    categories: Array<Category>;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface SelfEmployedContractPayloadDto
 */
export interface SelfEmployedContractPayloadDto {
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    birthPlace: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    passportSeries: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    passportNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    passportWhenGiven: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    passportIssuedBy: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    residencePlaceRegistrationAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    inn?: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    physicalAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SelfEmployedContractPayloadDto
     */
    selfieWithPassportSecondAndThirdPageUrl: string;
}
/**
 * 
 * @export
 * @interface SigninDto
 */
export interface SigninDto {
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    smscode: string;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    urlWithUtmMarks?: string;
}
/**
 * 
 * @export
 * @interface SmsCodeDto
 */
export interface SmsCodeDto {
    /**
     * 
     * @type {string}
     * @memberof SmsCodeDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface SpecialTariffRequestPayload
 */
export interface SpecialTariffRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof SpecialTariffRequestPayload
     */
    socialSiteUrl: string;
}
/**
 * 
 * @export
 * @interface SuggestedCategory
 */
export interface SuggestedCategory {
    /**
     * 
     * @type {string}
     * @memberof SuggestedCategory
     */
    suggestedCategoryName: string;
    /**
     * 
     * @type {User}
     * @memberof SuggestedCategory
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof SuggestedCategory
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCategory
     */
    source: SuggestedCategorySourceEnum;
    /**
     * 
     * @type {number}
     * @memberof SuggestedCategory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCategory
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCategory
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCategory
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SuggestedCategorySourceEnum {
    ExpertProfile = 'ExpertProfile',
    BecomeAnExpert = 'BecomeAnExpert',
    FindAnExpert = 'FindAnExpert'
}

/**
 * 
 * @export
 * @interface TariffPlan
 */
export interface TariffPlan {
    /**
     * 
     * @type {number}
     * @memberof TariffPlan
     */
    commissionPercent: number;
    /**
     * 
     * @type {string}
     * @memberof TariffPlan
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffPlan
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TariffPlan
     */
    isDefault: boolean;
    /**
     * 
     * @type {number}
     * @memberof TariffPlan
     */
    id: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof TariffPlan
     */
    users?: Array<User>;
    /**
     * 
     * @type {string}
     * @memberof TariffPlan
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TariffPlan
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof TariffPlan
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface Terms
 */
export interface Terms {
    /**
     * 
     * @type {Content}
     * @memberof Terms
     */
    privacyPolicy: Content;
    /**
     * 
     * @type {Content}
     * @memberof Terms
     */
    licenseAgreement: Content;
    /**
     * 
     * @type {Content}
     * @memberof Terms
     */
    termsOfUse: Content;
}
/**
 * 
 * @export
 * @interface TinkoffE2cNotificationDto
 */
export interface TinkoffE2cNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    TerminalKey?: number;
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    CustomerKey?: number;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    RequestKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TinkoffE2cNotificationDto
     */
    Success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    Status?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    PaymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    ErrorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    CardId?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    Pan?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    ExpDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    NotificationType?: TinkoffE2cNotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    RebillId?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffE2cNotificationDto
     */
    Token?: string;
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    OrderId?: number;
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    Amount?: number;
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    PaymentURL?: number;
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    Message?: number;
    /**
     * 
     * @type {number}
     * @memberof TinkoffE2cNotificationDto
     */
    Details?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TinkoffE2cNotificationDtoNotificationTypeEnum {
    Linkcard = 'LINKCARD'
}

/**
 * 
 * @export
 * @interface TinkoffEacqNotificationDto
 */
export interface TinkoffEacqNotificationDto {
    /**
     * 
     * @type {any}
     * @memberof TinkoffEacqNotificationDto
     */
    Status?: any;
    /**
     * 
     * @type {number}
     * @memberof TinkoffEacqNotificationDto
     */
    OrderId?: number;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    RequestKey?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    RebillId?: string;
    /**
     * 
     * @type {number}
     * @memberof TinkoffEacqNotificationDto
     */
    CustomerKey?: number;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    PaymentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TinkoffEacqNotificationDto
     */
    Success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    NotificationType?: TinkoffEacqNotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    CardId?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    Pan?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    ExpDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    Token?: string;
    /**
     * 
     * @type {string}
     * @memberof TinkoffEacqNotificationDto
     */
    SpAccumulationId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TinkoffEacqNotificationDtoNotificationTypeEnum {
    PaymentCreated = 'PAYMENT_CREATED',
    PaymentAuthorized = 'PAYMENT_AUTHORIZED',
    PaymentConfirmed = 'PAYMENT_CONFIRMED',
    PaymentReversed = 'PAYMENT_REVERSED',
    PaymentRefunded = 'PAYMENT_REFUNDED',
    PaymentRejected = 'PAYMENT_REJECTED',
    PayChecking = 'PAY_CHECKING'
}

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    bankPaymentId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    type: TransactionTypeEnum;
    /**
     * 
     * @type {Payment}
     * @memberof Transaction
     */
    payment: Payment;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    paymentId: number;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionTypeEnum {
    InitDebitSession = 'initDebitSession',
    AuthorizeDebitSession = 'authorizeDebitSession',
    ConfirmDebitSession = 'confirmDebitSession',
    InitTransferSession = 'initTransferSession',
    ConfirmTransferSession = 'confirmTransferSession',
    Refunded = 'refunded',
    CardAttached = 'cardAttached',
    InitWithdrawal = 'initWithdrawal',
    ConfirmWithdrawal = 'confirmWithdrawal'
}

/**
 * 
 * @export
 * @interface UpdateAboutDto
 */
export interface UpdateAboutDto {
    /**
     * 
     * @type {Content}
     * @memberof UpdateAboutDto
     */
    about?: Content;
    /**
     * 
     * @type {Array<Faq>}
     * @memberof UpdateAboutDto
     */
    faq?: Array<Faq>;
    /**
     * 
     * @type {Terms}
     * @memberof UpdateAboutDto
     */
    terms?: Terms;
}
/**
 * 
 * @export
 * @interface UpdateAdminArticleDto
 */
export interface UpdateAdminArticleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminArticleDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminArticleDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminArticleDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminArticleDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminArticleDto
     */
    categoryId?: number;
}
/**
 * 
 * @export
 * @interface UpdateAdminUserDto
 */
export interface UpdateAdminUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isEmailVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    emailVerificationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    aboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    personalSite?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    diplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    instagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    vKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    telegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    youtubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    odnoklassnikiUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    hasFreeConsultations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    hasFreeChat?: boolean;
    /**
     * 
     * @type {any}
     * @memberof UpdateAdminUserDto
     */
    ratingMetaData?: any;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    deletedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isProfileVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    videoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isExpert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    timeToBecomeAnExpert?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    totalEarned?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    totalPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    expertConsultationsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    outgoingReviewCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    reviewsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    expertRating?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    ratingPositionWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    outgoingClientRating?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    clientConsultationsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    clientBadReviewsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    expertLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    freeConsultationsRemain?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    consultationAveragePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    clientLikesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    clientDislikesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    expertLikesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    expertDislikesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    block?: UpdateAdminUserDtoBlockEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    reasonBlock?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isApproved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    lastExitTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    refreshZoomToken?: string;
    /**
     * 
     * @type {Array<ConsultationRequest>}
     * @memberof UpdateAdminUserDto
     */
    proposedConsultationRequests?: Array<ConsultationRequest>;
    /**
     * 
     * @type {NotificationsSetting}
     * @memberof UpdateAdminUserDto
     */
    notificationsSettings?: NotificationsSetting;
    /**
     * 
     * @type {Array<VerificationRequest>}
     * @memberof UpdateAdminUserDto
     */
    profileVerificationRequests?: Array<VerificationRequest>;
    /**
     * 
     * @type {Array<ChatRoom>}
     * @memberof UpdateAdminUserDto
     */
    clientChatRooms?: Array<ChatRoom>;
    /**
     * 
     * @type {Array<ChatRoom>}
     * @memberof UpdateAdminUserDto
     */
    expertChatRooms?: Array<ChatRoom>;
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof UpdateAdminUserDto
     */
    clientConsultations?: Array<Consultation>;
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof UpdateAdminUserDto
     */
    expertConsultations?: Array<Consultation>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof UpdateAdminUserDto
     */
    messages?: Array<Message>;
    /**
     * 
     * @type {Array<ConsultationOffer>}
     * @memberof UpdateAdminUserDto
     */
    consultationOffers?: Array<ConsultationOffer>;
    /**
     * 
     * @type {Array<Promocode>}
     * @memberof UpdateAdminUserDto
     */
    personalPromocodes?: Array<Promocode>;
    /**
     * 
     * @type {Array<UserAchievement>}
     * @memberof UpdateAdminUserDto
     */
    achievements?: Array<UserAchievement>;
    /**
     * 
     * @type {Array<UserCategoryMeta>}
     * @memberof UpdateAdminUserDto
     */
    categories?: Array<UserCategoryMeta>;
    /**
     * 
     * @type {ContentChangeRequest}
     * @memberof UpdateAdminUserDto
     */
    contentChangeRequest?: ContentChangeRequest;
    /**
     * 
     * @type {Wallet}
     * @memberof UpdateAdminUserDto
     */
    wallet?: Wallet;
    /**
     * 
     * @type {TariffPlan}
     * @memberof UpdateAdminUserDto
     */
    tariffPlan?: TariffPlan;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdminUserDto
     */
    tariffPlanId?: number;
    /**
     * 
     * @type {Array<ExtraService>}
     * @memberof UpdateAdminUserDto
     */
    offeredExtraServices?: Array<ExtraService>;
    /**
     * 
     * @type {Array<WithdrawalRequest>}
     * @memberof UpdateAdminUserDto
     */
    withdrawalRequests?: Array<WithdrawalRequest>;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    tinkoffSpAccumulationId?: string;
    /**
     * 
     * @type {Contract}
     * @memberof UpdateAdminUserDto
     */
    contract?: Contract;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isContractAccepted?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminUserDto
     */
    fcmTokens?: Array<string>;
    /**
     * 
     * @type {Array<Receipt>}
     * @memberof UpdateAdminUserDto
     */
    receipts?: Array<Receipt>;
    /**
     * 
     * @type {UtmMark}
     * @memberof UpdateAdminUserDto
     */
    utmMark?: UtmMark;
    /**
     * 
     * @type {Array<SuggestedCategory>}
     * @memberof UpdateAdminUserDto
     */
    suggestedCategories?: Array<SuggestedCategory>;
    /**
     * 
     * @type {Array<AnswerNpsPoll>}
     * @memberof UpdateAdminUserDto
     */
    answerNpsPolls?: Array<AnswerNpsPoll>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isExpertContacted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    expertRejection?: UpdateAdminUserDtoExpertRejectionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    commentExpertRejection?: string;
    /**
     * 
     * @type {Array<News>}
     * @memberof UpdateAdminUserDto
     */
    news?: Array<News>;
    /**
     * 
     * @type {Array<CustomRequest>}
     * @memberof UpdateAdminUserDto
     */
    customRequests?: Array<CustomRequest>;
    /**
     * 
     * @type {Array<News>}
     * @memberof UpdateAdminUserDto
     */
    userNews?: Array<News>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminUserDto
     */
    isSeeModalWindow?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminUserDto
     */
    steps?: Array<UpdateAdminUserDtoStepsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminUserDto
     */
    sendedEmails?: Array<UpdateAdminUserDtoSendedEmailsEnum>;
    /**
     * 
     * @type {ExpertRating}
     * @memberof UpdateAdminUserDto
     */
    expertRatingTable?: ExpertRating;
    /**
     * 
     * @type {Portfolio}
     * @memberof UpdateAdminUserDto
     */
    portfolio?: Portfolio;
    /**
     * 
     * @type {Array<ExpertService>}
     * @memberof UpdateAdminUserDto
     */
    expertServices?: Array<ExpertService>;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateAdminUserDtoBlockEnum {
    Full = 'full',
    Topic = 'topic',
    Partial = 'partial',
    Unblock = 'unblock'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateAdminUserDtoExpertRejectionEnum {
    DidNotAnswerUs = 'didNotAnswerUs',
    DoNotUnderstandWhatTheServiceIsAbout = 'doNotUnderstandWhatTheServiceIsAbout',
    WhileNotConsulting = 'whileNotConsulting',
    BigCommission = 'bigCommission',
    DidNotLikeTheSite = 'didNotLikeTheSite',
    AnotherReason = 'anotherReason'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateAdminUserDtoStepsEnum {
    _0checkboxexpert = 'step-0checkboxexpert',
    _1start = 'step-1start',
    _2photo = 'step-2photo',
    _3about = 'step-3about',
    _4theme = 'step-4theme',
    _5profile = 'step-5profile',
    _6doc = 'step-6doc',
    _7rek = 'step-7rek',
    _8done = 'step-8done'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateAdminUserDtoSendedEmailsEnum {
    Wellcome = 'wellcome',
    After3Hours = 'after_3_hours',
    After3Days = 'after_3_days'
}

/**
 * 
 * @export
 * @interface UpdateConsultationRequestDto
 */
export interface UpdateConsultationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateConsultationRequestDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateConsultationRequestDto
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsultationRequestDto
     */
    lastReadTime?: string;
}
/**
 * 
 * @export
 * @interface UpdateContentChangeRequestDto
 */
export interface UpdateContentChangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateContentChangeRequestDto
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContentChangeRequestDto
     */
    aboutSelf?: string;
}
/**
 * 
 * @export
 * @interface UpdateContractDto
 */
export interface UpdateContractDto {
    /**
     * 
     * @type {IEContractPayloadDto | IndividualContractPayloadDto | LLCContractPayloadDto | SelfEmployedContractPayloadDto}
     * @memberof UpdateContractDto
     */
    payload?: IEContractPayloadDto | IndividualContractPayloadDto | LLCContractPayloadDto | SelfEmployedContractPayloadDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateContractDto
     */
    adminComments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    pdfUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    userIpAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    timestampSendSignSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    timestampEnterSignSmsCode?: string;
}
/**
 * 
 * @export
 * @interface UpdateCustomRequestDto
 */
export interface UpdateCustomRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomRequestDto
     */
    type?: UpdateCustomRequestDtoTypeEnum;
    /**
     * 
     * @type {PersonalPromocodeRequestPayload | SpecialTariffRequestPayload}
     * @memberof UpdateCustomRequestDto
     */
    payload?: PersonalPromocodeRequestPayload | SpecialTariffRequestPayload;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomRequestDto
     */
    promocodeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCustomRequestDtoTypeEnum {
    PersonalPromocode = 'personalPromocode',
    SpecialTariff = 'specialTariff'
}

/**
 * 
 * @export
 * @interface UpdateDefaultPaymentMethodDto
 */
export interface UpdateDefaultPaymentMethodDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDefaultPaymentMethodDto
     */
    paymentMethodId: number;
}
/**
 * 
 * @export
 * @interface UpdateExpertServiceDto
 */
export interface UpdateExpertServiceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateExpertServiceDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExpertServiceDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateExpertServiceDto
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface UpdateFCMTokensDto
 */
export interface UpdateFCMTokensDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFCMTokensDto
     */
    tokens: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateIndividualContractDto
 */
export interface UpdateIndividualContractDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualContractDto
     */
    timestampEnterSignSmsCode?: string;
    /**
     * 
     * @type {IndividualContractPayloadDto}
     * @memberof UpdateIndividualContractDto
     */
    payload?: IndividualContractPayloadDto;
}
/**
 * 
 * @export
 * @interface UpdateLastExitTimeDto
 */
export interface UpdateLastExitTimeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLastExitTimeDto
     */
    lastExitTime?: string;
}
/**
 * 
 * @export
 * @interface UpdateLastReadMessageDto
 */
export interface UpdateLastReadMessageDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateLastReadMessageDto
     */
    messageId: number;
}
/**
 * 
 * @export
 * @interface UpdateNewsDto
 */
export interface UpdateNewsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    toWhom: UpdateNewsDtoToWhomEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    scheduledTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateNewsDtoToWhomEnum {
    All = 'all',
    Expert = 'expert',
    Client = 'client'
}

/**
 * 
 * @export
 * @interface UpdateNotificationsSettingDto
 */
export interface UpdateNotificationsSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultRequestTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultRequestSMS: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultRequestPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultStartTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultStartSMS: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultStartPush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultMessageTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultMessageSMS: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onConsultMessagePush: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onNewCategoryTelegram: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsSettingDto
     */
    onNewCategorySMS: boolean;
}
/**
 * 
 * @export
 * @interface UpdateOneCategoryDto
 */
export interface UpdateOneCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOneCategoryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneCategoryDto
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneCategoryDto
     */
    iconUrl?: string;
    /**
     * 
     * @type {Category}
     * @memberof UpdateOneCategoryDto
     */
    rootCategory?: Category;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneCategoryDto
     */
    SEODescription?: string;
}
/**
 * 
 * @export
 * @interface UpdatePromocodeDto
 */
export interface UpdatePromocodeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePromocodeDto
     */
    type?: UpdatePromocodeDtoTypeEnum;
    /**
     * 
     * @type {Privilege}
     * @memberof UpdatePromocodeDto
     */
    privilege?: Privilege;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromocodeDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromocodeDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromocodeDto
     */
    expiresIn?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromocodeDto
     */
    customRequestId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePromocodeDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {CustomRequest}
     * @memberof UpdatePromocodeDto
     */
    customRequest?: CustomRequest;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdatePromocodeDtoTypeEnum {
    FreeConsultation = 'freeConsultation'
}

/**
 * 
 * @export
 * @interface UpdateReviewDto
 */
export interface UpdateReviewDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateReviewDto
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateReviewDto
     */
    clientId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateReviewDto
     */
    expertId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateReviewDto
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReviewDto
     */
    isPromptly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReviewDto
     */
    onPositive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReviewDto
     */
    isSavvy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateReviewDto
     */
    wantRefund?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateReviewDto
     */
    consultationId?: number;
}
/**
 * 
 * @export
 * @interface UpdateRubricDto
 */
export interface UpdateRubricDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRubricDto
     */
    SEODescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRubricDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface UpdateSelfEmployedContractDto
 */
export interface UpdateSelfEmployedContractDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSelfEmployedContractDto
     */
    timestampAcceptExpertOffer?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSelfEmployedContractDto
     */
    timestampAcceptPrivacyPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSelfEmployedContractDto
     */
    signSmsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSelfEmployedContractDto
     */
    timestampEnterSignSmsCode?: string;
    /**
     * 
     * @type {SelfEmployedContractPayloadDto}
     * @memberof UpdateSelfEmployedContractDto
     */
    payload?: SelfEmployedContractPayloadDto;
}
/**
 * 
 * @export
 * @interface UpdateTariffPlanDto
 */
export interface UpdateTariffPlanDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateTariffPlanDto
     */
    commissionPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTariffPlanDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTariffPlanDto
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTariffPlanDto
     */
    isDefault?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserCategoryMetaDto
 */
export interface UpdateUserCategoryMetaDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserCategoryMetaDto
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserCategoryMetaDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCategoryMetaDto
     */
    lastReadTime?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    hasFreeConsultations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    hasFreeChat?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserProfileDto
 */
export interface UpdateUserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    aboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    birthDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserProfileDto
     */
    hasFreeConsultations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserProfileDto
     */
    hasFreeChat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserProfileDto
     */
    isExpert?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserProfileDto
     */
    isApproved?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserTariffPlanDto
 */
export interface UpdateUserTariffPlanDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserTariffPlanDto
     */
    tariffPlanId: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserTariffPlanDto
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface UpdateVerificationRequestDto
 */
export interface UpdateVerificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    instagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    vKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    telegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    youtubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    odnoklassnikiUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    diplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    reviewsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVerificationRequestDto
     */
    personalSite?: string;
}
/**
 * 
 * @export
 * @interface UsedPromocodePrivileges
 */
export interface UsedPromocodePrivileges {
    /**
     * 
     * @type {number}
     * @memberof UsedPromocodePrivileges
     */
    usedFreeConsultations?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isEmailVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    emailVerificationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    aboutSelf?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    personalSite?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    diplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    instagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    vKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    telegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    youtubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    odnoklassnikiUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    hasFreeConsultations?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    hasFreeChat?: boolean;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    ratingMetaData: any;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isProfileVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    videoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isExpert: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    timeToBecomeAnExpert?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    totalEarned: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    totalPayed: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    expertConsultationsCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    outgoingReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    reviewsCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    expertRating?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    ratingPositionWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    outgoingClientRating?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    clientConsultationsCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    clientBadReviewsCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    expertLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    freeConsultationsRemain: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    consultationAveragePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    clientLikesCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    clientDislikesCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    expertLikesCount: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    expertDislikesCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    block: UserBlockEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    reasonBlock?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isApproved: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastExitTime?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    refreshZoomToken: string;
    /**
     * 
     * @type {Array<ConsultationRequest>}
     * @memberof User
     */
    proposedConsultationRequests?: Array<ConsultationRequest>;
    /**
     * 
     * @type {NotificationsSetting}
     * @memberof User
     */
    notificationsSettings?: NotificationsSetting;
    /**
     * 
     * @type {Array<VerificationRequest>}
     * @memberof User
     */
    profileVerificationRequests?: Array<VerificationRequest>;
    /**
     * 
     * @type {Array<ChatRoom>}
     * @memberof User
     */
    clientChatRooms?: Array<ChatRoom>;
    /**
     * 
     * @type {Array<ChatRoom>}
     * @memberof User
     */
    expertChatRooms?: Array<ChatRoom>;
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof User
     */
    clientConsultations?: Array<Consultation>;
    /**
     * 
     * @type {Array<Consultation>}
     * @memberof User
     */
    expertConsultations?: Array<Consultation>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof User
     */
    messages?: Array<Message>;
    /**
     * 
     * @type {Array<ConsultationOffer>}
     * @memberof User
     */
    consultationOffers?: Array<ConsultationOffer>;
    /**
     * 
     * @type {Array<Promocode>}
     * @memberof User
     */
    personalPromocodes?: Array<Promocode>;
    /**
     * 
     * @type {Array<UserAchievement>}
     * @memberof User
     */
    achievements?: Array<UserAchievement>;
    /**
     * 
     * @type {Array<UserCategoryMeta>}
     * @memberof User
     */
    categories?: Array<UserCategoryMeta>;
    /**
     * 
     * @type {ContentChangeRequest}
     * @memberof User
     */
    contentChangeRequest?: ContentChangeRequest;
    /**
     * 
     * @type {Wallet}
     * @memberof User
     */
    wallet?: Wallet;
    /**
     * 
     * @type {TariffPlan}
     * @memberof User
     */
    tariffPlan?: TariffPlan;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    tariffPlanId?: number;
    /**
     * 
     * @type {Array<ExtraService>}
     * @memberof User
     */
    offeredExtraServices: Array<ExtraService>;
    /**
     * 
     * @type {Array<WithdrawalRequest>}
     * @memberof User
     */
    withdrawalRequests?: Array<WithdrawalRequest>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    tinkoffSpAccumulationId?: string;
    /**
     * 
     * @type {Contract}
     * @memberof User
     */
    contract?: Contract;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isContractAccepted: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    fcmTokens?: Array<string>;
    /**
     * 
     * @type {Array<Receipt>}
     * @memberof User
     */
    receipts?: Array<Receipt>;
    /**
     * 
     * @type {UtmMark}
     * @memberof User
     */
    utmMark?: UtmMark;
    /**
     * 
     * @type {Array<SuggestedCategory>}
     * @memberof User
     */
    suggestedCategories?: Array<SuggestedCategory>;
    /**
     * 
     * @type {Array<AnswerNpsPoll>}
     * @memberof User
     */
    answerNpsPolls?: Array<AnswerNpsPoll>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isExpertContacted: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    expertRejection: UserExpertRejectionEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    commentExpertRejection: string;
    /**
     * 
     * @type {Array<News>}
     * @memberof User
     */
    news?: Array<News>;
    /**
     * 
     * @type {Array<CustomRequest>}
     * @memberof User
     */
    customRequests?: Array<CustomRequest>;
    /**
     * 
     * @type {Array<News>}
     * @memberof User
     */
    userNews?: Array<News>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isSeeModalWindow: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    steps: Array<UserStepsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    sendedEmails: Array<UserSendedEmailsEnum>;
    /**
     * 
     * @type {ExpertRating}
     * @memberof User
     */
    expertRatingTable: ExpertRating;
    /**
     * 
     * @type {Portfolio}
     * @memberof User
     */
    portfolio: Portfolio;
    /**
     * 
     * @type {Array<ExpertService>}
     * @memberof User
     */
    expertServices: Array<ExpertService>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserBlockEnum {
    Full = 'full',
    Topic = 'topic',
    Partial = 'partial',
    Unblock = 'unblock'
}
/**
    * @export
    * @enum {string}
    */
export enum UserExpertRejectionEnum {
    DidNotAnswerUs = 'didNotAnswerUs',
    DoNotUnderstandWhatTheServiceIsAbout = 'doNotUnderstandWhatTheServiceIsAbout',
    WhileNotConsulting = 'whileNotConsulting',
    BigCommission = 'bigCommission',
    DidNotLikeTheSite = 'didNotLikeTheSite',
    AnotherReason = 'anotherReason'
}
/**
    * @export
    * @enum {string}
    */
export enum UserStepsEnum {
    _0checkboxexpert = 'step-0checkboxexpert',
    _1start = 'step-1start',
    _2photo = 'step-2photo',
    _3about = 'step-3about',
    _4theme = 'step-4theme',
    _5profile = 'step-5profile',
    _6doc = 'step-6doc',
    _7rek = 'step-7rek',
    _8done = 'step-8done'
}
/**
    * @export
    * @enum {string}
    */
export enum UserSendedEmailsEnum {
    Wellcome = 'wellcome',
    After3Hours = 'after_3_hours',
    After3Days = 'after_3_days'
}

/**
 * 
 * @export
 * @interface UserAchievement
 */
export interface UserAchievement {
    /**
     * 
     * @type {number}
     * @memberof UserAchievement
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserAchievement
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof UserAchievement
     */
    achievementId: number;
    /**
     * 
     * @type {User}
     * @memberof UserAchievement
     */
    user: User;
    /**
     * 
     * @type {Achievement}
     * @memberof UserAchievement
     */
    achievement: Achievement;
    /**
     * 
     * @type {string}
     * @memberof UserAchievement
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserAchievement
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserAchievement
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface UserCategoryMeta
 */
export interface UserCategoryMeta {
    /**
     * 
     * @type {number}
     * @memberof UserCategoryMeta
     */
    categoryId: number;
    /**
     * 
     * @type {number}
     * @memberof UserCategoryMeta
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof UserCategoryMeta
     */
    lastReadTime?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCategoryMeta
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof UserCategoryMeta
     */
    user: User;
    /**
     * 
     * @type {number}
     * @memberof UserCategoryMeta
     */
    userId: number;
    /**
     * 
     * @type {Category}
     * @memberof UserCategoryMeta
     */
    category: Category;
    /**
     * 
     * @type {string}
     * @memberof UserCategoryMeta
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserCategoryMeta
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserCategoryMeta
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface UserPromocode
 */
export interface UserPromocode {
    /**
     * 
     * @type {number}
     * @memberof UserPromocode
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof UserPromocode
     */
    user: User;
    /**
     * 
     * @type {number}
     * @memberof UserPromocode
     */
    userId: number;
    /**
     * 
     * @type {Promocode}
     * @memberof UserPromocode
     */
    promocode: Promocode;
    /**
     * 
     * @type {number}
     * @memberof UserPromocode
     */
    promocodeId: number;
    /**
     * 
     * @type {UsedPromocodePrivileges}
     * @memberof UserPromocode
     */
    usedPrivileges?: UsedPromocodePrivileges;
    /**
     * 
     * @type {string}
     * @memberof UserPromocode
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserPromocode
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UserPromocode
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface UtmMark
 */
export interface UtmMark {
    /**
     * 
     * @type {number}
     * @memberof UtmMark
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UtmMark
     */
    utm_source?: string;
    /**
     * 
     * @type {string}
     * @memberof UtmMark
     */
    utm_term?: string;
    /**
     * 
     * @type {string}
     * @memberof UtmMark
     */
    utm_campaign?: string;
    /**
     * 
     * @type {User}
     * @memberof UtmMark
     */
    user: User;
    /**
     * 
     * @type {number}
     * @memberof UtmMark
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UtmMark
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof UtmMark
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof UtmMark
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface VerificationRequest
 */
export interface VerificationRequest {
    /**
     * 
     * @type {number}
     * @memberof VerificationRequest
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof VerificationRequest
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentVideoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    instagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentInstagramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    facebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentFacebookUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    vKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentVKontakteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    telegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentTelegramUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    youtubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentYoutubeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    odnoklassnikiUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentOdnoklassnikiUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    diplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentDiplomasUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    reviewsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentReviewsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    personalSite?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    commentPersonalSite?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    status: VerificationRequestStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof VerificationRequest
     */
    author: User;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VerificationRequestStatusEnum {
    Pending = 'pending',
    Rejected = 'rejected',
    Fulfilled = 'fulfilled'
}

/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * 
     * @type {number}
     * @memberof Wallet
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof Wallet
     */
    owner: User;
    /**
     * 
     * @type {number}
     * @memberof Wallet
     */
    ownerId: number;
    /**
     * 
     * @type {number}
     * @memberof Wallet
     */
    balance: number;
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof Wallet
     */
    paymentMethods?: Array<PaymentMethod>;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Wallet
     */
    defaultPaymentMethod?: PaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof Wallet
     */
    defaultPaymentMethodId?: number;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Wallet
     */
    outgoingPayments?: Array<Payment>;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Wallet
     */
    incomingPayments?: Array<Payment>;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface WithdrawalRequest
 */
export interface WithdrawalRequest {
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    status: WithdrawalRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequest
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalRequest
     */
    ownerId: number;
    /**
     * 
     * @type {User}
     * @memberof WithdrawalRequest
     */
    owner: User;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalRequest
     */
    deletedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WithdrawalRequestStatusEnum {
    Fulfilled = 'fulfilled',
    Pending = 'pending',
    Rejected = 'rejected'
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheckApi(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: any) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheckApi(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerCheckApi(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AboutServiceApi - axios parameter creator
 * @export
 */
export const V1AboutServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get about service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutControllerGetOne: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AboutServiceApi - functional programming interface
 * @export
 */
export const V1AboutServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AboutServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get about service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutControllerGetOne(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<About>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutControllerGetOne(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AboutServiceApi - factory interface
 * @export
 */
export const V1AboutServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AboutServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get about service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutControllerGetOne(options?: any): AxiosPromise<About> {
            return localVarFp.aboutControllerGetOne(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AboutServiceApi - object-oriented interface
 * @export
 * @class V1AboutServiceApi
 * @extends {BaseAPI}
 */
export class V1AboutServiceApi extends BaseAPI {
    /**
     * 
     * @summary Get about service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AboutServiceApi
     */
    public aboutControllerGetOne(options?: any) {
        return V1AboutServiceApiFp(this.configuration).aboutControllerGetOne(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AchievementsApi - axios parameter creator
 * @export
 */
export const V1AchievementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get many achievements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        achievementsControllerGetMany: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/achievements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AchievementsApi - functional programming interface
 * @export
 */
export const V1AchievementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AchievementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get many achievements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async achievementsControllerGetMany(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Achievement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.achievementsControllerGetMany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AchievementsApi - factory interface
 * @export
 */
export const V1AchievementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AchievementsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get many achievements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        achievementsControllerGetMany(options?: any): AxiosPromise<Array<Achievement>> {
            return localVarFp.achievementsControllerGetMany(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AchievementsApi - object-oriented interface
 * @export
 * @class V1AchievementsApi
 * @extends {BaseAPI}
 */
export class V1AchievementsApi extends BaseAPI {
    /**
     * 
     * @summary Get many achievements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AchievementsApi
     */
    public achievementsControllerGetMany(options?: any) {
        return V1AchievementsApiFp(this.configuration).achievementsControllerGetMany(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminAboutApi - axios parameter creator
 * @export
 */
export const V1AdminAboutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get about service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutControllerGetOne: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update about service
         * @param {UpdateAboutDto} updateAboutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutControllerUpdate: async (updateAboutDto: UpdateAboutDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAboutDto' is not null or undefined
            assertParamExists('aboutControllerUpdate', 'updateAboutDto', updateAboutDto)
            const localVarPath = `/v1/admin/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAboutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminAboutApi - functional programming interface
 * @export
 */
export const V1AdminAboutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminAboutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get about service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutControllerGetOne(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<About>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutControllerGetOne(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update about service
         * @param {UpdateAboutDto} updateAboutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutControllerUpdate(updateAboutDto: UpdateAboutDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<About>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutControllerUpdate(updateAboutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminAboutApi - factory interface
 * @export
 */
export const V1AdminAboutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminAboutApiFp(configuration)
    return {
        /**
         * 
         * @summary Get about service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutControllerGetOne(options?: any): AxiosPromise<About> {
            return localVarFp.aboutControllerGetOne(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update about service
         * @param {UpdateAboutDto} updateAboutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutControllerUpdate(updateAboutDto: UpdateAboutDto, options?: any): AxiosPromise<About> {
            return localVarFp.aboutControllerUpdate(updateAboutDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminAboutApi - object-oriented interface
 * @export
 * @class V1AdminAboutApi
 * @extends {BaseAPI}
 */
export class V1AdminAboutApi extends BaseAPI {
    /**
     * 
     * @summary Get about service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminAboutApi
     */
    public aboutControllerGetOne(options?: any) {
        return V1AdminAboutApiFp(this.configuration).aboutControllerGetOne(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update about service
     * @param {UpdateAboutDto} updateAboutDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminAboutApi
     */
    public aboutControllerUpdate(updateAboutDto: UpdateAboutDto, options?: any) {
        return V1AdminAboutApiFp(this.configuration).aboutControllerUpdate(updateAboutDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminAnswerNpsPollsApi - axios parameter creator
 * @export
 */
export const V1AdminAnswerNpsPollsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple AnswerNpsPolls
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/answer-nps-polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single AnswerNpsPoll
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll', 'id', id)
            const localVarPath = `/v1/admin/answer-nps-polls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminAnswerNpsPollsApi - functional programming interface
 * @export
 */
export const V1AdminAnswerNpsPollsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminAnswerNpsPollsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple AnswerNpsPolls
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyAnswerNpsPollResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single AnswerNpsPoll
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswerNpsPoll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminAnswerNpsPollsApi - factory interface
 * @export
 */
export const V1AdminAnswerNpsPollsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminAnswerNpsPollsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple AnswerNpsPolls
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyAnswerNpsPollResponseDto> {
            return localVarFp.getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single AnswerNpsPoll
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<AnswerNpsPoll> {
            return localVarFp.getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminAnswerNpsPollsApi - object-oriented interface
 * @export
 * @class V1AdminAnswerNpsPollsApi
 * @extends {BaseAPI}
 */
export class V1AdminAnswerNpsPollsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple AnswerNpsPolls
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminAnswerNpsPollsApi
     */
    public getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminAnswerNpsPollsApiFp(this.configuration).getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single AnswerNpsPoll
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminAnswerNpsPollsApi
     */
    public getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminAnswerNpsPollsApiFp(this.configuration).getOneBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminArticlesApi - axios parameter creator
 * @export
 */
export const V1AdminArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Article
         * @param {CreateAdminArticleDto} createAdminArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminArticlesControllerArticle: async (createAdminArticleDto: CreateAdminArticleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminArticleDto' is not null or undefined
            assertParamExists('createOneBaseAdminArticlesControllerArticle', 'createAdminArticleDto', createAdminArticleDto)
            const localVarPath = `/v1/admin/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminArticleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Article
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminArticlesControllerArticle: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminArticlesControllerArticle', 'id', id)
            const localVarPath = `/v1/admin/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Articles
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminArticlesControllerArticle: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Article
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminArticlesControllerArticle: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminArticlesControllerArticle', 'id', id)
            const localVarPath = `/v1/admin/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Article
         * @param {number} id 
         * @param {UpdateAdminArticleDto} updateAdminArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminArticlesControllerArticle: async (id: number, updateAdminArticleDto: UpdateAdminArticleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminArticlesControllerArticle', 'id', id)
            // verify required parameter 'updateAdminArticleDto' is not null or undefined
            assertParamExists('updateOneBaseAdminArticlesControllerArticle', 'updateAdminArticleDto', updateAdminArticleDto)
            const localVarPath = `/v1/admin/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminArticleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminArticlesApi - functional programming interface
 * @export
 */
export const V1AdminArticlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminArticlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Article
         * @param {CreateAdminArticleDto} createAdminArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminArticlesControllerArticle(createAdminArticleDto: CreateAdminArticleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminArticlesControllerArticle(createAdminArticleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Article
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminArticlesControllerArticle(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminArticlesControllerArticle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Articles
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminArticlesControllerArticle(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyArticleResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminArticlesControllerArticle(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Article
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminArticlesControllerArticle(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminArticlesControllerArticle(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Article
         * @param {number} id 
         * @param {UpdateAdminArticleDto} updateAdminArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminArticlesControllerArticle(id: number, updateAdminArticleDto: UpdateAdminArticleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminArticlesControllerArticle(id, updateAdminArticleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminArticlesApi - factory interface
 * @export
 */
export const V1AdminArticlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminArticlesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Article
         * @param {CreateAdminArticleDto} createAdminArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminArticlesControllerArticle(createAdminArticleDto: CreateAdminArticleDto, options?: any): AxiosPromise<Article> {
            return localVarFp.createOneBaseAdminArticlesControllerArticle(createAdminArticleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Article
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminArticlesControllerArticle(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminArticlesControllerArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Articles
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminArticlesControllerArticle(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyArticleResponseDto> {
            return localVarFp.getManyBaseAdminArticlesControllerArticle(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Article
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminArticlesControllerArticle(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Article> {
            return localVarFp.getOneBaseAdminArticlesControllerArticle(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Article
         * @param {number} id 
         * @param {UpdateAdminArticleDto} updateAdminArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminArticlesControllerArticle(id: number, updateAdminArticleDto: UpdateAdminArticleDto, options?: any): AxiosPromise<Article> {
            return localVarFp.updateOneBaseAdminArticlesControllerArticle(id, updateAdminArticleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminArticlesApi - object-oriented interface
 * @export
 * @class V1AdminArticlesApi
 * @extends {BaseAPI}
 */
export class V1AdminArticlesApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Article
     * @param {CreateAdminArticleDto} createAdminArticleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminArticlesApi
     */
    public createOneBaseAdminArticlesControllerArticle(createAdminArticleDto: CreateAdminArticleDto, options?: any) {
        return V1AdminArticlesApiFp(this.configuration).createOneBaseAdminArticlesControllerArticle(createAdminArticleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Article
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminArticlesApi
     */
    public deleteOneBaseAdminArticlesControllerArticle(id: number, options?: any) {
        return V1AdminArticlesApiFp(this.configuration).deleteOneBaseAdminArticlesControllerArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Articles
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminArticlesApi
     */
    public getManyBaseAdminArticlesControllerArticle(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminArticlesApiFp(this.configuration).getManyBaseAdminArticlesControllerArticle(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Article
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminArticlesApi
     */
    public getOneBaseAdminArticlesControllerArticle(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminArticlesApiFp(this.configuration).getOneBaseAdminArticlesControllerArticle(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Article
     * @param {number} id 
     * @param {UpdateAdminArticleDto} updateAdminArticleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminArticlesApi
     */
    public updateOneBaseAdminArticlesControllerArticle(id: number, updateAdminArticleDto: UpdateAdminArticleDto, options?: any) {
        return V1AdminArticlesApiFp(this.configuration).updateOneBaseAdminArticlesControllerArticle(id, updateAdminArticleDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminCategoriesApi - axios parameter creator
 * @export
 */
export const V1AdminCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create one category
         * @param {CreateOneCategoryDto} createOneCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesControllerCreateOne: async (createOneCategoryDto: CreateOneCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOneCategoryDto' is not null or undefined
            assertParamExists('adminCategoriesControllerCreateOne', 'createOneCategoryDto', createOneCategoryDto)
            const localVarPath = `/v1/admin/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminCategoriesControllerCategory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminCategoriesControllerCategory', 'id', id)
            const localVarPath = `/v1/admin/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Categories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCategoriesControllerCategory: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Category
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCategoriesControllerCategory: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCategoriesControllerCategory', 'id', id)
            const localVarPath = `/v1/admin/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Category
         * @param {number} id 
         * @param {UpdateOneCategoryDto} updateOneCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminCategoriesControllerCategory: async (id: number, updateOneCategoryDto: UpdateOneCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminCategoriesControllerCategory', 'id', id)
            // verify required parameter 'updateOneCategoryDto' is not null or undefined
            assertParamExists('updateOneBaseAdminCategoriesControllerCategory', 'updateOneCategoryDto', updateOneCategoryDto)
            const localVarPath = `/v1/admin/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOneCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminCategoriesApi - functional programming interface
 * @export
 */
export const V1AdminCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create one category
         * @param {CreateOneCategoryDto} createOneCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoriesControllerCreateOne(createOneCategoryDto: CreateOneCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoriesControllerCreateOne(createOneCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminCategoriesControllerCategory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminCategoriesControllerCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Categories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCategoriesControllerCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyCategoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCategoriesControllerCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Category
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCategoriesControllerCategory(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCategoriesControllerCategory(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Category
         * @param {number} id 
         * @param {UpdateOneCategoryDto} updateOneCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminCategoriesControllerCategory(id: number, updateOneCategoryDto: UpdateOneCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminCategoriesControllerCategory(id, updateOneCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminCategoriesApi - factory interface
 * @export
 */
export const V1AdminCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create one category
         * @param {CreateOneCategoryDto} createOneCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoriesControllerCreateOne(createOneCategoryDto: CreateOneCategoryDto, options?: any): AxiosPromise<Category> {
            return localVarFp.adminCategoriesControllerCreateOne(createOneCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminCategoriesControllerCategory(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteOneBaseAdminCategoriesControllerCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Categories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCategoriesControllerCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyCategoryResponseDto> {
            return localVarFp.getManyBaseAdminCategoriesControllerCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Category
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCategoriesControllerCategory(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Category> {
            return localVarFp.getOneBaseAdminCategoriesControllerCategory(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Category
         * @param {number} id 
         * @param {UpdateOneCategoryDto} updateOneCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminCategoriesControllerCategory(id: number, updateOneCategoryDto: UpdateOneCategoryDto, options?: any): AxiosPromise<Category> {
            return localVarFp.updateOneBaseAdminCategoriesControllerCategory(id, updateOneCategoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminCategoriesApi - object-oriented interface
 * @export
 * @class V1AdminCategoriesApi
 * @extends {BaseAPI}
 */
export class V1AdminCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Create one category
     * @param {CreateOneCategoryDto} createOneCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCategoriesApi
     */
    public adminCategoriesControllerCreateOne(createOneCategoryDto: CreateOneCategoryDto, options?: any) {
        return V1AdminCategoriesApiFp(this.configuration).adminCategoriesControllerCreateOne(createOneCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCategoriesApi
     */
    public deleteOneBaseAdminCategoriesControllerCategory(id: number, options?: any) {
        return V1AdminCategoriesApiFp(this.configuration).deleteOneBaseAdminCategoriesControllerCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Categories
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCategoriesApi
     */
    public getManyBaseAdminCategoriesControllerCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminCategoriesApiFp(this.configuration).getManyBaseAdminCategoriesControllerCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Category
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCategoriesApi
     */
    public getOneBaseAdminCategoriesControllerCategory(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminCategoriesApiFp(this.configuration).getOneBaseAdminCategoriesControllerCategory(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Category
     * @param {number} id 
     * @param {UpdateOneCategoryDto} updateOneCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCategoriesApi
     */
    public updateOneBaseAdminCategoriesControllerCategory(id: number, updateOneCategoryDto: UpdateOneCategoryDto, options?: any) {
        return V1AdminCategoriesApiFp(this.configuration).updateOneBaseAdminCategoriesControllerCategory(id, updateOneCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminComplaintsApi - axios parameter creator
 * @export
 */
export const V1AdminComplaintsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Reviews
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminComplaintsControllerReview: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/complaints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Review
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminComplaintsControllerReview: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminComplaintsControllerReview', 'id', id)
            const localVarPath = `/v1/admin/complaints/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Review
         * @param {number} id 
         * @param {AdminUpdateComplaintDecisionDto} adminUpdateComplaintDecisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminComplaintsControllerReview: async (id: number, adminUpdateComplaintDecisionDto: AdminUpdateComplaintDecisionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminComplaintsControllerReview', 'id', id)
            // verify required parameter 'adminUpdateComplaintDecisionDto' is not null or undefined
            assertParamExists('updateOneBaseAdminComplaintsControllerReview', 'adminUpdateComplaintDecisionDto', adminUpdateComplaintDecisionDto)
            const localVarPath = `/v1/admin/complaints/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateComplaintDecisionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminComplaintsApi - functional programming interface
 * @export
 */
export const V1AdminComplaintsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminComplaintsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Reviews
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminComplaintsControllerReview(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyReviewResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminComplaintsControllerReview(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Review
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminComplaintsControllerReview(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminComplaintsControllerReview(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Review
         * @param {number} id 
         * @param {AdminUpdateComplaintDecisionDto} adminUpdateComplaintDecisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminComplaintsControllerReview(id: number, adminUpdateComplaintDecisionDto: AdminUpdateComplaintDecisionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminComplaintsControllerReview(id, adminUpdateComplaintDecisionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminComplaintsApi - factory interface
 * @export
 */
export const V1AdminComplaintsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminComplaintsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Reviews
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminComplaintsControllerReview(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyReviewResponseDto> {
            return localVarFp.getManyBaseAdminComplaintsControllerReview(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Review
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminComplaintsControllerReview(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Review> {
            return localVarFp.getOneBaseAdminComplaintsControllerReview(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Review
         * @param {number} id 
         * @param {AdminUpdateComplaintDecisionDto} adminUpdateComplaintDecisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminComplaintsControllerReview(id: number, adminUpdateComplaintDecisionDto: AdminUpdateComplaintDecisionDto, options?: any): AxiosPromise<Review> {
            return localVarFp.updateOneBaseAdminComplaintsControllerReview(id, adminUpdateComplaintDecisionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminComplaintsApi - object-oriented interface
 * @export
 * @class V1AdminComplaintsApi
 * @extends {BaseAPI}
 */
export class V1AdminComplaintsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Reviews
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminComplaintsApi
     */
    public getManyBaseAdminComplaintsControllerReview(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminComplaintsApiFp(this.configuration).getManyBaseAdminComplaintsControllerReview(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Review
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminComplaintsApi
     */
    public getOneBaseAdminComplaintsControllerReview(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminComplaintsApiFp(this.configuration).getOneBaseAdminComplaintsControllerReview(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Review
     * @param {number} id 
     * @param {AdminUpdateComplaintDecisionDto} adminUpdateComplaintDecisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminComplaintsApi
     */
    public updateOneBaseAdminComplaintsControllerReview(id: number, adminUpdateComplaintDecisionDto: AdminUpdateComplaintDecisionDto, options?: any) {
        return V1AdminComplaintsApiFp(this.configuration).updateOneBaseAdminComplaintsControllerReview(id, adminUpdateComplaintDecisionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminConsultationsApi - axios parameter creator
 * @export
 */
export const V1AdminConsultationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create multiple Consultations
         * @param {CreateManyConsultationDto} createManyConsultationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyBaseAdminConsultationsControllerConsultation: async (createManyConsultationDto: CreateManyConsultationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyConsultationDto' is not null or undefined
            assertParamExists('createManyBaseAdminConsultationsControllerConsultation', 'createManyConsultationDto', createManyConsultationDto)
            const localVarPath = `/v1/admin/consultations/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManyConsultationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single Consultation
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminConsultationsControllerConsultation: async (consultation: Consultation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultation' is not null or undefined
            assertParamExists('createOneBaseAdminConsultationsControllerConsultation', 'consultation', consultation)
            const localVarPath = `/v1/admin/consultations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consultation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminConsultationsControllerConsultation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminConsultationsControllerConsultation', 'id', id)
            const localVarPath = `/v1/admin/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Consultations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminConsultationsControllerConsultation: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/consultations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Consultation
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminConsultationsControllerConsultation: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminConsultationsControllerConsultation', 'id', id)
            const localVarPath = `/v1/admin/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Consultation
         * @param {number} id 
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneBaseAdminConsultationsControllerConsultation: async (id: number, consultation: Consultation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceOneBaseAdminConsultationsControllerConsultation', 'id', id)
            // verify required parameter 'consultation' is not null or undefined
            assertParamExists('replaceOneBaseAdminConsultationsControllerConsultation', 'consultation', consultation)
            const localVarPath = `/v1/admin/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consultation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Consultation
         * @param {number} id 
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminConsultationsControllerConsultation: async (id: number, consultation: Consultation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminConsultationsControllerConsultation', 'id', id)
            // verify required parameter 'consultation' is not null or undefined
            assertParamExists('updateOneBaseAdminConsultationsControllerConsultation', 'consultation', consultation)
            const localVarPath = `/v1/admin/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consultation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminConsultationsApi - functional programming interface
 * @export
 */
export const V1AdminConsultationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminConsultationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create multiple Consultations
         * @param {CreateManyConsultationDto} createManyConsultationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyBaseAdminConsultationsControllerConsultation(createManyConsultationDto: CreateManyConsultationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Consultation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManyBaseAdminConsultationsControllerConsultation(createManyConsultationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single Consultation
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminConsultationsControllerConsultation(consultation: Consultation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminConsultationsControllerConsultation(consultation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminConsultationsControllerConsultation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminConsultationsControllerConsultation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Consultations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminConsultationsControllerConsultation(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminConsultationsControllerConsultation(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Consultation
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminConsultationsControllerConsultation(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminConsultationsControllerConsultation(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replace a single Consultation
         * @param {number} id 
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneBaseAdminConsultationsControllerConsultation(id: number, consultation: Consultation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceOneBaseAdminConsultationsControllerConsultation(id, consultation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Consultation
         * @param {number} id 
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminConsultationsControllerConsultation(id: number, consultation: Consultation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminConsultationsControllerConsultation(id, consultation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminConsultationsApi - factory interface
 * @export
 */
export const V1AdminConsultationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminConsultationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create multiple Consultations
         * @param {CreateManyConsultationDto} createManyConsultationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyBaseAdminConsultationsControllerConsultation(createManyConsultationDto: CreateManyConsultationDto, options?: any): AxiosPromise<Array<Consultation>> {
            return localVarFp.createManyBaseAdminConsultationsControllerConsultation(createManyConsultationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single Consultation
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminConsultationsControllerConsultation(consultation: Consultation, options?: any): AxiosPromise<Consultation> {
            return localVarFp.createOneBaseAdminConsultationsControllerConsultation(consultation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminConsultationsControllerConsultation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminConsultationsControllerConsultation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Consultations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminConsultationsControllerConsultation(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<any> {
            return localVarFp.getManyBaseAdminConsultationsControllerConsultation(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Consultation
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminConsultationsControllerConsultation(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Consultation> {
            return localVarFp.getOneBaseAdminConsultationsControllerConsultation(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Consultation
         * @param {number} id 
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneBaseAdminConsultationsControllerConsultation(id: number, consultation: Consultation, options?: any): AxiosPromise<Consultation> {
            return localVarFp.replaceOneBaseAdminConsultationsControllerConsultation(id, consultation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Consultation
         * @param {number} id 
         * @param {Consultation} consultation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminConsultationsControllerConsultation(id: number, consultation: Consultation, options?: any): AxiosPromise<Consultation> {
            return localVarFp.updateOneBaseAdminConsultationsControllerConsultation(id, consultation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminConsultationsApi - object-oriented interface
 * @export
 * @class V1AdminConsultationsApi
 * @extends {BaseAPI}
 */
export class V1AdminConsultationsApi extends BaseAPI {
    /**
     * 
     * @summary Create multiple Consultations
     * @param {CreateManyConsultationDto} createManyConsultationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public createManyBaseAdminConsultationsControllerConsultation(createManyConsultationDto: CreateManyConsultationDto, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).createManyBaseAdminConsultationsControllerConsultation(createManyConsultationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single Consultation
     * @param {Consultation} consultation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public createOneBaseAdminConsultationsControllerConsultation(consultation: Consultation, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).createOneBaseAdminConsultationsControllerConsultation(consultation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Consultation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public deleteOneBaseAdminConsultationsControllerConsultation(id: number, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).deleteOneBaseAdminConsultationsControllerConsultation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Consultations
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public getManyBaseAdminConsultationsControllerConsultation(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).getManyBaseAdminConsultationsControllerConsultation(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Consultation
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public getOneBaseAdminConsultationsControllerConsultation(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).getOneBaseAdminConsultationsControllerConsultation(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Consultation
     * @param {number} id 
     * @param {Consultation} consultation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public replaceOneBaseAdminConsultationsControllerConsultation(id: number, consultation: Consultation, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).replaceOneBaseAdminConsultationsControllerConsultation(id, consultation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Consultation
     * @param {number} id 
     * @param {Consultation} consultation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminConsultationsApi
     */
    public updateOneBaseAdminConsultationsControllerConsultation(id: number, consultation: Consultation, options?: any) {
        return V1AdminConsultationsApiFp(this.configuration).updateOneBaseAdminConsultationsControllerConsultation(id, consultation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminContentChangeRequestsApi - axios parameter creator
 * @export
 */
export const V1AdminContentChangeRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Process content change request
         * @param {number} id 
         * @param {ApproveContentChangeRequestDto} approveContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminContentChangeRequestControllerProcessRequest: async (id: number, approveContentChangeRequestDto: ApproveContentChangeRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminContentChangeRequestControllerProcessRequest', 'id', id)
            // verify required parameter 'approveContentChangeRequestDto' is not null or undefined
            assertParamExists('adminContentChangeRequestControllerProcessRequest', 'approveContentChangeRequestDto', approveContentChangeRequestDto)
            const localVarPath = `/v1/admin/content-change-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveContentChangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single ContentChangeRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest', 'id', id)
            const localVarPath = `/v1/admin/content-change-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple ContentChangeRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminContentChangeRequestControllerContentChangeRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/content-change-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single ContentChangeRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminContentChangeRequestControllerContentChangeRequest: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminContentChangeRequestControllerContentChangeRequest', 'id', id)
            const localVarPath = `/v1/admin/content-change-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminContentChangeRequestsApi - functional programming interface
 * @export
 */
export const V1AdminContentChangeRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminContentChangeRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Process content change request
         * @param {number} id 
         * @param {ApproveContentChangeRequestDto} approveContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminContentChangeRequestControllerProcessRequest(id: number, approveContentChangeRequestDto: ApproveContentChangeRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentChangeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminContentChangeRequestControllerProcessRequest(id, approveContentChangeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single ContentChangeRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple ContentChangeRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminContentChangeRequestControllerContentChangeRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyContentChangeRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminContentChangeRequestControllerContentChangeRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single ContentChangeRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminContentChangeRequestControllerContentChangeRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentChangeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminContentChangeRequestControllerContentChangeRequest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminContentChangeRequestsApi - factory interface
 * @export
 */
export const V1AdminContentChangeRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminContentChangeRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Process content change request
         * @param {number} id 
         * @param {ApproveContentChangeRequestDto} approveContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminContentChangeRequestControllerProcessRequest(id: number, approveContentChangeRequestDto: ApproveContentChangeRequestDto, options?: any): AxiosPromise<ContentChangeRequest> {
            return localVarFp.adminContentChangeRequestControllerProcessRequest(id, approveContentChangeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single ContentChangeRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple ContentChangeRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminContentChangeRequestControllerContentChangeRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyContentChangeRequestResponseDto> {
            return localVarFp.getManyBaseAdminContentChangeRequestControllerContentChangeRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single ContentChangeRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminContentChangeRequestControllerContentChangeRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<ContentChangeRequest> {
            return localVarFp.getOneBaseAdminContentChangeRequestControllerContentChangeRequest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminContentChangeRequestsApi - object-oriented interface
 * @export
 * @class V1AdminContentChangeRequestsApi
 * @extends {BaseAPI}
 */
export class V1AdminContentChangeRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Process content change request
     * @param {number} id 
     * @param {ApproveContentChangeRequestDto} approveContentChangeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContentChangeRequestsApi
     */
    public adminContentChangeRequestControllerProcessRequest(id: number, approveContentChangeRequestDto: ApproveContentChangeRequestDto, options?: any) {
        return V1AdminContentChangeRequestsApiFp(this.configuration).adminContentChangeRequestControllerProcessRequest(id, approveContentChangeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single ContentChangeRequest
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContentChangeRequestsApi
     */
    public deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(id: number, options?: any) {
        return V1AdminContentChangeRequestsApiFp(this.configuration).deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple ContentChangeRequests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContentChangeRequestsApi
     */
    public getManyBaseAdminContentChangeRequestControllerContentChangeRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminContentChangeRequestsApiFp(this.configuration).getManyBaseAdminContentChangeRequestControllerContentChangeRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single ContentChangeRequest
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContentChangeRequestsApi
     */
    public getOneBaseAdminContentChangeRequestControllerContentChangeRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminContentChangeRequestsApiFp(this.configuration).getOneBaseAdminContentChangeRequestControllerContentChangeRequest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminContractsApi - axios parameter creator
 * @export
 */
export const V1AdminContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Process contract
         * @param {number} id 
         * @param {ProcessContractDto} processContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminContractsControllerProcessOne: async (id: number, processContractDto: ProcessContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminContractsControllerProcessOne', 'id', id)
            // verify required parameter 'processContractDto' is not null or undefined
            assertParamExists('adminContractsControllerProcessOne', 'processContractDto', processContractDto)
            const localVarPath = `/v1/admin/contracts/{id}/process`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Contract
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminContractsControllerContract: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminContractsControllerContract', 'id', id)
            const localVarPath = `/v1/admin/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Contracts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminContractsControllerContract: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Contract
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminContractsControllerContract: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminContractsControllerContract', 'id', id)
            const localVarPath = `/v1/admin/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Contract
         * @param {number} id 
         * @param {UpdateContractDto} updateContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminContractsControllerContract: async (id: number, updateContractDto: UpdateContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminContractsControllerContract', 'id', id)
            // verify required parameter 'updateContractDto' is not null or undefined
            assertParamExists('updateOneBaseAdminContractsControllerContract', 'updateContractDto', updateContractDto)
            const localVarPath = `/v1/admin/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminContractsApi - functional programming interface
 * @export
 */
export const V1AdminContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Process contract
         * @param {number} id 
         * @param {ProcessContractDto} processContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminContractsControllerProcessOne(id: number, processContractDto: ProcessContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminContractsControllerProcessOne(id, processContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Contract
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminContractsControllerContract(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminContractsControllerContract(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Contracts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminContractsControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyContractResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminContractsControllerContract(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Contract
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminContractsControllerContract(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminContractsControllerContract(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Contract
         * @param {number} id 
         * @param {UpdateContractDto} updateContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminContractsControllerContract(id: number, updateContractDto: UpdateContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminContractsControllerContract(id, updateContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminContractsApi - factory interface
 * @export
 */
export const V1AdminContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminContractsApiFp(configuration)
    return {
        /**
         * 
         * @summary Process contract
         * @param {number} id 
         * @param {ProcessContractDto} processContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminContractsControllerProcessOne(id: number, processContractDto: ProcessContractDto, options?: any): AxiosPromise<Contract> {
            return localVarFp.adminContractsControllerProcessOne(id, processContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Contract
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminContractsControllerContract(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteOneBaseAdminContractsControllerContract(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Contracts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminContractsControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyContractResponseDto> {
            return localVarFp.getManyBaseAdminContractsControllerContract(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Contract
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminContractsControllerContract(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Contract> {
            return localVarFp.getOneBaseAdminContractsControllerContract(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Contract
         * @param {number} id 
         * @param {UpdateContractDto} updateContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminContractsControllerContract(id: number, updateContractDto: UpdateContractDto, options?: any): AxiosPromise<Contract> {
            return localVarFp.updateOneBaseAdminContractsControllerContract(id, updateContractDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminContractsApi - object-oriented interface
 * @export
 * @class V1AdminContractsApi
 * @extends {BaseAPI}
 */
export class V1AdminContractsApi extends BaseAPI {
    /**
     * 
     * @summary Process contract
     * @param {number} id 
     * @param {ProcessContractDto} processContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContractsApi
     */
    public adminContractsControllerProcessOne(id: number, processContractDto: ProcessContractDto, options?: any) {
        return V1AdminContractsApiFp(this.configuration).adminContractsControllerProcessOne(id, processContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Contract
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContractsApi
     */
    public deleteOneBaseAdminContractsControllerContract(id: number, options?: any) {
        return V1AdminContractsApiFp(this.configuration).deleteOneBaseAdminContractsControllerContract(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Contracts
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContractsApi
     */
    public getManyBaseAdminContractsControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminContractsApiFp(this.configuration).getManyBaseAdminContractsControllerContract(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Contract
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContractsApi
     */
    public getOneBaseAdminContractsControllerContract(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminContractsApiFp(this.configuration).getOneBaseAdminContractsControllerContract(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Contract
     * @param {number} id 
     * @param {UpdateContractDto} updateContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminContractsApi
     */
    public updateOneBaseAdminContractsControllerContract(id: number, updateContractDto: UpdateContractDto, options?: any) {
        return V1AdminContractsApiFp(this.configuration).updateOneBaseAdminContractsControllerContract(id, updateContractDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminCustomRequestsApi - axios parameter creator
 * @export
 */
export const V1AdminCustomRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Process custom request
         * @param {number} id 
         * @param {ProcessCustomRequestsDto} processCustomRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomRequestsControllerProcessOne: async (id: number, processCustomRequestsDto: ProcessCustomRequestsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomRequestsControllerProcessOne', 'id', id)
            // verify required parameter 'processCustomRequestsDto' is not null or undefined
            assertParamExists('adminCustomRequestsControllerProcessOne', 'processCustomRequestsDto', processCustomRequestsDto)
            const localVarPath = `/v1/admin/custom-requests/{id}/process`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processCustomRequestsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single CustomRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminCustomRequestsControllerCustomRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminCustomRequestsControllerCustomRequest', 'id', id)
            const localVarPath = `/v1/admin/custom-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple CustomRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomRequestsControllerCustomRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/custom-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single CustomRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomRequestsControllerCustomRequest: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCustomRequestsControllerCustomRequest', 'id', id)
            const localVarPath = `/v1/admin/custom-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminCustomRequestsApi - functional programming interface
 * @export
 */
export const V1AdminCustomRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminCustomRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Process custom request
         * @param {number} id 
         * @param {ProcessCustomRequestsDto} processCustomRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomRequestsControllerProcessOne(id: number, processCustomRequestsDto: ProcessCustomRequestsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomRequestsControllerProcessOne(id, processCustomRequestsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single CustomRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminCustomRequestsControllerCustomRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminCustomRequestsControllerCustomRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple CustomRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCustomRequestsControllerCustomRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyCustomRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCustomRequestsControllerCustomRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single CustomRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCustomRequestsControllerCustomRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCustomRequestsControllerCustomRequest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminCustomRequestsApi - factory interface
 * @export
 */
export const V1AdminCustomRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminCustomRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Process custom request
         * @param {number} id 
         * @param {ProcessCustomRequestsDto} processCustomRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomRequestsControllerProcessOne(id: number, processCustomRequestsDto: ProcessCustomRequestsDto, options?: any): AxiosPromise<CustomRequest> {
            return localVarFp.adminCustomRequestsControllerProcessOne(id, processCustomRequestsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single CustomRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminCustomRequestsControllerCustomRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminCustomRequestsControllerCustomRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple CustomRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomRequestsControllerCustomRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyCustomRequestResponseDto> {
            return localVarFp.getManyBaseAdminCustomRequestsControllerCustomRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single CustomRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomRequestsControllerCustomRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<CustomRequest> {
            return localVarFp.getOneBaseAdminCustomRequestsControllerCustomRequest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminCustomRequestsApi - object-oriented interface
 * @export
 * @class V1AdminCustomRequestsApi
 * @extends {BaseAPI}
 */
export class V1AdminCustomRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Process custom request
     * @param {number} id 
     * @param {ProcessCustomRequestsDto} processCustomRequestsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCustomRequestsApi
     */
    public adminCustomRequestsControllerProcessOne(id: number, processCustomRequestsDto: ProcessCustomRequestsDto, options?: any) {
        return V1AdminCustomRequestsApiFp(this.configuration).adminCustomRequestsControllerProcessOne(id, processCustomRequestsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single CustomRequest
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCustomRequestsApi
     */
    public deleteOneBaseAdminCustomRequestsControllerCustomRequest(id: number, options?: any) {
        return V1AdminCustomRequestsApiFp(this.configuration).deleteOneBaseAdminCustomRequestsControllerCustomRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple CustomRequests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCustomRequestsApi
     */
    public getManyBaseAdminCustomRequestsControllerCustomRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminCustomRequestsApiFp(this.configuration).getManyBaseAdminCustomRequestsControllerCustomRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single CustomRequest
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminCustomRequestsApi
     */
    public getOneBaseAdminCustomRequestsControllerCustomRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminCustomRequestsApiFp(this.configuration).getOneBaseAdminCustomRequestsControllerCustomRequest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminExpertServicesApi - axios parameter creator
 * @export
 */
export const V1AdminExpertServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single ExpertService
         * @param {ExpertService} expertService 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminExpertServicesControllerExpertService: async (expertService: ExpertService, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertService' is not null or undefined
            assertParamExists('createOneBaseAdminExpertServicesControllerExpertService', 'expertService', expertService)
            const localVarPath = `/v1/admin/expert-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertService, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single ExpertService
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminExpertServicesControllerExpertService: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminExpertServicesControllerExpertService', 'id', id)
            const localVarPath = `/v1/admin/expert-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple ExpertServices
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminExpertServicesControllerExpertService: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/expert-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single ExpertService
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminExpertServicesControllerExpertService: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminExpertServicesControllerExpertService', 'id', id)
            const localVarPath = `/v1/admin/expert-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single ExpertService
         * @param {number} id 
         * @param {UpdateExpertServiceDto} updateExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminExpertServicesControllerExpertService: async (id: number, updateExpertServiceDto: UpdateExpertServiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminExpertServicesControllerExpertService', 'id', id)
            // verify required parameter 'updateExpertServiceDto' is not null or undefined
            assertParamExists('updateOneBaseAdminExpertServicesControllerExpertService', 'updateExpertServiceDto', updateExpertServiceDto)
            const localVarPath = `/v1/admin/expert-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpertServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminExpertServicesApi - functional programming interface
 * @export
 */
export const V1AdminExpertServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminExpertServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single ExpertService
         * @param {ExpertService} expertService 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminExpertServicesControllerExpertService(expertService: ExpertService, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminExpertServicesControllerExpertService(expertService, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single ExpertService
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminExpertServicesControllerExpertService(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminExpertServicesControllerExpertService(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple ExpertServices
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminExpertServicesControllerExpertService(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyExpertServiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminExpertServicesControllerExpertService(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single ExpertService
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminExpertServicesControllerExpertService(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminExpertServicesControllerExpertService(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single ExpertService
         * @param {number} id 
         * @param {UpdateExpertServiceDto} updateExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminExpertServicesControllerExpertService(id: number, updateExpertServiceDto: UpdateExpertServiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminExpertServicesControllerExpertService(id, updateExpertServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminExpertServicesApi - factory interface
 * @export
 */
export const V1AdminExpertServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminExpertServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single ExpertService
         * @param {ExpertService} expertService 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminExpertServicesControllerExpertService(expertService: ExpertService, options?: any): AxiosPromise<ExpertService> {
            return localVarFp.createOneBaseAdminExpertServicesControllerExpertService(expertService, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single ExpertService
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminExpertServicesControllerExpertService(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminExpertServicesControllerExpertService(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple ExpertServices
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminExpertServicesControllerExpertService(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyExpertServiceResponseDto> {
            return localVarFp.getManyBaseAdminExpertServicesControllerExpertService(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single ExpertService
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminExpertServicesControllerExpertService(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<ExpertService> {
            return localVarFp.getOneBaseAdminExpertServicesControllerExpertService(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single ExpertService
         * @param {number} id 
         * @param {UpdateExpertServiceDto} updateExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminExpertServicesControllerExpertService(id: number, updateExpertServiceDto: UpdateExpertServiceDto, options?: any): AxiosPromise<ExpertService> {
            return localVarFp.updateOneBaseAdminExpertServicesControllerExpertService(id, updateExpertServiceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminExpertServicesApi - object-oriented interface
 * @export
 * @class V1AdminExpertServicesApi
 * @extends {BaseAPI}
 */
export class V1AdminExpertServicesApi extends BaseAPI {
    /**
     * 
     * @summary Create a single ExpertService
     * @param {ExpertService} expertService 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminExpertServicesApi
     */
    public createOneBaseAdminExpertServicesControllerExpertService(expertService: ExpertService, options?: any) {
        return V1AdminExpertServicesApiFp(this.configuration).createOneBaseAdminExpertServicesControllerExpertService(expertService, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single ExpertService
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminExpertServicesApi
     */
    public deleteOneBaseAdminExpertServicesControllerExpertService(id: number, options?: any) {
        return V1AdminExpertServicesApiFp(this.configuration).deleteOneBaseAdminExpertServicesControllerExpertService(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple ExpertServices
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminExpertServicesApi
     */
    public getManyBaseAdminExpertServicesControllerExpertService(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminExpertServicesApiFp(this.configuration).getManyBaseAdminExpertServicesControllerExpertService(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single ExpertService
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminExpertServicesApi
     */
    public getOneBaseAdminExpertServicesControllerExpertService(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminExpertServicesApiFp(this.configuration).getOneBaseAdminExpertServicesControllerExpertService(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single ExpertService
     * @param {number} id 
     * @param {UpdateExpertServiceDto} updateExpertServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminExpertServicesApi
     */
    public updateOneBaseAdminExpertServicesControllerExpertService(id: number, updateExpertServiceDto: UpdateExpertServiceDto, options?: any) {
        return V1AdminExpertServicesApiFp(this.configuration).updateOneBaseAdminExpertServicesControllerExpertService(id, updateExpertServiceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminInvoicesApi - axios parameter creator
 * @export
 */
export const V1AdminInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel one invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvoicesControllerCancelOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminInvoicesControllerCancelOne', 'id', id)
            const localVarPath = `/v1/admin/invoices/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Invoices
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminInvoicesControllerInvoice: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Invoice
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminInvoicesControllerInvoice: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminInvoicesControllerInvoice', 'id', id)
            const localVarPath = `/v1/admin/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminInvoicesApi - functional programming interface
 * @export
 */
export const V1AdminInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel one invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvoicesControllerCancelOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvoicesControllerCancelOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Invoices
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminInvoicesControllerInvoice(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyInvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminInvoicesControllerInvoice(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Invoice
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminInvoicesControllerInvoice(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminInvoicesControllerInvoice(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminInvoicesApi - factory interface
 * @export
 */
export const V1AdminInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel one invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvoicesControllerCancelOne(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminInvoicesControllerCancelOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Invoices
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminInvoicesControllerInvoice(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyInvoiceResponseDto> {
            return localVarFp.getManyBaseAdminInvoicesControllerInvoice(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Invoice
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminInvoicesControllerInvoice(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Invoice> {
            return localVarFp.getOneBaseAdminInvoicesControllerInvoice(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminInvoicesApi - object-oriented interface
 * @export
 * @class V1AdminInvoicesApi
 * @extends {BaseAPI}
 */
export class V1AdminInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Cancel one invoice
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminInvoicesApi
     */
    public adminInvoicesControllerCancelOne(id: number, options?: any) {
        return V1AdminInvoicesApiFp(this.configuration).adminInvoicesControllerCancelOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Invoices
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminInvoicesApi
     */
    public getManyBaseAdminInvoicesControllerInvoice(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminInvoicesApiFp(this.configuration).getManyBaseAdminInvoicesControllerInvoice(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Invoice
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminInvoicesApi
     */
    public getOneBaseAdminInvoicesControllerInvoice(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminInvoicesApiFp(this.configuration).getOneBaseAdminInvoicesControllerInvoice(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminNewsApi - axios parameter creator
 * @export
 */
export const V1AdminNewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single News
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminNewsConrollerNews: async (createNewsDto: CreateNewsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNewsDto' is not null or undefined
            assertParamExists('createOneBaseAdminNewsConrollerNews', 'createNewsDto', createNewsDto)
            const localVarPath = `/v1/admin/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single News
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminNewsConrollerNews: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminNewsConrollerNews', 'id', id)
            const localVarPath = `/v1/admin/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple News
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminNewsConrollerNews: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single News
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminNewsConrollerNews: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminNewsConrollerNews', 'id', id)
            const localVarPath = `/v1/admin/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single News
         * @param {number} id 
         * @param {UpdateNewsDto} updateNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminNewsConrollerNews: async (id: number, updateNewsDto: UpdateNewsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminNewsConrollerNews', 'id', id)
            // verify required parameter 'updateNewsDto' is not null or undefined
            assertParamExists('updateOneBaseAdminNewsConrollerNews', 'updateNewsDto', updateNewsDto)
            const localVarPath = `/v1/admin/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNewsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminNewsApi - functional programming interface
 * @export
 */
export const V1AdminNewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminNewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single News
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminNewsConrollerNews(createNewsDto: CreateNewsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminNewsConrollerNews(createNewsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single News
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminNewsConrollerNews(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminNewsConrollerNews(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple News
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminNewsConrollerNews(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyNewsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminNewsConrollerNews(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single News
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminNewsConrollerNews(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminNewsConrollerNews(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single News
         * @param {number} id 
         * @param {UpdateNewsDto} updateNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminNewsConrollerNews(id: number, updateNewsDto: UpdateNewsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminNewsConrollerNews(id, updateNewsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminNewsApi - factory interface
 * @export
 */
export const V1AdminNewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminNewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single News
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminNewsConrollerNews(createNewsDto: CreateNewsDto, options?: any): AxiosPromise<News> {
            return localVarFp.createOneBaseAdminNewsConrollerNews(createNewsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single News
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminNewsConrollerNews(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminNewsConrollerNews(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple News
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminNewsConrollerNews(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyNewsResponseDto> {
            return localVarFp.getManyBaseAdminNewsConrollerNews(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single News
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminNewsConrollerNews(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<News> {
            return localVarFp.getOneBaseAdminNewsConrollerNews(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single News
         * @param {number} id 
         * @param {UpdateNewsDto} updateNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminNewsConrollerNews(id: number, updateNewsDto: UpdateNewsDto, options?: any): AxiosPromise<News> {
            return localVarFp.updateOneBaseAdminNewsConrollerNews(id, updateNewsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminNewsApi - object-oriented interface
 * @export
 * @class V1AdminNewsApi
 * @extends {BaseAPI}
 */
export class V1AdminNewsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single News
     * @param {CreateNewsDto} createNewsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNewsApi
     */
    public createOneBaseAdminNewsConrollerNews(createNewsDto: CreateNewsDto, options?: any) {
        return V1AdminNewsApiFp(this.configuration).createOneBaseAdminNewsConrollerNews(createNewsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single News
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNewsApi
     */
    public deleteOneBaseAdminNewsConrollerNews(id: number, options?: any) {
        return V1AdminNewsApiFp(this.configuration).deleteOneBaseAdminNewsConrollerNews(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple News
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNewsApi
     */
    public getManyBaseAdminNewsConrollerNews(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminNewsApiFp(this.configuration).getManyBaseAdminNewsConrollerNews(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single News
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNewsApi
     */
    public getOneBaseAdminNewsConrollerNews(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminNewsApiFp(this.configuration).getOneBaseAdminNewsConrollerNews(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single News
     * @param {number} id 
     * @param {UpdateNewsDto} updateNewsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNewsApi
     */
    public updateOneBaseAdminNewsConrollerNews(id: number, updateNewsDto: UpdateNewsDto, options?: any) {
        return V1AdminNewsApiFp(this.configuration).updateOneBaseAdminNewsConrollerNews(id, updateNewsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminNpsPollsApi - axios parameter creator
 * @export
 */
export const V1AdminNpsPollsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Nps Poll
         * @param {'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNpsPollsControllerCreateNpsPoll: async (type?: 'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/nps-polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple NpsPolls
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminNpsPollsControllerNpsPoll: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/nps-polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single NpsPoll
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminNpsPollsControllerNpsPoll: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminNpsPollsControllerNpsPoll', 'id', id)
            const localVarPath = `/v1/admin/nps-polls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminNpsPollsApi - functional programming interface
 * @export
 */
export const V1AdminNpsPollsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminNpsPollsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Nps Poll
         * @param {'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminNpsPollsControllerCreateNpsPoll(type?: 'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpsPoll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminNpsPollsControllerCreateNpsPoll(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple NpsPolls
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminNpsPollsControllerNpsPoll(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyNpsPollResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminNpsPollsControllerNpsPoll(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single NpsPoll
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminNpsPollsControllerNpsPoll(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpsPoll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminNpsPollsControllerNpsPoll(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminNpsPollsApi - factory interface
 * @export
 */
export const V1AdminNpsPollsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminNpsPollsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Nps Poll
         * @param {'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminNpsPollsControllerCreateNpsPoll(type?: 'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll', options?: any): AxiosPromise<NpsPoll> {
            return localVarFp.adminNpsPollsControllerCreateNpsPoll(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple NpsPolls
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminNpsPollsControllerNpsPoll(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyNpsPollResponseDto> {
            return localVarFp.getManyBaseAdminNpsPollsControllerNpsPoll(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single NpsPoll
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminNpsPollsControllerNpsPoll(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<NpsPoll> {
            return localVarFp.getOneBaseAdminNpsPollsControllerNpsPoll(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminNpsPollsApi - object-oriented interface
 * @export
 * @class V1AdminNpsPollsApi
 * @extends {BaseAPI}
 */
export class V1AdminNpsPollsApi extends BaseAPI {
    /**
     * 
     * @summary Create Nps Poll
     * @param {'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNpsPollsApi
     */
    public adminNpsPollsControllerCreateNpsPoll(type?: 'after48hours' | 'after2weeks' | 'forExperts' | 'forClients' | 'forAll', options?: any) {
        return V1AdminNpsPollsApiFp(this.configuration).adminNpsPollsControllerCreateNpsPoll(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple NpsPolls
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNpsPollsApi
     */
    public getManyBaseAdminNpsPollsControllerNpsPoll(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminNpsPollsApiFp(this.configuration).getManyBaseAdminNpsPollsControllerNpsPoll(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single NpsPoll
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminNpsPollsApi
     */
    public getOneBaseAdminNpsPollsControllerNpsPoll(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminNpsPollsApiFp(this.configuration).getOneBaseAdminNpsPollsControllerNpsPoll(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPaymentsApi - axios parameter creator
 * @export
 */
export const V1AdminPaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Payments
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminPaymentsControllerPayment: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Payment
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPaymentsControllerPayment: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminPaymentsControllerPayment', 'id', id)
            const localVarPath = `/v1/admin/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPaymentsApi - functional programming interface
 * @export
 */
export const V1AdminPaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Payments
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminPaymentsControllerPayment(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyPaymentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminPaymentsControllerPayment(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Payment
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminPaymentsControllerPayment(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminPaymentsControllerPayment(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPaymentsApi - factory interface
 * @export
 */
export const V1AdminPaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Payments
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminPaymentsControllerPayment(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyPaymentResponseDto> {
            return localVarFp.getManyBaseAdminPaymentsControllerPayment(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Payment
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPaymentsControllerPayment(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Payment> {
            return localVarFp.getOneBaseAdminPaymentsControllerPayment(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPaymentsApi - object-oriented interface
 * @export
 * @class V1AdminPaymentsApi
 * @extends {BaseAPI}
 */
export class V1AdminPaymentsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Payments
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPaymentsApi
     */
    public getManyBaseAdminPaymentsControllerPayment(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPaymentsApiFp(this.configuration).getManyBaseAdminPaymentsControllerPayment(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Payment
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPaymentsApi
     */
    public getOneBaseAdminPaymentsControllerPayment(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPaymentsApiFp(this.configuration).getOneBaseAdminPaymentsControllerPayment(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPortfoliosApi - axios parameter creator
 * @export
 */
export const V1AdminPortfoliosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Process portfolio
         * @param {number} id 
         * @param {ProcessPortfolioDto} processPortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPortfoliosConrollerProcessPortfolio: async (id: number, processPortfolioDto: ProcessPortfolioDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminPortfoliosConrollerProcessPortfolio', 'id', id)
            // verify required parameter 'processPortfolioDto' is not null or undefined
            assertParamExists('adminPortfoliosConrollerProcessPortfolio', 'processPortfolioDto', processPortfolioDto)
            const localVarPath = `/v1/admin/portfolios/{id}/process`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processPortfolioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Portfolios
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminPortfoliosConrollerPortfolio: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/portfolios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Portfolio
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPortfoliosConrollerPortfolio: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminPortfoliosConrollerPortfolio', 'id', id)
            const localVarPath = `/v1/admin/portfolios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPortfoliosApi - functional programming interface
 * @export
 */
export const V1AdminPortfoliosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPortfoliosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Process portfolio
         * @param {number} id 
         * @param {ProcessPortfolioDto} processPortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPortfoliosConrollerProcessPortfolio(id: number, processPortfolioDto: ProcessPortfolioDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPortfoliosConrollerProcessPortfolio(id, processPortfolioDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Portfolios
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminPortfoliosConrollerPortfolio(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyPortfolioResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminPortfoliosConrollerPortfolio(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Portfolio
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminPortfoliosConrollerPortfolio(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminPortfoliosConrollerPortfolio(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPortfoliosApi - factory interface
 * @export
 */
export const V1AdminPortfoliosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPortfoliosApiFp(configuration)
    return {
        /**
         * 
         * @summary Process portfolio
         * @param {number} id 
         * @param {ProcessPortfolioDto} processPortfolioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPortfoliosConrollerProcessPortfolio(id: number, processPortfolioDto: ProcessPortfolioDto, options?: any): AxiosPromise<Portfolio> {
            return localVarFp.adminPortfoliosConrollerProcessPortfolio(id, processPortfolioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Portfolios
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminPortfoliosConrollerPortfolio(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyPortfolioResponseDto> {
            return localVarFp.getManyBaseAdminPortfoliosConrollerPortfolio(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Portfolio
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPortfoliosConrollerPortfolio(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Portfolio> {
            return localVarFp.getOneBaseAdminPortfoliosConrollerPortfolio(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPortfoliosApi - object-oriented interface
 * @export
 * @class V1AdminPortfoliosApi
 * @extends {BaseAPI}
 */
export class V1AdminPortfoliosApi extends BaseAPI {
    /**
     * 
     * @summary Process portfolio
     * @param {number} id 
     * @param {ProcessPortfolioDto} processPortfolioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPortfoliosApi
     */
    public adminPortfoliosConrollerProcessPortfolio(id: number, processPortfolioDto: ProcessPortfolioDto, options?: any) {
        return V1AdminPortfoliosApiFp(this.configuration).adminPortfoliosConrollerProcessPortfolio(id, processPortfolioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Portfolios
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPortfoliosApi
     */
    public getManyBaseAdminPortfoliosConrollerPortfolio(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPortfoliosApiFp(this.configuration).getManyBaseAdminPortfoliosConrollerPortfolio(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Portfolio
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPortfoliosApi
     */
    public getOneBaseAdminPortfoliosConrollerPortfolio(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPortfoliosApiFp(this.configuration).getOneBaseAdminPortfoliosConrollerPortfolio(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPromocodesApi - axios parameter creator
 * @export
 */
export const V1AdminPromocodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create one promocode
         * @param {CreatePromocodeDto} createPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminPromocodesControllerPromocode: async (createPromocodeDto: CreatePromocodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPromocodeDto' is not null or undefined
            assertParamExists('createOneBaseAdminPromocodesControllerPromocode', 'createPromocodeDto', createPromocodeDto)
            const localVarPath = `/v1/admin/promocodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPromocodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Promocode
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminPromocodesControllerPromocode: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminPromocodesControllerPromocode', 'id', id)
            const localVarPath = `/v1/admin/promocodes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Promocodes
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminPromocodesControllerPromocode: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/promocodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Promocode
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPromocodesControllerPromocode: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminPromocodesControllerPromocode', 'id', id)
            const localVarPath = `/v1/admin/promocodes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update one promocode
         * @param {number} id 
         * @param {UpdatePromocodeDto} updatePromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminPromocodesControllerPromocode: async (id: number, updatePromocodeDto: UpdatePromocodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminPromocodesControllerPromocode', 'id', id)
            // verify required parameter 'updatePromocodeDto' is not null or undefined
            assertParamExists('updateOneBaseAdminPromocodesControllerPromocode', 'updatePromocodeDto', updatePromocodeDto)
            const localVarPath = `/v1/admin/promocodes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePromocodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPromocodesApi - functional programming interface
 * @export
 */
export const V1AdminPromocodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPromocodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create one promocode
         * @param {CreatePromocodeDto} createPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminPromocodesControllerPromocode(createPromocodeDto: CreatePromocodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promocode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminPromocodesControllerPromocode(createPromocodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Promocode
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminPromocodesControllerPromocode(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminPromocodesControllerPromocode(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Promocodes
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminPromocodesControllerPromocode(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyPromocodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminPromocodesControllerPromocode(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Promocode
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminPromocodesControllerPromocode(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promocode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminPromocodesControllerPromocode(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update one promocode
         * @param {number} id 
         * @param {UpdatePromocodeDto} updatePromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminPromocodesControllerPromocode(id: number, updatePromocodeDto: UpdatePromocodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promocode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminPromocodesControllerPromocode(id, updatePromocodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPromocodesApi - factory interface
 * @export
 */
export const V1AdminPromocodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPromocodesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create one promocode
         * @param {CreatePromocodeDto} createPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminPromocodesControllerPromocode(createPromocodeDto: CreatePromocodeDto, options?: any): AxiosPromise<Promocode> {
            return localVarFp.createOneBaseAdminPromocodesControllerPromocode(createPromocodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Promocode
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminPromocodesControllerPromocode(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminPromocodesControllerPromocode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Promocodes
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminPromocodesControllerPromocode(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyPromocodeResponseDto> {
            return localVarFp.getManyBaseAdminPromocodesControllerPromocode(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Promocode
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminPromocodesControllerPromocode(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Promocode> {
            return localVarFp.getOneBaseAdminPromocodesControllerPromocode(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update one promocode
         * @param {number} id 
         * @param {UpdatePromocodeDto} updatePromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminPromocodesControllerPromocode(id: number, updatePromocodeDto: UpdatePromocodeDto, options?: any): AxiosPromise<Promocode> {
            return localVarFp.updateOneBaseAdminPromocodesControllerPromocode(id, updatePromocodeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPromocodesApi - object-oriented interface
 * @export
 * @class V1AdminPromocodesApi
 * @extends {BaseAPI}
 */
export class V1AdminPromocodesApi extends BaseAPI {
    /**
     * 
     * @summary Create one promocode
     * @param {CreatePromocodeDto} createPromocodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPromocodesApi
     */
    public createOneBaseAdminPromocodesControllerPromocode(createPromocodeDto: CreatePromocodeDto, options?: any) {
        return V1AdminPromocodesApiFp(this.configuration).createOneBaseAdminPromocodesControllerPromocode(createPromocodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Promocode
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPromocodesApi
     */
    public deleteOneBaseAdminPromocodesControllerPromocode(id: number, options?: any) {
        return V1AdminPromocodesApiFp(this.configuration).deleteOneBaseAdminPromocodesControllerPromocode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Promocodes
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPromocodesApi
     */
    public getManyBaseAdminPromocodesControllerPromocode(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPromocodesApiFp(this.configuration).getManyBaseAdminPromocodesControllerPromocode(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Promocode
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPromocodesApi
     */
    public getOneBaseAdminPromocodesControllerPromocode(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPromocodesApiFp(this.configuration).getOneBaseAdminPromocodesControllerPromocode(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update one promocode
     * @param {number} id 
     * @param {UpdatePromocodeDto} updatePromocodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPromocodesApi
     */
    public updateOneBaseAdminPromocodesControllerPromocode(id: number, updatePromocodeDto: UpdatePromocodeDto, options?: any) {
        return V1AdminPromocodesApiFp(this.configuration).updateOneBaseAdminPromocodesControllerPromocode(id, updatePromocodeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminQuestionsAndFeedbackApi - axios parameter creator
 * @export
 */
export const V1AdminQuestionsAndFeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple ConsultationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/question-and-feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single ConsultationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest', 'id', id)
            const localVarPath = `/v1/admin/question-and-feedback/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminQuestionsAndFeedbackApi - functional programming interface
 * @export
 */
export const V1AdminQuestionsAndFeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminQuestionsAndFeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple ConsultationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyConsultationRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single ConsultationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminQuestionsAndFeedbackApi - factory interface
 * @export
 */
export const V1AdminQuestionsAndFeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminQuestionsAndFeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple ConsultationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyConsultationRequestResponseDto> {
            return localVarFp.getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single ConsultationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<ConsultationRequest> {
            return localVarFp.getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminQuestionsAndFeedbackApi - object-oriented interface
 * @export
 * @class V1AdminQuestionsAndFeedbackApi
 * @extends {BaseAPI}
 */
export class V1AdminQuestionsAndFeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple ConsultationRequests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminQuestionsAndFeedbackApi
     */
    public getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminQuestionsAndFeedbackApiFp(this.configuration).getManyBaseAdminQuestionsAndFeedbackControllerConsultationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single ConsultationRequest
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminQuestionsAndFeedbackApi
     */
    public getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminQuestionsAndFeedbackApiFp(this.configuration).getOneBaseAdminQuestionsAndFeedbackControllerConsultationRequest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminReceiptsApi - axios parameter creator
 * @export
 */
export const V1AdminReceiptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a single Receipt
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminReceiptsControllerReceipt: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminReceiptsControllerReceipt', 'id', id)
            const localVarPath = `/v1/admin/receipts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Receipts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminReceiptsControllerReceipt: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/receipts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Receipt
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminReceiptsControllerReceipt: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminReceiptsControllerReceipt', 'id', id)
            const localVarPath = `/v1/admin/receipts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Receipt
         * @param {number} id 
         * @param {AdminUpdateReceiptDto} adminUpdateReceiptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminReceiptsControllerReceipt: async (id: number, adminUpdateReceiptDto: AdminUpdateReceiptDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminReceiptsControllerReceipt', 'id', id)
            // verify required parameter 'adminUpdateReceiptDto' is not null or undefined
            assertParamExists('updateOneBaseAdminReceiptsControllerReceipt', 'adminUpdateReceiptDto', adminUpdateReceiptDto)
            const localVarPath = `/v1/admin/receipts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateReceiptDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminReceiptsApi - functional programming interface
 * @export
 */
export const V1AdminReceiptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminReceiptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a single Receipt
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminReceiptsControllerReceipt(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminReceiptsControllerReceipt(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Receipts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminReceiptsControllerReceipt(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyReceiptResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminReceiptsControllerReceipt(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Receipt
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminReceiptsControllerReceipt(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Receipt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminReceiptsControllerReceipt(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Receipt
         * @param {number} id 
         * @param {AdminUpdateReceiptDto} adminUpdateReceiptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminReceiptsControllerReceipt(id: number, adminUpdateReceiptDto: AdminUpdateReceiptDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Receipt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminReceiptsControllerReceipt(id, adminUpdateReceiptDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminReceiptsApi - factory interface
 * @export
 */
export const V1AdminReceiptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminReceiptsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a single Receipt
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminReceiptsControllerReceipt(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminReceiptsControllerReceipt(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Receipts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminReceiptsControllerReceipt(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyReceiptResponseDto> {
            return localVarFp.getManyBaseAdminReceiptsControllerReceipt(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Receipt
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminReceiptsControllerReceipt(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Receipt> {
            return localVarFp.getOneBaseAdminReceiptsControllerReceipt(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Receipt
         * @param {number} id 
         * @param {AdminUpdateReceiptDto} adminUpdateReceiptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminReceiptsControllerReceipt(id: number, adminUpdateReceiptDto: AdminUpdateReceiptDto, options?: any): AxiosPromise<Receipt> {
            return localVarFp.updateOneBaseAdminReceiptsControllerReceipt(id, adminUpdateReceiptDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminReceiptsApi - object-oriented interface
 * @export
 * @class V1AdminReceiptsApi
 * @extends {BaseAPI}
 */
export class V1AdminReceiptsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a single Receipt
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminReceiptsApi
     */
    public deleteOneBaseAdminReceiptsControllerReceipt(id: number, options?: any) {
        return V1AdminReceiptsApiFp(this.configuration).deleteOneBaseAdminReceiptsControllerReceipt(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Receipts
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminReceiptsApi
     */
    public getManyBaseAdminReceiptsControllerReceipt(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminReceiptsApiFp(this.configuration).getManyBaseAdminReceiptsControllerReceipt(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Receipt
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminReceiptsApi
     */
    public getOneBaseAdminReceiptsControllerReceipt(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminReceiptsApiFp(this.configuration).getOneBaseAdminReceiptsControllerReceipt(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Receipt
     * @param {number} id 
     * @param {AdminUpdateReceiptDto} adminUpdateReceiptDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminReceiptsApi
     */
    public updateOneBaseAdminReceiptsControllerReceipt(id: number, adminUpdateReceiptDto: AdminUpdateReceiptDto, options?: any) {
        return V1AdminReceiptsApiFp(this.configuration).updateOneBaseAdminReceiptsControllerReceipt(id, adminUpdateReceiptDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminSuggestedCategoriesApi - axios parameter creator
 * @export
 */
export const V1AdminSuggestedCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple SuggestedCategories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggested-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminSuggestedCategoriesApi - functional programming interface
 * @export
 */
export const V1AdminSuggestedCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminSuggestedCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple SuggestedCategories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManySuggestedCategoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminSuggestedCategoriesApi - factory interface
 * @export
 */
export const V1AdminSuggestedCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminSuggestedCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple SuggestedCategories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManySuggestedCategoryResponseDto> {
            return localVarFp.getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminSuggestedCategoriesApi - object-oriented interface
 * @export
 * @class V1AdminSuggestedCategoriesApi
 * @extends {BaseAPI}
 */
export class V1AdminSuggestedCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple SuggestedCategories
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminSuggestedCategoriesApi
     */
    public getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminSuggestedCategoriesApiFp(this.configuration).getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminTariffPlansApi - axios parameter creator
 * @export
 */
export const V1AdminTariffPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single TariffPlan
         * @param {CreateTariffPlanDto} createTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminTariffPlansControllerTariffPlan: async (createTariffPlanDto: CreateTariffPlanDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTariffPlanDto' is not null or undefined
            assertParamExists('createOneBaseAdminTariffPlansControllerTariffPlan', 'createTariffPlanDto', createTariffPlanDto)
            const localVarPath = `/v1/admin/tariff-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTariffPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single TariffPlan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminTariffPlansControllerTariffPlan: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminTariffPlansControllerTariffPlan', 'id', id)
            const localVarPath = `/v1/admin/tariff-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple TariffPlans
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTariffPlansControllerTariffPlan: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/tariff-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single TariffPlan
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTariffPlansControllerTariffPlan: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminTariffPlansControllerTariffPlan', 'id', id)
            const localVarPath = `/v1/admin/tariff-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single TariffPlan
         * @param {number} id 
         * @param {UpdateTariffPlanDto} updateTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminTariffPlansControllerTariffPlan: async (id: number, updateTariffPlanDto: UpdateTariffPlanDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminTariffPlansControllerTariffPlan', 'id', id)
            // verify required parameter 'updateTariffPlanDto' is not null or undefined
            assertParamExists('updateOneBaseAdminTariffPlansControllerTariffPlan', 'updateTariffPlanDto', updateTariffPlanDto)
            const localVarPath = `/v1/admin/tariff-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTariffPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminTariffPlansApi - functional programming interface
 * @export
 */
export const V1AdminTariffPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminTariffPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single TariffPlan
         * @param {CreateTariffPlanDto} createTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminTariffPlansControllerTariffPlan(createTariffPlanDto: CreateTariffPlanDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminTariffPlansControllerTariffPlan(createTariffPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single TariffPlan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminTariffPlansControllerTariffPlan(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminTariffPlansControllerTariffPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple TariffPlans
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminTariffPlansControllerTariffPlan(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyTariffPlanResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminTariffPlansControllerTariffPlan(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single TariffPlan
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminTariffPlansControllerTariffPlan(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminTariffPlansControllerTariffPlan(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single TariffPlan
         * @param {number} id 
         * @param {UpdateTariffPlanDto} updateTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminTariffPlansControllerTariffPlan(id: number, updateTariffPlanDto: UpdateTariffPlanDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminTariffPlansControllerTariffPlan(id, updateTariffPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminTariffPlansApi - factory interface
 * @export
 */
export const V1AdminTariffPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminTariffPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single TariffPlan
         * @param {CreateTariffPlanDto} createTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminTariffPlansControllerTariffPlan(createTariffPlanDto: CreateTariffPlanDto, options?: any): AxiosPromise<TariffPlan> {
            return localVarFp.createOneBaseAdminTariffPlansControllerTariffPlan(createTariffPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single TariffPlan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminTariffPlansControllerTariffPlan(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminTariffPlansControllerTariffPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple TariffPlans
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTariffPlansControllerTariffPlan(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyTariffPlanResponseDto> {
            return localVarFp.getManyBaseAdminTariffPlansControllerTariffPlan(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single TariffPlan
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTariffPlansControllerTariffPlan(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<TariffPlan> {
            return localVarFp.getOneBaseAdminTariffPlansControllerTariffPlan(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single TariffPlan
         * @param {number} id 
         * @param {UpdateTariffPlanDto} updateTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminTariffPlansControllerTariffPlan(id: number, updateTariffPlanDto: UpdateTariffPlanDto, options?: any): AxiosPromise<TariffPlan> {
            return localVarFp.updateOneBaseAdminTariffPlansControllerTariffPlan(id, updateTariffPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminTariffPlansApi - object-oriented interface
 * @export
 * @class V1AdminTariffPlansApi
 * @extends {BaseAPI}
 */
export class V1AdminTariffPlansApi extends BaseAPI {
    /**
     * 
     * @summary Create a single TariffPlan
     * @param {CreateTariffPlanDto} createTariffPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTariffPlansApi
     */
    public createOneBaseAdminTariffPlansControllerTariffPlan(createTariffPlanDto: CreateTariffPlanDto, options?: any) {
        return V1AdminTariffPlansApiFp(this.configuration).createOneBaseAdminTariffPlansControllerTariffPlan(createTariffPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single TariffPlan
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTariffPlansApi
     */
    public deleteOneBaseAdminTariffPlansControllerTariffPlan(id: number, options?: any) {
        return V1AdminTariffPlansApiFp(this.configuration).deleteOneBaseAdminTariffPlansControllerTariffPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple TariffPlans
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTariffPlansApi
     */
    public getManyBaseAdminTariffPlansControllerTariffPlan(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminTariffPlansApiFp(this.configuration).getManyBaseAdminTariffPlansControllerTariffPlan(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single TariffPlan
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTariffPlansApi
     */
    public getOneBaseAdminTariffPlansControllerTariffPlan(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminTariffPlansApiFp(this.configuration).getOneBaseAdminTariffPlansControllerTariffPlan(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single TariffPlan
     * @param {number} id 
     * @param {UpdateTariffPlanDto} updateTariffPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTariffPlansApi
     */
    public updateOneBaseAdminTariffPlansControllerTariffPlan(id: number, updateTariffPlanDto: UpdateTariffPlanDto, options?: any) {
        return V1AdminTariffPlansApiFp(this.configuration).updateOneBaseAdminTariffPlansControllerTariffPlan(id, updateTariffPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminTransactionsApi - axios parameter creator
 * @export
 */
export const V1AdminTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Transactions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTransactionsControllerTransaction: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Transaction
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTransactionsControllerTransaction: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminTransactionsControllerTransaction', 'id', id)
            const localVarPath = `/v1/admin/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminTransactionsApi - functional programming interface
 * @export
 */
export const V1AdminTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Transactions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyTransactionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminTransactionsControllerTransaction(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Transaction
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminTransactionsControllerTransaction(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminTransactionsControllerTransaction(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminTransactionsApi - factory interface
 * @export
 */
export const V1AdminTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Transactions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyTransactionResponseDto> {
            return localVarFp.getManyBaseAdminTransactionsControllerTransaction(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Transaction
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminTransactionsControllerTransaction(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Transaction> {
            return localVarFp.getOneBaseAdminTransactionsControllerTransaction(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminTransactionsApi - object-oriented interface
 * @export
 * @class V1AdminTransactionsApi
 * @extends {BaseAPI}
 */
export class V1AdminTransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Transactions
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTransactionsApi
     */
    public getManyBaseAdminTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminTransactionsApiFp(this.configuration).getManyBaseAdminTransactionsControllerTransaction(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Transaction
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminTransactionsApi
     */
    public getOneBaseAdminTransactionsControllerTransaction(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminTransactionsApiFp(this.configuration).getOneBaseAdminTransactionsControllerTransaction(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminUsersApi - axios parameter creator
 * @export
 */
export const V1AdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Block or unblock user
         * @param {BlockUserDto} blockUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerBlockUser: async (blockUserDto: BlockUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockUserDto' is not null or undefined
            assertParamExists('adminUsersControllerBlockUser', 'blockUserDto', blockUserDto)
            const localVarPath = `/v1/admin/users/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create multiple Users
         * @param {CreateManyUserDto} createManyUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyBaseAdminUsersControllerUser: async (createManyUserDto: CreateManyUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManyUserDto' is not null or undefined
            assertParamExists('createManyBaseAdminUsersControllerUser', 'createManyUserDto', createManyUserDto)
            const localVarPath = `/v1/admin/users/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManyUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a single User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminUsersControllerUser: async (user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createOneBaseAdminUsersControllerUser', 'user', user)
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminUsersControllerUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminUsersControllerUser', 'id', id)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminUsersControllerUser: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminUsersControllerUser: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminUsersControllerUser', 'id', id)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single User
         * @param {number} id 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneBaseAdminUsersControllerUser: async (id: number, user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceOneBaseAdminUsersControllerUser', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('replaceOneBaseAdminUsersControllerUser', 'user', user)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single User
         * @param {number} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminUsersControllerUser: async (id: number, updateAdminUserDto: UpdateAdminUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminUsersControllerUser', 'id', id)
            // verify required parameter 'updateAdminUserDto' is not null or undefined
            assertParamExists('updateOneBaseAdminUsersControllerUser', 'updateAdminUserDto', updateAdminUserDto)
            const localVarPath = `/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminUsersApi - functional programming interface
 * @export
 */
export const V1AdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Block or unblock user
         * @param {BlockUserDto} blockUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerBlockUser(blockUserDto: BlockUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerBlockUser(blockUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create multiple Users
         * @param {CreateManyUserDto} createManyUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManyBaseAdminUsersControllerUser(createManyUserDto: CreateManyUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManyBaseAdminUsersControllerUser(createManyUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a single User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminUsersControllerUser(user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminUsersControllerUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminUsersControllerUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminUsersControllerUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminUsersControllerUser(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminUsersControllerUser(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminUsersControllerUser(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminUsersControllerUser(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replace a single User
         * @param {number} id 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneBaseAdminUsersControllerUser(id: number, user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceOneBaseAdminUsersControllerUser(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single User
         * @param {number} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminUsersControllerUser(id: number, updateAdminUserDto: UpdateAdminUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminUsersControllerUser(id, updateAdminUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminUsersApi - factory interface
 * @export
 */
export const V1AdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Block or unblock user
         * @param {BlockUserDto} blockUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerBlockUser(blockUserDto: BlockUserDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminUsersControllerBlockUser(blockUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create multiple Users
         * @param {CreateManyUserDto} createManyUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManyBaseAdminUsersControllerUser(createManyUserDto: CreateManyUserDto, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.createManyBaseAdminUsersControllerUser(createManyUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a single User
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminUsersControllerUser(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.createOneBaseAdminUsersControllerUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminUsersControllerUser(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteOneBaseAdminUsersControllerUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminUsersControllerUser(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyUserResponseDto> {
            return localVarFp.getManyBaseAdminUsersControllerUser(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminUsersControllerUser(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<User> {
            return localVarFp.getOneBaseAdminUsersControllerUser(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single User
         * @param {number} id 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneBaseAdminUsersControllerUser(id: number, user: User, options?: any): AxiosPromise<User> {
            return localVarFp.replaceOneBaseAdminUsersControllerUser(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single User
         * @param {number} id 
         * @param {UpdateAdminUserDto} updateAdminUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminUsersControllerUser(id: number, updateAdminUserDto: UpdateAdminUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.updateOneBaseAdminUsersControllerUser(id, updateAdminUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminUsersApi - object-oriented interface
 * @export
 * @class V1AdminUsersApi
 * @extends {BaseAPI}
 */
export class V1AdminUsersApi extends BaseAPI {
    /**
     * 
     * @summary Block or unblock user
     * @param {BlockUserDto} blockUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public adminUsersControllerBlockUser(blockUserDto: BlockUserDto, options?: any) {
        return V1AdminUsersApiFp(this.configuration).adminUsersControllerBlockUser(blockUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create multiple Users
     * @param {CreateManyUserDto} createManyUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public createManyBaseAdminUsersControllerUser(createManyUserDto: CreateManyUserDto, options?: any) {
        return V1AdminUsersApiFp(this.configuration).createManyBaseAdminUsersControllerUser(createManyUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a single User
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public createOneBaseAdminUsersControllerUser(user: User, options?: any) {
        return V1AdminUsersApiFp(this.configuration).createOneBaseAdminUsersControllerUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public deleteOneBaseAdminUsersControllerUser(id: number, options?: any) {
        return V1AdminUsersApiFp(this.configuration).deleteOneBaseAdminUsersControllerUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Users
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public getManyBaseAdminUsersControllerUser(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminUsersApiFp(this.configuration).getManyBaseAdminUsersControllerUser(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single User
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public getOneBaseAdminUsersControllerUser(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminUsersApiFp(this.configuration).getOneBaseAdminUsersControllerUser(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single User
     * @param {number} id 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public replaceOneBaseAdminUsersControllerUser(id: number, user: User, options?: any) {
        return V1AdminUsersApiFp(this.configuration).replaceOneBaseAdminUsersControllerUser(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single User
     * @param {number} id 
     * @param {UpdateAdminUserDto} updateAdminUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersApi
     */
    public updateOneBaseAdminUsersControllerUser(id: number, updateAdminUserDto: UpdateAdminUserDto, options?: any) {
        return V1AdminUsersApiFp(this.configuration).updateOneBaseAdminUsersControllerUser(id, updateAdminUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminUsersTariffPlansApi - axios parameter creator
 * @export
 */
export const V1AdminUsersTariffPlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update user tariff plan
         * @param {number} userId 
         * @param {UpdateUserTariffPlanDto} updateUserTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersTariffPlansControllerUpdateUserTariffPlan: async (userId: number, updateUserTariffPlanDto: UpdateUserTariffPlanDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUsersTariffPlansControllerUpdateUserTariffPlan', 'userId', userId)
            // verify required parameter 'updateUserTariffPlanDto' is not null or undefined
            assertParamExists('adminUsersTariffPlansControllerUpdateUserTariffPlan', 'updateUserTariffPlanDto', updateUserTariffPlanDto)
            const localVarPath = `/v1/admin/users-tariff-plans/{userId}/updatePlan`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserTariffPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminUsersTariffPlansControllerUser: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/users-tariff-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} userId 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminUsersTariffPlansControllerUser: async (userId: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOneBaseAdminUsersTariffPlansControllerUser', 'userId', userId)
            const localVarPath = `/v1/admin/users-tariff-plans/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminUsersTariffPlansApi - functional programming interface
 * @export
 */
export const V1AdminUsersTariffPlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminUsersTariffPlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update user tariff plan
         * @param {number} userId 
         * @param {UpdateUserTariffPlanDto} updateUserTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersTariffPlansControllerUpdateUserTariffPlan(userId: number, updateUserTariffPlanDto: UpdateUserTariffPlanDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersTariffPlansControllerUpdateUserTariffPlan(userId, updateUserTariffPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminUsersTariffPlansControllerUser(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminUsersTariffPlansControllerUser(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} userId 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminUsersTariffPlansControllerUser(userId: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminUsersTariffPlansControllerUser(userId, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminUsersTariffPlansApi - factory interface
 * @export
 */
export const V1AdminUsersTariffPlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminUsersTariffPlansApiFp(configuration)
    return {
        /**
         * 
         * @summary Update user tariff plan
         * @param {number} userId 
         * @param {UpdateUserTariffPlanDto} updateUserTariffPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersTariffPlansControllerUpdateUserTariffPlan(userId: number, updateUserTariffPlanDto: UpdateUserTariffPlanDto, options?: any): AxiosPromise<User> {
            return localVarFp.adminUsersTariffPlansControllerUpdateUserTariffPlan(userId, updateUserTariffPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Users
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminUsersTariffPlansControllerUser(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyUserResponseDto> {
            return localVarFp.getManyBaseAdminUsersTariffPlansControllerUser(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single User
         * @param {number} userId 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminUsersTariffPlansControllerUser(userId: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<User> {
            return localVarFp.getOneBaseAdminUsersTariffPlansControllerUser(userId, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminUsersTariffPlansApi - object-oriented interface
 * @export
 * @class V1AdminUsersTariffPlansApi
 * @extends {BaseAPI}
 */
export class V1AdminUsersTariffPlansApi extends BaseAPI {
    /**
     * 
     * @summary Update user tariff plan
     * @param {number} userId 
     * @param {UpdateUserTariffPlanDto} updateUserTariffPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersTariffPlansApi
     */
    public adminUsersTariffPlansControllerUpdateUserTariffPlan(userId: number, updateUserTariffPlanDto: UpdateUserTariffPlanDto, options?: any) {
        return V1AdminUsersTariffPlansApiFp(this.configuration).adminUsersTariffPlansControllerUpdateUserTariffPlan(userId, updateUserTariffPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Users
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersTariffPlansApi
     */
    public getManyBaseAdminUsersTariffPlansControllerUser(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminUsersTariffPlansApiFp(this.configuration).getManyBaseAdminUsersTariffPlansControllerUser(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single User
     * @param {number} userId 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminUsersTariffPlansApi
     */
    public getOneBaseAdminUsersTariffPlansControllerUser(userId: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminUsersTariffPlansApiFp(this.configuration).getOneBaseAdminUsersTariffPlansControllerUser(userId, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminVerificationRequestsApi - axios parameter creator
 * @export
 */
export const V1AdminVerificationRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Processing verification request
         * @param {number} id 
         * @param {AdminUpdateVerificationRequestDto} adminUpdateVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVerificationRequestsControllerProcessingVerificationRequest: async (id: number, adminUpdateVerificationRequestDto: AdminUpdateVerificationRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminVerificationRequestsControllerProcessingVerificationRequest', 'id', id)
            // verify required parameter 'adminUpdateVerificationRequestDto' is not null or undefined
            assertParamExists('adminVerificationRequestsControllerProcessingVerificationRequest', 'adminUpdateVerificationRequestDto', adminUpdateVerificationRequestDto)
            const localVarPath = `/v1/admin/verification-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateVerificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single VerificationRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminVerificationRequestsControllerVerificationRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminVerificationRequestsControllerVerificationRequest', 'id', id)
            const localVarPath = `/v1/admin/verification-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple VerificationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminVerificationRequestsControllerVerificationRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/verification-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single VerificationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminVerificationRequestsControllerVerificationRequest: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminVerificationRequestsControllerVerificationRequest', 'id', id)
            const localVarPath = `/v1/admin/verification-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminVerificationRequestsApi - functional programming interface
 * @export
 */
export const V1AdminVerificationRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminVerificationRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Processing verification request
         * @param {number} id 
         * @param {AdminUpdateVerificationRequestDto} adminUpdateVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminVerificationRequestsControllerProcessingVerificationRequest(id: number, adminUpdateVerificationRequestDto: AdminUpdateVerificationRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminVerificationRequestsControllerProcessingVerificationRequest(id, adminUpdateVerificationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single VerificationRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple VerificationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminVerificationRequestsControllerVerificationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyVerificationRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminVerificationRequestsControllerVerificationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single VerificationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminVerificationRequestsControllerVerificationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminVerificationRequestsControllerVerificationRequest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminVerificationRequestsApi - factory interface
 * @export
 */
export const V1AdminVerificationRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminVerificationRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Processing verification request
         * @param {number} id 
         * @param {AdminUpdateVerificationRequestDto} adminUpdateVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminVerificationRequestsControllerProcessingVerificationRequest(id: number, adminUpdateVerificationRequestDto: AdminUpdateVerificationRequestDto, options?: any): AxiosPromise<VerificationRequest> {
            return localVarFp.adminVerificationRequestsControllerProcessingVerificationRequest(id, adminUpdateVerificationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single VerificationRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple VerificationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminVerificationRequestsControllerVerificationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyVerificationRequestResponseDto> {
            return localVarFp.getManyBaseAdminVerificationRequestsControllerVerificationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single VerificationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminVerificationRequestsControllerVerificationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<VerificationRequest> {
            return localVarFp.getOneBaseAdminVerificationRequestsControllerVerificationRequest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminVerificationRequestsApi - object-oriented interface
 * @export
 * @class V1AdminVerificationRequestsApi
 * @extends {BaseAPI}
 */
export class V1AdminVerificationRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Processing verification request
     * @param {number} id 
     * @param {AdminUpdateVerificationRequestDto} adminUpdateVerificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminVerificationRequestsApi
     */
    public adminVerificationRequestsControllerProcessingVerificationRequest(id: number, adminUpdateVerificationRequestDto: AdminUpdateVerificationRequestDto, options?: any) {
        return V1AdminVerificationRequestsApiFp(this.configuration).adminVerificationRequestsControllerProcessingVerificationRequest(id, adminUpdateVerificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single VerificationRequest
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminVerificationRequestsApi
     */
    public deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(id: number, options?: any) {
        return V1AdminVerificationRequestsApiFp(this.configuration).deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple VerificationRequests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminVerificationRequestsApi
     */
    public getManyBaseAdminVerificationRequestsControllerVerificationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminVerificationRequestsApiFp(this.configuration).getManyBaseAdminVerificationRequestsControllerVerificationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single VerificationRequest
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminVerificationRequestsApi
     */
    public getOneBaseAdminVerificationRequestsControllerVerificationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminVerificationRequestsApiFp(this.configuration).getOneBaseAdminVerificationRequestsControllerVerificationRequest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminWalletsApi - axios parameter creator
 * @export
 */
export const V1AdminWalletsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Withdrawal all balance from wallet
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWalletsControllerWithdrawalWallet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminWalletsControllerWithdrawalWallet', 'id', id)
            const localVarPath = `/v1/admin/wallets/{id}/withdrawal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Wallets
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminWalletsControllerWallet: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Wallet
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminWalletsControllerWallet: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminWalletsControllerWallet', 'id', id)
            const localVarPath = `/v1/admin/wallets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminWalletsApi - functional programming interface
 * @export
 */
export const V1AdminWalletsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminWalletsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Withdrawal all balance from wallet
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminWalletsControllerWithdrawalWallet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminWalletsControllerWithdrawalWallet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Wallets
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminWalletsControllerWallet(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyWalletResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminWalletsControllerWallet(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Wallet
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminWalletsControllerWallet(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminWalletsControllerWallet(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminWalletsApi - factory interface
 * @export
 */
export const V1AdminWalletsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminWalletsApiFp(configuration)
    return {
        /**
         * 
         * @summary Withdrawal all balance from wallet
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminWalletsControllerWithdrawalWallet(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminWalletsControllerWithdrawalWallet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Wallets
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminWalletsControllerWallet(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyWalletResponseDto> {
            return localVarFp.getManyBaseAdminWalletsControllerWallet(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Wallet
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminWalletsControllerWallet(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Wallet> {
            return localVarFp.getOneBaseAdminWalletsControllerWallet(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminWalletsApi - object-oriented interface
 * @export
 * @class V1AdminWalletsApi
 * @extends {BaseAPI}
 */
export class V1AdminWalletsApi extends BaseAPI {
    /**
     * 
     * @summary Withdrawal all balance from wallet
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminWalletsApi
     */
    public adminWalletsControllerWithdrawalWallet(id: number, options?: any) {
        return V1AdminWalletsApiFp(this.configuration).adminWalletsControllerWithdrawalWallet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Wallets
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminWalletsApi
     */
    public getManyBaseAdminWalletsControllerWallet(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminWalletsApiFp(this.configuration).getManyBaseAdminWalletsControllerWallet(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Wallet
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminWalletsApi
     */
    public getOneBaseAdminWalletsControllerWallet(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminWalletsApiFp(this.configuration).getOneBaseAdminWalletsControllerWallet(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ArticlesApi - axios parameter creator
 * @export
 */
export const V1ArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get many articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetAllArticles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one article
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('articlesControllerGetOne', 'id', id)
            const localVarPath = `/v1/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ArticlesApi - functional programming interface
 * @export
 */
export const V1ArticlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ArticlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get many articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetAllArticles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetAllArticles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one article
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articlesControllerGetOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articlesControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ArticlesApi - factory interface
 * @export
 */
export const V1ArticlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ArticlesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get many articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetAllArticles(options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.articlesControllerGetAllArticles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one article
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articlesControllerGetOne(id: number, options?: any): AxiosPromise<Article> {
            return localVarFp.articlesControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ArticlesApi - object-oriented interface
 * @export
 * @class V1ArticlesApi
 * @extends {BaseAPI}
 */
export class V1ArticlesApi extends BaseAPI {
    /**
     * 
     * @summary Get many articles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ArticlesApi
     */
    public articlesControllerGetAllArticles(options?: any) {
        return V1ArticlesApiFp(this.configuration).articlesControllerGetAllArticles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one article
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ArticlesApi
     */
    public articlesControllerGetOne(id: number, options?: any) {
        return V1ArticlesApiFp(this.configuration).articlesControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CategoriesApi - axios parameter creator
 * @export
 */
export const V1CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create suggested category from user
         * @param {CreateSuggestedCategoryDto} createSuggestedCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerCreateSuggestedCategoryFromUser: async (createSuggestedCategoryDto: CreateSuggestedCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSuggestedCategoryDto' is not null or undefined
            assertParamExists('categoriesControllerCreateSuggestedCategoryFromUser', 'createSuggestedCategoryDto', createSuggestedCategoryDto)
            const localVarPath = `/v1/categories/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSuggestedCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated experts by category id
         * @param {number} id 
         * @param {'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt'} sortCriteria 
         * @param {'ASC' | 'DESC'} direction 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'freeConsultations'} [consultationsFilter] 
         * @param {'allCategories' | 'rootSubcategories'} [subcategoriesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetExpertsByCategory: async (id: number, sortCriteria: 'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt', direction: 'ASC' | 'DESC', limit?: number, page?: number, consultationsFilter?: 'freeConsultations', subcategoriesFilter?: 'allCategories' | 'rootSubcategories', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesControllerGetExpertsByCategory', 'id', id)
            // verify required parameter 'sortCriteria' is not null or undefined
            assertParamExists('categoriesControllerGetExpertsByCategory', 'sortCriteria', sortCriteria)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('categoriesControllerGetExpertsByCategory', 'direction', direction)
            const localVarPath = `/v1/categories/{id}/experts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortCriteria !== undefined) {
                localVarQueryParameter['sortCriteria'] = sortCriteria;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (consultationsFilter !== undefined) {
                localVarQueryParameter['consultationsFilter'] = consultationsFilter;
            }

            if (subcategoriesFilter !== undefined) {
                localVarQueryParameter['subcategoriesFilter'] = subcategoriesFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many categories
         * @param {string} [name] 
         * @param {Array<'SUB_CATEGORIES'>} [join] 
         * @param {number} [rubricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetManyCategories: async (name?: string, join?: Array<'SUB_CATEGORIES'>, rubricId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (rubricId !== undefined) {
                localVarQueryParameter['rubricId'] = rubricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one category (with subcategories and root categories)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetOneCategory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesControllerGetOneCategory', 'id', id)
            const localVarPath = `/v1/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CategoriesApi - functional programming interface
 * @export
 */
export const V1CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create suggested category from user
         * @param {CreateSuggestedCategoryDto} createSuggestedCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerCreateSuggestedCategoryFromUser(createSuggestedCategoryDto: CreateSuggestedCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerCreateSuggestedCategoryFromUser(createSuggestedCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated experts by category id
         * @param {number} id 
         * @param {'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt'} sortCriteria 
         * @param {'ASC' | 'DESC'} direction 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'freeConsultations'} [consultationsFilter] 
         * @param {'allCategories' | 'rootSubcategories'} [subcategoriesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerGetExpertsByCategory(id: number, sortCriteria: 'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt', direction: 'ASC' | 'DESC', limit?: number, page?: number, consultationsFilter?: 'freeConsultations', subcategoriesFilter?: 'allCategories' | 'rootSubcategories', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyCategoryExpertsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerGetExpertsByCategory(id, sortCriteria, direction, limit, page, consultationsFilter, subcategoriesFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many categories
         * @param {string} [name] 
         * @param {Array<'SUB_CATEGORIES'>} [join] 
         * @param {number} [rubricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerGetManyCategories(name?: string, join?: Array<'SUB_CATEGORIES'>, rubricId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerGetManyCategories(name, join, rubricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one category (with subcategories and root categories)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerGetOneCategory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerGetOneCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CategoriesApi - factory interface
 * @export
 */
export const V1CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create suggested category from user
         * @param {CreateSuggestedCategoryDto} createSuggestedCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerCreateSuggestedCategoryFromUser(createSuggestedCategoryDto: CreateSuggestedCategoryDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.categoriesControllerCreateSuggestedCategoryFromUser(createSuggestedCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated experts by category id
         * @param {number} id 
         * @param {'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt'} sortCriteria 
         * @param {'ASC' | 'DESC'} direction 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'freeConsultations'} [consultationsFilter] 
         * @param {'allCategories' | 'rootSubcategories'} [subcategoriesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetExpertsByCategory(id: number, sortCriteria: 'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt', direction: 'ASC' | 'DESC', limit?: number, page?: number, consultationsFilter?: 'freeConsultations', subcategoriesFilter?: 'allCategories' | 'rootSubcategories', options?: any): AxiosPromise<GetManyCategoryExpertsDto> {
            return localVarFp.categoriesControllerGetExpertsByCategory(id, sortCriteria, direction, limit, page, consultationsFilter, subcategoriesFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many categories
         * @param {string} [name] 
         * @param {Array<'SUB_CATEGORIES'>} [join] 
         * @param {number} [rubricId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetManyCategories(name?: string, join?: Array<'SUB_CATEGORIES'>, rubricId?: number, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesControllerGetManyCategories(name, join, rubricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one category (with subcategories and root categories)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetOneCategory(id: number, options?: any): AxiosPromise<GetCategoryDto> {
            return localVarFp.categoriesControllerGetOneCategory(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CategoriesApi - object-oriented interface
 * @export
 * @class V1CategoriesApi
 * @extends {BaseAPI}
 */
export class V1CategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Create suggested category from user
     * @param {CreateSuggestedCategoryDto} createSuggestedCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerCreateSuggestedCategoryFromUser(createSuggestedCategoryDto: CreateSuggestedCategoryDto, options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerCreateSuggestedCategoryFromUser(createSuggestedCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated experts by category id
     * @param {number} id 
     * @param {'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt'} sortCriteria 
     * @param {'ASC' | 'DESC'} direction 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {'freeConsultations'} [consultationsFilter] 
     * @param {'allCategories' | 'rootSubcategories'} [subcategoriesFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerGetExpertsByCategory(id: number, sortCriteria: 'rating' | 'outcomingRating' | 'countOfConsultationExpert' | 'countOfConsultationsUser' | 'totalProceeds' | 'countComplaint' | 'userComplaintCount' | 'createdAt', direction: 'ASC' | 'DESC', limit?: number, page?: number, consultationsFilter?: 'freeConsultations', subcategoriesFilter?: 'allCategories' | 'rootSubcategories', options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerGetExpertsByCategory(id, sortCriteria, direction, limit, page, consultationsFilter, subcategoriesFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many categories
     * @param {string} [name] 
     * @param {Array<'SUB_CATEGORIES'>} [join] 
     * @param {number} [rubricId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerGetManyCategories(name?: string, join?: Array<'SUB_CATEGORIES'>, rubricId?: number, options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerGetManyCategories(name, join, rubricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one category (with subcategories and root categories)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerGetOneCategory(id: number, options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerGetOneCategory(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ChatsApi - axios parameter creator
 * @export
 */
export const V1ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel one chat consultation offer
         * @param {number} chatId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerCancelChatConsultationOffer: async (chatId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('chatRoomsControllerCancelChatConsultationOffer', 'chatId', chatId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerCancelChatConsultationOffer', 'id', id)
            const localVarPath = `/v1/chat/{chatId}/chatConsultationOffer/{id}/cancel`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create chat room
         * @param {CreateChatRoomDto} createChatRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerCreateChat: async (createChatRoomDto: CreateChatRoomDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChatRoomDto' is not null or undefined
            assertParamExists('chatRoomsControllerCreateChat', 'createChatRoomDto', createChatRoomDto)
            const localVarPath = `/v1/chat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChatRoomDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one chat consultation offer
         * @param {number} id 
         * @param {CreateChatConsultationOfferDto} createChatConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerCreateChatConsultationOffer: async (id: number, createChatConsultationOfferDto: CreateChatConsultationOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerCreateChatConsultationOffer', 'id', id)
            // verify required parameter 'createChatConsultationOfferDto' is not null or undefined
            assertParamExists('chatRoomsControllerCreateChatConsultationOffer', 'createChatConsultationOfferDto', createChatConsultationOfferDto)
            const localVarPath = `/v1/chat/{id}/chatConsultationOffer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChatConsultationOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete one chat
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerDeleteChat: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerDeleteChat', 'id', id)
            const localVarPath = `/v1/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chat room messages
         * @param {number} id 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerGetChatMessages: async (id: number, limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerGetChatMessages', 'id', id)
            const localVarPath = `/v1/chat/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many chat rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerGetMany: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/chat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one chat room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerGetOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerGetOne', 'id', id)
            const localVarPath = `/v1/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle mute chat
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerToggleMuteOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerToggleMuteOne', 'id', id)
            const localVarPath = `/v1/chat/{id}/mute`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update last read message ID
         * @param {number} id 
         * @param {UpdateLastReadMessageDto} updateLastReadMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerUpdateLastReadMessage: async (id: number, updateLastReadMessageDto: UpdateLastReadMessageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatRoomsControllerUpdateLastReadMessage', 'id', id)
            // verify required parameter 'updateLastReadMessageDto' is not null or undefined
            assertParamExists('chatRoomsControllerUpdateLastReadMessage', 'updateLastReadMessageDto', updateLastReadMessageDto)
            const localVarPath = `/v1/chat/{id}/lastReadMessage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLastReadMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ChatsApi - functional programming interface
 * @export
 */
export const V1ChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel one chat consultation offer
         * @param {number} chatId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerCancelChatConsultationOffer(chatId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatConsultationOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerCancelChatConsultationOffer(chatId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create chat room
         * @param {CreateChatRoomDto} createChatRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerCreateChat(createChatRoomDto: CreateChatRoomDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatRoom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerCreateChat(createChatRoomDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one chat consultation offer
         * @param {number} id 
         * @param {CreateChatConsultationOfferDto} createChatConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerCreateChatConsultationOffer(id: number, createChatConsultationOfferDto: CreateChatConsultationOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatConsultationOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerCreateChatConsultationOffer(id, createChatConsultationOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete one chat
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerDeleteChat(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerDeleteChat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chat room messages
         * @param {number} id 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerGetChatMessages(id: number, limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChatRoomManyMessages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerGetChatMessages(id, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many chat rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerGetMany(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatRoom>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerGetMany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one chat room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerGetOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatRoom>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle mute chat
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerToggleMuteOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerToggleMuteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update last read message ID
         * @param {number} id 
         * @param {UpdateLastReadMessageDto} updateLastReadMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatRoomsControllerUpdateLastReadMessage(id: number, updateLastReadMessageDto: UpdateLastReadMessageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatRoomsControllerUpdateLastReadMessage(id, updateLastReadMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ChatsApi - factory interface
 * @export
 */
export const V1ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ChatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel one chat consultation offer
         * @param {number} chatId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerCancelChatConsultationOffer(chatId: number, id: number, options?: any): AxiosPromise<ChatConsultationOffer> {
            return localVarFp.chatRoomsControllerCancelChatConsultationOffer(chatId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create chat room
         * @param {CreateChatRoomDto} createChatRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerCreateChat(createChatRoomDto: CreateChatRoomDto, options?: any): AxiosPromise<ChatRoom> {
            return localVarFp.chatRoomsControllerCreateChat(createChatRoomDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one chat consultation offer
         * @param {number} id 
         * @param {CreateChatConsultationOfferDto} createChatConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerCreateChatConsultationOffer(id: number, createChatConsultationOfferDto: CreateChatConsultationOfferDto, options?: any): AxiosPromise<ChatConsultationOffer> {
            return localVarFp.chatRoomsControllerCreateChatConsultationOffer(id, createChatConsultationOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete one chat
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerDeleteChat(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.chatRoomsControllerDeleteChat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chat room messages
         * @param {number} id 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerGetChatMessages(id: number, limit?: number, page?: number, options?: any): AxiosPromise<GetChatRoomManyMessages> {
            return localVarFp.chatRoomsControllerGetChatMessages(id, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many chat rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerGetMany(options?: any): AxiosPromise<Array<ChatRoom>> {
            return localVarFp.chatRoomsControllerGetMany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one chat room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerGetOne(id: number, options?: any): AxiosPromise<Array<ChatRoom>> {
            return localVarFp.chatRoomsControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle mute chat
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerToggleMuteOne(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.chatRoomsControllerToggleMuteOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update last read message ID
         * @param {number} id 
         * @param {UpdateLastReadMessageDto} updateLastReadMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatRoomsControllerUpdateLastReadMessage(id: number, updateLastReadMessageDto: UpdateLastReadMessageDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.chatRoomsControllerUpdateLastReadMessage(id, updateLastReadMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ChatsApi - object-oriented interface
 * @export
 * @class V1ChatsApi
 * @extends {BaseAPI}
 */
export class V1ChatsApi extends BaseAPI {
    /**
     * 
     * @summary Cancel one chat consultation offer
     * @param {number} chatId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerCancelChatConsultationOffer(chatId: number, id: number, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerCancelChatConsultationOffer(chatId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create chat room
     * @param {CreateChatRoomDto} createChatRoomDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerCreateChat(createChatRoomDto: CreateChatRoomDto, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerCreateChat(createChatRoomDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one chat consultation offer
     * @param {number} id 
     * @param {CreateChatConsultationOfferDto} createChatConsultationOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerCreateChatConsultationOffer(id: number, createChatConsultationOfferDto: CreateChatConsultationOfferDto, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerCreateChatConsultationOffer(id, createChatConsultationOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete one chat
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerDeleteChat(id: number, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerDeleteChat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chat room messages
     * @param {number} id 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerGetChatMessages(id: number, limit?: number, page?: number, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerGetChatMessages(id, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many chat rooms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerGetMany(options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerGetMany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one chat room
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerGetOne(id: number, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle mute chat
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerToggleMuteOne(id: number, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerToggleMuteOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update last read message ID
     * @param {number} id 
     * @param {UpdateLastReadMessageDto} updateLastReadMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatRoomsControllerUpdateLastReadMessage(id: number, updateLastReadMessageDto: UpdateLastReadMessageDto, options?: any) {
        return V1ChatsApiFp(this.configuration).chatRoomsControllerUpdateLastReadMessage(id, updateLastReadMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ConsultationInvoicesApi - axios parameter creator
 * @export
 */
export const V1ConsultationInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Decline one consultation invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationInvoicesControllerDeclineOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationInvoicesControllerDeclineOne', 'id', id)
            const localVarPath = `/v1/consultation-invoices/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ConsultationInvoicesApi - functional programming interface
 * @export
 */
export const V1ConsultationInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ConsultationInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Decline one consultation invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationInvoicesControllerDeclineOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationInvoicesControllerDeclineOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ConsultationInvoicesApi - factory interface
 * @export
 */
export const V1ConsultationInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ConsultationInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Decline one consultation invoice
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationInvoicesControllerDeclineOne(id: number, options?: any): AxiosPromise<ConsultationInvoice> {
            return localVarFp.consultationInvoicesControllerDeclineOne(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ConsultationInvoicesApi - object-oriented interface
 * @export
 * @class V1ConsultationInvoicesApi
 * @extends {BaseAPI}
 */
export class V1ConsultationInvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Decline one consultation invoice
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationInvoicesApi
     */
    public consultationInvoicesControllerDeclineOne(id: number, options?: any) {
        return V1ConsultationInvoicesApiFp(this.configuration).consultationInvoicesControllerDeclineOne(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ConsultationOffersApi - axios parameter creator
 * @export
 */
export const V1ConsultationOffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept consultation offer
         * @param {number} id 
         * @param {AcceptConsultationOfferDto} acceptConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerAcceptOne: async (id: number, acceptConsultationOfferDto: AcceptConsultationOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationOffersControllerAcceptOne', 'id', id)
            // verify required parameter 'acceptConsultationOfferDto' is not null or undefined
            assertParamExists('consultationOffersControllerAcceptOne', 'acceptConsultationOfferDto', acceptConsultationOfferDto)
            const localVarPath = `/v1/consultation-offers/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptConsultationOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept consultation offer with promocode
         * @param {number} id 
         * @param {AcceptPromocodeConsultationOfferDto} acceptPromocodeConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerAcceptOneWithPromocode: async (id: number, acceptPromocodeConsultationOfferDto: AcceptPromocodeConsultationOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationOffersControllerAcceptOneWithPromocode', 'id', id)
            // verify required parameter 'acceptPromocodeConsultationOfferDto' is not null or undefined
            assertParamExists('consultationOffersControllerAcceptOneWithPromocode', 'acceptPromocodeConsultationOfferDto', acceptPromocodeConsultationOfferDto)
            const localVarPath = `/v1/consultation-offers/{id}/accept/withPromocode`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptPromocodeConsultationOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create consultation offer
         * @param {CreateConsultationOfferDto} createConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerCreateOne: async (createConsultationOfferDto: CreateConsultationOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConsultationOfferDto' is not null or undefined
            assertParamExists('consultationOffersControllerCreateOne', 'createConsultationOfferDto', createConsultationOfferDto)
            const localVarPath = `/v1/consultation-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many consultation offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerGetMany: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/consultation-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ConsultationOffersApi - functional programming interface
 * @export
 */
export const V1ConsultationOffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ConsultationOffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept consultation offer
         * @param {number} id 
         * @param {AcceptConsultationOfferDto} acceptConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationOffersControllerAcceptOne(id: number, acceptConsultationOfferDto: AcceptConsultationOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationOffersControllerAcceptOne(id, acceptConsultationOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept consultation offer with promocode
         * @param {number} id 
         * @param {AcceptPromocodeConsultationOfferDto} acceptPromocodeConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationOffersControllerAcceptOneWithPromocode(id: number, acceptPromocodeConsultationOfferDto: AcceptPromocodeConsultationOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationOffersControllerAcceptOneWithPromocode(id, acceptPromocodeConsultationOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create consultation offer
         * @param {CreateConsultationOfferDto} createConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationOffersControllerCreateOne(createConsultationOfferDto: CreateConsultationOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationOffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationOffersControllerCreateOne(createConsultationOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many consultation offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationOffersControllerGetMany(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsultationOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationOffersControllerGetMany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ConsultationOffersApi - factory interface
 * @export
 */
export const V1ConsultationOffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ConsultationOffersApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept consultation offer
         * @param {number} id 
         * @param {AcceptConsultationOfferDto} acceptConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerAcceptOne(id: number, acceptConsultationOfferDto: AcceptConsultationOfferDto, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationOffersControllerAcceptOne(id, acceptConsultationOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept consultation offer with promocode
         * @param {number} id 
         * @param {AcceptPromocodeConsultationOfferDto} acceptPromocodeConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerAcceptOneWithPromocode(id: number, acceptPromocodeConsultationOfferDto: AcceptPromocodeConsultationOfferDto, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationOffersControllerAcceptOneWithPromocode(id, acceptPromocodeConsultationOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create consultation offer
         * @param {CreateConsultationOfferDto} createConsultationOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerCreateOne(createConsultationOfferDto: CreateConsultationOfferDto, options?: any): AxiosPromise<ConsultationOffer> {
            return localVarFp.consultationOffersControllerCreateOne(createConsultationOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many consultation offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationOffersControllerGetMany(options?: any): AxiosPromise<Array<ConsultationOffer>> {
            return localVarFp.consultationOffersControllerGetMany(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ConsultationOffersApi - object-oriented interface
 * @export
 * @class V1ConsultationOffersApi
 * @extends {BaseAPI}
 */
export class V1ConsultationOffersApi extends BaseAPI {
    /**
     * 
     * @summary Accept consultation offer
     * @param {number} id 
     * @param {AcceptConsultationOfferDto} acceptConsultationOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationOffersApi
     */
    public consultationOffersControllerAcceptOne(id: number, acceptConsultationOfferDto: AcceptConsultationOfferDto, options?: any) {
        return V1ConsultationOffersApiFp(this.configuration).consultationOffersControllerAcceptOne(id, acceptConsultationOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept consultation offer with promocode
     * @param {number} id 
     * @param {AcceptPromocodeConsultationOfferDto} acceptPromocodeConsultationOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationOffersApi
     */
    public consultationOffersControllerAcceptOneWithPromocode(id: number, acceptPromocodeConsultationOfferDto: AcceptPromocodeConsultationOfferDto, options?: any) {
        return V1ConsultationOffersApiFp(this.configuration).consultationOffersControllerAcceptOneWithPromocode(id, acceptPromocodeConsultationOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create consultation offer
     * @param {CreateConsultationOfferDto} createConsultationOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationOffersApi
     */
    public consultationOffersControllerCreateOne(createConsultationOfferDto: CreateConsultationOfferDto, options?: any) {
        return V1ConsultationOffersApiFp(this.configuration).consultationOffersControllerCreateOne(createConsultationOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many consultation offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationOffersApi
     */
    public consultationOffersControllerGetMany(options?: any) {
        return V1ConsultationOffersApiFp(this.configuration).consultationOffersControllerGetMany(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ConsultationRequestsApi - axios parameter creator
 * @export
 */
export const V1ConsultationRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create one consultation request
         * @param {CreateConsultationRequestDto} createConsultationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseConsultationRequestsControllerConsultationRequest: async (createConsultationRequestDto: CreateConsultationRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConsultationRequestDto' is not null or undefined
            assertParamExists('createOneBaseConsultationRequestsControllerConsultationRequest', 'createConsultationRequestDto', createConsultationRequestDto)
            const localVarPath = `/v1/consultation-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single ConsultationRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseConsultationRequestsControllerConsultationRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseConsultationRequestsControllerConsultationRequest', 'id', id)
            const localVarPath = `/v1/consultation-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple ConsultationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseConsultationRequestsControllerConsultationRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/consultation-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single ConsultationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseConsultationRequestsControllerConsultationRequest: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseConsultationRequestsControllerConsultationRequest', 'id', id)
            const localVarPath = `/v1/consultation-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update one consultation request
         * @param {number} id 
         * @param {UpdateConsultationRequestDto} updateConsultationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseConsultationRequestsControllerConsultationRequest: async (id: number, updateConsultationRequestDto: UpdateConsultationRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseConsultationRequestsControllerConsultationRequest', 'id', id)
            // verify required parameter 'updateConsultationRequestDto' is not null or undefined
            assertParamExists('updateOneBaseConsultationRequestsControllerConsultationRequest', 'updateConsultationRequestDto', updateConsultationRequestDto)
            const localVarPath = `/v1/consultation-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConsultationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ConsultationRequestsApi - functional programming interface
 * @export
 */
export const V1ConsultationRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ConsultationRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create one consultation request
         * @param {CreateConsultationRequestDto} createConsultationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseConsultationRequestsControllerConsultationRequest(createConsultationRequestDto: CreateConsultationRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseConsultationRequestsControllerConsultationRequest(createConsultationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single ConsultationRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseConsultationRequestsControllerConsultationRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseConsultationRequestsControllerConsultationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple ConsultationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseConsultationRequestsControllerConsultationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyConsultationRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseConsultationRequestsControllerConsultationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single ConsultationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseConsultationRequestsControllerConsultationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseConsultationRequestsControllerConsultationRequest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update one consultation request
         * @param {number} id 
         * @param {UpdateConsultationRequestDto} updateConsultationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseConsultationRequestsControllerConsultationRequest(id: number, updateConsultationRequestDto: UpdateConsultationRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseConsultationRequestsControllerConsultationRequest(id, updateConsultationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ConsultationRequestsApi - factory interface
 * @export
 */
export const V1ConsultationRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ConsultationRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create one consultation request
         * @param {CreateConsultationRequestDto} createConsultationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseConsultationRequestsControllerConsultationRequest(createConsultationRequestDto: CreateConsultationRequestDto, options?: any): AxiosPromise<ConsultationRequest> {
            return localVarFp.createOneBaseConsultationRequestsControllerConsultationRequest(createConsultationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single ConsultationRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseConsultationRequestsControllerConsultationRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseConsultationRequestsControllerConsultationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple ConsultationRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseConsultationRequestsControllerConsultationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyConsultationRequestResponseDto> {
            return localVarFp.getManyBaseConsultationRequestsControllerConsultationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single ConsultationRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseConsultationRequestsControllerConsultationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<ConsultationRequest> {
            return localVarFp.getOneBaseConsultationRequestsControllerConsultationRequest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update one consultation request
         * @param {number} id 
         * @param {UpdateConsultationRequestDto} updateConsultationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseConsultationRequestsControllerConsultationRequest(id: number, updateConsultationRequestDto: UpdateConsultationRequestDto, options?: any): AxiosPromise<ConsultationRequest> {
            return localVarFp.updateOneBaseConsultationRequestsControllerConsultationRequest(id, updateConsultationRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ConsultationRequestsApi - object-oriented interface
 * @export
 * @class V1ConsultationRequestsApi
 * @extends {BaseAPI}
 */
export class V1ConsultationRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Create one consultation request
     * @param {CreateConsultationRequestDto} createConsultationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationRequestsApi
     */
    public createOneBaseConsultationRequestsControllerConsultationRequest(createConsultationRequestDto: CreateConsultationRequestDto, options?: any) {
        return V1ConsultationRequestsApiFp(this.configuration).createOneBaseConsultationRequestsControllerConsultationRequest(createConsultationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single ConsultationRequest
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationRequestsApi
     */
    public deleteOneBaseConsultationRequestsControllerConsultationRequest(id: number, options?: any) {
        return V1ConsultationRequestsApiFp(this.configuration).deleteOneBaseConsultationRequestsControllerConsultationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple ConsultationRequests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationRequestsApi
     */
    public getManyBaseConsultationRequestsControllerConsultationRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1ConsultationRequestsApiFp(this.configuration).getManyBaseConsultationRequestsControllerConsultationRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single ConsultationRequest
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationRequestsApi
     */
    public getOneBaseConsultationRequestsControllerConsultationRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1ConsultationRequestsApiFp(this.configuration).getOneBaseConsultationRequestsControllerConsultationRequest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update one consultation request
     * @param {number} id 
     * @param {UpdateConsultationRequestDto} updateConsultationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationRequestsApi
     */
    public updateOneBaseConsultationRequestsControllerConsultationRequest(id: number, updateConsultationRequestDto: UpdateConsultationRequestDto, options?: any) {
        return V1ConsultationRequestsApiFp(this.configuration).updateOneBaseConsultationRequestsControllerConsultationRequest(id, updateConsultationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ConsultationsApi - axios parameter creator
 * @export
 */
export const V1ConsultationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel one paid consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCancelPaidConsultation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationsControllerCancelPaidConsultation', 'id', id)
            const localVarPath = `/v1/consultations/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create consultation receipt
         * @param {number} id 
         * @param {CreateConsultationReceiptDto} createConsultationReceiptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateConsultationReceipt: async (id: number, createConsultationReceiptDto: CreateConsultationReceiptDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationsControllerCreateConsultationReceipt', 'id', id)
            // verify required parameter 'createConsultationReceiptDto' is not null or undefined
            assertParamExists('consultationsControllerCreateConsultationReceipt', 'createConsultationReceiptDto', createConsultationReceiptDto)
            const localVarPath = `/v1/consultations/{id}/receipt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationReceiptDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one booking consultation
         * @param {BookingConsultationDto} bookingConsultationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneBookingConsultation: async (bookingConsultationDto: BookingConsultationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingConsultationDto' is not null or undefined
            assertParamExists('consultationsControllerCreateOneBookingConsultation', 'bookingConsultationDto', bookingConsultationDto)
            const localVarPath = `/v1/consultations/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingConsultationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one consultation from expert service
         * @param {CreateConsultationThroughExpertServiceDto} createConsultationThroughExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneFromExpertServices: async (createConsultationThroughExpertServiceDto: CreateConsultationThroughExpertServiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConsultationThroughExpertServiceDto' is not null or undefined
            assertParamExists('consultationsControllerCreateOneFromExpertServices', 'createConsultationThroughExpertServiceDto', createConsultationThroughExpertServiceDto)
            const localVarPath = `/v1/consultations/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationThroughExpertServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one consultation from user category meta
         * @param {CreateConsultationThroughUserCategoryMetaDto} createConsultationThroughUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneFromUserCategoryMeta: async (createConsultationThroughUserCategoryMetaDto: CreateConsultationThroughUserCategoryMetaDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConsultationThroughUserCategoryMetaDto' is not null or undefined
            assertParamExists('consultationsControllerCreateOneFromUserCategoryMeta', 'createConsultationThroughUserCategoryMetaDto', createConsultationThroughUserCategoryMetaDto)
            const localVarPath = `/v1/consultations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationThroughUserCategoryMetaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one consultation from user category meta with promocode
         * @param {CreateConsultationThroughUserCategoryMetaWithPromocodeDto} createConsultationThroughUserCategoryMetaWithPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneFromUserMetaWithPromocode: async (createConsultationThroughUserCategoryMetaWithPromocodeDto: CreateConsultationThroughUserCategoryMetaWithPromocodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createConsultationThroughUserCategoryMetaWithPromocodeDto' is not null or undefined
            assertParamExists('consultationsControllerCreateOneFromUserMetaWithPromocode', 'createConsultationThroughUserCategoryMetaWithPromocodeDto', createConsultationThroughUserCategoryMetaWithPromocodeDto)
            const localVarPath = `/v1/consultations/withPromocode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationThroughUserCategoryMetaWithPromocodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create consultation prolongation offer
         * @param {number} id 
         * @param {CreateConsultationProlongationInvoiceDto} createConsultationProlongationInvoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateProlongationRequest: async (id: number, createConsultationProlongationInvoiceDto: CreateConsultationProlongationInvoiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationsControllerCreateProlongationRequest', 'id', id)
            // verify required parameter 'createConsultationProlongationInvoiceDto' is not null or undefined
            assertParamExists('consultationsControllerCreateProlongationRequest', 'createConsultationProlongationInvoiceDto', createConsultationProlongationInvoiceDto)
            const localVarPath = `/v1/consultations/{id}/prolongation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsultationProlongationInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get consultation by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerGetById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationsControllerGetById', 'id', id)
            const localVarPath = `/v1/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many consultations
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<'messages' | 'invoices' | 'receipts'>} [join] 
         * @param {Array<'asExpert' | 'asClient' | 'PAID'>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerGetMany: async (limit?: number, page?: number, join?: Array<'messages' | 'invoices' | 'receipts'>, filter?: Array<'asExpert' | 'asClient' | 'PAID'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/consultations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self consultations without review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerGetSelfWithoutReview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/consultations/self/withoutReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start single consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerStartConsultation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationsControllerStartConsultation', 'id', id)
            const localVarPath = `/v1/consultations/{id}/start`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop single consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerStopConsultation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('consultationsControllerStopConsultation', 'id', id)
            const localVarPath = `/v1/consultations/{id}/stop`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one extra service offer
         * @param {number} consultationId 
         * @param {CreateExtraServiceDto} createExtraServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraServicesControllerCreateOne: async (consultationId: number, createExtraServiceDto: CreateExtraServiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('extraServicesControllerCreateOne', 'consultationId', consultationId)
            // verify required parameter 'createExtraServiceDto' is not null or undefined
            assertParamExists('extraServicesControllerCreateOne', 'createExtraServiceDto', createExtraServiceDto)
            const localVarPath = `/v1/consultations/{consultationId}/extra-services`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExtraServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject one extra service
         * @param {number} consultationId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraServicesControllerRejectOne: async (consultationId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'consultationId' is not null or undefined
            assertParamExists('extraServicesControllerRejectOne', 'consultationId', consultationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('extraServicesControllerRejectOne', 'id', id)
            const localVarPath = `/v1/consultations/{consultationId}/extra-services/{id}/reject`
                .replace(`{${"consultationId"}}`, encodeURIComponent(String(consultationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ConsultationsApi - functional programming interface
 * @export
 */
export const V1ConsultationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ConsultationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel one paid consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCancelPaidConsultation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCancelPaidConsultation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create consultation receipt
         * @param {number} id 
         * @param {CreateConsultationReceiptDto} createConsultationReceiptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCreateConsultationReceipt(id: number, createConsultationReceiptDto: CreateConsultationReceiptDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Receipt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCreateConsultationReceipt(id, createConsultationReceiptDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one booking consultation
         * @param {BookingConsultationDto} bookingConsultationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCreateOneBookingConsultation(bookingConsultationDto: BookingConsultationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCreateOneBookingConsultation(bookingConsultationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one consultation from expert service
         * @param {CreateConsultationThroughExpertServiceDto} createConsultationThroughExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCreateOneFromExpertServices(createConsultationThroughExpertServiceDto: CreateConsultationThroughExpertServiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCreateOneFromExpertServices(createConsultationThroughExpertServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one consultation from user category meta
         * @param {CreateConsultationThroughUserCategoryMetaDto} createConsultationThroughUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCreateOneFromUserCategoryMeta(createConsultationThroughUserCategoryMetaDto: CreateConsultationThroughUserCategoryMetaDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCreateOneFromUserCategoryMeta(createConsultationThroughUserCategoryMetaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one consultation from user category meta with promocode
         * @param {CreateConsultationThroughUserCategoryMetaWithPromocodeDto} createConsultationThroughUserCategoryMetaWithPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCreateOneFromUserMetaWithPromocode(createConsultationThroughUserCategoryMetaWithPromocodeDto: CreateConsultationThroughUserCategoryMetaWithPromocodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCreateOneFromUserMetaWithPromocode(createConsultationThroughUserCategoryMetaWithPromocodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create consultation prolongation offer
         * @param {number} id 
         * @param {CreateConsultationProlongationInvoiceDto} createConsultationProlongationInvoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerCreateProlongationRequest(id: number, createConsultationProlongationInvoiceDto: CreateConsultationProlongationInvoiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationInvoice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerCreateProlongationRequest(id, createConsultationProlongationInvoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get consultation by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerGetById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many consultations
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<'messages' | 'invoices' | 'receipts'>} [join] 
         * @param {Array<'asExpert' | 'asClient' | 'PAID'>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerGetMany(limit?: number, page?: number, join?: Array<'messages' | 'invoices' | 'receipts'>, filter?: Array<'asExpert' | 'asClient' | 'PAID'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyConsultationsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerGetMany(limit, page, join, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self consultations without review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerGetSelfWithoutReview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Consultation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerGetSelfWithoutReview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start single consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerStartConsultation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerStartConsultation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop single consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultationsControllerStopConsultation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Consultation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultationsControllerStopConsultation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one extra service offer
         * @param {number} consultationId 
         * @param {CreateExtraServiceDto} createExtraServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extraServicesControllerCreateOne(consultationId: number, createExtraServiceDto: CreateExtraServiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtraService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extraServicesControllerCreateOne(consultationId, createExtraServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject one extra service
         * @param {number} consultationId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extraServicesControllerRejectOne(consultationId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extraServicesControllerRejectOne(consultationId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ConsultationsApi - factory interface
 * @export
 */
export const V1ConsultationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ConsultationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel one paid consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCancelPaidConsultation(id: number, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerCancelPaidConsultation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create consultation receipt
         * @param {number} id 
         * @param {CreateConsultationReceiptDto} createConsultationReceiptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateConsultationReceipt(id: number, createConsultationReceiptDto: CreateConsultationReceiptDto, options?: any): AxiosPromise<Receipt> {
            return localVarFp.consultationsControllerCreateConsultationReceipt(id, createConsultationReceiptDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one booking consultation
         * @param {BookingConsultationDto} bookingConsultationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneBookingConsultation(bookingConsultationDto: BookingConsultationDto, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerCreateOneBookingConsultation(bookingConsultationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one consultation from expert service
         * @param {CreateConsultationThroughExpertServiceDto} createConsultationThroughExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneFromExpertServices(createConsultationThroughExpertServiceDto: CreateConsultationThroughExpertServiceDto, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerCreateOneFromExpertServices(createConsultationThroughExpertServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one consultation from user category meta
         * @param {CreateConsultationThroughUserCategoryMetaDto} createConsultationThroughUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneFromUserCategoryMeta(createConsultationThroughUserCategoryMetaDto: CreateConsultationThroughUserCategoryMetaDto, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerCreateOneFromUserCategoryMeta(createConsultationThroughUserCategoryMetaDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one consultation from user category meta with promocode
         * @param {CreateConsultationThroughUserCategoryMetaWithPromocodeDto} createConsultationThroughUserCategoryMetaWithPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateOneFromUserMetaWithPromocode(createConsultationThroughUserCategoryMetaWithPromocodeDto: CreateConsultationThroughUserCategoryMetaWithPromocodeDto, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerCreateOneFromUserMetaWithPromocode(createConsultationThroughUserCategoryMetaWithPromocodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create consultation prolongation offer
         * @param {number} id 
         * @param {CreateConsultationProlongationInvoiceDto} createConsultationProlongationInvoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerCreateProlongationRequest(id: number, createConsultationProlongationInvoiceDto: CreateConsultationProlongationInvoiceDto, options?: any): AxiosPromise<ConsultationInvoice> {
            return localVarFp.consultationsControllerCreateProlongationRequest(id, createConsultationProlongationInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get consultation by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerGetById(id: number, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many consultations
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<'messages' | 'invoices' | 'receipts'>} [join] 
         * @param {Array<'asExpert' | 'asClient' | 'PAID'>} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerGetMany(limit?: number, page?: number, join?: Array<'messages' | 'invoices' | 'receipts'>, filter?: Array<'asExpert' | 'asClient' | 'PAID'>, options?: any): AxiosPromise<GetManyConsultationsResponseDto> {
            return localVarFp.consultationsControllerGetMany(limit, page, join, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self consultations without review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerGetSelfWithoutReview(options?: any): AxiosPromise<Array<Consultation>> {
            return localVarFp.consultationsControllerGetSelfWithoutReview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start single consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerStartConsultation(id: number, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerStartConsultation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop single consultation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultationsControllerStopConsultation(id: number, options?: any): AxiosPromise<Consultation> {
            return localVarFp.consultationsControllerStopConsultation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one extra service offer
         * @param {number} consultationId 
         * @param {CreateExtraServiceDto} createExtraServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraServicesControllerCreateOne(consultationId: number, createExtraServiceDto: CreateExtraServiceDto, options?: any): AxiosPromise<ExtraService> {
            return localVarFp.extraServicesControllerCreateOne(consultationId, createExtraServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject one extra service
         * @param {number} consultationId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extraServicesControllerRejectOne(consultationId: number, id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.extraServicesControllerRejectOne(consultationId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ConsultationsApi - object-oriented interface
 * @export
 * @class V1ConsultationsApi
 * @extends {BaseAPI}
 */
export class V1ConsultationsApi extends BaseAPI {
    /**
     * 
     * @summary Cancel one paid consultation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCancelPaidConsultation(id: number, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCancelPaidConsultation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create consultation receipt
     * @param {number} id 
     * @param {CreateConsultationReceiptDto} createConsultationReceiptDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCreateConsultationReceipt(id: number, createConsultationReceiptDto: CreateConsultationReceiptDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCreateConsultationReceipt(id, createConsultationReceiptDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one booking consultation
     * @param {BookingConsultationDto} bookingConsultationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCreateOneBookingConsultation(bookingConsultationDto: BookingConsultationDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCreateOneBookingConsultation(bookingConsultationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one consultation from expert service
     * @param {CreateConsultationThroughExpertServiceDto} createConsultationThroughExpertServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCreateOneFromExpertServices(createConsultationThroughExpertServiceDto: CreateConsultationThroughExpertServiceDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCreateOneFromExpertServices(createConsultationThroughExpertServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one consultation from user category meta
     * @param {CreateConsultationThroughUserCategoryMetaDto} createConsultationThroughUserCategoryMetaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCreateOneFromUserCategoryMeta(createConsultationThroughUserCategoryMetaDto: CreateConsultationThroughUserCategoryMetaDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCreateOneFromUserCategoryMeta(createConsultationThroughUserCategoryMetaDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one consultation from user category meta with promocode
     * @param {CreateConsultationThroughUserCategoryMetaWithPromocodeDto} createConsultationThroughUserCategoryMetaWithPromocodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCreateOneFromUserMetaWithPromocode(createConsultationThroughUserCategoryMetaWithPromocodeDto: CreateConsultationThroughUserCategoryMetaWithPromocodeDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCreateOneFromUserMetaWithPromocode(createConsultationThroughUserCategoryMetaWithPromocodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create consultation prolongation offer
     * @param {number} id 
     * @param {CreateConsultationProlongationInvoiceDto} createConsultationProlongationInvoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerCreateProlongationRequest(id: number, createConsultationProlongationInvoiceDto: CreateConsultationProlongationInvoiceDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerCreateProlongationRequest(id, createConsultationProlongationInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get consultation by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerGetById(id: number, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many consultations
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {Array<'messages' | 'invoices' | 'receipts'>} [join] 
     * @param {Array<'asExpert' | 'asClient' | 'PAID'>} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerGetMany(limit?: number, page?: number, join?: Array<'messages' | 'invoices' | 'receipts'>, filter?: Array<'asExpert' | 'asClient' | 'PAID'>, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerGetMany(limit, page, join, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self consultations without review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerGetSelfWithoutReview(options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerGetSelfWithoutReview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start single consultation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerStartConsultation(id: number, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerStartConsultation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop single consultation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public consultationsControllerStopConsultation(id: number, options?: any) {
        return V1ConsultationsApiFp(this.configuration).consultationsControllerStopConsultation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one extra service offer
     * @param {number} consultationId 
     * @param {CreateExtraServiceDto} createExtraServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public extraServicesControllerCreateOne(consultationId: number, createExtraServiceDto: CreateExtraServiceDto, options?: any) {
        return V1ConsultationsApiFp(this.configuration).extraServicesControllerCreateOne(consultationId, createExtraServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject one extra service
     * @param {number} consultationId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ConsultationsApi
     */
    public extraServicesControllerRejectOne(consultationId: number, id: number, options?: any) {
        return V1ConsultationsApiFp(this.configuration).extraServicesControllerRejectOne(consultationId, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ContentChangeRequestsApi - axios parameter creator
 * @export
 */
export const V1ContentChangeRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create content-change request
         * @param {CreateContentChangeRequestDto} createContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseContentChangeRequestsControllerContentChangeRequest: async (createContentChangeRequestDto: CreateContentChangeRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContentChangeRequestDto' is not null or undefined
            assertParamExists('createOneBaseContentChangeRequestsControllerContentChangeRequest', 'createContentChangeRequestDto', createContentChangeRequestDto)
            const localVarPath = `/v1/content-change-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContentChangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single ContentChangeRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseContentChangeRequestsControllerContentChangeRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseContentChangeRequestsControllerContentChangeRequest', 'id', id)
            const localVarPath = `/v1/content-change-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self content change requests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseContentChangeRequestsControllerContentChangeRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/content-change-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update content-change request
         * @param {number} id 
         * @param {UpdateContentChangeRequestDto} updateContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseContentChangeRequestsControllerContentChangeRequest: async (id: number, updateContentChangeRequestDto: UpdateContentChangeRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseContentChangeRequestsControllerContentChangeRequest', 'id', id)
            // verify required parameter 'updateContentChangeRequestDto' is not null or undefined
            assertParamExists('updateOneBaseContentChangeRequestsControllerContentChangeRequest', 'updateContentChangeRequestDto', updateContentChangeRequestDto)
            const localVarPath = `/v1/content-change-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContentChangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ContentChangeRequestsApi - functional programming interface
 * @export
 */
export const V1ContentChangeRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ContentChangeRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create content-change request
         * @param {CreateContentChangeRequestDto} createContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseContentChangeRequestsControllerContentChangeRequest(createContentChangeRequestDto: CreateContentChangeRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentChangeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseContentChangeRequestsControllerContentChangeRequest(createContentChangeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single ContentChangeRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseContentChangeRequestsControllerContentChangeRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseContentChangeRequestsControllerContentChangeRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self content change requests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseContentChangeRequestsControllerContentChangeRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentChangeRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseContentChangeRequestsControllerContentChangeRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update content-change request
         * @param {number} id 
         * @param {UpdateContentChangeRequestDto} updateContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseContentChangeRequestsControllerContentChangeRequest(id: number, updateContentChangeRequestDto: UpdateContentChangeRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentChangeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseContentChangeRequestsControllerContentChangeRequest(id, updateContentChangeRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ContentChangeRequestsApi - factory interface
 * @export
 */
export const V1ContentChangeRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ContentChangeRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create content-change request
         * @param {CreateContentChangeRequestDto} createContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseContentChangeRequestsControllerContentChangeRequest(createContentChangeRequestDto: CreateContentChangeRequestDto, options?: any): AxiosPromise<ContentChangeRequest> {
            return localVarFp.createOneBaseContentChangeRequestsControllerContentChangeRequest(createContentChangeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single ContentChangeRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseContentChangeRequestsControllerContentChangeRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseContentChangeRequestsControllerContentChangeRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self content change requests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseContentChangeRequestsControllerContentChangeRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<Array<ContentChangeRequest>> {
            return localVarFp.getManyBaseContentChangeRequestsControllerContentChangeRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update content-change request
         * @param {number} id 
         * @param {UpdateContentChangeRequestDto} updateContentChangeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseContentChangeRequestsControllerContentChangeRequest(id: number, updateContentChangeRequestDto: UpdateContentChangeRequestDto, options?: any): AxiosPromise<ContentChangeRequest> {
            return localVarFp.updateOneBaseContentChangeRequestsControllerContentChangeRequest(id, updateContentChangeRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ContentChangeRequestsApi - object-oriented interface
 * @export
 * @class V1ContentChangeRequestsApi
 * @extends {BaseAPI}
 */
export class V1ContentChangeRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Create content-change request
     * @param {CreateContentChangeRequestDto} createContentChangeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContentChangeRequestsApi
     */
    public createOneBaseContentChangeRequestsControllerContentChangeRequest(createContentChangeRequestDto: CreateContentChangeRequestDto, options?: any) {
        return V1ContentChangeRequestsApiFp(this.configuration).createOneBaseContentChangeRequestsControllerContentChangeRequest(createContentChangeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single ContentChangeRequest
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContentChangeRequestsApi
     */
    public deleteOneBaseContentChangeRequestsControllerContentChangeRequest(id: number, options?: any) {
        return V1ContentChangeRequestsApiFp(this.configuration).deleteOneBaseContentChangeRequestsControllerContentChangeRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self content change requests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContentChangeRequestsApi
     */
    public getManyBaseContentChangeRequestsControllerContentChangeRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1ContentChangeRequestsApiFp(this.configuration).getManyBaseContentChangeRequestsControllerContentChangeRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update content-change request
     * @param {number} id 
     * @param {UpdateContentChangeRequestDto} updateContentChangeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContentChangeRequestsApi
     */
    public updateOneBaseContentChangeRequestsControllerContentChangeRequest(id: number, updateContentChangeRequestDto: UpdateContentChangeRequestDto, options?: any) {
        return V1ContentChangeRequestsApiFp(this.configuration).updateOneBaseContentChangeRequestsControllerContentChangeRequest(id, updateContentChangeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ContractsApi - axios parameter creator
 * @export
 */
export const V1ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create one individual contract
         * @param {CreateIndividualContractDto} createIndividualContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerCreateOneIndividual: async (createIndividualContractDto: CreateIndividualContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIndividualContractDto' is not null or undefined
            assertParamExists('contractsControllerCreateOneIndividual', 'createIndividualContractDto', createIndividualContractDto)
            const localVarPath = `/v1/contracts/individual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIndividualContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create one self employed contract
         * @param {CreateSelfEmployedContractDto} createSelfEmployedContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerCreateOneSelfEmployed: async (createSelfEmployedContractDto: CreateSelfEmployedContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSelfEmployedContractDto' is not null or undefined
            assertParamExists('contractsControllerCreateOneSelfEmployed', 'createSelfEmployedContractDto', createSelfEmployedContractDto)
            const localVarPath = `/v1/contracts/selfEmployed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSelfEmployedContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerGetSelf: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contracts/self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract sign sms code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerSendSignSmsCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/contracts/send-sign-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update one individual contract
         * @param {number} id 
         * @param {UpdateIndividualContractDto} updateIndividualContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerUpdateOneIndividual: async (id: number, updateIndividualContractDto: UpdateIndividualContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contractsControllerUpdateOneIndividual', 'id', id)
            // verify required parameter 'updateIndividualContractDto' is not null or undefined
            assertParamExists('contractsControllerUpdateOneIndividual', 'updateIndividualContractDto', updateIndividualContractDto)
            const localVarPath = `/v1/contracts/individual/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndividualContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update one self employed contract
         * @param {number} id 
         * @param {UpdateSelfEmployedContractDto} updateSelfEmployedContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerUpdateOneSelfEmployed: async (id: number, updateSelfEmployedContractDto: UpdateSelfEmployedContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contractsControllerUpdateOneSelfEmployed', 'id', id)
            // verify required parameter 'updateSelfEmployedContractDto' is not null or undefined
            assertParamExists('contractsControllerUpdateOneSelfEmployed', 'updateSelfEmployedContractDto', updateSelfEmployedContractDto)
            const localVarPath = `/v1/contracts/selfEmployed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSelfEmployedContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ContractsApi - functional programming interface
 * @export
 */
export const V1ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create one individual contract
         * @param {CreateIndividualContractDto} createIndividualContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsControllerCreateOneIndividual(createIndividualContractDto: CreateIndividualContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsControllerCreateOneIndividual(createIndividualContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create one self employed contract
         * @param {CreateSelfEmployedContractDto} createSelfEmployedContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsControllerCreateOneSelfEmployed(createSelfEmployedContractDto: CreateSelfEmployedContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsControllerCreateOneSelfEmployed(createSelfEmployedContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsControllerGetSelf(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsControllerGetSelf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract sign sms code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsControllerSendSignSmsCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContractSignSmsCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsControllerSendSignSmsCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update one individual contract
         * @param {number} id 
         * @param {UpdateIndividualContractDto} updateIndividualContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsControllerUpdateOneIndividual(id: number, updateIndividualContractDto: UpdateIndividualContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsControllerUpdateOneIndividual(id, updateIndividualContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update one self employed contract
         * @param {number} id 
         * @param {UpdateSelfEmployedContractDto} updateSelfEmployedContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsControllerUpdateOneSelfEmployed(id: number, updateSelfEmployedContractDto: UpdateSelfEmployedContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsControllerUpdateOneSelfEmployed(id, updateSelfEmployedContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ContractsApi - factory interface
 * @export
 */
export const V1ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ContractsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create one individual contract
         * @param {CreateIndividualContractDto} createIndividualContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerCreateOneIndividual(createIndividualContractDto: CreateIndividualContractDto, options?: any): AxiosPromise<Contract> {
            return localVarFp.contractsControllerCreateOneIndividual(createIndividualContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create one self employed contract
         * @param {CreateSelfEmployedContractDto} createSelfEmployedContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerCreateOneSelfEmployed(createSelfEmployedContractDto: CreateSelfEmployedContractDto, options?: any): AxiosPromise<Contract> {
            return localVarFp.contractsControllerCreateOneSelfEmployed(createSelfEmployedContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerGetSelf(options?: any): AxiosPromise<Contract> {
            return localVarFp.contractsControllerGetSelf(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract sign sms code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerSendSignSmsCode(options?: any): AxiosPromise<GetContractSignSmsCodeResponseDto> {
            return localVarFp.contractsControllerSendSignSmsCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update one individual contract
         * @param {number} id 
         * @param {UpdateIndividualContractDto} updateIndividualContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerUpdateOneIndividual(id: number, updateIndividualContractDto: UpdateIndividualContractDto, options?: any): AxiosPromise<Contract> {
            return localVarFp.contractsControllerUpdateOneIndividual(id, updateIndividualContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update one self employed contract
         * @param {number} id 
         * @param {UpdateSelfEmployedContractDto} updateSelfEmployedContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsControllerUpdateOneSelfEmployed(id: number, updateSelfEmployedContractDto: UpdateSelfEmployedContractDto, options?: any): AxiosPromise<Contract> {
            return localVarFp.contractsControllerUpdateOneSelfEmployed(id, updateSelfEmployedContractDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ContractsApi - object-oriented interface
 * @export
 * @class V1ContractsApi
 * @extends {BaseAPI}
 */
export class V1ContractsApi extends BaseAPI {
    /**
     * 
     * @summary Create one individual contract
     * @param {CreateIndividualContractDto} createIndividualContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContractsApi
     */
    public contractsControllerCreateOneIndividual(createIndividualContractDto: CreateIndividualContractDto, options?: any) {
        return V1ContractsApiFp(this.configuration).contractsControllerCreateOneIndividual(createIndividualContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create one self employed contract
     * @param {CreateSelfEmployedContractDto} createSelfEmployedContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContractsApi
     */
    public contractsControllerCreateOneSelfEmployed(createSelfEmployedContractDto: CreateSelfEmployedContractDto, options?: any) {
        return V1ContractsApiFp(this.configuration).contractsControllerCreateOneSelfEmployed(createSelfEmployedContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContractsApi
     */
    public contractsControllerGetSelf(options?: any) {
        return V1ContractsApiFp(this.configuration).contractsControllerGetSelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract sign sms code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContractsApi
     */
    public contractsControllerSendSignSmsCode(options?: any) {
        return V1ContractsApiFp(this.configuration).contractsControllerSendSignSmsCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update one individual contract
     * @param {number} id 
     * @param {UpdateIndividualContractDto} updateIndividualContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContractsApi
     */
    public contractsControllerUpdateOneIndividual(id: number, updateIndividualContractDto: UpdateIndividualContractDto, options?: any) {
        return V1ContractsApiFp(this.configuration).contractsControllerUpdateOneIndividual(id, updateIndividualContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update one self employed contract
     * @param {number} id 
     * @param {UpdateSelfEmployedContractDto} updateSelfEmployedContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ContractsApi
     */
    public contractsControllerUpdateOneSelfEmployed(id: number, updateSelfEmployedContractDto: UpdateSelfEmployedContractDto, options?: any) {
        return V1ContractsApiFp(this.configuration).contractsControllerUpdateOneSelfEmployed(id, updateSelfEmployedContractDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CustomRequestsApi - axios parameter creator
 * @export
 */
export const V1CustomRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single CustomRequest
         * @param {CreateCustomRequestDto} createCustomRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseCustomRequestsControllerCustomRequest: async (createCustomRequestDto: CreateCustomRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomRequestDto' is not null or undefined
            assertParamExists('createOneBaseCustomRequestsControllerCustomRequest', 'createCustomRequestDto', createCustomRequestDto)
            const localVarPath = `/v1/custom-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single CustomRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCustomRequestsControllerCustomRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseCustomRequestsControllerCustomRequest', 'id', id)
            const localVarPath = `/v1/custom-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple CustomRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCustomRequestsControllerCustomRequest: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/custom-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single CustomRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCustomRequestsControllerCustomRequest: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseCustomRequestsControllerCustomRequest', 'id', id)
            const localVarPath = `/v1/custom-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single CustomRequest
         * @param {number} id 
         * @param {UpdateCustomRequestDto} updateCustomRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseCustomRequestsControllerCustomRequest: async (id: number, updateCustomRequestDto: UpdateCustomRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseCustomRequestsControllerCustomRequest', 'id', id)
            // verify required parameter 'updateCustomRequestDto' is not null or undefined
            assertParamExists('updateOneBaseCustomRequestsControllerCustomRequest', 'updateCustomRequestDto', updateCustomRequestDto)
            const localVarPath = `/v1/custom-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CustomRequestsApi - functional programming interface
 * @export
 */
export const V1CustomRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CustomRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single CustomRequest
         * @param {CreateCustomRequestDto} createCustomRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseCustomRequestsControllerCustomRequest(createCustomRequestDto: CreateCustomRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseCustomRequestsControllerCustomRequest(createCustomRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single CustomRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseCustomRequestsControllerCustomRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseCustomRequestsControllerCustomRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple CustomRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseCustomRequestsControllerCustomRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyCustomRequestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseCustomRequestsControllerCustomRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single CustomRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseCustomRequestsControllerCustomRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseCustomRequestsControllerCustomRequest(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single CustomRequest
         * @param {number} id 
         * @param {UpdateCustomRequestDto} updateCustomRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseCustomRequestsControllerCustomRequest(id: number, updateCustomRequestDto: UpdateCustomRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseCustomRequestsControllerCustomRequest(id, updateCustomRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CustomRequestsApi - factory interface
 * @export
 */
export const V1CustomRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CustomRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single CustomRequest
         * @param {CreateCustomRequestDto} createCustomRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseCustomRequestsControllerCustomRequest(createCustomRequestDto: CreateCustomRequestDto, options?: any): AxiosPromise<CustomRequest> {
            return localVarFp.createOneBaseCustomRequestsControllerCustomRequest(createCustomRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single CustomRequest
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCustomRequestsControllerCustomRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseCustomRequestsControllerCustomRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple CustomRequests
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCustomRequestsControllerCustomRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyCustomRequestResponseDto> {
            return localVarFp.getManyBaseCustomRequestsControllerCustomRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single CustomRequest
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCustomRequestsControllerCustomRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<CustomRequest> {
            return localVarFp.getOneBaseCustomRequestsControllerCustomRequest(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single CustomRequest
         * @param {number} id 
         * @param {UpdateCustomRequestDto} updateCustomRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseCustomRequestsControllerCustomRequest(id: number, updateCustomRequestDto: UpdateCustomRequestDto, options?: any): AxiosPromise<CustomRequest> {
            return localVarFp.updateOneBaseCustomRequestsControllerCustomRequest(id, updateCustomRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CustomRequestsApi - object-oriented interface
 * @export
 * @class V1CustomRequestsApi
 * @extends {BaseAPI}
 */
export class V1CustomRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single CustomRequest
     * @param {CreateCustomRequestDto} createCustomRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomRequestsApi
     */
    public createOneBaseCustomRequestsControllerCustomRequest(createCustomRequestDto: CreateCustomRequestDto, options?: any) {
        return V1CustomRequestsApiFp(this.configuration).createOneBaseCustomRequestsControllerCustomRequest(createCustomRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single CustomRequest
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomRequestsApi
     */
    public deleteOneBaseCustomRequestsControllerCustomRequest(id: number, options?: any) {
        return V1CustomRequestsApiFp(this.configuration).deleteOneBaseCustomRequestsControllerCustomRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple CustomRequests
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomRequestsApi
     */
    public getManyBaseCustomRequestsControllerCustomRequest(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1CustomRequestsApiFp(this.configuration).getManyBaseCustomRequestsControllerCustomRequest(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single CustomRequest
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomRequestsApi
     */
    public getOneBaseCustomRequestsControllerCustomRequest(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1CustomRequestsApiFp(this.configuration).getOneBaseCustomRequestsControllerCustomRequest(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single CustomRequest
     * @param {number} id 
     * @param {UpdateCustomRequestDto} updateCustomRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomRequestsApi
     */
    public updateOneBaseCustomRequestsControllerCustomRequest(id: number, updateCustomRequestDto: UpdateCustomRequestDto, options?: any) {
        return V1CustomRequestsApiFp(this.configuration).updateOneBaseCustomRequestsControllerCustomRequest(id, updateCustomRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1EmailsApi - axios parameter creator
 * @export
 */
export const V1EmailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send Email After Registration
         * @param {'wellcome' | 'after_3_hours' | 'after_3_days'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmailAfterRegistration: async (type: 'wellcome' | 'after_3_hours' | 'after_3_days', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('emailControllerSendEmailAfterRegistration', 'type', type)
            const localVarPath = `/v1/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1EmailsApi - functional programming interface
 * @export
 */
export const V1EmailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1EmailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send Email After Registration
         * @param {'wellcome' | 'after_3_hours' | 'after_3_days'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSendEmailAfterRegistration(type: 'wellcome' | 'after_3_hours' | 'after_3_days', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSendEmailAfterRegistration(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1EmailsApi - factory interface
 * @export
 */
export const V1EmailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1EmailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Send Email After Registration
         * @param {'wellcome' | 'after_3_hours' | 'after_3_days'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmailAfterRegistration(type: 'wellcome' | 'after_3_hours' | 'after_3_days', options?: any): AxiosPromise<boolean> {
            return localVarFp.emailControllerSendEmailAfterRegistration(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1EmailsApi - object-oriented interface
 * @export
 * @class V1EmailsApi
 * @extends {BaseAPI}
 */
export class V1EmailsApi extends BaseAPI {
    /**
     * 
     * @summary Send Email After Registration
     * @param {'wellcome' | 'after_3_hours' | 'after_3_days'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1EmailsApi
     */
    public emailControllerSendEmailAfterRegistration(type: 'wellcome' | 'after_3_hours' | 'after_3_days', options?: any) {
        return V1EmailsApiFp(this.configuration).emailControllerSendEmailAfterRegistration(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ExpertServicesApi - axios parameter creator
 * @export
 */
export const V1ExpertServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create expert service
         * @param {CreateExpertServiceDto} createExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerCreateOne: async (createExpertServiceDto: CreateExpertServiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExpertServiceDto' is not null or undefined
            assertParamExists('expertServicesControllerCreateOne', 'createExpertServiceDto', createExpertServiceDto)
            const localVarPath = `/v1/expert-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExpertServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many expert services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerGetMany: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/expert-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many expert services by expert ID
         * @param {number} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerGetManyServicesByExpertId: async (expertId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertId' is not null or undefined
            assertParamExists('expertServicesControllerGetManyServicesByExpertId', 'expertId', expertId)
            const localVarPath = `/v1/expert-services/{expertId}`
                .replace(`{${"expertId"}}`, encodeURIComponent(String(expertId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one expert service
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerGetOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertServicesControllerGetOne', 'id', id)
            const localVarPath = `/v1/expert-services/me/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove expert service
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerRemoveOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertServicesControllerRemoveOne', 'id', id)
            const localVarPath = `/v1/expert-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update expert service
         * @param {number} id 
         * @param {UpdateExpertServiceDto} updateExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerUpdateOne: async (id: number, updateExpertServiceDto: UpdateExpertServiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expertServicesControllerUpdateOne', 'id', id)
            // verify required parameter 'updateExpertServiceDto' is not null or undefined
            assertParamExists('expertServicesControllerUpdateOne', 'updateExpertServiceDto', updateExpertServiceDto)
            const localVarPath = `/v1/expert-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExpertServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ExpertServicesApi - functional programming interface
 * @export
 */
export const V1ExpertServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ExpertServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create expert service
         * @param {CreateExpertServiceDto} createExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertServicesControllerCreateOne(createExpertServiceDto: CreateExpertServiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertServicesControllerCreateOne(createExpertServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many expert services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertServicesControllerGetMany(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertServicesControllerGetMany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many expert services by expert ID
         * @param {number} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertServicesControllerGetManyServicesByExpertId(expertId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertServicesControllerGetManyServicesByExpertId(expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one expert service
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertServicesControllerGetOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertServicesControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove expert service
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertServicesControllerRemoveOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertServicesControllerRemoveOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update expert service
         * @param {number} id 
         * @param {UpdateExpertServiceDto} updateExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expertServicesControllerUpdateOne(id: number, updateExpertServiceDto: UpdateExpertServiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expertServicesControllerUpdateOne(id, updateExpertServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ExpertServicesApi - factory interface
 * @export
 */
export const V1ExpertServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ExpertServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create expert service
         * @param {CreateExpertServiceDto} createExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerCreateOne(createExpertServiceDto: CreateExpertServiceDto, options?: any): AxiosPromise<ExpertService> {
            return localVarFp.expertServicesControllerCreateOne(createExpertServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many expert services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerGetMany(options?: any): AxiosPromise<Array<ExpertService>> {
            return localVarFp.expertServicesControllerGetMany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many expert services by expert ID
         * @param {number} expertId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerGetManyServicesByExpertId(expertId: number, options?: any): AxiosPromise<Array<ExpertService>> {
            return localVarFp.expertServicesControllerGetManyServicesByExpertId(expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one expert service
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerGetOne(id: number, options?: any): AxiosPromise<ExpertService> {
            return localVarFp.expertServicesControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove expert service
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerRemoveOne(id: number, options?: any): AxiosPromise<number> {
            return localVarFp.expertServicesControllerRemoveOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update expert service
         * @param {number} id 
         * @param {UpdateExpertServiceDto} updateExpertServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertServicesControllerUpdateOne(id: number, updateExpertServiceDto: UpdateExpertServiceDto, options?: any): AxiosPromise<ExpertService> {
            return localVarFp.expertServicesControllerUpdateOne(id, updateExpertServiceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ExpertServicesApi - object-oriented interface
 * @export
 * @class V1ExpertServicesApi
 * @extends {BaseAPI}
 */
export class V1ExpertServicesApi extends BaseAPI {
    /**
     * 
     * @summary Create expert service
     * @param {CreateExpertServiceDto} createExpertServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ExpertServicesApi
     */
    public expertServicesControllerCreateOne(createExpertServiceDto: CreateExpertServiceDto, options?: any) {
        return V1ExpertServicesApiFp(this.configuration).expertServicesControllerCreateOne(createExpertServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many expert services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ExpertServicesApi
     */
    public expertServicesControllerGetMany(options?: any) {
        return V1ExpertServicesApiFp(this.configuration).expertServicesControllerGetMany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many expert services by expert ID
     * @param {number} expertId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ExpertServicesApi
     */
    public expertServicesControllerGetManyServicesByExpertId(expertId: number, options?: any) {
        return V1ExpertServicesApiFp(this.configuration).expertServicesControllerGetManyServicesByExpertId(expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one expert service
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ExpertServicesApi
     */
    public expertServicesControllerGetOne(id: number, options?: any) {
        return V1ExpertServicesApiFp(this.configuration).expertServicesControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove expert service
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ExpertServicesApi
     */
    public expertServicesControllerRemoveOne(id: number, options?: any) {
        return V1ExpertServicesApiFp(this.configuration).expertServicesControllerRemoveOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update expert service
     * @param {number} id 
     * @param {UpdateExpertServiceDto} updateExpertServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ExpertServicesApi
     */
    public expertServicesControllerUpdateOne(id: number, updateExpertServiceDto: UpdateExpertServiceDto, options?: any) {
        return V1ExpertServicesApiFp(this.configuration).expertServicesControllerUpdateOne(id, updateExpertServiceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1MessagesApi - axios parameter creator
 * @export
 */
export const V1MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create message
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerCreateMessage: async (createMessageDto: CreateMessageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMessageDto' is not null or undefined
            assertParamExists('messagesControllerCreateMessage', 'createMessageDto', createMessageDto)
            const localVarPath = `/v1/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1MessagesApi - functional programming interface
 * @export
 */
export const V1MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create message
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagesControllerCreateMessage(createMessageDto: CreateMessageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagesControllerCreateMessage(createMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1MessagesApi - factory interface
 * @export
 */
export const V1MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1MessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create message
         * @param {CreateMessageDto} createMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagesControllerCreateMessage(createMessageDto: CreateMessageDto, options?: any): AxiosPromise<Message> {
            return localVarFp.messagesControllerCreateMessage(createMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1MessagesApi - object-oriented interface
 * @export
 * @class V1MessagesApi
 * @extends {BaseAPI}
 */
export class V1MessagesApi extends BaseAPI {
    /**
     * 
     * @summary Create message
     * @param {CreateMessageDto} createMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1MessagesApi
     */
    public messagesControllerCreateMessage(createMessageDto: CreateMessageDto, options?: any) {
        return V1MessagesApiFp(this.configuration).messagesControllerCreateMessage(createMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1NewsApi - axios parameter creator
 * @export
 */
export const V1NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create News After Registration
         * @param {'default' | 'wellcome' | 'after_3_hours' | 'after_3_days'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerCreateNewsRegistration: async (type: 'default' | 'wellcome' | 'after_3_hours' | 'after_3_days', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('newsControllerCreateNewsRegistration', 'type', type)
            const localVarPath = `/v1/news/after-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get News
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetNews: async (limit?: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get news by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetNewsById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsControllerGetNewsById', 'id', id)
            const localVarPath = `/v1/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Increment number of views for each news
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerIncrementNumberOfView: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsControllerIncrementNumberOfView', 'id', id)
            const localVarPath = `/v1/news/{id}/view`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1NewsApi - functional programming interface
 * @export
 */
export const V1NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create News After Registration
         * @param {'default' | 'wellcome' | 'after_3_hours' | 'after_3_days'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerCreateNewsRegistration(type: 'default' | 'wellcome' | 'after_3_hours' | 'after_3_days', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerCreateNewsRegistration(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get News
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGetNews(limit?: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyNewsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGetNews(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get news by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGetNewsById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGetNewsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Increment number of views for each news
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerIncrementNumberOfView(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerIncrementNumberOfView(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1NewsApi - factory interface
 * @export
 */
export const V1NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create News After Registration
         * @param {'default' | 'wellcome' | 'after_3_hours' | 'after_3_days'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerCreateNewsRegistration(type: 'default' | 'wellcome' | 'after_3_hours' | 'after_3_days', options?: any): AxiosPromise<News> {
            return localVarFp.newsControllerCreateNewsRegistration(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get News
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetNews(limit?: number, page?: number, options?: any): AxiosPromise<GetManyNewsResponseDto> {
            return localVarFp.newsControllerGetNews(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get news by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetNewsById(id: number, options?: any): AxiosPromise<News> {
            return localVarFp.newsControllerGetNewsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Increment number of views for each news
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerIncrementNumberOfView(id: number, options?: any): AxiosPromise<News> {
            return localVarFp.newsControllerIncrementNumberOfView(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1NewsApi - object-oriented interface
 * @export
 * @class V1NewsApi
 * @extends {BaseAPI}
 */
export class V1NewsApi extends BaseAPI {
    /**
     * 
     * @summary Create News After Registration
     * @param {'default' | 'wellcome' | 'after_3_hours' | 'after_3_days'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NewsApi
     */
    public newsControllerCreateNewsRegistration(type: 'default' | 'wellcome' | 'after_3_hours' | 'after_3_days', options?: any) {
        return V1NewsApiFp(this.configuration).newsControllerCreateNewsRegistration(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get News
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NewsApi
     */
    public newsControllerGetNews(limit?: number, page?: number, options?: any) {
        return V1NewsApiFp(this.configuration).newsControllerGetNews(limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get news by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NewsApi
     */
    public newsControllerGetNewsById(id: number, options?: any) {
        return V1NewsApiFp(this.configuration).newsControllerGetNewsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Increment number of views for each news
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NewsApi
     */
    public newsControllerIncrementNumberOfView(id: number, options?: any) {
        return V1NewsApiFp(this.configuration).newsControllerIncrementNumberOfView(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1NotificationsSettingsApi - axios parameter creator
 * @export
 */
export const V1NotificationsSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get one notification settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsSettingsControllerGetSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification settings
         * @param {UpdateNotificationsSettingDto} updateNotificationsSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsSettingsControllerUpdateSettings: async (updateNotificationsSettingDto: UpdateNotificationsSettingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationsSettingDto' is not null or undefined
            assertParamExists('notificationsSettingsControllerUpdateSettings', 'updateNotificationsSettingDto', updateNotificationsSettingDto)
            const localVarPath = `/v1/notifications-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationsSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1NotificationsSettingsApi - functional programming interface
 * @export
 */
export const V1NotificationsSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1NotificationsSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get one notification settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsSettingsControllerGetSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsSettingsControllerGetSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update notification settings
         * @param {UpdateNotificationsSettingDto} updateNotificationsSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsSettingsControllerUpdateSettings(updateNotificationsSettingDto: UpdateNotificationsSettingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsSettingsControllerUpdateSettings(updateNotificationsSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1NotificationsSettingsApi - factory interface
 * @export
 */
export const V1NotificationsSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1NotificationsSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get one notification settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsSettingsControllerGetSettings(options?: any): AxiosPromise<NotificationsSetting> {
            return localVarFp.notificationsSettingsControllerGetSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification settings
         * @param {UpdateNotificationsSettingDto} updateNotificationsSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsSettingsControllerUpdateSettings(updateNotificationsSettingDto: UpdateNotificationsSettingDto, options?: any): AxiosPromise<NotificationsSetting> {
            return localVarFp.notificationsSettingsControllerUpdateSettings(updateNotificationsSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1NotificationsSettingsApi - object-oriented interface
 * @export
 * @class V1NotificationsSettingsApi
 * @extends {BaseAPI}
 */
export class V1NotificationsSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get one notification settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NotificationsSettingsApi
     */
    public notificationsSettingsControllerGetSettings(options?: any) {
        return V1NotificationsSettingsApiFp(this.configuration).notificationsSettingsControllerGetSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification settings
     * @param {UpdateNotificationsSettingDto} updateNotificationsSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NotificationsSettingsApi
     */
    public notificationsSettingsControllerUpdateSettings(updateNotificationsSettingDto: UpdateNotificationsSettingDto, options?: any) {
        return V1NotificationsSettingsApiFp(this.configuration).notificationsSettingsControllerUpdateSettings(updateNotificationsSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1NpsPollsApi - axios parameter creator
 * @export
 */
export const V1NpsPollsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Answer For Default Nps Poll
         * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        npsPollsControllerCreateAnswerForDefaultNpsPoll: async (createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnswerNpsPollsDto' is not null or undefined
            assertParamExists('npsPollsControllerCreateAnswerForDefaultNpsPoll', 'createAnswerNpsPollsDto', createAnswerNpsPollsDto)
            const localVarPath = `/v1/nps-polls/create-answer-for-default-poll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnswerNpsPollsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Answer Nps Poll
         * @param {number} npsPollId 
         * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        npsPollsControllerCreateAnswerNpsPoll: async (npsPollId: number, createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'npsPollId' is not null or undefined
            assertParamExists('npsPollsControllerCreateAnswerNpsPoll', 'npsPollId', npsPollId)
            // verify required parameter 'createAnswerNpsPollsDto' is not null or undefined
            assertParamExists('npsPollsControllerCreateAnswerNpsPoll', 'createAnswerNpsPollsDto', createAnswerNpsPollsDto)
            const localVarPath = `/v1/nps-polls/{npsPollId}/create-answer-nps-poll`
                .replace(`{${"npsPollId"}}`, encodeURIComponent(String(npsPollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnswerNpsPollsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Polls That The User Has Not Completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        npsPollsControllerGetPollsThatTheUserHasNotCompleted: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nps-polls/for-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1NpsPollsApi - functional programming interface
 * @export
 */
export const V1NpsPollsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1NpsPollsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Answer For Default Nps Poll
         * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async npsPollsControllerCreateAnswerForDefaultNpsPoll(createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.npsPollsControllerCreateAnswerForDefaultNpsPoll(createAnswerNpsPollsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Answer Nps Poll
         * @param {number} npsPollId 
         * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async npsPollsControllerCreateAnswerNpsPoll(npsPollId: number, createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.npsPollsControllerCreateAnswerNpsPoll(npsPollId, createAnswerNpsPollsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Polls That The User Has Not Completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async npsPollsControllerGetPollsThatTheUserHasNotCompleted(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NpsPoll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.npsPollsControllerGetPollsThatTheUserHasNotCompleted(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1NpsPollsApi - factory interface
 * @export
 */
export const V1NpsPollsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1NpsPollsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Answer For Default Nps Poll
         * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        npsPollsControllerCreateAnswerForDefaultNpsPoll(createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options?: any): AxiosPromise<void> {
            return localVarFp.npsPollsControllerCreateAnswerForDefaultNpsPoll(createAnswerNpsPollsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Answer Nps Poll
         * @param {number} npsPollId 
         * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        npsPollsControllerCreateAnswerNpsPoll(npsPollId: number, createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options?: any): AxiosPromise<void> {
            return localVarFp.npsPollsControllerCreateAnswerNpsPoll(npsPollId, createAnswerNpsPollsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Polls That The User Has Not Completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        npsPollsControllerGetPollsThatTheUserHasNotCompleted(options?: any): AxiosPromise<Array<NpsPoll>> {
            return localVarFp.npsPollsControllerGetPollsThatTheUserHasNotCompleted(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1NpsPollsApi - object-oriented interface
 * @export
 * @class V1NpsPollsApi
 * @extends {BaseAPI}
 */
export class V1NpsPollsApi extends BaseAPI {
    /**
     * 
     * @summary Create Answer For Default Nps Poll
     * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NpsPollsApi
     */
    public npsPollsControllerCreateAnswerForDefaultNpsPoll(createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options?: any) {
        return V1NpsPollsApiFp(this.configuration).npsPollsControllerCreateAnswerForDefaultNpsPoll(createAnswerNpsPollsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Answer Nps Poll
     * @param {number} npsPollId 
     * @param {CreateAnswerNpsPollsDto} createAnswerNpsPollsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NpsPollsApi
     */
    public npsPollsControllerCreateAnswerNpsPoll(npsPollId: number, createAnswerNpsPollsDto: CreateAnswerNpsPollsDto, options?: any) {
        return V1NpsPollsApiFp(this.configuration).npsPollsControllerCreateAnswerNpsPoll(npsPollId, createAnswerNpsPollsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Polls That The User Has Not Completed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1NpsPollsApi
     */
    public npsPollsControllerGetPollsThatTheUserHasNotCompleted(options?: any) {
        return V1NpsPollsApiFp(this.configuration).npsPollsControllerGetPollsThatTheUserHasNotCompleted(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PaymentMethodsApi - axios parameter creator
 * @export
 */
export const V1PaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get attach card URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsControllerGetAttachCardUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment-methods/attach-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsControllerGetUserPaymentMethods: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update default payment method
         * @param {UpdateDefaultPaymentMethodDto} updateDefaultPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsControllerUpdateDefaultPaymentMethod: async (updateDefaultPaymentMethodDto: UpdateDefaultPaymentMethodDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDefaultPaymentMethodDto' is not null or undefined
            assertParamExists('paymentMethodsControllerUpdateDefaultPaymentMethod', 'updateDefaultPaymentMethodDto', updateDefaultPaymentMethodDto)
            const localVarPath = `/v1/payment-methods/defaultPaymentMethod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDefaultPaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PaymentMethodsApi - functional programming interface
 * @export
 */
export const V1PaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get attach card URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentMethodsControllerGetAttachCardUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAttachCardUrlResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodsControllerGetAttachCardUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentMethodsControllerGetUserPaymentMethods(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodsControllerGetUserPaymentMethods(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update default payment method
         * @param {UpdateDefaultPaymentMethodDto} updateDefaultPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentMethodsControllerUpdateDefaultPaymentMethod(updateDefaultPaymentMethodDto: UpdateDefaultPaymentMethodDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodsControllerUpdateDefaultPaymentMethod(updateDefaultPaymentMethodDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PaymentMethodsApi - factory interface
 * @export
 */
export const V1PaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get attach card URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsControllerGetAttachCardUrl(options?: any): AxiosPromise<GetAttachCardUrlResponseDto> {
            return localVarFp.paymentMethodsControllerGetAttachCardUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsControllerGetUserPaymentMethods(options?: any): AxiosPromise<Array<PaymentMethod>> {
            return localVarFp.paymentMethodsControllerGetUserPaymentMethods(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update default payment method
         * @param {UpdateDefaultPaymentMethodDto} updateDefaultPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsControllerUpdateDefaultPaymentMethod(updateDefaultPaymentMethodDto: UpdateDefaultPaymentMethodDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.paymentMethodsControllerUpdateDefaultPaymentMethod(updateDefaultPaymentMethodDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PaymentMethodsApi - object-oriented interface
 * @export
 * @class V1PaymentMethodsApi
 * @extends {BaseAPI}
 */
export class V1PaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @summary Get attach card URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PaymentMethodsApi
     */
    public paymentMethodsControllerGetAttachCardUrl(options?: any) {
        return V1PaymentMethodsApiFp(this.configuration).paymentMethodsControllerGetAttachCardUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self payment methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PaymentMethodsApi
     */
    public paymentMethodsControllerGetUserPaymentMethods(options?: any) {
        return V1PaymentMethodsApiFp(this.configuration).paymentMethodsControllerGetUserPaymentMethods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update default payment method
     * @param {UpdateDefaultPaymentMethodDto} updateDefaultPaymentMethodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PaymentMethodsApi
     */
    public paymentMethodsControllerUpdateDefaultPaymentMethod(updateDefaultPaymentMethodDto: UpdateDefaultPaymentMethodDto, options?: any) {
        return V1PaymentMethodsApiFp(this.configuration).paymentMethodsControllerUpdateDefaultPaymentMethod(updateDefaultPaymentMethodDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PaymentsApi - axios parameter creator
 * @export
 */
export const V1PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user payments
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'transfer' | 'refill' | 'withdrawal' | 'revert'} [type] 
         * @param {'pending' | 'fulfilled' | 'rejected'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetManyPayments: async (limit?: number, page?: number, type?: 'transfer' | 'refill' | 'withdrawal' | 'revert', status?: 'pending' | 'fulfilled' | 'rejected', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PaymentsApi - functional programming interface
 * @export
 */
export const V1PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user payments
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'transfer' | 'refill' | 'withdrawal' | 'revert'} [type] 
         * @param {'pending' | 'fulfilled' | 'rejected'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerGetManyPayments(limit?: number, page?: number, type?: 'transfer' | 'refill' | 'withdrawal' | 'revert', status?: 'pending' | 'fulfilled' | 'rejected', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyPaymentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerGetManyPayments(limit, page, type, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PaymentsApi - factory interface
 * @export
 */
export const V1PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user payments
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {'transfer' | 'refill' | 'withdrawal' | 'revert'} [type] 
         * @param {'pending' | 'fulfilled' | 'rejected'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetManyPayments(limit?: number, page?: number, type?: 'transfer' | 'refill' | 'withdrawal' | 'revert', status?: 'pending' | 'fulfilled' | 'rejected', options?: any): AxiosPromise<GetManyPaymentsResponseDto> {
            return localVarFp.paymentsControllerGetManyPayments(limit, page, type, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PaymentsApi - object-oriented interface
 * @export
 * @class V1PaymentsApi
 * @extends {BaseAPI}
 */
export class V1PaymentsApi extends BaseAPI {
    /**
     * 
     * @summary Get user payments
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {'transfer' | 'refill' | 'withdrawal' | 'revert'} [type] 
     * @param {'pending' | 'fulfilled' | 'rejected'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PaymentsApi
     */
    public paymentsControllerGetManyPayments(limit?: number, page?: number, type?: 'transfer' | 'refill' | 'withdrawal' | 'revert', status?: 'pending' | 'fulfilled' | 'rejected', options?: any) {
        return V1PaymentsApiFp(this.configuration).paymentsControllerGetManyPayments(limit, page, type, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PortfoliosApi - axios parameter creator
 * @export
 */
export const V1PortfoliosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add image urls in portfolio
         * @param {number} id 
         * @param {AddImageUrlsDto} addImageUrlsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerAddImageUrls: async (id: number, addImageUrlsDto: AddImageUrlsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portfoliosControllerAddImageUrls', 'id', id)
            // verify required parameter 'addImageUrlsDto' is not null or undefined
            assertParamExists('portfoliosControllerAddImageUrls', 'addImageUrlsDto', addImageUrlsDto)
            const localVarPath = `/v1/portfolios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addImageUrlsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add one image url in portfolio
         * @param {number} id 
         * @param {AddOneImageUrlDto} addOneImageUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerAddOneImageUrl: async (id: number, addOneImageUrlDto: AddOneImageUrlDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portfoliosControllerAddOneImageUrl', 'id', id)
            // verify required parameter 'addOneImageUrlDto' is not null or undefined
            assertParamExists('portfoliosControllerAddOneImageUrl', 'addOneImageUrlDto', addOneImageUrlDto)
            const localVarPath = `/v1/portfolios/{id}/add-one`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOneImageUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete one image url in portfolio
         * @param {number} id 
         * @param {IndexDto} indexDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerDeleteOneImageUrl: async (id: number, indexDto: IndexDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portfoliosControllerDeleteOneImageUrl', 'id', id)
            // verify required parameter 'indexDto' is not null or undefined
            assertParamExists('portfoliosControllerDeleteOneImageUrl', 'indexDto', indexDto)
            const localVarPath = `/v1/portfolios/{id}/delete-one`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get portfolio by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerGetById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portfoliosControllerGetById', 'id', id)
            const localVarPath = `/v1/portfolios/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get you portfolio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerGetPortfolio: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/portfolios/me-portfolio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PortfoliosApi - functional programming interface
 * @export
 */
export const V1PortfoliosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PortfoliosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add image urls in portfolio
         * @param {number} id 
         * @param {AddImageUrlsDto} addImageUrlsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfoliosControllerAddImageUrls(id: number, addImageUrlsDto: AddImageUrlsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfoliosControllerAddImageUrls(id, addImageUrlsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add one image url in portfolio
         * @param {number} id 
         * @param {AddOneImageUrlDto} addOneImageUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfoliosControllerAddOneImageUrl(id: number, addOneImageUrlDto: AddOneImageUrlDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfoliosControllerAddOneImageUrl(id, addOneImageUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete one image url in portfolio
         * @param {number} id 
         * @param {IndexDto} indexDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfoliosControllerDeleteOneImageUrl(id: number, indexDto: IndexDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfoliosControllerDeleteOneImageUrl(id, indexDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get portfolio by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfoliosControllerGetById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfoliosControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get you portfolio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfoliosControllerGetPortfolio(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portfolio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfoliosControllerGetPortfolio(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PortfoliosApi - factory interface
 * @export
 */
export const V1PortfoliosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PortfoliosApiFp(configuration)
    return {
        /**
         * 
         * @summary Add image urls in portfolio
         * @param {number} id 
         * @param {AddImageUrlsDto} addImageUrlsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerAddImageUrls(id: number, addImageUrlsDto: AddImageUrlsDto, options?: any): AxiosPromise<Portfolio> {
            return localVarFp.portfoliosControllerAddImageUrls(id, addImageUrlsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add one image url in portfolio
         * @param {number} id 
         * @param {AddOneImageUrlDto} addOneImageUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerAddOneImageUrl(id: number, addOneImageUrlDto: AddOneImageUrlDto, options?: any): AxiosPromise<Portfolio> {
            return localVarFp.portfoliosControllerAddOneImageUrl(id, addOneImageUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete one image url in portfolio
         * @param {number} id 
         * @param {IndexDto} indexDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerDeleteOneImageUrl(id: number, indexDto: IndexDto, options?: any): AxiosPromise<Portfolio> {
            return localVarFp.portfoliosControllerDeleteOneImageUrl(id, indexDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get portfolio by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerGetById(id: number, options?: any): AxiosPromise<Portfolio> {
            return localVarFp.portfoliosControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get you portfolio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfoliosControllerGetPortfolio(options?: any): AxiosPromise<Portfolio> {
            return localVarFp.portfoliosControllerGetPortfolio(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PortfoliosApi - object-oriented interface
 * @export
 * @class V1PortfoliosApi
 * @extends {BaseAPI}
 */
export class V1PortfoliosApi extends BaseAPI {
    /**
     * 
     * @summary Add image urls in portfolio
     * @param {number} id 
     * @param {AddImageUrlsDto} addImageUrlsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PortfoliosApi
     */
    public portfoliosControllerAddImageUrls(id: number, addImageUrlsDto: AddImageUrlsDto, options?: any) {
        return V1PortfoliosApiFp(this.configuration).portfoliosControllerAddImageUrls(id, addImageUrlsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add one image url in portfolio
     * @param {number} id 
     * @param {AddOneImageUrlDto} addOneImageUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PortfoliosApi
     */
    public portfoliosControllerAddOneImageUrl(id: number, addOneImageUrlDto: AddOneImageUrlDto, options?: any) {
        return V1PortfoliosApiFp(this.configuration).portfoliosControllerAddOneImageUrl(id, addOneImageUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete one image url in portfolio
     * @param {number} id 
     * @param {IndexDto} indexDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PortfoliosApi
     */
    public portfoliosControllerDeleteOneImageUrl(id: number, indexDto: IndexDto, options?: any) {
        return V1PortfoliosApiFp(this.configuration).portfoliosControllerDeleteOneImageUrl(id, indexDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get portfolio by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PortfoliosApi
     */
    public portfoliosControllerGetById(id: number, options?: any) {
        return V1PortfoliosApiFp(this.configuration).portfoliosControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get you portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PortfoliosApi
     */
    public portfoliosControllerGetPortfolio(options?: any) {
        return V1PortfoliosApiFp(this.configuration).portfoliosControllerGetPortfolio(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PromocodesApi - axios parameter creator
 * @export
 */
export const V1PromocodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve a single Promocode
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBasePromocodesControllerPromocode: async (id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBasePromocodesControllerPromocode', 'id', id)
            const localVarPath = `/v1/promocodes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply promocode
         * @param {ApplyPromocodeDto} applyPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodesControllerApplyPromocode: async (applyPromocodeDto: ApplyPromocodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyPromocodeDto' is not null or undefined
            assertParamExists('promocodesControllerApplyPromocode', 'applyPromocodeDto', applyPromocodeDto)
            const localVarPath = `/v1/promocodes/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyPromocodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get expert promocodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodesControllerGetExpertPromocodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/promocodes/expert-promocodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self promocodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodesControllerGetSelfPromocodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/promocodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PromocodesApi - functional programming interface
 * @export
 */
export const V1PromocodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PromocodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve a single Promocode
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBasePromocodesControllerPromocode(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promocode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBasePromocodesControllerPromocode(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply promocode
         * @param {ApplyPromocodeDto} applyPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promocodesControllerApplyPromocode(applyPromocodeDto: ApplyPromocodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promocodesControllerApplyPromocode(applyPromocodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get expert promocodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promocodesControllerGetExpertPromocodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Promocode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promocodesControllerGetExpertPromocodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self promocodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promocodesControllerGetSelfPromocodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPromocode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promocodesControllerGetSelfPromocodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PromocodesApi - factory interface
 * @export
 */
export const V1PromocodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PromocodesApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve a single Promocode
         * @param {number} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBasePromocodesControllerPromocode(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Promocode> {
            return localVarFp.getOneBasePromocodesControllerPromocode(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply promocode
         * @param {ApplyPromocodeDto} applyPromocodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodesControllerApplyPromocode(applyPromocodeDto: ApplyPromocodeDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.promocodesControllerApplyPromocode(applyPromocodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get expert promocodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodesControllerGetExpertPromocodes(options?: any): AxiosPromise<Array<Promocode>> {
            return localVarFp.promocodesControllerGetExpertPromocodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self promocodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodesControllerGetSelfPromocodes(options?: any): AxiosPromise<Array<UserPromocode>> {
            return localVarFp.promocodesControllerGetSelfPromocodes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PromocodesApi - object-oriented interface
 * @export
 * @class V1PromocodesApi
 * @extends {BaseAPI}
 */
export class V1PromocodesApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve a single Promocode
     * @param {number} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PromocodesApi
     */
    public getOneBasePromocodesControllerPromocode(id: number, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1PromocodesApiFp(this.configuration).getOneBasePromocodesControllerPromocode(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply promocode
     * @param {ApplyPromocodeDto} applyPromocodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PromocodesApi
     */
    public promocodesControllerApplyPromocode(applyPromocodeDto: ApplyPromocodeDto, options?: any) {
        return V1PromocodesApiFp(this.configuration).promocodesControllerApplyPromocode(applyPromocodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get expert promocodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PromocodesApi
     */
    public promocodesControllerGetExpertPromocodes(options?: any) {
        return V1PromocodesApiFp(this.configuration).promocodesControllerGetExpertPromocodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self promocodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PromocodesApi
     */
    public promocodesControllerGetSelfPromocodes(options?: any) {
        return V1PromocodesApiFp(this.configuration).promocodesControllerGetSelfPromocodes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PusherApi - axios parameter creator
 * @export
 */
export const V1PusherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pusher Auth (presence channel)
         * @param {PusherAuthDto} pusherAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pusherControllerAuth: async (pusherAuthDto: PusherAuthDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pusherAuthDto' is not null or undefined
            assertParamExists('pusherControllerAuth', 'pusherAuthDto', pusherAuthDto)
            const localVarPath = `/v1/pusher/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pusherAuthDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PusherApi - functional programming interface
 * @export
 */
export const V1PusherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PusherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pusher Auth (presence channel)
         * @param {PusherAuthDto} pusherAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pusherControllerAuth(pusherAuthDto: PusherAuthDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pusherControllerAuth(pusherAuthDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PusherApi - factory interface
 * @export
 */
export const V1PusherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PusherApiFp(configuration)
    return {
        /**
         * 
         * @summary Pusher Auth (presence channel)
         * @param {PusherAuthDto} pusherAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pusherControllerAuth(pusherAuthDto: PusherAuthDto, options?: any): AxiosPromise<any> {
            return localVarFp.pusherControllerAuth(pusherAuthDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PusherApi - object-oriented interface
 * @export
 * @class V1PusherApi
 * @extends {BaseAPI}
 */
export class V1PusherApi extends BaseAPI {
    /**
     * 
     * @summary Pusher Auth (presence channel)
     * @param {PusherAuthDto} pusherAuthDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PusherApi
     */
    public pusherControllerAuth(pusherAuthDto: PusherAuthDto, options?: any) {
        return V1PusherApiFp(this.configuration).pusherControllerAuth(pusherAuthDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ReviewsApi - axios parameter creator
 * @export
 */
export const V1ReviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Reviews
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseReviewsControllerReview: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create review
         * @param {CreateReviewDto} createReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsControllerCreateOne: async (createReviewDto: CreateReviewDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReviewDto' is not null or undefined
            assertParamExists('reviewsControllerCreateOne', 'createReviewDto', createReviewDto)
            const localVarPath = `/v1/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReviewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete one review by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsControllerDeleteOneReviewById: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reviews/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update review by id
         * @param {UpdateReviewDto} updateReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsControllerUpdateReview: async (updateReviewDto: UpdateReviewDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateReviewDto' is not null or undefined
            assertParamExists('reviewsControllerUpdateReview', 'updateReviewDto', updateReviewDto)
            const localVarPath = `/v1/reviews/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReviewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ReviewsApi - functional programming interface
 * @export
 */
export const V1ReviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ReviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Reviews
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseReviewsControllerReview(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyReviewResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseReviewsControllerReview(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create review
         * @param {CreateReviewDto} createReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewsControllerCreateOne(createReviewDto: CreateReviewDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewsControllerCreateOne(createReviewDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete one review by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewsControllerDeleteOneReviewById(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewsControllerDeleteOneReviewById(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update review by id
         * @param {UpdateReviewDto} updateReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewsControllerUpdateReview(updateReviewDto: UpdateReviewDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewsControllerUpdateReview(updateReviewDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ReviewsApi - factory interface
 * @export
 */
export const V1ReviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ReviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Reviews
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseReviewsControllerReview(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyReviewResponseDto> {
            return localVarFp.getManyBaseReviewsControllerReview(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create review
         * @param {CreateReviewDto} createReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsControllerCreateOne(createReviewDto: CreateReviewDto, options?: any): AxiosPromise<Review> {
            return localVarFp.reviewsControllerCreateOne(createReviewDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete one review by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsControllerDeleteOneReviewById(options?: any): AxiosPromise<Review> {
            return localVarFp.reviewsControllerDeleteOneReviewById(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update review by id
         * @param {UpdateReviewDto} updateReviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewsControllerUpdateReview(updateReviewDto: UpdateReviewDto, options?: any): AxiosPromise<Review> {
            return localVarFp.reviewsControllerUpdateReview(updateReviewDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ReviewsApi - object-oriented interface
 * @export
 * @class V1ReviewsApi
 * @extends {BaseAPI}
 */
export class V1ReviewsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Reviews
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ReviewsApi
     */
    public getManyBaseReviewsControllerReview(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1ReviewsApiFp(this.configuration).getManyBaseReviewsControllerReview(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create review
     * @param {CreateReviewDto} createReviewDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ReviewsApi
     */
    public reviewsControllerCreateOne(createReviewDto: CreateReviewDto, options?: any) {
        return V1ReviewsApiFp(this.configuration).reviewsControllerCreateOne(createReviewDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete one review by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ReviewsApi
     */
    public reviewsControllerDeleteOneReviewById(options?: any) {
        return V1ReviewsApiFp(this.configuration).reviewsControllerDeleteOneReviewById(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update review by id
     * @param {UpdateReviewDto} updateReviewDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ReviewsApi
     */
    public reviewsControllerUpdateReview(updateReviewDto: UpdateReviewDto, options?: any) {
        return V1ReviewsApiFp(this.configuration).reviewsControllerUpdateReview(updateReviewDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1RubricsApi - axios parameter creator
 * @export
 */
export const V1RubricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all rubric with categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerGetAllRubricCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/rubrics/with-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many rubrics
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<'ONLY_ACTIVE'>} [filters] 
         * @param {Array<'FIRST_LEVEL_OF_CATEGORIES'>} [joins] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerGetManyRubrics: async (limit?: number, page?: number, filters?: Array<'ONLY_ACTIVE'>, joins?: Array<'FIRST_LEVEL_OF_CATEGORIES'>, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/rubrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (joins) {
                localVarQueryParameter['joins'] = joins;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one rubric categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerGetOneRubricCategories: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rubricsControllerGetOneRubricCategories', 'id', id)
            const localVarPath = `/v1/rubrics/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one rubric categories
         * @param {number} id 
         * @param {UpdateRubricDto} updateRubricDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerUpdateOneCategoriy: async (id: number, updateRubricDto: UpdateRubricDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rubricsControllerUpdateOneCategoriy', 'id', id)
            // verify required parameter 'updateRubricDto' is not null or undefined
            assertParamExists('rubricsControllerUpdateOneCategoriy', 'updateRubricDto', updateRubricDto)
            const localVarPath = `/v1/rubrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRubricDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1RubricsApi - functional programming interface
 * @export
 */
export const V1RubricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1RubricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all rubric with categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rubricsControllerGetAllRubricCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rubric>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rubricsControllerGetAllRubricCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many rubrics
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<'ONLY_ACTIVE'>} [filters] 
         * @param {Array<'FIRST_LEVEL_OF_CATEGORIES'>} [joins] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rubricsControllerGetManyRubrics(limit?: number, page?: number, filters?: Array<'ONLY_ACTIVE'>, joins?: Array<'FIRST_LEVEL_OF_CATEGORIES'>, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyRubricsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rubricsControllerGetManyRubrics(limit, page, filters, joins, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one rubric categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rubricsControllerGetOneRubricCategories(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rubricsControllerGetOneRubricCategories(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one rubric categories
         * @param {number} id 
         * @param {UpdateRubricDto} updateRubricDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rubricsControllerUpdateOneCategoriy(id: number, updateRubricDto: UpdateRubricDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rubricsControllerUpdateOneCategoriy(id, updateRubricDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1RubricsApi - factory interface
 * @export
 */
export const V1RubricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1RubricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all rubric with categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerGetAllRubricCategories(options?: any): AxiosPromise<Array<Rubric>> {
            return localVarFp.rubricsControllerGetAllRubricCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many rubrics
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {Array<'ONLY_ACTIVE'>} [filters] 
         * @param {Array<'FIRST_LEVEL_OF_CATEGORIES'>} [joins] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerGetManyRubrics(limit?: number, page?: number, filters?: Array<'ONLY_ACTIVE'>, joins?: Array<'FIRST_LEVEL_OF_CATEGORIES'>, name?: string, options?: any): AxiosPromise<GetManyRubricsResponseDto> {
            return localVarFp.rubricsControllerGetManyRubrics(limit, page, filters, joins, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one rubric categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerGetOneRubricCategories(id: number, options?: any): AxiosPromise<Rubric> {
            return localVarFp.rubricsControllerGetOneRubricCategories(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one rubric categories
         * @param {number} id 
         * @param {UpdateRubricDto} updateRubricDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rubricsControllerUpdateOneCategoriy(id: number, updateRubricDto: UpdateRubricDto, options?: any): AxiosPromise<Rubric> {
            return localVarFp.rubricsControllerUpdateOneCategoriy(id, updateRubricDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1RubricsApi - object-oriented interface
 * @export
 * @class V1RubricsApi
 * @extends {BaseAPI}
 */
export class V1RubricsApi extends BaseAPI {
    /**
     * 
     * @summary Get all rubric with categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RubricsApi
     */
    public rubricsControllerGetAllRubricCategories(options?: any) {
        return V1RubricsApiFp(this.configuration).rubricsControllerGetAllRubricCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many rubrics
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {Array<'ONLY_ACTIVE'>} [filters] 
     * @param {Array<'FIRST_LEVEL_OF_CATEGORIES'>} [joins] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RubricsApi
     */
    public rubricsControllerGetManyRubrics(limit?: number, page?: number, filters?: Array<'ONLY_ACTIVE'>, joins?: Array<'FIRST_LEVEL_OF_CATEGORIES'>, name?: string, options?: any) {
        return V1RubricsApiFp(this.configuration).rubricsControllerGetManyRubrics(limit, page, filters, joins, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one rubric categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RubricsApi
     */
    public rubricsControllerGetOneRubricCategories(id: number, options?: any) {
        return V1RubricsApiFp(this.configuration).rubricsControllerGetOneRubricCategories(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one rubric categories
     * @param {number} id 
     * @param {UpdateRubricDto} updateRubricDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RubricsApi
     */
    public rubricsControllerUpdateOneCategoriy(id: number, updateRubricDto: UpdateRubricDto, options?: any) {
        return V1RubricsApiFp(this.configuration).rubricsControllerUpdateOneCategoriy(id, updateRubricDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1StorageApi - axios parameter creator
 * @export
 */
export const V1StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Presign url for file upload
         * @param {PresignUrlDto} presignUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerSignFileUrl: async (presignUrlDto: PresignUrlDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'presignUrlDto' is not null or undefined
            assertParamExists('storageControllerSignFileUrl', 'presignUrlDto', presignUrlDto)
            const localVarPath = `/v1/storage/sign-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(presignUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1StorageApi - functional programming interface
 * @export
 */
export const V1StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Presign url for file upload
         * @param {PresignUrlDto} presignUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageControllerSignFileUrl(presignUrlDto: PresignUrlDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageControllerSignFileUrl(presignUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1StorageApi - factory interface
 * @export
 */
export const V1StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1StorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Presign url for file upload
         * @param {PresignUrlDto} presignUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerSignFileUrl(presignUrlDto: PresignUrlDto, options?: any): AxiosPromise<string> {
            return localVarFp.storageControllerSignFileUrl(presignUrlDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1StorageApi - object-oriented interface
 * @export
 * @class V1StorageApi
 * @extends {BaseAPI}
 */
export class V1StorageApi extends BaseAPI {
    /**
     * 
     * @summary Presign url for file upload
     * @param {PresignUrlDto} presignUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1StorageApi
     */
    public storageControllerSignFileUrl(presignUrlDto: PresignUrlDto, options?: any) {
        return V1StorageApiFp(this.configuration).storageControllerSignFileUrl(presignUrlDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1UserCategoriesMetadataApi - axios parameter creator
 * @export
 */
export const V1UserCategoriesMetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk update user categories metadata
         * @param {BulkUpdateUserCategoryMetaDto} bulkUpdateUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerAddUserToCategories: async (bulkUpdateUserCategoryMetaDto: BulkUpdateUserCategoryMetaDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpdateUserCategoryMetaDto' is not null or undefined
            assertParamExists('userCategoriesMetaControllerAddUserToCategories', 'bulkUpdateUserCategoryMetaDto', bulkUpdateUserCategoryMetaDto)
            const localVarPath = `/v1/user-categories-meta/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateUserCategoryMetaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user category metadata
         * @param {CreateUserCategoryMetaDto} createUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerCreateOne: async (createUserCategoryMetaDto: CreateUserCategoryMetaDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserCategoryMetaDto' is not null or undefined
            assertParamExists('userCategoriesMetaControllerCreateOne', 'createUserCategoryMetaDto', createUserCategoryMetaDto)
            const localVarPath = `/v1/user-categories-meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserCategoryMetaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get many user category metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerGetMany: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user-categories-meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove user category metadata
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerRemoveOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userCategoriesMetaControllerRemoveOne', 'id', id)
            const localVarPath = `/v1/user-categories-meta/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user category metadata
         * @param {number} id 
         * @param {UpdateUserCategoryMetaDto} updateUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerUpdateOne: async (id: number, updateUserCategoryMetaDto: UpdateUserCategoryMetaDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userCategoriesMetaControllerUpdateOne', 'id', id)
            // verify required parameter 'updateUserCategoryMetaDto' is not null or undefined
            assertParamExists('userCategoriesMetaControllerUpdateOne', 'updateUserCategoryMetaDto', updateUserCategoryMetaDto)
            const localVarPath = `/v1/user-categories-meta/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCategoryMetaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1UserCategoriesMetadataApi - functional programming interface
 * @export
 */
export const V1UserCategoriesMetadataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1UserCategoriesMetadataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk update user categories metadata
         * @param {BulkUpdateUserCategoryMetaDto} bulkUpdateUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCategoriesMetaControllerAddUserToCategories(bulkUpdateUserCategoryMetaDto: BulkUpdateUserCategoryMetaDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCategoryMeta>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCategoriesMetaControllerAddUserToCategories(bulkUpdateUserCategoryMetaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user category metadata
         * @param {CreateUserCategoryMetaDto} createUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCategoriesMetaControllerCreateOne(createUserCategoryMetaDto: CreateUserCategoryMetaDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCategoryMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCategoriesMetaControllerCreateOne(createUserCategoryMetaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get many user category metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCategoriesMetaControllerGetMany(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCategoryMeta>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCategoriesMetaControllerGetMany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove user category metadata
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCategoriesMetaControllerRemoveOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCategoryMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCategoriesMetaControllerRemoveOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user category metadata
         * @param {number} id 
         * @param {UpdateUserCategoryMetaDto} updateUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCategoriesMetaControllerUpdateOne(id: number, updateUserCategoryMetaDto: UpdateUserCategoryMetaDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCategoryMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCategoriesMetaControllerUpdateOne(id, updateUserCategoryMetaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1UserCategoriesMetadataApi - factory interface
 * @export
 */
export const V1UserCategoriesMetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1UserCategoriesMetadataApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk update user categories metadata
         * @param {BulkUpdateUserCategoryMetaDto} bulkUpdateUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerAddUserToCategories(bulkUpdateUserCategoryMetaDto: BulkUpdateUserCategoryMetaDto, options?: any): AxiosPromise<Array<UserCategoryMeta>> {
            return localVarFp.userCategoriesMetaControllerAddUserToCategories(bulkUpdateUserCategoryMetaDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user category metadata
         * @param {CreateUserCategoryMetaDto} createUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerCreateOne(createUserCategoryMetaDto: CreateUserCategoryMetaDto, options?: any): AxiosPromise<UserCategoryMeta> {
            return localVarFp.userCategoriesMetaControllerCreateOne(createUserCategoryMetaDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get many user category metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerGetMany(options?: any): AxiosPromise<Array<UserCategoryMeta>> {
            return localVarFp.userCategoriesMetaControllerGetMany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove user category metadata
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerRemoveOne(id: number, options?: any): AxiosPromise<UserCategoryMeta> {
            return localVarFp.userCategoriesMetaControllerRemoveOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user category metadata
         * @param {number} id 
         * @param {UpdateUserCategoryMetaDto} updateUserCategoryMetaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCategoriesMetaControllerUpdateOne(id: number, updateUserCategoryMetaDto: UpdateUserCategoryMetaDto, options?: any): AxiosPromise<UserCategoryMeta> {
            return localVarFp.userCategoriesMetaControllerUpdateOne(id, updateUserCategoryMetaDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1UserCategoriesMetadataApi - object-oriented interface
 * @export
 * @class V1UserCategoriesMetadataApi
 * @extends {BaseAPI}
 */
export class V1UserCategoriesMetadataApi extends BaseAPI {
    /**
     * 
     * @summary Bulk update user categories metadata
     * @param {BulkUpdateUserCategoryMetaDto} bulkUpdateUserCategoryMetaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UserCategoriesMetadataApi
     */
    public userCategoriesMetaControllerAddUserToCategories(bulkUpdateUserCategoryMetaDto: BulkUpdateUserCategoryMetaDto, options?: any) {
        return V1UserCategoriesMetadataApiFp(this.configuration).userCategoriesMetaControllerAddUserToCategories(bulkUpdateUserCategoryMetaDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user category metadata
     * @param {CreateUserCategoryMetaDto} createUserCategoryMetaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UserCategoriesMetadataApi
     */
    public userCategoriesMetaControllerCreateOne(createUserCategoryMetaDto: CreateUserCategoryMetaDto, options?: any) {
        return V1UserCategoriesMetadataApiFp(this.configuration).userCategoriesMetaControllerCreateOne(createUserCategoryMetaDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get many user category metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UserCategoriesMetadataApi
     */
    public userCategoriesMetaControllerGetMany(options?: any) {
        return V1UserCategoriesMetadataApiFp(this.configuration).userCategoriesMetaControllerGetMany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove user category metadata
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UserCategoriesMetadataApi
     */
    public userCategoriesMetaControllerRemoveOne(id: number, options?: any) {
        return V1UserCategoriesMetadataApiFp(this.configuration).userCategoriesMetaControllerRemoveOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user category metadata
     * @param {number} id 
     * @param {UpdateUserCategoryMetaDto} updateUserCategoryMetaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UserCategoriesMetadataApi
     */
    public userCategoriesMetaControllerUpdateOne(id: number, updateUserCategoryMetaDto: UpdateUserCategoryMetaDto, options?: any) {
        return V1UserCategoriesMetadataApiFp(this.configuration).userCategoriesMetaControllerUpdateOne(id, updateUserCategoryMetaDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1UsersApi - axios parameter creator
 * @export
 */
export const V1UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Registration Step For Expert
         * @param {ExpertStepDto} expertStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAddStep: async (expertStepDto: ExpertStepDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertStepDto' is not null or undefined
            assertParamExists('usersControllerAddStep', 'expertStepDto', expertStepDto)
            const localVarPath = `/v1/users/steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm verification email
         * @param {ConfirmVerificationEmailDto} confirmVerificationEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirmVerificationEmail: async (confirmVerificationEmailDto: ConfirmVerificationEmailDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmVerificationEmailDto' is not null or undefined
            assertParamExists('usersControllerConfirmVerificationEmail', 'confirmVerificationEmailDto', confirmVerificationEmailDto)
            const localVarPath = `/v1/users/email/confirm-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmVerificationEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete zoom token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteZoomToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/deleteZoomToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check customer email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerEmailIsUniq: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersControllerEmailIsUniq', 'email', email)
            const localVarPath = `/v1/users/email/check-is-uniq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerGetUserOne', 'id', id)
            const localVarPath = `/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save saveZoomToken
         * @param {ExpertZoomCodeDto} expertZoomCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSaveZoomToken: async (expertZoomCodeDto: ExpertZoomCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'expertZoomCodeDto' is not null or undefined
            assertParamExists('usersControllerSaveZoomToken', 'expertZoomCodeDto', expertZoomCodeDto)
            const localVarPath = `/v1/users/saveZoomToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expertZoomCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send SMS-code for sign in
         * @param {SmsCodeDto} smsCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendSms: async (smsCodeDto: SmsCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsCodeDto' is not null or undefined
            assertParamExists('usersControllerSendSms', 'smsCodeDto', smsCodeDto)
            const localVarPath = `/v1/users/phone/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send verification email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendVerificationEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/me/email/send-verification-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in via SMS-code and phone
         * @param {SigninDto} signinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSignInSms: async (signinDto: SigninDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signinDto' is not null or undefined
            assertParamExists('usersControllerSignInSms', 'signinDto', signinDto)
            const localVarPath = `/v1/users/phone/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update FCM Tokens
         * @param {UpdateFCMTokensDto} updateFCMTokensDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateFCMTokens: async (updateFCMTokensDto: UpdateFCMTokensDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFCMTokensDto' is not null or undefined
            assertParamExists('usersControllerUpdateFCMTokens', 'updateFCMTokensDto', updateFCMTokensDto)
            const localVarPath = `/v1/users/me/fcm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFCMTokensDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update IAM user
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIAM: async (updateUserDto: UpdateUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('usersControllerUpdateIAM', 'updateUserDto', updateUserDto)
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update IAM user profile
         * @param {UpdateUserProfileDto} updateUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIAMProfile: async (updateUserProfileDto: UpdateUserProfileDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserProfileDto' is not null or undefined
            assertParamExists('usersControllerUpdateIAMProfile', 'updateUserProfileDto', updateUserProfileDto)
            const localVarPath = `/v1/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user isExpert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIsExpert: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/isExpert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user isSeeModalWindow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIsSeeModalWindow: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/isSeeModalWindow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user last exit time
         * @param {UpdateLastExitTimeDto} updateLastExitTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateLastExitTime: async (updateLastExitTimeDto: UpdateLastExitTimeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLastExitTimeDto' is not null or undefined
            assertParamExists('usersControllerUpdateLastExitTime', 'updateLastExitTimeDto', updateLastExitTimeDto)
            const localVarPath = `/v1/users/lastExitTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLastExitTimeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1UsersApi - functional programming interface
 * @export
 */
export const V1UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Registration Step For Expert
         * @param {ExpertStepDto} expertStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAddStep(expertStepDto: ExpertStepDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAddStep(expertStepDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm verification email
         * @param {ConfirmVerificationEmailDto} confirmVerificationEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerConfirmVerificationEmail(confirmVerificationEmailDto: ConfirmVerificationEmailDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerConfirmVerificationEmail(confirmVerificationEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete zoom token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteZoomToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteZoomToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check customer email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerEmailIsUniq(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerEmailIsUniq(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUserOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUserOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save saveZoomToken
         * @param {ExpertZoomCodeDto} expertZoomCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSaveZoomToken(expertZoomCodeDto: ExpertZoomCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSaveZoomToken(expertZoomCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send SMS-code for sign in
         * @param {SmsCodeDto} smsCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSendSms(smsCodeDto: SmsCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSendSms(smsCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send verification email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSendVerificationEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSendVerificationEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign in via SMS-code and phone
         * @param {SigninDto} signinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSignInSms(signinDto: SigninDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSignInSms(signinDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update FCM Tokens
         * @param {UpdateFCMTokensDto} updateFCMTokensDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateFCMTokens(updateFCMTokensDto: UpdateFCMTokensDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateFCMTokens(updateFCMTokensDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update IAM user
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateIAM(updateUserDto: UpdateUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateIAM(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update IAM user profile
         * @param {UpdateUserProfileDto} updateUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateIAMProfile(updateUserProfileDto: UpdateUserProfileDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateIAMProfile(updateUserProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user isExpert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateIsExpert(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateIsExpert(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user isSeeModalWindow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateIsSeeModalWindow(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateIsSeeModalWindow(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user last exit time
         * @param {UpdateLastExitTimeDto} updateLastExitTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateLastExitTime(updateLastExitTimeDto: UpdateLastExitTimeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateLastExitTime(updateLastExitTimeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1UsersApi - factory interface
 * @export
 */
export const V1UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Registration Step For Expert
         * @param {ExpertStepDto} expertStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAddStep(expertStepDto: ExpertStepDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerAddStep(expertStepDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm verification email
         * @param {ConfirmVerificationEmailDto} confirmVerificationEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirmVerificationEmail(confirmVerificationEmailDto: ConfirmVerificationEmailDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerConfirmVerificationEmail(confirmVerificationEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteMe(options?: any): AxiosPromise<boolean> {
            return localVarFp.usersControllerDeleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete zoom token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteZoomToken(options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerDeleteZoomToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check customer email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerEmailIsUniq(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.usersControllerEmailIsUniq(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetMe(options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserOne(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerGetUserOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save saveZoomToken
         * @param {ExpertZoomCodeDto} expertZoomCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSaveZoomToken(expertZoomCodeDto: ExpertZoomCodeDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerSaveZoomToken(expertZoomCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send SMS-code for sign in
         * @param {SmsCodeDto} smsCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendSms(smsCodeDto: SmsCodeDto, options?: any): AxiosPromise<string> {
            return localVarFp.usersControllerSendSms(smsCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send verification email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendVerificationEmail(options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerSendVerificationEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in via SMS-code and phone
         * @param {SigninDto} signinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSignInSms(signinDto: SigninDto, options?: any): AxiosPromise<AuthUser> {
            return localVarFp.usersControllerSignInSms(signinDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update FCM Tokens
         * @param {UpdateFCMTokensDto} updateFCMTokensDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateFCMTokens(updateFCMTokensDto: UpdateFCMTokensDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerUpdateFCMTokens(updateFCMTokensDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update IAM user
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIAM(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerUpdateIAM(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update IAM user profile
         * @param {UpdateUserProfileDto} updateUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIAMProfile(updateUserProfileDto: UpdateUserProfileDto, options?: any): AxiosPromise<User> {
            return localVarFp.usersControllerUpdateIAMProfile(updateUserProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user isExpert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIsExpert(options?: any): AxiosPromise<boolean> {
            return localVarFp.usersControllerUpdateIsExpert(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user isSeeModalWindow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateIsSeeModalWindow(options?: any): AxiosPromise<boolean> {
            return localVarFp.usersControllerUpdateIsSeeModalWindow(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user last exit time
         * @param {UpdateLastExitTimeDto} updateLastExitTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateLastExitTime(updateLastExitTimeDto: UpdateLastExitTimeDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.usersControllerUpdateLastExitTime(updateLastExitTimeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1UsersApi - object-oriented interface
 * @export
 * @class V1UsersApi
 * @extends {BaseAPI}
 */
export class V1UsersApi extends BaseAPI {
    /**
     * 
     * @summary Add Registration Step For Expert
     * @param {ExpertStepDto} expertStepDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerAddStep(expertStepDto: ExpertStepDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerAddStep(expertStepDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm verification email
     * @param {ConfirmVerificationEmailDto} confirmVerificationEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerConfirmVerificationEmail(confirmVerificationEmailDto: ConfirmVerificationEmailDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerConfirmVerificationEmail(confirmVerificationEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerDeleteMe(options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerDeleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete zoom token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerDeleteZoomToken(options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerDeleteZoomToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check customer email for uniqueness
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerEmailIsUniq(email: string, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerEmailIsUniq(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerGetMe(options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerGetUserOne(id: number, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerGetUserOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save saveZoomToken
     * @param {ExpertZoomCodeDto} expertZoomCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerSaveZoomToken(expertZoomCodeDto: ExpertZoomCodeDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerSaveZoomToken(expertZoomCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send SMS-code for sign in
     * @param {SmsCodeDto} smsCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerSendSms(smsCodeDto: SmsCodeDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerSendSms(smsCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send verification email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerSendVerificationEmail(options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerSendVerificationEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in via SMS-code and phone
     * @param {SigninDto} signinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerSignInSms(signinDto: SigninDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerSignInSms(signinDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update FCM Tokens
     * @param {UpdateFCMTokensDto} updateFCMTokensDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerUpdateFCMTokens(updateFCMTokensDto: UpdateFCMTokensDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerUpdateFCMTokens(updateFCMTokensDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update IAM user
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerUpdateIAM(updateUserDto: UpdateUserDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerUpdateIAM(updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update IAM user profile
     * @param {UpdateUserProfileDto} updateUserProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerUpdateIAMProfile(updateUserProfileDto: UpdateUserProfileDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerUpdateIAMProfile(updateUserProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user isExpert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerUpdateIsExpert(options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerUpdateIsExpert(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user isSeeModalWindow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerUpdateIsSeeModalWindow(options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerUpdateIsSeeModalWindow(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user last exit time
     * @param {UpdateLastExitTimeDto} updateLastExitTimeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1UsersApi
     */
    public usersControllerUpdateLastExitTime(updateLastExitTimeDto: UpdateLastExitTimeDto, options?: any) {
        return V1UsersApiFp(this.configuration).usersControllerUpdateLastExitTime(updateLastExitTimeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1VerificationRequestsApi - axios parameter creator
 * @export
 */
export const V1VerificationRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create verification request
         * @param {CreateVerificationRequestDto} createVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerCreateOne: async (createVerificationRequestDto: CreateVerificationRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVerificationRequestDto' is not null or undefined
            assertParamExists('verificationRequestsControllerCreateOne', 'createVerificationRequestDto', createVerificationRequestDto)
            const localVarPath = `/v1/verification-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVerificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete verification request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerDeleteOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verificationRequestsControllerDeleteOne', 'id', id)
            const localVarPath = `/v1/verification-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get self verification requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerGetUserRequests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/verification-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update verification request
         * @param {number} id 
         * @param {UpdateVerificationRequestDto} updateVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerUpdateOne: async (id: number, updateVerificationRequestDto: UpdateVerificationRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verificationRequestsControllerUpdateOne', 'id', id)
            // verify required parameter 'updateVerificationRequestDto' is not null or undefined
            assertParamExists('verificationRequestsControllerUpdateOne', 'updateVerificationRequestDto', updateVerificationRequestDto)
            const localVarPath = `/v1/verification-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVerificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1VerificationRequestsApi - functional programming interface
 * @export
 */
export const V1VerificationRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1VerificationRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create verification request
         * @param {CreateVerificationRequestDto} createVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationRequestsControllerCreateOne(createVerificationRequestDto: CreateVerificationRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationRequestsControllerCreateOne(createVerificationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete verification request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationRequestsControllerDeleteOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationRequestsControllerDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get self verification requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationRequestsControllerGetUserRequests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerificationRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationRequestsControllerGetUserRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update verification request
         * @param {number} id 
         * @param {UpdateVerificationRequestDto} updateVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationRequestsControllerUpdateOne(id: number, updateVerificationRequestDto: UpdateVerificationRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationRequestsControllerUpdateOne(id, updateVerificationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1VerificationRequestsApi - factory interface
 * @export
 */
export const V1VerificationRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1VerificationRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create verification request
         * @param {CreateVerificationRequestDto} createVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerCreateOne(createVerificationRequestDto: CreateVerificationRequestDto, options?: any): AxiosPromise<VerificationRequest> {
            return localVarFp.verificationRequestsControllerCreateOne(createVerificationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete verification request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerDeleteOne(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.verificationRequestsControllerDeleteOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get self verification requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerGetUserRequests(options?: any): AxiosPromise<Array<VerificationRequest>> {
            return localVarFp.verificationRequestsControllerGetUserRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update verification request
         * @param {number} id 
         * @param {UpdateVerificationRequestDto} updateVerificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequestsControllerUpdateOne(id: number, updateVerificationRequestDto: UpdateVerificationRequestDto, options?: any): AxiosPromise<VerificationRequest> {
            return localVarFp.verificationRequestsControllerUpdateOne(id, updateVerificationRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1VerificationRequestsApi - object-oriented interface
 * @export
 * @class V1VerificationRequestsApi
 * @extends {BaseAPI}
 */
export class V1VerificationRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Create verification request
     * @param {CreateVerificationRequestDto} createVerificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1VerificationRequestsApi
     */
    public verificationRequestsControllerCreateOne(createVerificationRequestDto: CreateVerificationRequestDto, options?: any) {
        return V1VerificationRequestsApiFp(this.configuration).verificationRequestsControllerCreateOne(createVerificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete verification request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1VerificationRequestsApi
     */
    public verificationRequestsControllerDeleteOne(id: number, options?: any) {
        return V1VerificationRequestsApiFp(this.configuration).verificationRequestsControllerDeleteOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get self verification requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1VerificationRequestsApi
     */
    public verificationRequestsControllerGetUserRequests(options?: any) {
        return V1VerificationRequestsApiFp(this.configuration).verificationRequestsControllerGetUserRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update verification request
     * @param {number} id 
     * @param {UpdateVerificationRequestDto} updateVerificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1VerificationRequestsApi
     */
    public verificationRequestsControllerUpdateOne(id: number, updateVerificationRequestDto: UpdateVerificationRequestDto, options?: any) {
        return V1VerificationRequestsApiFp(this.configuration).verificationRequestsControllerUpdateOne(id, updateVerificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1WalletsApi - axios parameter creator
 * @export
 */
export const V1WalletsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get self wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetSelfWallet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1WalletsApi - functional programming interface
 * @export
 */
export const V1WalletsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1WalletsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get self wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsControllerGetSelfWallet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsControllerGetSelfWallet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1WalletsApi - factory interface
 * @export
 */
export const V1WalletsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1WalletsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get self wallet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsControllerGetSelfWallet(options?: any): AxiosPromise<Wallet> {
            return localVarFp.walletsControllerGetSelfWallet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1WalletsApi - object-oriented interface
 * @export
 * @class V1WalletsApi
 * @extends {BaseAPI}
 */
export class V1WalletsApi extends BaseAPI {
    /**
     * 
     * @summary Get self wallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1WalletsApi
     */
    public walletsControllerGetSelfWallet(options?: any) {
        return V1WalletsApiFp(this.configuration).walletsControllerGetSelfWallet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1WithdrawalRequestApi - axios parameter creator
 * @export
 */
export const V1WithdrawalRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create withdrawal request
         * @param {CreateWithdrawalRequestsDto} createWithdrawalRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestsControllerCreateOne: async (createWithdrawalRequestsDto: CreateWithdrawalRequestsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWithdrawalRequestsDto' is not null or undefined
            assertParamExists('withdrawalRequestsControllerCreateOne', 'createWithdrawalRequestsDto', createWithdrawalRequestsDto)
            const localVarPath = `/v1/withdrawal-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWithdrawalRequestsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete withdrawal request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestsControllerDeleteOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('withdrawalRequestsControllerDeleteOne', 'id', id)
            const localVarPath = `/v1/withdrawal-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user withdrawal requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestsControllerGetManyForUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/withdrawal-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1WithdrawalRequestApi - functional programming interface
 * @export
 */
export const V1WithdrawalRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1WithdrawalRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create withdrawal request
         * @param {CreateWithdrawalRequestsDto} createWithdrawalRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalRequestsControllerCreateOne(createWithdrawalRequestsDto: CreateWithdrawalRequestsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalRequestsControllerCreateOne(createWithdrawalRequestsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete withdrawal request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalRequestsControllerDeleteOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalRequestsControllerDeleteOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user withdrawal requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalRequestsControllerGetManyForUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WithdrawalRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalRequestsControllerGetManyForUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1WithdrawalRequestApi - factory interface
 * @export
 */
export const V1WithdrawalRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1WithdrawalRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Create withdrawal request
         * @param {CreateWithdrawalRequestsDto} createWithdrawalRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestsControllerCreateOne(createWithdrawalRequestsDto: CreateWithdrawalRequestsDto, options?: any): AxiosPromise<WithdrawalRequest> {
            return localVarFp.withdrawalRequestsControllerCreateOne(createWithdrawalRequestsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete withdrawal request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestsControllerDeleteOne(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.withdrawalRequestsControllerDeleteOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user withdrawal requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalRequestsControllerGetManyForUser(options?: any): AxiosPromise<Array<WithdrawalRequest>> {
            return localVarFp.withdrawalRequestsControllerGetManyForUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1WithdrawalRequestApi - object-oriented interface
 * @export
 * @class V1WithdrawalRequestApi
 * @extends {BaseAPI}
 */
export class V1WithdrawalRequestApi extends BaseAPI {
    /**
     * 
     * @summary Create withdrawal request
     * @param {CreateWithdrawalRequestsDto} createWithdrawalRequestsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1WithdrawalRequestApi
     */
    public withdrawalRequestsControllerCreateOne(createWithdrawalRequestsDto: CreateWithdrawalRequestsDto, options?: any) {
        return V1WithdrawalRequestApiFp(this.configuration).withdrawalRequestsControllerCreateOne(createWithdrawalRequestsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete withdrawal request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1WithdrawalRequestApi
     */
    public withdrawalRequestsControllerDeleteOne(id: number, options?: any) {
        return V1WithdrawalRequestApiFp(this.configuration).withdrawalRequestsControllerDeleteOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user withdrawal requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1WithdrawalRequestApi
     */
    public withdrawalRequestsControllerGetManyForUser(options?: any) {
        return V1WithdrawalRequestApiFp(this.configuration).withdrawalRequestsControllerGetManyForUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Tinkoff E2C webhook endpoint
         * @param {TinkoffE2cNotificationDto} tinkoffE2cNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerTakeE2cNotification: async (tinkoffE2cNotificationDto: TinkoffE2cNotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tinkoffE2cNotificationDto' is not null or undefined
            assertParamExists('webhookControllerTakeE2cNotification', 'tinkoffE2cNotificationDto', tinkoffE2cNotificationDto)
            const localVarPath = `/v1/webhooks/bank-webhook/tinkoff/e2c`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tinkoffE2cNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tinkoff EACQ webhook endpoint
         * @param {TinkoffEacqNotificationDto} tinkoffEacqNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerTakeEacqNotification: async (tinkoffEacqNotificationDto: TinkoffEacqNotificationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tinkoffEacqNotificationDto' is not null or undefined
            assertParamExists('webhookControllerTakeEacqNotification', 'tinkoffEacqNotificationDto', tinkoffEacqNotificationDto)
            const localVarPath = `/v1/webhooks/bank-webhook/tinkoff/eacq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tinkoffEacqNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Tinkoff E2C webhook endpoint
         * @param {TinkoffE2cNotificationDto} tinkoffE2cNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookControllerTakeE2cNotification(tinkoffE2cNotificationDto: TinkoffE2cNotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookControllerTakeE2cNotification(tinkoffE2cNotificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tinkoff EACQ webhook endpoint
         * @param {TinkoffEacqNotificationDto} tinkoffEacqNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookControllerTakeEacqNotification(tinkoffEacqNotificationDto: TinkoffEacqNotificationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookControllerTakeEacqNotification(tinkoffEacqNotificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Tinkoff E2C webhook endpoint
         * @param {TinkoffE2cNotificationDto} tinkoffE2cNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerTakeE2cNotification(tinkoffE2cNotificationDto: TinkoffE2cNotificationDto, options?: any): AxiosPromise<string> {
            return localVarFp.webhookControllerTakeE2cNotification(tinkoffE2cNotificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tinkoff EACQ webhook endpoint
         * @param {TinkoffEacqNotificationDto} tinkoffEacqNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerTakeEacqNotification(tinkoffEacqNotificationDto: TinkoffEacqNotificationDto, options?: any): AxiosPromise<string> {
            return localVarFp.webhookControllerTakeEacqNotification(tinkoffEacqNotificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @summary Tinkoff E2C webhook endpoint
     * @param {TinkoffE2cNotificationDto} tinkoffE2cNotificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhookControllerTakeE2cNotification(tinkoffE2cNotificationDto: TinkoffE2cNotificationDto, options?: any) {
        return WebhooksApiFp(this.configuration).webhookControllerTakeE2cNotification(tinkoffE2cNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tinkoff EACQ webhook endpoint
     * @param {TinkoffEacqNotificationDto} tinkoffEacqNotificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhookControllerTakeEacqNotification(tinkoffEacqNotificationDto: TinkoffEacqNotificationDto, options?: any) {
        return WebhooksApiFp(this.configuration).webhookControllerTakeEacqNotification(tinkoffEacqNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


