import * as React from 'react';

function SvgBurger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={20} {...props}>
      <path d="M0 2h25M0 10h25M0 18h25" fill="none" fillRule="evenodd" stroke="#232832" strokeWidth={1.5} />
    </svg>
  );
}

export default SvgBurger;
