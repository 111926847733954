import * as React from 'react';

function SvgSmartAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M12.71 14.31a1.908 1.908 0 01-.99.261c-.365 0-.717-.092-.991-.26l-4.487-2.753s-.405-.249-.405.314v2.748c0 1.448 2.634 3.008 5.882 3.008 3.249 0 5.883-1.56 5.883-3.008v-2.903c0-.45-.288-.23-.288-.23L12.71 14.31z" />
          <path d="M19.18 9.37c.345-.211.345-.557 0-.769l-6.833-3.417c-.344-.212-.908-.212-1.253 0L4.259 8.6c-.345.212-.345.558 0 .769l6.834 4.192c.345.211.909.211 1.253 0m6.552 2.022v-4.63s.003-.219-.127-.146c-.104.058-.36.2-.45.279-.104.09-.08.293-.08.293v4.204c0 .06-.052.088-.077.102a.802.802 0 00.405 1.49.8.8 0 00.402-1.491c-.024-.014-.073-.042-.073-.101z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmartAchivment;
