import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ConsultationType = {
  expertId: number;
  expertFirstName: string;
  expertLastName: string;
  categoryName: string;
  consultationPrice: number;
  zoomLink?: string;
  categoryId: number;
  consultationId?: number;
};

export interface BookingZoomState {
  consultation: ConsultationType;
  consultationDate: string | null;
  consultationTime: string | null;
  isErrorGetLink: boolean;
}

const initialState: BookingZoomState = {
  consultation: {
    expertId: 0,
    expertFirstName: '',
    expertLastName: '',
    categoryName: '',
    categoryId: 0,
    consultationPrice: 0,
    zoomLink: '',
  } as ConsultationType,
  consultationDate: null,
  consultationTime: null,
  isErrorGetLink: false,
};

const bookingZoomSlice = createSlice({
  name: 'bookingZoom',
  initialState,
  reducers: {
    setConsultation(state, { payload }: PayloadAction<ConsultationType>) {
      state.consultation = payload;
      state.isErrorGetLink = false;
    },
    setDate(state, { payload }: PayloadAction<string | null>) {
      state.consultationDate = payload;
    },
    setLink(state, { payload }: PayloadAction<string>) {
      state.consultation.zoomLink = payload;
    },
    setMskTime(state, { payload }: PayloadAction<string | null>) {
      state.consultationTime = payload;
    },
    setError(state, { payload }: PayloadAction<boolean>) {
      state.isErrorGetLink = payload;
    },
    setConsultationId(state, { payload }: PayloadAction<number>) {
      state.consultation.consultationId = payload;
    },
  },
});

export const { reducer } = bookingZoomSlice;
export const actions = {
  ...bookingZoomSlice.actions,
};
