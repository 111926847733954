import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import nextI18nextConfig from '../../../../next-i18next.config';
import Error404En from '../../../../public/locales/en/404.page.json';
import Error500En from '../../../../public/locales/en/500.page.json';
import askExpertFormEn from '../../../../public/locales/en/ask-expert-form.component.json';
import categoryPageEn from '../../../../public/locales/en/category.page.json';
import chatPageEn from '../../../../public/locales/en/chat.page.json';
import commonEn from '../../../../public/locales/en/common.json';
import confirmEmailPageEn from '../../../../public/locales/en/confirm-email.page.json';
import contractPageEn from '../../../../public/locales/en/contract.page.json';
import errorsMessagesEn from '../../../../public/locales/en/errors.messages.json';
import expertPageEn from '../../../../public/locales/en/expert.page.json';
import ExpertPreviewEn from '../../../../public/locales/en/expert.preview.json';
import expertProfileEn from '../../../../public/locales/en/expert.profile.json';
import footerComponentEn from '../../../../public/locales/en/footer.component.json';
import headerComponentEn from '../../../../public/locales/en/header.component.json';
import mobilePageEn from '../../../../public/locales/en/mobile.page.json';
import profilePageEn from '../../../../public/locales/en/profile.page.json';
import topicsEn from '../../../../public/locales/en/topics.page.json';
import utilsEn from '../../../../public/locales/en/utils.json';
import Error404Ru from '../../../../public/locales/ru/404.page.json';
import Error500Ru from '../../../../public/locales/ru/500.page.json';
import askExpertFormRu from '../../../../public/locales/ru/ask-expert-form.component.json';
import categoryPageRu from '../../../../public/locales/ru/category.page.json';
import chatPageRu from '../../../../public/locales/ru/chat.page.json';
import commonRu from '../../../../public/locales/ru/common.json';
import confirmEmailPageRu from '../../../../public/locales/ru/confirm-email.page.json';
import contractPageRu from '../../../../public/locales/ru/contract.page.json';
import errorsMessagesRu from '../../../../public/locales/ru/errors.messages.json';
import expertPageRu from '../../../../public/locales/ru/expert.page.json';
import ExpertPreviewRu from '../../../../public/locales/ru/expert.preview.json';
import expertProfileRu from '../../../../public/locales/ru/expert.profile.json';
import footerComponentRu from '../../../../public/locales/ru/footer.component.json';
import headerComponentRu from '../../../../public/locales/ru/header.component.json';
import mobilePageRu from '../../../../public/locales/ru/mobile.page.json';
import profilePageRu from '../../../../public/locales/ru/profile.page.json';
import topicsRu from '../../../../public/locales/ru/topics.page.json';
import utilsRu from '../../../../public/locales/ru/utils.json';

export const resources = {
  ru: {
    headerComponent: headerComponentRu,
    footerComponent: footerComponentRu,
    ExpertPreview: ExpertPreviewRu,
    utils: utilsRu,
    topics: topicsRu,
    Error404: Error404Ru,
    Error500: Error500Ru,
    askExpertForm: askExpertFormRu,
    categoryPage: categoryPageRu,
    chatPage: chatPageRu,
    common: commonRu,
    confirmEmailPage: confirmEmailPageRu,
    contractPage: contractPageRu,
    errorsMessages: errorsMessagesRu,
    expertPage: expertPageRu,
    expertProfile: expertProfileRu,
    mobilePage: mobilePageRu,
    profilePage: profilePageRu,
  },
  en: {
    headerComponent: ExpertPreviewEn,
    footerComponent: footerComponentEn,
    ExpertPreview: headerComponentEn,
    utils: topicsEn,
    topics: utilsEn,
    Error404En,
    Error500: Error500En,
    askExpertForm: askExpertFormEn,
    categoryPage: categoryPageEn,
    chatPage: chatPageEn,
    common: commonEn,
    confirmEmailPage: confirmEmailPageEn,
    contractPage: contractPageEn,
    errorsMessages: errorsMessagesEn,
    expertPage: expertPageEn,
    expertProfile: expertProfileEn,
    mobilePage: mobilePageEn,
    profilePage: profilePageEn,
  },
};

i18n.use(initReactI18next).init({
  ...nextI18nextConfig.i18n,
  resources,
});

export default i18n;
