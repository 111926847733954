import React, { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { FieldWrapperProps } from '../field-wrapper/field-wrapper';

type Props = {
  prefix?: string;
  sizeLimitMb?: number;
  largeSizeError?: string;
};

type PhotoInputProps = InputHTMLAttributes<HTMLInputElement> & FieldWrapperProps & Props;

export const PhotoInput: FC<PhotoInputProps> = ({
  label,
  error,
  title,
  required,
  prefix,
  largeSizeError,
  sizeLimitMb = 15,
  onChange = () => {},
  ...props
}) => {
  const [fileName, setFileName] = useState<string | null>(null);

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const file = files.item(0);
      if (file) {
        if (file.size >= sizeLimitMb * 1_048_576) {
          if (largeSizeError) {
            toast.error(largeSizeError);
          }
          return;
        }
        setFileName(file.name);
      }
    }
    onChange(e);
  };

  return (
    <div>
      <Title>
        {title}
        {required && <Required>*</Required>}
      </Title>
      <Container>
        <OpenButton htmlFor={prefix}>
          <span>{label}</span>
        </OpenButton>
        <FileName>
          <span>{fileName}</span>
        </FileName>
        <input
          id={prefix}
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: 'none' }}
          onChange={onChangeFile}
          {...props}
        />
      </Container>
      {error && <Error>{error}</Error>}
    </div>
  );
};

const Container = styled.div`
  display: flex;
  gap: 15px;
`;

const OpenButton = styled.label`
  user-select: none;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.2s;
  align-items: center;
  color: var(--purple);
  border-radius: 28px;
  background-color: var(--white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--purple);
  opacity: 1;
  outline: none;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  display: flex;
  max-height: 46px;

  &:hover {
    background-color: var(--purple);
    color: var(--white);
    border-color: var(--white);
  }
`;

const FileName = styled.div`
  color: var(--gray);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Error = styled.span`
  margin: 15px 10px;
  font-weight: 500;
  font-size: 13px;
  color: var(--red);
  min-height: 14px;
`;

const Required = styled.span`
  color: var(--purple);
  margin-left: 5px;
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 22px;
  display: block;
`;
