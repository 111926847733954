import dayjs from 'dayjs';
import { PromocodeTypeEnum, User, UserPromocode } from 'shared/api';

export const checkCanUseFreeToken = (promocodes: UserPromocode[], expert?: User): boolean => {
  if (!expert?.hasFreeConsultations) {
    return false;
  }
  const canUseTokensArray: boolean[] = promocodes
    .filter(
      (p) => p.promocode.type === PromocodeTypeEnum.FreeConsultation && dayjs(p.promocode.expiresIn).diff(dayjs()) > 0,
    )
    .map((p) => {
      if (p.usedPrivileges?.usedFreeConsultations) {
        return p.usedPrivileges.usedFreeConsultations < p.promocode.privilege.freeConsultationsCount;
      }
      return true;
    });
  return canUseTokensArray.includes(true);
};

export const findFirstNotUsedPromocode = (userPromocodes: UserPromocode[]) =>
  userPromocodes
    .filter(
      (p) => p.promocode.type === PromocodeTypeEnum.FreeConsultation && dayjs(p.promocode.expiresIn).diff(dayjs()) > 0,
    )
    .find((p) => {
      if (p.usedPrivileges) {
        return p.promocode.privilege.freeConsultationsCount > p.usedPrivileges!.usedFreeConsultations!;
      }
      return true;
    });
