import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" {...props}>
      <defs>
        <linearGradient id="verification_mark_1" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#1FABED" />
          <stop offset="100%" stopColor="#1B6DED" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          fill="url(#verification_mark_1)"
          d="M23.288 6.239l-3.009-1.34a.43.43 0 01-.22-.22L18.72 1.671l-3.276.342a.445.445 0 01-.3-.08L12.48 0 9.813 1.932a.434.434 0 01-.3.081l-3.275-.342L4.9 4.679a.43.43 0 01-.22.22L1.67 6.24l.343 3.274a.444.444 0 01-.08.3L0 12.48l1.932 2.667a.427.427 0 01.08.3l-.341 3.274 3.008 1.339a.43.43 0 01.22.22l1.34 3.01 3.275-.343.043-.003c.091 0 .18.03.254.084l2.668 1.932 2.667-1.932a.433.433 0 01.3-.081l3.275.342 1.338-3.009a.437.437 0 01.22-.22l3.01-1.339-.343-3.274a.437.437 0 01.08-.3l1.933-2.667-1.932-2.666a.429.429 0 01-.08-.3l.341-3.274z"
        />
        <path
          fill="#FFF"
          d="M19.376 9.402l-8.988 8.59a.468.468 0 01-.642 0L5.892 14.31a.423.423 0 010-.614l1.285-1.224a.467.467 0 01.64 0l2.25 2.145 7.382-7.055a.468.468 0 01.642 0l1.285 1.228a.42.42 0 010 .613z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
