import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { CreateExpertServiceDto, ExpertService, UpdateExpertServiceDto } from 'shared/api';
import { ExtraParamsThunkType } from 'shared/common/types';

export const getManyExpertsServicesAsync = createAsyncThunk<
  ExpertService[],
  undefined,
  ExtraParamsThunkType<SerializedError>
>('services/getServices', async (_, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ExpertServicesApi.expertServicesControllerGetMany();
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const getManyServicesByExpertIdAsync = createAsyncThunk<
  ExpertService[],
  number,
  ExtraParamsThunkType<SerializedError>
>('services/getServices', async (id, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ExpertServicesApi.expertServicesControllerGetManyServicesByExpertId(id);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const getOneExpertsServiceAsync = createAsyncThunk<ExpertService, number, ExtraParamsThunkType<SerializedError>>(
  'services/getOneService',
  async (id, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1ExpertServicesApi.expertServicesControllerGetOne(id);
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const createExpertsServiceAsync = createAsyncThunk<
  ExpertService,
  CreateExpertServiceDto,
  ExtraParamsThunkType<SerializedError>
>('services/createOneService', async (dto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ExpertServicesApi.expertServicesControllerCreateOne(dto);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const updateExpertsServiceAsync = createAsyncThunk<
  ExpertService,
  { id: number; dto: UpdateExpertServiceDto },
  ExtraParamsThunkType<SerializedError>
>('services/updateOneService', async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ExpertServicesApi.expertServicesControllerUpdateOne(id, dto);
    return data;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const deleteExpertsServiceAsync = createAsyncThunk<number, number, ExtraParamsThunkType<SerializedError>>(
  'services/deleteOneService',
  async (id, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1ExpertServicesApi.expertServicesControllerRemoveOne(id);
      return data;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);
