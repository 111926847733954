import { createSelector } from '@reduxjs/toolkit';
import { ContentChangeRequestStatusEnum, VerificationRequestStatusEnum } from 'shared/api';

import { RootState } from '../../index';
import { ProfileState } from './slice';

export const selectProfileStore = (state: RootState): ProfileState => state.profile;

export const selectContentChangeRequest = (state: RootState) => {
  if (state.profile.contentChangeRequests.length === 0) {
    return;
  }

  return state.profile.contentChangeRequests.find((r) => r.status === ContentChangeRequestStatusEnum.Pending);
};

export const selectVerificationRequest = (state: RootState) =>
  state.profile.verificationRequests.find((r) => r.status === VerificationRequestStatusEnum.Pending);

export const selectUserCategoryMetaByCategoryId = (id: number) => (state: RootState) =>
  state.profile.user.categories?.find((category) => category.categoryId === id);

export const selectLastVerificationRequest = (state: RootState) => {
  if (state.profile.verificationRequests.length === 0) {
    return;
  }

  return state.profile.verificationRequests.slice(-1)[0];
};

export const selectUser = createSelector(selectProfileStore, (profile) => profile.user);

export const selectUserPortfolio = createSelector(selectUser, (user) => user.portfolio);

export const selectUserPortfolioUpdating = createSelector(
  selectProfileStore,
  (profile) => profile.updatePortfolioImagesLoading,
);

export const selectToken = createSelector(selectProfileStore, (profile) => profile.token);

export const selectIsAuthentication = createSelector(selectProfileStore, (profile) => !!profile.token);

export const selectNotificationSetting = createSelector(selectProfileStore, (profile) => profile.notificationsSetting);
export const selectUserPromocodes = createSelector(selectProfileStore, (profile) => profile.promocodes);
export const selectWallet = createSelector(selectProfileStore, (profile) => profile.wallet);
export const selectPayments = createSelector(selectProfileStore, (profile) => profile.payments);
export const selectPaymentMethods = createSelector(selectProfileStore, (profile) => profile.paymentMethods);
export const selectConsultationsWithoutReview = createSelector(
  selectProfileStore,
  (profile) => profile.consultationsWithoutReview,
);
export const selectIsNeedReview = createSelector(selectProfileStore, (profile) =>
  Boolean(
    profile.consultationsWithoutReview.length &&
      profile.consultationsWithoutReview[0].reviews.filter((review) => review.authorId === profile.user.id),
  ),
);
export const selectContract = createSelector(selectProfileStore, (profile) => profile.contract);
export const selectConsultations = createSelector(selectProfileStore, (profile) => profile.payedConsultations);
export const selectMyConsultations = createSelector(selectProfileStore, (profile) =>
  profile.payedConsultations.data?.filter((item) => item.clientId === profile.user.id),
);
export const selectGetManyBaseCustomRequest = createSelector(
  selectProfileStore,
  (profile) => profile.getManyBaseCustomRequest,
);
export const selectGetContractInfoRequest = createSelector(
  selectProfileStore,
  (profile) => profile.getContractInfoRequest,
);
export const selectGetExpertPromocodesRequest = createSelector(
  selectProfileStore,
  (profile) => profile.getExpertPromocodesRequest,
);

export const selectLastContentChangeRequest = createSelector(
  selectProfileStore,
  (profile) => profile.lastContentChangeRequest,
);
export const selectLastStep = createSelector(selectProfileStore, (profile) => profile.lastStep);
export const selectExpertPromocode = createSelector(selectProfileStore, (profile) => profile.expertPromocode);
export const selectHasAnyConsultations = createSelector(
  selectProfileStore,
  (profile) => profile.consultations?.total > 0,
);
export const selectIsShowHelloBar = createSelector(selectProfileStore, (profile) => profile.isShowHelloBar);
