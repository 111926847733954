import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

type AchievementProps = {
  label: string;
  icon: React.ReactNode;
};

export const Achievement: FC<AchievementProps> = ({ label, icon }) => (
  <Container>
    <Icon>{icon}</Icon>
    <Label>{label}</Label>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-right: 8px;
  background-color: var(--gray7);
  border-radius: 12px;
`;

const Icon = styled.div`
  width: 23px;
  height: 23px;
`;

const Label = styled.div`
  color: var(--purple);
  font-weight: 500;
  font-size: 11px;
  margin-left: 5px;
`;
