import * as React from 'react';

function SvgStopIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={14} height={14} rx={2} fill="#fff" />
    </svg>
  );
}

export default SvgStopIcon;
