import { RootState } from 'store';

export const selectAllMyConsultationRequests = (state: RootState) =>
  state.consultationRequests.userConsultationRequests;
export const selectAllClientRequests = (state: RootState) => state.consultationRequests.clientRequests;

export const selectResponseRoomById = (id: number | null) => (state: RootState) =>
  state.consultationRequests.userConsultationRequests.find((request) => request.id === id);
export const selectRequestRoomById = (id: number | null) => (state: RootState) =>
  state.consultationRequests.clientRequests.find((room) => room.categoryId === id);

export const selectCurrentRequestRoomId = (state: RootState) => state.consultationRequests.currentRequestRoom;
export const selectCurrentResponseRoomId = (state: RootState) => state.consultationRequests.currentResponseRoom;

export const selectActiveRequestMessageId = (state: RootState) => state.consultationRequests.activeRequestMessageId;

export const selectRequestUnreadCount = (state: RootState) => state.consultationRequests.requestUnreadCount;
export const selectResponseUnreadCount = (state: RootState) => state.consultationRequests.responseUnreadCount;
