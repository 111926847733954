import * as React from 'react';

function SvgClockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 0a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9zm2.964 13.236L8.1 9.373V3.6h1.8v5.027l3.336 3.337-1.272 1.272z"
        fill="#79818C"
      />
    </svg>
  );
}

export default SvgClockIcon;
