import * as React from 'react';

function SvgLargeAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M8.257 14.543l.261-1.35H7.15l.99-5.085H6.781L4.657 9.764l.783 1.062.45-.351c.138-.108.246-.222.324-.342h.018l-.108.495-.513 2.565H4.243L4 14.543h4.257zm2.767.108c.426 0 .813-.102 1.161-.306.348-.204.631-.463.85-.779.22-.315.404-.673.554-1.075.15-.402.255-.792.315-1.17.06-.378.09-.741.09-1.089 0-1.488-.711-2.232-2.133-2.232a2.33 2.33 0 00-1.764.778c-.21.237-.394.506-.553.806-.16.3-.284.618-.374.954a8.5 8.5 0 00-.203.967 6.23 6.23 0 00-.067.896c0 1.5.708 2.25 2.124 2.25zm.054-1.44c-.366 0-.549-.294-.549-.882 0-.258.027-.546.081-.864.054-.318.13-.631.23-.94.098-.31.233-.568.405-.774.17-.207.358-.311.562-.311.366 0 .549.294.549.882 0 .264-.027.555-.081.873-.054.318-.13.63-.23.936a2.278 2.278 0 01-.405.77c-.17.207-.358.31-.562.31zm5.224 1.44c.426 0 .813-.102 1.161-.306.348-.204.631-.463.85-.779.22-.315.404-.673.554-1.075.15-.402.255-.792.315-1.17.06-.378.09-.741.09-1.089 0-1.488-.711-2.232-2.133-2.232a2.325 2.325 0 00-1.764.778 4.07 4.07 0 00-.553.806c-.16.3-.284.618-.374.954a8.5 8.5 0 00-.203.967c-.044.31-.067.608-.067.896 0 1.5.708 2.25 2.124 2.25zm.054-1.44c-.366 0-.549-.294-.549-.882 0-.258.027-.546.081-.864.054-.318.13-.631.23-.94.099-.31.234-.568.405-.774.17-.207.358-.311.562-.311.366 0 .549.294.549.882 0 .264-.027.555-.081.873-.054.318-.13.63-.23.936a2.312 2.312 0 01-.404.77c-.172.207-.359.31-.563.31z"
        />
      </g>
    </svg>
  );
}

export default SvgLargeAchivment;
