import * as React from 'react';

function SvgPositiveAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M11.5 5C7.925 5 5 7.925 5 11.5S7.925 18 11.5 18s6.5-2.925 6.5-6.5S15.075 5 11.5 5zm2.275 3.9c.52 0 .975.455.975.975s-.455.975-.975.975-.975-.455-.975-.975.455-.975.975-.975zm-4.55 0c.52 0 .975.455.975.975s-.455.975-.975.975-.975-.455-.975-.975.455-.975.975-.975zm2.275 6.5c-1.69 0-3.12-1.105-3.64-2.6h7.28c-.52 1.495-1.95 2.6-3.64 2.6z"
        />
      </g>
    </svg>
  );
}

export default SvgPositiveAchivment;
