import * as React from 'react';

function Telegram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#000000">
          <path
            d="M0,9.6 C0,5.0744 0,2.812 1.4056,1.4056 C2.812,0 5.0744,0 9.6,0 L10.4,0 C14.9256,0 17.188,0 18.5944,1.4056 C20,2.812 20,5.0744 20,9.6 L20,10.4 C20,14.9256 20,17.188 18.5944,18.5944 C17.188,20 14.9256,20 10.4,20 L9.6,20 C5.0744,20 2.812,20 1.4056,18.5944 C0,17.188 0,14.9256 0,10.4 L0,9.6 Z M10.3624,7.3784 C9.3896,7.7832 7.4456,8.62 4.5304,9.8904 C4.0568,10.0784 3.8096,10.2632 3.7864,10.4424 C3.7488,10.7472 4.1296,10.8664 4.6488,11.0304 C4.7192,11.0528 4.7928,11.0752 4.8672,11.1 C5.3776,11.2656 6.0648,11.46 6.4216,11.468 C6.7456,11.4744 7.1072,11.3408 7.5064,11.0672 C10.2296,9.2288 11.636,8.2992 11.724,8.2792 C11.7864,8.2648 11.8728,8.2472 11.932,8.2992 C11.9896,8.3512 11.984,8.4496 11.9776,8.476 C11.94,8.6368 10.444,10.028 9.6696,10.748 C9.4288,10.972 9.2576,11.1312 9.2224,11.1672 C9.144,11.2488 9.064,11.3256 8.9872,11.3992 C8.5128,11.8568 8.1576,12.2 9.0072,12.76 C9.4152,13.0288 9.7424,13.2512 10.068,13.4736 C10.424,13.716 10.7792,13.9576 11.2384,14.2592 C11.356,14.336 11.4672,14.4152 11.576,14.4928 C11.9904,14.7888 12.3632,15.0536 12.8232,15.0112 C13.0904,14.9872 13.3664,14.736 13.5064,13.9864 C13.8376,12.2152 14.4888,8.3784 14.6392,6.7968 C14.6485251,6.66541331 14.6431586,6.5333963 14.6232,6.4032 C14.611205,6.29802854 14.5600865,6.2012175 14.48,6.132 C14.36,6.0352 14.176,6.0144 14.0928,6.01591658 C13.7168,6.0224 13.1392,6.224 10.3624,7.3784 L10.3624,7.3784 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}
export default Telegram;
