//import { ChangeEvent } from 'hoist-non-react-statics/node_modules/@types/react';
import React, { ForwardedRef, forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { PhoneInputProps } from 'react-phone-number-input';
import styled from 'styled-components';

import { FieldWrapper } from '../field-wrapper';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper';

type InputPhoneProps = PhoneInputProps & FieldWrapperProps;

export const InputPhone = forwardRef(
  (
    { label, error, successMessage, width, centered, required, onChange, disabled, name, value }: InputPhoneProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <FieldWrapper
      label={label}
      error={error}
      successMessage={successMessage}
      width={width}
      centered={centered}
      required={required}
    >
      <StyledPhone
        format="+ #############"
        placeholder="+79991234567"
        mask=" "
        name={name}
        value={value}
        getInputRef={ref}
        disabled={disabled || false}
        onChange={(e: any) => {
          e.persist();
          onChange(e.target.value);
        }}
      />
    </FieldWrapper>
  ),
);

const StyledPhone = styled(NumberFormat)`
  width: 100%;
  height: 50px;
  outline: none;
  border: 1px solid var(--gray3);
  border-radius: 12px;
  background-color: var(--white);
  padding: 0 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.67;
  color: var(--gray);

  :focus {
    color: var(--text);
  }

  &:disabled {
    background-color: #f9f9f9;
  }
`;
