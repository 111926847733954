import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';

interface ChatWidgetProps {
  title: string;
  description?: string;
  theme?: string;
  price?: number | string;
  duration?: string;
  actionBlock: React.ReactNode;
  currency?: string;
  variant?: 'me' | 'companion';
  expiresIn?: string;
}

export const ChatWidget: FC<ChatWidgetProps> = ({
  title,
  description,
  theme,
  price,
  duration,
  actionBlock,
  currency = '₽',
  variant = 'me',
  expiresIn,
}) => {
  const { t } = useTranslation('utils');

  return (
    <Container $variant={variant}>
      <BackgroundGradient>
        <Content>
          <Title>{title}</Title>
          {!!description && <Description>{description}</Description>}
          {!!theme && (
            <ConsultInfo>
              <Label>{t('chatWidget.theme')}</Label>
              <ConsultValue>{theme}</ConsultValue>
            </ConsultInfo>
          )}
          {!!price && (
            <ConsultInfo>
              <Label>{t('chatWidget.price')}</Label>
              <ConsultValue>
                {price}
                {typeof price === 'number' && currency}
              </ConsultValue>
            </ConsultInfo>
          )}
          {!!duration && (
            <ConsultInfo>
              <Label>{t('chatWidget.duration')}</Label>
              <ConsultValue>{duration}</ConsultValue>
            </ConsultInfo>
          )}
          <ActionBlock>{actionBlock}</ActionBlock>
          {!!expiresIn && <Expires>{expiresIn}</Expires>}
        </Content>
      </BackgroundGradient>
    </Container>
  );
};

const Container = styled.div<{ $variant: 'me' | 'companion' }>`
  display: flex;
  justify-content: ${({ $variant }) => ($variant === 'me' ? 'flex-end' : 'flex-start')};
`;
const BackgroundGradient = styled.div`
  width: 280px;
  border-radius: 10px;
  padding-top: 6px;
  margin-top: 15px;
  background-image: var(--gradientPrimary);
  ${CONDITION_DESKTOP} {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 15px;
  }
`;
const Content = styled.div`
  border-radius: 10px;
  padding: 16px;
  background-color: var(--gray10);
  ${CONDITION_DESKTOP} {
    background-color: var(--white);
    padding: 20px;
  }
`;
const Title = styled.h4`
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 4px;
  ${CONDITION_DESKTOP} {
    font-size: 16px;
    margin-bottom: 11px;
  }
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 18px;
  overflow-wrap: break-word;
  ${CONDITION_DESKTOP} {
    font-size: 13px;
    margin-bottom: 25px;
  }
`;
const ConsultInfo = styled.div`
  margin-bottom: 15px;
`;
const Label = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: var(--gray2);
`;
const ConsultValue = styled.p`
  font-size: 13px;
  line-height: 20px;
`;
const ActionBlock = styled.div`
  margin-top: 25px;
  ${CONDITION_DESKTOP} {
    margin-top: 30px;
  }
`;
const Expires = styled.p`
  color: var(--gray);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  ${CONDITION_DESKTOP} {
    font-size: 11px;
  }
`;
