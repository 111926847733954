import * as React from 'react';

function SvgScrollDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
      <g stroke="#FFF" strokeWidth={2} fill="none" fillRule="evenodd">
        <circle cx={20} cy={20} r={20} fill="#8C2DE4" />
        <path strokeLinecap="round" d="M13 17l7 7 7-7" />
      </g>
    </svg>
  );
}

export default SvgScrollDown;
