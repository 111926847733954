export const ellipsizeText = (text: string | undefined, count: number, isDots: boolean = true): string | undefined => {
  if (!text) {
    return undefined;
  }
  const dots = isDots ? '...' : '';
  return text.length > count ? `${text.slice(0, count)}${dots}` : text;
};

export const ellipsizeTextCenter = (text: string | undefined, count: number): string | undefined => {
  if (!text) {
    return undefined;
  }
  const dots = '...';
  return text.length > count ? `${text.slice(0, count - 5)}${dots}${text.slice(text.length - 5, text.length)}` : text;
};
