import * as React from 'react';

function SvgArrowRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={93} height={152} fill="none" viewBox="0 0 93 152" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={16.51}
        y={0.284}
        width={106.496}
        height={23}
        rx={10}
        transform="rotate(44.722 16.51 .284)"
        fill="#232832"
      />
      <rect y={134.725} width={106.496} height={23} rx={10} transform="rotate(-44.561 0 134.725)" fill="#232832" />
    </svg>
  );
}

export default SvgArrowRightIcon;
