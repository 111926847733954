import * as React from 'react';

function SvgFireAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M10.5 12.8c-1.3 1.3-1.3 3.9 0 5.2-2.263-.562-3.9-2.6-3.9-5.2 0-3.25 3.25-3.25 2.6-7.8 1.3.01 2.77 1.248 3.25 4.55.503-.47 1.396-1.964 1.3-2.6C16.033 8.581 17 10.2 17 12.8c0 2.6-1.626 4.589-3.9 5.2 1.3-1.3 1.3-2.6 0-3.9-.351 1.128-.784 1.564-1.3 1.3-.516-.259-.949-1.127-1.3-2.6"
        />
      </g>
    </svg>
  );
}

export default SvgFireAchivment;
