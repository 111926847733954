import { combineReducers } from '@reduxjs/toolkit';

import * as app from './ducks/app/slice';
import * as audioMessages from './ducks/audioMessages';
import * as channelNews from './ducks/channelNews';
import * as chatMessages from './ducks/chatMessages';
import * as chatRooms from './ducks/chatRooms';
import * as consultationRequests from './ducks/consultationRequests';
import * as consultations from './ducks/consultations';
import * as consultationsChatRoom from './ducks/consultationsChatRoom';
import * as services from './ducks/expert-services';
import * as profile from './ducks/profile';
import * as rubrics from './ducks/rubrics';
import * as bookingZoom from './ducks/bookingZoom';

export const actions = {
  app: app.actions,
  profile: profile.actions,
  chatRooms: chatRooms.actions,
  chatMessages: chatMessages.actions,
  consultationRequests: consultationRequests.actions,
  consultationsChatRoom: consultationsChatRoom.actions,
  audioMessages: audioMessages.actions,
  channelNews: channelNews.actions,
  rubrics: rubrics.actions,
  services: services.actions,
  consultations: consultations.actions,
  bookingZoom: bookingZoom.actions,
};

export const rootReducer = combineReducers({
  app: app.reducer,
  profile: profile.reducer,
  chatRooms: chatRooms.reducer,
  chatMessages: chatMessages.reducer,
  consultationRequests: consultationRequests.reducer,
  consultationsChatRoom: consultationsChatRoom.reducer,
  audioMessages: audioMessages.reducer,
  channelNews: channelNews.reducer,
  rubrics: rubrics.reducer,
  services: services.reducer,
  bookingZoom: bookingZoom.reducer,
});

export const selectors = {
  app: app.selectors,
  chatRooms: chatRooms.selectors,
  chatMessages: chatMessages.selectors,
  profile: profile.selectors,
  consultationRequests: consultationRequests.selectors,
  consultationsChatRoom: consultationsChatRoom.selectors,
  audioMessages: audioMessages.selectors,
  channelNews: channelNews.selectors,
  rubrics: rubrics.selectors,
  bookingZoom: bookingZoom.selectors,
};
