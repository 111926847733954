import * as React from 'react';

function SvgBurgerClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={25} height={20} {...props}>
      <defs>
        <path
          id="burgerClose_svg__a"
          d="M10.625 8.987l6.994-6.993a1.162 1.162 0 000-1.645 1.162 1.162 0 00-1.645 0L8.98 7.342 1.987.35A1.163 1.163 0 10.34 1.994l6.994 6.993L.34 15.981a1.162 1.162 0 00.823 1.986c.298 0 .596-.114.823-.34l6.993-6.994 6.994 6.993a1.164 1.164 0 001.645-1.645l-6.994-6.994z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 1)">
        <mask id="burgerClose_svg__b" fill="#fff">
          <use xlinkHref="#burgerClose_svg__a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#burgerClose_svg__a" />
        <path fill={props.fill ? props.fill : '#93969D'} d="M0 0h18v18H0z" mask="url(#burgerClose_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgBurgerClose;
