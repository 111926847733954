import * as React from 'react';

function SvgCheckPurple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={15}
      height={15}
      viewBox="0 0 15 15"
      {...props}
    >
      <defs>
        <path id="check-white_svg__a" d="M0 8.222l5.132 5.236 9.855-10.024-1.415-1.421-8.44 8.584-3.737-3.805z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="check-white_svg__b" fill="#fff">
          <use xlinkHref="#check-white_svg__a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#check-white_svg__a" />
        <path fill="#8B33FF" d="M0 0h15v15H0z" mask="url(#check-white_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgCheckPurple;
