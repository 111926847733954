import React, { FC } from 'react';
import styled from 'styled-components';

type ButtonFillableProps = {
  fill: number;
  leftIcon?: React.ReactNode;
};

export const ButtonFillable: FC<ButtonFillableProps> = ({ fill, leftIcon, children }) => (
    <ButtonContainer $fill={fill}>
      {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
      <ButtonText>{children}</ButtonText>
    </ButtonContainer>
  );

const IconContainer = styled.div`
  margin: 0 5px;
  display: flex;
  justify-content: center;
`;

const ButtonText = styled.span`
  font-size: 15;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0 5px;
  align-self: flex-end;
`;

const ButtonContainer = styled.button<{ $fill: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px;
  color: var(--white);
  background-image: ${({ $fill }) => `linear-gradient(to right, var(--red3) ${$fill}%, var(--red) ${$fill}%)`};
  box-shadow: 0 0 7.5px 0 rgba(255, 51, 102, 0.5);
  border-radius: 28px;
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
`;
