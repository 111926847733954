import * as React from 'react';

function SvgChatStatusPendingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
      <g fill="#DABFFF" fillRule="nonzero">
        <path d="M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zm0 11.124A5.13 5.13 0 01.876 6 5.13 5.13 0 016 .876 5.13 5.13 0 0111.124 6 5.13 5.13 0 016 11.124z" />
        <path d="M8.044 6H6.146V3.372a.438.438 0 10-.876 0v3.066c0 .242.196.438.438.438h2.336a.438.438 0 100-.876z" />
      </g>
    </svg>
  );
}

export default SvgChatStatusPendingIcon;
