import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TFunction } from 'next-i18next';

import { chooseEndingWord } from './chooseEndingWords';
import { ellipsizeText } from './ellipsizeText';

require('dayjs/locale/ru');
require('dayjs/locale/en');

const UsedLocales = {
  ru: 'ru',
  en: 'en',
};

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export const diffTimeFromNow = (date: string | undefined, locale: string): string | undefined => {
  const dayjsLocale = Object.prototype.hasOwnProperty.call(UsedLocales, locale) ? locale : UsedLocales.en;
  return dayjs(date).locale(dayjsLocale).fromNow();
};

export const makeLocaleDate = (date: string | undefined, locale: string) => {
  const dayjsLocale = Object.prototype.hasOwnProperty.call(UsedLocales, locale) ? locale : UsedLocales.en;
  return dayjs(date).locale(dayjsLocale);
};

export const makeMessageTime = (date: string | undefined, locale: string): string | undefined => {
  const dayjsLocale = Object.prototype.hasOwnProperty.call(UsedLocales, locale) ? locale : UsedLocales.en;
  return dayjs(date).startOf('day').diff(dayjs().startOf('day')) === 0
    ? dayjs(date).format('HH:mm')
    : ellipsizeText(dayjs(date).locale(dayjsLocale).format('DD MMMM'), 6, false);
};

export const getEventDateFromString = (date: string, locale: string) => {
  const dayjsLocale = UsedLocales.hasOwnProperty(locale) ? locale : UsedLocales.en;
  return dayjs(date).locale(dayjsLocale).format('D MMMM YYYY');
};

export const getDigitsEventDateFromString = (date: string, locale: string) => {
  const dayjsLocale = Object.prototype.hasOwnProperty.call(UsedLocales, locale) ? locale : UsedLocales.en;
  return dayjs(date).locale(dayjsLocale).format('DD.MM.YYYY');
};

export function getClearTime(date: Date) {
  return date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
}

export const makeLastExitTimeStatus = ({ date, t }: { date: string | undefined | null; t: TFunction }): string => {
  const diffHours = dayjs().diff(dayjs(date), 'hour');
  if (diffHours >= 1) {
    if (diffHours >= 24) {
      return t('lastExitTimeStatus.moreOneDay');
    }
    return chooseEndingWord(diffHours, [
      t('lastExitTimeStatus.less24Hours.one', { count: diffHours }),
      t('lastExitTimeStatus.less24Hours.two', { count: diffHours }),
      t('lastExitTimeStatus.less24Hours.many', { count: diffHours }),
    ]);
  }
  const diffMinutes = dayjs().diff(dayjs(date), 'm');
  if (diffMinutes >= 1) {
    return chooseEndingWord(diffMinutes, [
      t('lastExitTimeStatus.lessOneHour.one', { count: diffMinutes }),
      t('lastExitTimeStatus.lessOneHour.two', { count: diffMinutes }),
      t('lastExitTimeStatus.lessOneHour.many', { count: diffMinutes }),
    ]);
  }
  return t('lastExitTimeStatus.justNow');
};

export const formattingAudioTime = (value: number): string => {
  const hours = Math.floor(value / 60 / 60);
  const minutes = Math.floor(value / 60) - hours * 60;
  const seconds = Math.floor(value % 60);

  const formatted = [minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')];
  if (hours > 0) {
    formatted.unshift(hours.toString().padStart(2, '0'));
  }

  return formatted.join(':');
};

export const getEventDateTimeFromString = (date: string, locale: string) => {
  const dayjsLocale = Object.prototype.hasOwnProperty.call(UsedLocales, locale) ? locale : UsedLocales.en;
  return dayjs(date).locale(dayjsLocale).format('DD MMMM, HH:mm');
};

export const getEventDateAndMonthFromString = (date: string | undefined, locale: string) => {
  if (!date) {
    return date;
  }

  const dayjsLocale = Object.prototype.hasOwnProperty.call(UsedLocales, locale) ? locale : UsedLocales.en;
  return dayjs(date).locale(dayjsLocale).format('DD MMM');
};

export const getDiffenceBetweenTwoDatesInHours = (date1: Date, date2: Date) =>
  Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600);

export const getDiffenceBetweenTwoDatesInDays = (date1: Date, date2: Date) =>
  Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
