import * as React from 'react';

function SvgLikeSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={15} height={15} {...props}>
      <defs>
        <path
          id="likeSmallIcon_svg__a"
          d="M8.133.254c.942 0 1.957.865 1.957 2.766 0 .648-.111 1.191-.243 1.605h2.329A2.827 2.827 0 0115 7.449c0 .038-.145 2.978-.715 4.176l.052.008C14.117 12.983 12.89 14 11.484 14h-.89c-2.494 0-4.175-.49-5.18-.929A1.75 1.75 0 013.867 14H2.695a1.76 1.76 0 01-1.757-1.758v-5.86a1.76 1.76 0 011.757-1.757h1.172c.391 0 .753.128 1.045.345.614-.419 1.815-1.38 1.815-2.49v-.187c.009-.767.112-2.04 1.406-2.04zM3.867 5.797H2.695a.586.586 0 00-.586.586v5.86c0 .323.263.585.586.585h1.172a.586.586 0 00.586-.586v-5.86a.585.585 0 00-.586-.585zm4.266-4.371c-.11.015-.235.181-.235 1.054 0 1.64-1.473 2.88-2.331 3.462.038.14.058.288.058.44v5.495c.762.371 2.37.951 4.969.951h.89c.824 0 1.568-.608 1.695-1.384a.47.47 0 01.012-.054c.07-.342.622-3.627.637-3.951 0-.9-.742-1.642-1.652-1.642H8.953a.587.587 0 01-.487-.913c.002-.004.452-.709.452-1.864 0-1.17-.47-1.594-.785-1.594z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="likeSmallIcon_svg__b" fill="#fff">
          <use xlinkHref="#likeSmallIcon_svg__a" />
        </mask>
        <use fill="#24AF1E" fillRule="nonzero" xlinkHref="#likeSmallIcon_svg__a" />
        <path fill="#24AF1E" d="M0 0h15v15H0z" mask="url(#likeSmallIcon_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgLikeSmallIcon;
