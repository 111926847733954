import * as React from 'react';

function SvgDefaultAvatar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 31 31" {...props}>
      <path
        fill="#8B33FF"
        d="M15.3 0C6.863 0 0 6.863 0 15.3c0 8.437 6.863 15.3 15.3 15.3 8.437 0 15.3-6.863 15.3-15.3C30.6 6.863 23.737 0 15.3 0zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 016.451-4.4A6.506 6.506 0 018.8 13.3c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.512 6.512 0 01-3.019 5.491 12.424 12.424 0 016.452 4.4C22.905 26.116 19.321 28 15.3 28z"
      />
    </svg>
  );
}

export default SvgDefaultAvatar;
