import * as React from 'react';

function SvgChatStatusSendIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
      <path
        d="M11.69 2.094a.407.407 0 00-.576.056L5.661 8.77 2.7 5.81a.41.41 0 00-.58.58L5.4 9.67a.41.41 0 00.29.12h.02a.41.41 0 00.297-.15l5.74-6.97a.408.408 0 00-.057-.575z"
        fill="#DABFFF"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgChatStatusSendIcon;
