import * as React from 'react';

function SvgTargetAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M11.296 18a6.294 6.294 0 005.558-9.251 1.03 1.03 0 01-.156.01c-.03 0-.06 0-.091-.002l-.8-.062-.577.576a4.627 4.627 0 11-1.498-1.497l.521-.522-.07-.918a1.144 1.144 0 01.003-.219 6.293 6.293 0 00-9.183 5.592A6.293 6.293 0 0011.296 18z" />
          <path d="M11.296 10.08c.041 0 .082.002.124.005l1.162-1.162.025-.025a3.099 3.099 0 101.497 1.499l-.025.024-1.163 1.162a1.626 1.626 0 11-1.62-1.503z" />
          <path d="M17.782 6.095l.373-.373a.436.436 0 000-.616l-.258-.26a.432.432 0 00-.614 0l-.428.428-.088-1.139A.145.145 0 0016.62 4a.142.142 0 00-.103.043l-1.682 1.682a.733.733 0 00-.213.576l.004.066.081 1.053-.608.608-1.094 1.095-.025.025-1.06 1.059-.47.47a.361.361 0 00-.105.23l-.03.37a.367.367 0 00.366.396h.02l.388-.02a.356.356 0 00.241-.107l.466-.465 1.06-1.06.024-.024 1.095-1.095.66-.66.961.073.043.003.056.001a.74.74 0 00.52-.214l1.68-1.681a.146.146 0 00-.092-.25l-1.021-.08z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgTargetAchivment;
