import React, { FC, HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  size?: string;
  color?: string;
}

export const Spinner: FC<SpinnerProps> = ({ size = '20px', color = 'var(--purple)', ...props }) => (
  <SpinnerContainer {...props} $size={size}>
    <SpinnerBorder $size={size} $color={color} />
  </SpinnerContainer>
);

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;
const SpinnerContainer = styled.div<{ $size: string }>`
  width: 100%;
  height: ${({ $size }) => `calc(1.45 * ${$size})`};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SpinnerBorder = styled.div<{ $size: string; $color: string }>`
  border: ${({ $color }) => `3px solid ${$color}`};
  border-right-color: transparent;
  border-radius: 50%;
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  animation: ${rotate} 0.75s linear infinite;
`;
