import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  AcceptConsultationOfferDto,
  AcceptPromocodeConsultationOfferDto,
  BookingConsultationDto,
  Consultation,
  ConsultationInvoice,
  CreateConsultationProlongationInvoiceDto,
  CreateConsultationThroughExpertServiceDto,
  CreateConsultationThroughUserCategoryMetaDto,
  CreateConsultationThroughUserCategoryMetaWithPromocodeDto,
  CreateExtraServiceDto,
  ExtraService,
} from 'shared/api';
import { ExtraParamsThunkType } from 'shared/common/types';

export const createExtraServiceAsync = createAsyncThunk<
  ExtraService,
  { id: number; dto: CreateExtraServiceDto },
  ExtraParamsThunkType<SerializedError>
>('consultations/create-one', async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.extraServicesControllerCreateOne(id, dto);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const acceptConsultationOfferAsync = createAsyncThunk<
  Consultation,
  { id: number; dto: AcceptConsultationOfferDto },
  ExtraParamsThunkType<SerializedError>
>('consultations/accept-consultation-offer', async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationOffersApi.consultationOffersControllerAcceptOne(id, dto);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const acceptConsultationOfferWithPromocodeAsync = createAsyncThunk<
  Consultation,
  { id: number; dto: AcceptPromocodeConsultationOfferDto },
  ExtraParamsThunkType<SerializedError>
>(
  'consultations/accept-consultation-offer-with-promocode',
  async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1ConsultationOffersApi.consultationOffersControllerAcceptOneWithPromocode(id, dto);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createConsultationFromExpertServicesAsync = createAsyncThunk<
  Consultation,
  CreateConsultationThroughExpertServiceDto,
  ExtraParamsThunkType<SerializedError>
>('consultations/create-consultation-from-expert-services', async (dto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerCreateOneFromExpertServices(dto);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createConsultationFromUserMetaWithPromocodeAsync = createAsyncThunk<
  Consultation,
  { dto: CreateConsultationThroughUserCategoryMetaWithPromocodeDto; options: any },
  ExtraParamsThunkType<SerializedError>
>(
  'consultations/create-consultation-from-user-meta-with-promocode',
  async ({ dto, options }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1ConsultationsApi.consultationsControllerCreateOneFromUserMetaWithPromocode(
        dto,
        options,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createConsultationFromUserMetaAsync = createAsyncThunk<
  Consultation,
  { dto: CreateConsultationThroughUserCategoryMetaDto; options: any },
  ExtraParamsThunkType<SerializedError>
>('consultations/create-consultation-from-user-meta', async ({ dto, options }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerCreateOneFromUserCategoryMeta(dto, options);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createBookingConsultationFromUserMetaAsync = createAsyncThunk<
  Consultation,
  { dto: BookingConsultationDto; options: any },
  ExtraParamsThunkType<SerializedError>
>(
  'consultations/create-booking-consultation-from-user-meta',
  async ({ dto, options }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.V1ConsultationsApi.consultationsControllerCreateOneBookingConsultation(dto, options);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const startConsultationAsync = createAsyncThunk<
  Consultation,
  { id: number; options: any },
  ExtraParamsThunkType<SerializedError>
>('consultations/start-consultation', async ({ id, options }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerStartConsultation(id, options);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getConsultationById = createAsyncThunk<
  Consultation,
  { id: number },
  ExtraParamsThunkType<SerializedError>
>('consultations/get-consultation', async ({ id }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerGetById(id);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const rejectConsultationAsync = createAsyncThunk<
  boolean,
  { consultationId: number; id: number },
  ExtraParamsThunkType<SerializedError>
>('consultations/reject-consultation', async ({ consultationId, id }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.extraServicesControllerRejectOne(consultationId, id);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createConsultationProlongationRequestAsync = createAsyncThunk<
  ConsultationInvoice,
  { id: number; dto: CreateConsultationProlongationInvoiceDto },
  ExtraParamsThunkType<SerializedError>
>('consultations/create-prolongation-request', async ({ id, dto }, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.V1ConsultationsApi.consultationsControllerCreateProlongationRequest(id, dto);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
