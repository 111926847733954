import React, { ForwardedRef, forwardRef,TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextareaModalProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

export const TextareaModal = forwardRef(
  ({ error, ...props }: TextareaModalProps, ref: ForwardedRef<HTMLTextAreaElement>) => (
      <>
        <StyledTextarea {...props} ref={ref} />
        {error && <ErrorText>{error}</ErrorText>}
      </>
    ),
);

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 125px;
  outline: none;
  border: 1px solid var(--gray3);
  border-radius: 12px;
  background-color: var(--white);
  padding: 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.33;
  resize: none;

  &::placeholder {
    color: var(--gray);
  }

  &:disabled {
    background-color: #f9f9f9;
  }
`;
const ErrorText = styled.span`
  margin: 10px 0;
  font-weight: 500;
  font-size: 13px;
  color: var(--gray);
  min-height: 14px;
  color: var(--red);
`;
