import * as React from 'react';

function GoBackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.7378 6.33063L7.17893 10.7717C7.28165 10.8745 7.41877 10.9311 7.56498 10.9311C7.71118 10.9311 7.8483 10.8745 7.95102 10.7717L8.27808 10.4447C8.4909 10.2316 8.4909 9.88535 8.27808 9.67262L4.54875 5.94328L8.28222 2.20982C8.38494 2.10702 8.44165 1.96998 8.44165 1.82385C8.44165 1.67756 8.38494 1.54053 8.28222 1.43764L7.95516 1.11075C7.85236 1.00795 7.71532 0.951317 7.56911 0.951317C7.42291 0.951317 7.28579 1.00795 7.18307 1.11075L2.7378 5.55586C2.63484 5.65898 2.57828 5.79667 2.57861 5.94304C2.57828 6.08998 2.63484 6.22758 2.7378 6.33063Z"
        fill="black"
      />
      <mask id="mask0_62_718" maskUnits="userSpaceOnUse" x="2" y="0" width="7" height="11">
        <path
          d="M2.7378 6.33063L7.17893 10.7717C7.28165 10.8745 7.41877 10.9311 7.56498 10.9311C7.71118 10.9311 7.8483 10.8745 7.95102 10.7717L8.27808 10.4447C8.4909 10.2316 8.4909 9.88535 8.27808 9.67262L4.54875 5.94328L8.28222 2.20982C8.38494 2.10702 8.44165 1.96998 8.44165 1.82385C8.44165 1.67756 8.38494 1.54053 8.28222 1.43764L7.95516 1.11075C7.85236 1.00795 7.71532 0.951317 7.56911 0.951317C7.42291 0.951317 7.28579 1.00795 7.18307 1.11075L2.7378 5.55586C2.63484 5.65898 2.57828 5.79667 2.57861 5.94304C2.57828 6.08998 2.63484 6.22758 2.7378 6.33063Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_62_718)">
        <rect x="10.5" y="1" width="10" height="10" transform="rotate(90 10.5 1)" fill="#79818C" />
      </g>
    </svg>
  );
}

export default GoBackIcon;
