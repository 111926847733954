import * as React from 'react';

function SvgRatingStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={30} {...props}>
      <path
        d="M15.999 23.617l-9.886 5.549 2.612-10.47L0 11.656l11.504-.923L15.999.834l4.497 9.901L32 11.656l-8.725 7.04 2.612 10.47z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgRatingStar;
