import React, { FC, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

const DEFAULT_MAX_COUNT = 1000;

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  label: string;
  max?: number;
  $width?: string;
  isShowCounterAndLabel?: boolean;
}

export const Textarea: FC<TextareaProps> = ({ error, label, max, $width, isShowCounterAndLabel = true, ...props }) => (
    <Container $width={$width}>
      <HeadRow>
        {isShowCounterAndLabel && <Label>{label}</Label>}
        {isShowCounterAndLabel && (
          <Counter>
            {props.value?.toString().length} / {max || DEFAULT_MAX_COUNT}
          </Counter>
        )}
      </HeadRow>
      <StyledTextarea {...props} />
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );

const Container = styled.div<{ $width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => ($width || '100%')};
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -7px;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 14px;
`;

const Counter = styled.div`
  font-size: 15px;
  color: var(--gray9);
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 146px;
  outline: none;
  border: 1px solid var(--gray3);
  border-radius: 5px;
  background-color: var(--white);
  padding: 15px;
  font-size: 15px;
  line-height: 1.33;
  resize: none;
  color: var(--text);

  &:focus {
    color: var(--text);
  }

  &::placeholder {
    color: var(--gray);
  }

  &:disabled {
    background-color: #f9f9f9;
  }
`;

const ErrorText = styled.span`
  margin: 10px 0;
  font-weight: 500;
  font-size: 13px;
  color: var(--gray);
  min-height: 14px;
  color: var(--red);
`;
