import * as React from 'react';

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#EE8835" transform="translate(0 .528)">
          <path d="M7.49921053 11.3913158L2.865 14.1663158 4.08947368 8.93131579 0 5.41105263 5.39210526 4.95 7.49921053 -3.50596745e-16 9.60789474 4.95078947 15 5.41105263 10.9105263 8.93131579 12.135 14.1663158z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgStar;
