import * as React from 'react';

function SvgBackArrowPurple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={21} {...props}>
      <path
        d="M0 10.395c0-.41.152-.762.48-1.079L9.598.398C9.855.141 10.184 0 10.57 0c.774 0 1.395.61 1.395 1.395 0 .375-.164.726-.422.996l-8.215 8.004 8.215 8.003c.258.27.422.61.422.997 0 .785-.621 1.394-1.395 1.394-.386 0-.715-.14-.972-.398L.48 11.46c-.328-.305-.48-.656-.48-1.066z"
        fill="#8B33FF"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgBackArrowPurple;
