import * as React from 'react';

function SvgDislike(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
      <g fill="#F36" fillRule="nonzero">
        <path d="M4.125 2.067h-1.25C1.841 2.067 1 2.907 1 3.942v6.25c0 1.033.841 1.875 1.875 1.875h1.25A1.878 1.878 0 006 10.192v-6.25a1.877 1.877 0 00-1.875-1.875zm-1.25 8.75a.625.625 0 01-.625-.625v-6.25c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625v6.25c0 .345-.28.625-.625.625h-1.25z" />
        <path d="M12.25 2.067h-.95c-3.376 0-5.356.842-6.235 1.345a.624.624 0 10.62 1.085c.621-.355 2.413-1.18 5.615-1.18h.95c.879 0 1.672.648 1.807 1.476l.013.057c.075.365.664 3.87.68 4.215 0 .96-.791 1.752-1.762 1.752H9.55a.624.624 0 00-.519.973c.002.004.481.757.481 1.99 0 1.247-.5 1.7-.837 1.7-.118-.017-.25-.195-.25-1.126 0-1.936-1.928-3.35-2.756-3.867a.625.625 0 10-.663 1.06c.509.317 2.169 1.46 2.169 2.807 0 .771 0 2.375 1.5 2.375 1.005 0 2.087-.922 2.087-2.95 0-.691-.118-1.271-.258-1.712h2.484A3.017 3.017 0 0016 9.054c0-.04-.155-3.176-.762-4.454l.055-.008c-.236-1.44-1.543-2.525-3.043-2.525z" />
      </g>
    </svg>
  );
}

export default SvgDislike;
