import Cross from 'app/assets/svg/Cross';
import dynamic from 'next/dynamic';
import React, { FC, useEffect } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';

const Portal = dynamic(() => import('app/hocs/Portal'), { ssr: false });

interface ModalProps {
  isVisible: boolean;
  onClose?: () => void;
  width?: string;
  $isMovedToRight?: boolean;
}

export const Modal: FC<ModalProps> = ({ isVisible, onClose, width = '300px', children, $isMovedToRight }) => {
  if (!isVisible) {
    return null;
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape' && onClose) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <Container>
      {!!onClose && (
        <CrossContainer onClick={onClose} $width={width}>
          <Cross />
        </CrossContainer>
      )}
      <Wrapper $width={width} $isMovedToRight={$isMovedToRight}>
        {children}
      </Wrapper>
    </Container>
  );
};

const Container = styled(Portal)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Wrapper = styled.div<{ $width: string; $isMovedToRight: boolean | undefined }>`
  position: relative;
  top: 50px;
  left: ${({ $isMovedToRight }) => ($isMovedToRight ? '66%' : '50%')};
  padding: 30px 20px 40px;
  background-color: var(--white);
  transform: translateX(-50%);
  border-radius: 8px;
  width: ${({ $width }) => $width};
  max-height: calc(100% - 110px);

  ${CONDITION_DESKTOP} {
    top: 100px;
  }

  & > button {
    margin-top: 15px;
    width: 100%;
    margin-bottom: 30px;
  }
`;
const CrossContainer = styled.div<{ $width: string }>`
  position: absolute;
  width: 50px;
  right: ${({ $width }) => `calc(50% - ${parseInt($width, 10) / 2}px - 15px)`};
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;

  :hover {
    cursor: pointer;
    background-color: #8b33ffc9;
  }
  ${CONDITION_DESKTOP} {
    top: 50px;
  }
`;
