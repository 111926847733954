import React, { FC } from 'react';
import { coverImageStyle } from 'shared/helpers';
import styled from 'styled-components';

interface ChatToastProps {
  icon?: string;
  title: string;
  content: string;
}

export const ChatToast: FC<ChatToastProps> = ({ icon, title, content }) => (
  <Container>
    <Icon $url={icon} />
    <Text>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Text>
  </Container>
);

const Container = styled.div`
  display: flex;
  width: 260px;
  height: 70px;
  align-items: center;
`;
const Icon = styled.div<{ $url?: string }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: var(--gray2);
  ${({ $url }) => $url && { ...coverImageStyle($url) }}
`;
const Text = styled.div`
  width: 200px;
`;
const Title = styled.p`
  font-weight: bold;
  color: var(--text);
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-align: left;
`;
const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 1.33;
  color: var(--gray);
  font-size: 13px;
  text-align: left;
`;
