import * as React from 'react';

function SvgArrowRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={7} height={7} {...props}>
      <defs>
        <path
          id="arrowRightIcon_svg__a"
          d="M4 3.22L.89.112a.38.38 0 00-.54 0L.121.34a.382.382 0 000 .54l2.61 2.611L.119 6.105a.383.383 0 000 .54l.23.23c.071.071.167.11.27.11a.382.382 0 00.27-.11L4 3.763a.383.383 0 000-.543z"
        />
      </defs>
      <g transform="translate(1.4)" fill="none" fillRule="evenodd" opacity={0.5}>
        <mask id="arrowRightIcon_svg__b" fill="#fff">
          <use xlinkHref="#arrowRightIcon_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#arrowRightIcon_svg__a" />
        <path fill="#FFF" d="M-1.4 0h7v7h-7z" mask="url(#arrowRightIcon_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgArrowRightIcon;
