import { ArrowLeft } from 'app/assets/svg';
import { FC } from 'react';
import styled from 'styled-components';
import getMonth from 'date-fns/getMonth';

type HeaderProps = {
  monthDate: Date;
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  isMinDate?: boolean;
};

export const Header: FC<HeaderProps> = ({
  decreaseMonth,
  prevMonthButtonDisabled,
  increaseMonth,
  nextMonthButtonDisabled,
  date,
}) => {
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  const currentYear = date.getFullYear();

  return (
    <Root>
      <Button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        $isDisabled={prevMonthButtonDisabled}
      >
        <ArrowLeft />
      </Button>
      <HeaderContainer>
        <p>{months[getMonth(date)]}</p>
        <p>{currentYear}</p>
      </HeaderContainer>
      <Button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        $isDisabled={nextMonthButtonDisabled}
      >
        <ArrowRight />
      </Button>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled.button<{ $isDisabled: boolean }>`
  display: flex;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

const HeaderContainer = styled.div`
  display: flex;
  margin: 0 12px;

  p {
    :not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;
