import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeOptions as NotificationType } from 'react-toastify';

import type { RootState } from '../../index';
import {
  checkEmailIsUniqAsync,
  confirmVerificationEmailAsync,
  getInfoAboutServiceAsync,
  signFileUrlAsync,
} from './actions';

interface Notification {
  message: string;
  type?: NotificationType;
}

interface FreeChatInfo {
  isFreeChatRedirect: boolean;
  isFreeChatAsk: boolean;
}
interface FreeThemeChatInfo {
  isFreeThemeRedirect: boolean;
  isSignedUp: boolean;
}

interface AuthModal {
  isWait: boolean;
  counter: number;
  isVisible: boolean;
  isRedirect: boolean;
}

interface JustModal {
  isVisible: boolean;
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    notification: {
      message: '',
    } as Notification,
    freeChat: {
      isFreeChatRedirect: false,
      isFreeChatAsk: false,
    } as FreeChatInfo,
    freeThemeChat: {
      isFreeThemeRedirect: false,
      isSignedUp: false,
    } as FreeThemeChatInfo,
    authModal: {
      isWait: false,
      counter: 60,
      isVisible: false,
      isRedirect: false,
    } as AuthModal,
    thanksModal: {
      isVisible: false,
    } as JustModal,
    reviewMobileModal: {
      isVisible: false,
    } as JustModal,
    complainModal: {
      isVisible: false,
    } as JustModal,
    prolongationModal: {
      isVisible: false,
    } as JustModal,
    extraServiceModal: {
      isVisible: false,
    } as JustModal,
    permanentReviewModal: {
      isVisible: false,
    } as JustModal,
    defaultThanksModal: {
      isVisible: false,
    } as JustModal,
    categoryModal: {
      isVisible: false,
    } as JustModal,
    warningModal: {
      isVisible: false,
    } as JustModal,
    freeAskModal: {
      isVisible: false,
    } as JustModal,
    npspollModal: {
      isVisible: false,
    } as JustModal,
    paymentModal: {
      isVisible: false,
    } as JustModal,
    servicePaymentModal: {
      isVisible: false,
    } as JustModal,
  },
  reducers: {
    showNotification(state, action: PayloadAction<Notification>) {
      state.notification = action.payload;
    },
    showAuthModal: {
      prepare: (value = false) => ({
        payload: value,
      }),
      reducer: (state, action: PayloadAction<boolean>) => {
        if (action.payload) {
          state.authModal.isRedirect = action.payload;
        }
        state.authModal.isVisible = true;
      },
    },
    hideAuthModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.authModal.isVisible = false;
      },
    },
    setWait: {
      prepare: (value: boolean) => ({
        payload: value,
      }),
      reducer: (state, action: PayloadAction<boolean>) => {
        state.authModal.isWait = action.payload;
      },
    },
    setCounter: {
      prepare: (value: number) => ({
        payload: value,
      }),
      reducer: (state, action: PayloadAction<number>) => {
        state.authModal.counter = action.payload;
      },
    },
    showNpsPollModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.npspollModal.isVisible = true;
      },
    },
    hideNpsPollModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.npspollModal.isVisible = false;
      },
    },
    showPaymentModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.paymentModal.isVisible = true;
      },
    },
    showServicePaymentModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.servicePaymentModal.isVisible = true;
      },
    },
    hidePaymentModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.paymentModal.isVisible = false;
      },
    },
    hideServicePaymentModal: {
      prepare: () => ({
        payload: {},
      }),
      reducer: (state) => {
        state.servicePaymentModal.isVisible = false;
      },
    },
    setIsVisibleThanksModal(state, { payload }: PayloadAction<boolean>) {
      state.thanksModal.isVisible = payload;
    },
    setIsVisibleReviewMobileModal(state, { payload }: PayloadAction<boolean>) {
      state.reviewMobileModal.isVisible = payload;
    },
    setIsVisibleComplainModal(state, { payload }: PayloadAction<boolean>) {
      state.complainModal.isVisible = payload;
    },
    setIsVisibleProlongationModal(state, { payload }: PayloadAction<boolean>) {
      state.prolongationModal.isVisible = payload;
    },
    setIsVisibleExtraServiceModal(state, { payload }: PayloadAction<boolean>) {
      state.extraServiceModal.isVisible = payload;
    },
    setIsVisiblePermanentReviewModal(state, { payload }: PayloadAction<boolean>) {
      state.permanentReviewModal.isVisible = payload;
    },
    setIsVisibleDefaultThanksModal(state, { payload }: PayloadAction<boolean>) {
      state.defaultThanksModal.isVisible = payload;
    },
    setIsVisibleCategoryModal(state, { payload }: PayloadAction<boolean>) {
      state.categoryModal.isVisible = payload;
    },
    setIsVisibleWarningModal(state, { payload }: PayloadAction<boolean>) {
      state.warningModal.isVisible = payload;
    },
    setIsVisibleFreeAskModal(state, { payload }: PayloadAction<boolean>) {
      state.freeAskModal.isVisible = payload;
    },
    setIsVisiblePollModal(state, { payload }: PayloadAction<boolean>) {
      state.npspollModal.isVisible = payload;
    },
    setIsVisiblePaymentModal(state, { payload }: PayloadAction<boolean>) {
      state.paymentModal.isVisible = payload;
    },
    setIsVisibleServicePaymentModal(state, { payload }: PayloadAction<boolean>) {
      state.servicePaymentModal.isVisible = payload;
    },
    setFreeChatRedirectInfo(state, { payload }: PayloadAction<boolean>) {
      state.freeChat.isFreeChatRedirect = payload;
    },
    setFreeThemeChatRedirectInfo(state, { payload }: PayloadAction<Partial<FreeThemeChatInfo>>) {
      state.freeThemeChat.isFreeThemeRedirect =
        typeof payload.isFreeThemeRedirect === 'undefined'
          ? state.freeThemeChat.isFreeThemeRedirect
          : payload.isFreeThemeRedirect;
      state.freeThemeChat.isSignedUp =
        typeof payload.isSignedUp === 'undefined' ? state.freeThemeChat.isSignedUp : payload.isSignedUp;
    },

    setFreeChatAskInfo(state, { payload }: PayloadAction<boolean>) {
      state.freeChat.isFreeChatAsk = payload;
    },
  },
});

export const { reducer } = appSlice;
export const actions = {
  ...appSlice.actions,
  signFileUrlAsync,
  checkEmailIsUniqAsync,
  confirmVerificationEmailAsync,
  getInfoAboutServiceAsync,
};

export const selectors = {
  selectNotification: (state: RootState) => state.app.notification,
  selectAuthModalIsVisible: (state: RootState) => state.app.authModal.isVisible,
  selectAuthModalIsWait: (state: RootState) => state.app.authModal.isWait,
  selectAuthModalCounter: (state: RootState) => state.app.authModal.counter,
  selectIsVisibleThanksModal: (state: RootState) => state.app.thanksModal.isVisible,
  setIsVisiblePollModal: (state: RootState) => state.app.npspollModal.isVisible,
  selectIsVisibleReviewMobileModal: (state: RootState) => state.app.reviewMobileModal.isVisible,
  selectIsVisibleComplainModal: (state: RootState) => state.app.complainModal.isVisible,
  selectIsVisibleProlongationModal: (state: RootState) => state.app.prolongationModal.isVisible,
  selectIsVisibleExtraServiceModal: (state: RootState) => state.app.extraServiceModal.isVisible,
  selectIsVisiblePermanentReviewModal: (state: RootState) => state.app.permanentReviewModal.isVisible,
  selectIsVisibleDefaultThanksModal: (state: RootState) => state.app.defaultThanksModal.isVisible,
  selectIsModalsOpen: (state: RootState) =>
    state.app.authModal.isVisible ||
    state.app.permanentReviewModal.isVisible ||
    state.app.defaultThanksModal.isVisible ||
    state.app.extraServiceModal.isVisible ||
    state.app.npspollModal.isVisible,
  selectIsVisibleCategoryModal: (state: RootState) => state.app.categoryModal.isVisible,
  selectIsVisibleWarningModal: (state: RootState) => state.app.warningModal.isVisible,
  selectIsVisibleFreeAskModal: (state: RootState) => state.app.freeAskModal.isVisible,
  selectIsVisiblePaymentModal: (state: RootState) => state.app.paymentModal.isVisible,
  selectIsVisibleServicePaymentModal: (state: RootState) => state.app.servicePaymentModal.isVisible,
  selectIsRedirectPaymentModal: (state: RootState) => state.app.authModal.isRedirect,
  selectIsFreeChatRedirectInfo: (state: RootState) => state.app.freeChat.isFreeChatRedirect,
  selectIsFreeChatAskInfo: (state: RootState) => state.app.freeChat.isFreeChatAsk,
  selectIsFreeThemeRedirectInfo: (state: RootState) => state.app.freeThemeChat,
};
