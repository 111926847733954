import { DefaultAvatar, UserMenuBurger } from 'app/assets/svg';
import React, { FC } from 'react';
import { User } from 'shared/api';
import { coverImageStyle } from 'shared/helpers/coverImageStyle';
import styled from 'styled-components';

type UserMenuButtonProps = {
  profile: User;
  onClick: () => void;
  isOpenMenu: boolean;
};

export const UserMenuButton: FC<UserMenuButtonProps> = ({ profile: { avatarUrl }, onClick, isOpenMenu }) => (
  <Wrapper type="button" onClick={onClick} $isOpenMenu={isOpenMenu}>
    <UserMenuBurger />
    <Avatar $url={avatarUrl}>{!avatarUrl && <DefaultAvatar width={30} height={30} />}</Avatar>
  </Wrapper>
);

const Wrapper = styled.button<{ $isOpenMenu: boolean }>`
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid var(--gray4);
  height: 40px;
  padding: 0 5px 0 15px;
  border-radius: 20px;
  background-color: var(--white);
  margin: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  box-shadow: ${({ $isOpenMenu }) => $isOpenMenu && '0 0 15px 0 rgba(0, 0, 0, 0.1)'};

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }
`;

const Avatar = styled.div<{ $url?: string }>`
  margin-left: 11px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  ${({ $url }) => $url && { ...coverImageStyle($url) }}
`;
