import * as React from 'react';

function Time(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C15.5234 0 20 4.47656 20 10C20 15.5234 15.5234 20 10 20C4.47656 20 0 15.5234 0 10C0 4.47656 4.47656 0 10 0ZM9.0625 4.6875C9.0625 4.16797 9.48047 3.75 10 3.75C10.5195 3.75 10.9375 4.16797 10.9375 4.6875V9.5L14.2695 11.7188C14.6992 12.0078 14.8164 12.5898 14.4961 13.0195C14.2422 13.4492 13.6602 13.5664 13.2305 13.2461L9.48047 10.7461C9.21875 10.6055 9.0625 10.3125 9.0625 9.96484V4.6875ZM10 18.125C5.51172 18.125 1.875 14.4883 1.875 10C1.875 5.51172 5.51172 1.875 10 1.875C14.4883 1.875 18.125 5.51172 18.125 10C18.125 14.4883 14.4883 18.125 10 18.125Z"
        fill="#79818C"
      />
    </svg>
  );
}

export default Time;
