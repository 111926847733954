import { DefaultAvatar } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import styled from 'styled-components';

interface LoginButtonProps extends ButtonHTMLAttributes<any> {}

export const LoginButton: FC<LoginButtonProps> = (props) => {
  const { t } = useTranslation('header.component');
  return (
    <Wrapper {...props}>
      {t('login')}
      <Icon>
        <DefaultAvatar />
      </Icon>
    </Wrapper>
  );
};

const Wrapper = styled.button`
  position: relative;
  outline: none;
  border: none;
  height: 30px;
  width: 92px;
  padding-left: 12px;
  border-radius: 25px;
  background-color: var(--gray7);
  margin: 0;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  color: var(--purple);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  ${CONDITION_DESKTOP} {
    width: 106px;
    padding-left: 17px;
    height: 40px;
    line-height: 40px;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 3px;
  right: 5px;
  width: 25px;
  height: 25px;
  ${CONDITION_DESKTOP} {
    width: 30px;
    height: 30px;
    top: 5px;
  }
`;
