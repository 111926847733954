import { ConsultationInvoiceTypeEnum, ConsultationStatusEnum, InvoiceStatusEnum } from 'shared/api';
import { RootState } from 'store';

export const selectIsReviewModalOpen = (state: RootState) => state.consultationsChatRoom.isReviewOpenModal;

export const selectConsultations = (state: RootState) => state.consultationsChatRoom.consultations;

export const selectActiveConsultation = (state: RootState) =>
  state.consultationsChatRoom.consultations.find((c) => c.status === ConsultationStatusEnum.Active);

export const selectPendingStartConsultation = (state: RootState) =>
  state.consultationsChatRoom.consultations.find((c) => c.status === ConsultationStatusEnum.PendingStart);

export const selectPendingPaymentConsultation = (state: RootState) =>
  state.consultationsChatRoom.consultations.find((c) => c.status === ConsultationStatusEnum.PendingPayment);

export const selectPendingProlongationPaymentConsultation = (state: RootState) =>
  state.consultationsChatRoom.consultations.find((c) => {
    const prolongationInvoice = c.invoices?.find(
      (invoice) =>
        invoice.type === ConsultationInvoiceTypeEnum.Prolongation &&
        invoice.invoice.status === InvoiceStatusEnum.Pending,
    );

    return prolongationInvoice;
  });

export const selectPendingExtraServicePaymentConsultation = (state: RootState) =>
  state.consultationsChatRoom.consultations.find((c) => {
    if (!c.extraServices) {
      return false;
    }

    const pendingExtraService = c.extraServices.find(
      (extraService) => extraService.invoice.status === InvoiceStatusEnum.Pending,
    );

    return pendingExtraService;
  });

export const selectLeftMessagesCount = (state: RootState) => {
  const activeConsultation = selectActiveConsultation(state);

  if (!activeConsultation) {
    return null;
  }

  return activeConsultation.messagesLimit - activeConsultation.expertMessagesCount;
};
