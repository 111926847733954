import React, { FC, forwardRef } from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import styled from 'styled-components';

import { FieldWrapper } from '../field-wrapper';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper';

type SelectProps = FieldWrapperProps & ReactSelectProps;

export const Select: FC<SelectProps> = forwardRef(
  ({ label, error, successMessage, width, centered, required, ...props }, ref: any) => (
      <FieldWrapper
        label={label}
        error={error}
        successMessage={successMessage}
        width={width}
        centered={centered}
        required={required}
      >
        <StyledSelect ref={ref} classNamePrefix="Select" {...props} />
      </FieldWrapper>
    ),
);

const StyledSelect = styled(ReactSelect)`
  .Select__control {
    width: 100%;
    height: 50px;
    outline: none;
    border: 1px solid var(--gray3);
    border-radius: 12px;
    background-color: var(--white);
    padding: 0 7px;
    color: var(--gray);
    font-size: 15px;
    line-height: 1.67;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    &:hover {
      border-color: var(--gray3);
    }
  }
  .Select__control--is-focused {
    outline: none;
    border-color: var(--gray3);
    box-shadow: none;
  }

  .Select__single-value {
    color: var(--text);
  }

  .Select__placeholder {
    color: var(--gray);
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__indicator {
    color: var(--gray);
    &:hover {
      color: var(--gray);
    }
  }

  .Select__menu {
    color: var(--text);
    font-size: 15px;
    top: 90%;
    background-color: var(--white);
  }
`;
