import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  api,
  ConsultationOffer,
  ConsultationRequest,
  CreateConsultationOfferDto,
  GetManyConsultationRequestResponseDto,
} from 'shared/api';
import { httpClient } from 'shared/api/httpClient';
import { Params } from 'shared/common/types';
import { getUrl, handleAsyncError } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';

// responses tab
export const createConsultationRequest = createAsyncThunk(
  'consultationRequests/createConsultationRequest',
  async ({ categoryId, content }: { categoryId: number; content: string }) => {
    try {
      const { data } =
        await api.V1ConsultationRequestsApi.createOneBaseConsultationRequestsControllerConsultationRequest({
          categoryId,
          content,
        });
      return data;
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const fetchManyConsultationsRequests = createAsyncThunk(
  'consultationRequests/fetchManyConsultationsRequests',
  async (params: Params) => {
    try {
      const response = await api.V1ConsultationRequestsApi.getManyBaseConsultationRequestsControllerConsultationRequest(
        params.fields,
        params.s,
        params.filter,
        params.or,
        params.sort,
        params.join,
        params.limit,
        params.offset,
        params.page,
        params.cache,
        params.options,
      );
      const data = response.data as GetManyConsultationRequestResponseDto;

      return data.data;
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const addNewOfferToRequest = createAction<ConsultationOffer>('consultationRequests/addNewOfferToRequest');

export const setCurrentResponseRoom = createAction<{ roomId: number | null }>(
  'consultationRequests/setCurrentResponseRoom',
);

export const resetResponseUnreadCountLocal = createAction<{ requestId: number }>(
  'consultationRequests/resetResponseUnreadCountLocal',
);
export const resetResponseUnreadCount = createAsyncThunk(
  'consultationRequests/resetResponsesUnreadCount',
  async ({ requestId, lastReadTime }: { requestId: number; lastReadTime: string }) => {
    api.V1ConsultationRequestsApi.updateOneBaseConsultationRequestsControllerConsultationRequest(requestId, {
      lastReadTime,
    });
    return { requestId };
  },
);

// requests tab
export const fetchManyClientsRequests = createAsyncThunk(
  'consultationRequests/fetchManyClientsRequests',
  async (params: Params) => {
    try {
      const categoriesResponse = await api.V1UserCategoriesApi.userCategoriesMetaControllerGetMany();
      const requestsResponse =
        await api.V1ConsultationRequestsApi.getManyBaseConsultationRequestsControllerConsultationRequest(
          params.fields,
          params.s,
          params.filter,
          params.or,
          params.sort,
          params.join,
          params.limit,
          params.offset,
          params.page,
          params.cache,
          params.options,
        );

      const { data: categories } = categoriesResponse;
      const { data: requests } = requestsResponse.data as GetManyConsultationRequestResponseDto;

      return { categories, requests };
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const clearClientRequests = createAction('consultationRequests/clearClientRequests');

export const addNewClientRequest = createAction<ConsultationRequest>('consultationRequests/addNewClientRequest');

export const setCurrentRequestRoom = createAction<{ roomId: number | null }>(
  'consultationRequests/setCurrentRequestRoom',
);

export const setActiveRequestMessageId = createAction<{ requestId: number | null }>(
  'consultationRequests/setActiveRequestMessageId',
);

export const createOffer = createAsyncThunk(
  'consultationRequests/createOffer',
  async ({ consultationRequestId, description, price }: CreateConsultationOfferDto) => {
    try {
      const { data } = await api.V1ConsultationOffersApi.consultationOffersControllerCreateOne({
        consultationRequestId,
        price,
        description,
      });
      return data;
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);

export const resetRequestUnreadCountLocal = createAction<{ categoryId: number }>(
  'consultationRequests/resetRequestUnreadCountLocal',
);

export const resetRequestUnreadCount = createAsyncThunk(
  'consultationRequests/resetRequestUnreadCount',
  async ({ id, lastReadTime }: { id: number; lastReadTime: string }) => {
    api.V1UserCategoriesApi.userCategoriesMetaControllerUpdateOne(id, { lastReadTime });
    return { id };
  },
);

export const resetState = createAction('chatRooms/resetState');

export const createReceipt = createAsyncThunk(
  'consultationRequests/createReceipt',
  async ({ consultationId, receipt }: { consultationId: number; receipt: File }) => {
    try {
      const { data: signedUrl } = await api.V1StorageApi.storageControllerSignFileUrl({
        fileKey: `${dayjs().format('YYYY-MM-DD_HHmmssSSS')}.${receipt.name}`,
        contentType: receipt.type,
      });
      await httpClient.put(signedUrl, receipt);
      const { data } = await api.V1ConsultationsApi.consultationsControllerCreateConsultationReceipt(consultationId, {
        receiptFileUrl: getUrl(signedUrl),
      });
      return data;
    } catch (error: any) {
      captureError(error);
      handleAsyncError(error);
    }
  },
);
