import Link from 'next/link';
import React, { FC } from 'react';
import styled from 'styled-components';

type CategoryButtonProps = {
  label: string;
  quantity?: number;
  href: string;
};

export const CategoryButton: FC<CategoryButtonProps> = ({ label, quantity, href }) => (
    <Link href={href} passHref>
      <a>
        <Container>
          <Label>{label}</Label>
          {!!quantity && <Number>{quantity}</Number>}
        </Container>
      </a>
    </Link>
  );

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 6px 16px 5px 12px;
  border: solid 1px rgba(232, 232, 232, 0.5);
  border-radius: 17px;
  margin-right: 11px;
  cursor: pointer;
  margin-bottom: 10px;
  -webkit-tap-highlight-color: transparent;
  font-weight: 500;
  font-size: 12px;
  color: var(--white);
  transition: all 0.2s;
  user-select: none;

  &:hover {
    border-color: rgba(232, 232, 232, 0.9);
  }
`;

const Label = styled.div`
  opacity: 0.9;
`;

const Number = styled.div`
  opacity: 0.5;
  margin-left: 5px;
`;
