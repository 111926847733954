import { RatingStar } from 'app/assets/svg';
import { useTranslation } from 'next-i18next';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';

interface RatingFieldProps extends HTMLAttributes<HTMLDivElement> {
  rating: number;
  setRating: (rating: number) => void;
}

export const RatingField: FC<RatingFieldProps> = ({ rating, setRating }) => {
  const { t } = useTranslation('utils');
  const [ratingArray, setRatingArray] = useState<React.ReactNode[]>(new Array(5).fill(<></>));

  useEffect(() => {
    constructRating(rating);
  }, [rating]);

  const changeDisplay = (i: number) => {
    constructRating(i);
  };

  const handleClick = (i: number) => {
    setRating(i);
  };

  const constructRating = (currentRating: number) => {
    const updateArray = ratingArray.map((_, i) => (
      <Star
        key={i}
        onMouseEnter={() => changeDisplay(i + 1)}
        onMouseLeave={() => changeDisplay(rating)}
        onClick={() => handleClick(i + 1)}
      >
        <RatingStar fill={i < currentRating ? '#E87329' : '#93969D'} />
      </Star>
    ));
    setRatingArray(updateArray);
  };

  const renderRatingText = () => {
    switch (rating) {
      case 1:
        return t('modals.expertReview.rating.1');
      case 2:
        return t('modals.expertReview.rating.2');
      case 3:
        return t('modals.expertReview.rating.3');
      case 4:
        return t('modals.expertReview.rating.4');
      case 5:
        return t('modals.expertReview.rating.5');
      default:
        return ' ';
    }
  };

  return (
    <Container>
      {ratingArray.map((r, i) => (
        <span key={i}>{r}</span>
      ))}
      <RatingText>{renderRatingText()}</RatingText>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 15px;
`;
const Star = styled.span`
  display: inline-block;
  cursor: pointer;

  & > svg {
    margin: 0 3px;
  }
`;
const RatingText = styled.p`
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: var(--gray2);
  margin-top: 5px;
  height: 20px;
`;
