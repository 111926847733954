import * as React from 'react';

function SimpleChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.51922 3.16801C1.58561 2.69398 1.92809 2.36168 2.39084 2.23932C3.60905 1.9172 6.3241 1.33334 9.99992 1.33334C13.5779 1.33334 16.2322 1.88653 17.4873 2.21305C18.0079 2.3485 18.3945 2.73342 18.4701 3.26603C18.5666 3.94669 18.6666 5.14527 18.6666 7.19004C18.6666 9.24109 18.566 10.5763 18.4692 11.3863C18.39 12.0485 17.9133 12.5582 17.2775 12.7594C15.8138 13.2224 13.2033 14.0403 12.4447 14.2255L11.0807 18.6667H9.29531L7.60984 14.2255C6.60771 13.9896 4.07002 13.1969 2.66087 12.7499C2.04402 12.5543 1.58458 12.0643 1.51226 11.4213C1.42374 10.6342 1.33325 9.31096 1.33325 7.19004C1.33325 5.01346 1.42855 3.81535 1.51922 3.16801Z"
        fill="#D8D8D8"
      />
      <mask id="mask0_0_505" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.51922 3.16801C1.58561 2.69398 1.92809 2.36168 2.39084 2.23932C3.60905 1.9172 6.3241 1.33334 9.99992 1.33334C13.5779 1.33334 16.2322 1.88653 17.4873 2.21305C18.0079 2.3485 18.3945 2.73342 18.4701 3.26603C18.5666 3.94669 18.6666 5.14527 18.6666 7.19004C18.6666 9.24109 18.566 10.5763 18.4692 11.3863C18.39 12.0485 17.9133 12.5582 17.2775 12.7594C15.8138 13.2224 13.2033 14.0403 12.4447 14.2255L11.0807 18.6667H9.29531L7.60984 14.2255C6.60771 13.9896 4.07002 13.1969 2.66087 12.7499C2.04402 12.5543 1.58458 12.0643 1.51226 11.4213C1.42374 10.6342 1.33325 9.31096 1.33325 7.19004C1.33325 5.01346 1.42855 3.81535 1.51922 3.16801Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_505)">
        <rect width="66.6667" height="20" fill="#79818C" />
      </g>
    </svg>
  );
}

export default SimpleChat;
