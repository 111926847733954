import * as React from 'react';

function SvgDislikeSmallFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={15} height={15} {...props}>
      <defs>
        <path
          id="dislikeSmallFilled_svg__a"
          d="M4.2 0c3.109 0 5.112.686 6.112 1.15v7.742c-.925.616-2.363 1.81-2.363 3.296 0 1.562-.312 1.874-.937 1.874s-2.031-.624-2.031-2.5c0-1.562.468-2.5.468-2.5H2.5A2.497 2.497 0 01.037 6.139l.7-4.063A2.495 2.495 0 013.199 0zm9.706.156C14.51.156 15 .646 15 1.25v7.188c0 .603-.49 1.093-1.094 1.093h-1.562c-.603 0-1.094-.49-1.094-1.094V1.25c0-.603.49-1.094 1.094-1.094z"
        />
      </defs>
      <g transform="rotate(180 7.5 7.5)" fill="none" fillRule="evenodd">
        <mask id="dislikeSmallFilled_svg__b" fill="#fff">
          <use xlinkHref="#dislikeSmallFilled_svg__a" />
        </mask>
        <path fill="#24AF1E" d="M0 0h15v15H0z" mask="url(#dislikeSmallFilled_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgDislikeSmallFilled;
