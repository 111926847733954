import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../index';
import { ServicesState } from './slice';

export const selectServicesStore = (state: RootState): ServicesState => state.services;

export const selectServices = createSelector(selectServicesStore, (services) => services.services);

export const selectServicesLoading = createSelector(selectServicesStore, (services) => services.servicesLoading);

export const selectServicesCreating = createSelector(selectServicesStore, (services) => services.crearteServiceLoading);

export const selectServicesUpdating = createSelector(selectServicesStore, (services) => services.updateServiceLoading);

export const selectServicesRemoving = createSelector(selectServicesStore, (services) => services.removeServiceLoading);
