import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'shared/api';
import { MessageExtended } from 'shared/common/types';
import { handleAsyncError } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';

export const addChatMessage = createAction<MessageExtended>('chatMessages/addChatMessage');

export const receiveChatMessage = createAction<MessageExtended>('chatMessages/receiveChatMessage');

export const clearChatMessages = createAction('chatMessages/clearChatMessages');

export const sendChatMessage = createAsyncThunk(
  'chatMessages/sendChatMessage',
  async (
    {
      chatRoomId,
      content,
      consultationId,
      attachments,
    }: { chatRoomId: number; content: string; consultationId?: number; attachments?: string[] },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.V1MessagesApi.messagesControllerCreateMessage({
        chatRoomId,
        content,
        ...(consultationId && { consultationId }),
        ...(attachments && { attachments }),
      });
      return data;
    } catch (e) {
      captureError(e);
      return rejectWithValue({ content });
    }
  },
);

export const getChatMessages = createAsyncThunk(
  'chatMessages/getChatMessages',
  async ({ chatRoomId, limit, page }: { chatRoomId: number; limit: number; page: number }) => {
    try {
      const { data } = await api.V1ChatsApi.chatRoomsControllerGetChatMessages(chatRoomId, limit, page);
      return data;
    } catch (e) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const fetchMoreChatMessages = createAsyncThunk(
  'chatMessages/fetchMoreChatMessages',
  async ({ chatRoomId, limit, page }: { chatRoomId: number; limit: number; page: number }) => {
    try {
      const { data } = await api.V1ChatsApi.chatRoomsControllerGetChatMessages(chatRoomId, limit, page);
      return data;
    } catch (e) {
      captureError(e);
      handleAsyncError(e);
    }
  },
);

export const resetState = createAction('chatMessages/resetState');
