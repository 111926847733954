import {
  V1AboutServiceApi,
  V1AchievementsApi,
  V1AdminPortfoliosApi,
  V1CategoriesApi,
  V1ChatsApi,
  V1ConsultationInvoicesApi,
  V1ConsultationOffersApi,
  V1ConsultationRequestsApi,
  V1ConsultationsApi,
  V1ContentChangeRequestsApi,
  V1ContractsApi,
  V1CustomRequestsApi,
  V1EmailsApi,
  V1ExpertServicesApi,
  V1MessagesApi,
  V1NewsApi,
  V1NotificationsSettingsApi,
  V1NpsPollsApi,
  V1PaymentMethodsApi,
  V1PaymentsApi,
  V1PortfoliosApi,
  V1PromocodesApi,
  V1PusherApi,
  V1ReviewsApi,
  V1RubricsApi,
  V1StorageApi,
  V1UserCategoriesMetadataApi,
  V1UsersApi,
  V1VerificationRequestsApi,
  V1WalletsApi,
} from 'shared/api/generated';
import { API_BASE_URL } from 'shared/common/constants';

import { httpClient } from './httpClient';

export const api = {
  V1RubricsApi: new V1RubricsApi(undefined, API_BASE_URL, httpClient),
  V1CategoriesApi: new V1CategoriesApi(undefined, API_BASE_URL, httpClient),
  V1UsersApi: new V1UsersApi(undefined, API_BASE_URL, httpClient),
  V1StorageApi: new V1StorageApi(undefined, API_BASE_URL, httpClient),
  V1ChatsApi: new V1ChatsApi(undefined, API_BASE_URL, httpClient),
  V1MessagesApi: new V1MessagesApi(undefined, API_BASE_URL, httpClient),
  PusherApi: new V1PusherApi(undefined, API_BASE_URL, httpClient),
  V1UserCategoriesApi: new V1UserCategoriesMetadataApi(undefined, API_BASE_URL, httpClient),
  V1VerificationRequestsApi: new V1VerificationRequestsApi(undefined, API_BASE_URL, httpClient),
  V1ConsultationRequestsApi: new V1ConsultationRequestsApi(undefined, API_BASE_URL, httpClient),
  V1AchievementsApi: new V1AchievementsApi(undefined, API_BASE_URL, httpClient),
  V1ReviewsApi: new V1ReviewsApi(undefined, API_BASE_URL, httpClient),
  V1ContentChangeRequestApi: new V1ContentChangeRequestsApi(undefined, API_BASE_URL, httpClient),
  V1ConsultationOffersApi: new V1ConsultationOffersApi(undefined, API_BASE_URL, httpClient),
  V1NotificationsSettingsApi: new V1NotificationsSettingsApi(undefined, API_BASE_URL, httpClient),
  V1AboutServiceApi: new V1AboutServiceApi(undefined, API_BASE_URL, httpClient),
  V1PromocodesApi: new V1PromocodesApi(undefined, API_BASE_URL, httpClient),
  V1ConsultationsApi: new V1ConsultationsApi(undefined, API_BASE_URL, httpClient),
  V1ConsultationInvoicesApi: new V1ConsultationInvoicesApi(undefined, API_BASE_URL, httpClient),
  V1WalletsApi: new V1WalletsApi(undefined, API_BASE_URL, httpClient),
  V1CustomRequestsApi: new V1CustomRequestsApi(undefined, API_BASE_URL, httpClient),
  V1PaymentsApi: new V1PaymentsApi(undefined, API_BASE_URL, httpClient),
  V1PaymentMethodsApi: new V1PaymentMethodsApi(undefined, API_BASE_URL, httpClient),
  V1ContractsApi: new V1ContractsApi(undefined, API_BASE_URL, httpClient),
  V1NpsPollsApi: new V1NpsPollsApi(undefined, API_BASE_URL, httpClient),
  V1EmailsApi: new V1EmailsApi(undefined, API_BASE_URL, httpClient),
  V1NewsApi: new V1NewsApi(undefined, API_BASE_URL, httpClient),
  V1PortfoliosApi: new V1PortfoliosApi(undefined, API_BASE_URL, httpClient),
  V1AdminPortfoliosApi: new V1AdminPortfoliosApi(undefined, API_BASE_URL, httpClient),
  V1ExpertServicesApi: new V1ExpertServicesApi(undefined, API_BASE_URL, httpClient),
};
export * from 'shared/api/generated';
export { httpClient };
