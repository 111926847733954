import { RootState } from 'store';

export const selectChatMessages = (state: RootState) => state.chatMessages.chatMessages;
export const selectChatMessagesPage = (state: RootState) => state.chatMessages.page;
export const selectChatMessagesPageCount = (state: RootState) => state.chatMessages.pageCount;
export const selectFetchingChatMessagesStatus = (state: RootState) => state.chatMessages.fetchingChatMessagesStatus;
export const selectFetchingMoreChatMessagesStatus = (state: RootState) =>
  state.chatMessages.fetchingMoreChatMessagesStatus;
export const selectIsShouldFetchChatMessagesAgain = (state: RootState) =>
  state.chatMessages.isShouldFetchChatMessagesAgain;
