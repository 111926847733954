import * as React from 'react';

function SvgStarSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
      <path
        d="M6 9.447l-3.708 2.22.98-4.189L0 4.662l4.314-.369L6 .333l1.686 3.961L12 4.662 8.728 7.478l.98 4.189z"
        fill="#EE8835"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgStarSmallIcon;
