import * as React from 'react';

function SvgPlaneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={15} height={15} {...props}>
      <defs>
        <path id="planeIcon_svg__a" d="M.007 0L0 4.958l10.714 1.417L0 7.792l.007 4.958L15 6.375z" />
      </defs>
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <mask id="planeIcon_svg__b" fill="#fff">
          <use xlinkHref="#planeIcon_svg__a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#planeIcon_svg__a" />
        <path fill="#FFF" d="M0-1h15v15H0z" mask="url(#planeIcon_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgPlaneIcon;
