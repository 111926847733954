import * as React from 'react';

function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={25} height={25} {...props}>
      <defs>
        <path
          id="chat_svg__a"
          d="M.07 13.559a13.51 13.51 0 01-.068-1.407c-.004-.47.021-3.717.07-4.184.062-.591.154-1.18.275-1.766A7.23 7.23 0 012.428 2.45 7.304 7.304 0 016.276.52a37.526 37.526 0 0112.448 0 7.322 7.322 0 013.848 1.93 7.235 7.235 0 012.08 3.752c.122.586.215 1.175.277 1.766.049.467.072 3.714.07 4.184.002.47-.023.939-.074 1.407-.063.591-.153 1.18-.275 1.765a7.237 7.237 0 01-2.08 3.754 7.302 7.302 0 01-3.846 1.93c-2.05.343-4.125.515-6.203.513h-.647C9.239 24.168 6.439 24.864 4.66 25h-.055a.65.65 0 01-.638-.392.637.637 0 01.163-.727 6.918 6.918 0 001.777-2.955 7.299 7.299 0 01-3.612-1.984 7.236 7.236 0 01-1.95-3.617A18.492 18.492 0 01.07 13.56zm15.588-2.796c0 .604.365 1.149.925 1.376.56.23 1.203.104 1.634-.322a1.488 1.488 0 00-1.06-2.543 1.492 1.492 0 00-1.499 1.489zm-4.652 0a1.48 1.48 0 00.926 1.376c.56.23 1.203.104 1.633-.322a1.488 1.488 0 00-1.059-2.543c-.397 0-.779.156-1.06.436-.281.278-.44.657-.44 1.053zm-4.655 0c0 .604.365 1.149.925 1.376.56.23 1.203.104 1.634-.322a1.488 1.488 0 00-1.06-2.543 1.506 1.506 0 00-1.39.917c-.076.18-.116.375-.116.572h.007z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="chat_svg__b" fill="#fff">
          <use xlinkHref="#chat_svg__a" />
        </mask>
        <use fill="#79818C" fillRule="nonzero" xlinkHref="#chat_svg__a" />
        <path fill="#93969D" d="M0 0h25v25H0z" mask="url(#chat_svg__b)" />
      </g>
    </svg>
  );
}

export default SvgChat;
