import * as React from 'react';

function SvgFastAnswerAchivment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={11.5} cy={11.5} r={11.5} fill="#8B33FF" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M4.892 9.623h1.894v1H4.892zm0 3.865h1.894v1H4.892zM4 11.556h2.786v1H4zm13.652-3.081l.43.43.61-.61-1.47-1.47-.61.61.43.43-.596.597a4.978 4.978 0 00-2.493-1.208V6.186h.729V5h-3.138v1.186h.729v1.068a4.999 4.999 0 00-4.152 4.92 4.999 4.999 0 004.992 4.993 4.998 4.998 0 004.992-4.992 4.97 4.97 0 00-1.069-3.084l.616-.616zm-4.54 7.521c-2.106 0-3.82-1.714-3.82-3.821s1.714-3.821 3.82-3.821a3.825 3.825 0 013.822 3.82 3.825 3.825 0 01-3.821 3.822z" />
          <path d="M13.113 9.216a2.963 2.963 0 00-2.96 2.959c0 1.631 1.328 2.959 2.96 2.959s2.96-1.328 2.96-2.96a2.962 2.962 0 00-2.96-2.958zm.305 3.264l-.61-.61 1.23-1.23.61.61-1.23 1.23z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgFastAnswerAchivment;
