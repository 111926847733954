import React, { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

type TabButtonSize = 'big' | 'medium';

type TabButtonProps = {
  $size?: TabButtonSize;
  icon?: string;
  iconRetina?: string;
  index: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
};

export const TabButton: FC<TabButtonProps> = ({
  $size = 'medium',
  index,
  activeIndex,
  setActiveIndex,
  icon,
  iconRetina,
  children,
}) => {
  const handleClick = () => {
    setActiveIndex(index);
  };

  return (
    <TabContainer $isActive={index === activeIndex} $size={$size} onClick={handleClick}>
      {icon && <IconContainer src={icon} srcSet={`${iconRetina} 2x`} alt="" />}
      <TabText>{children}</TabText>
    </TabContainer>
  );
};

const TabContainer = styled.div<{ $isActive: boolean; $size: TabButtonSize }>(({ $isActive, $size }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: 45,
  backgroundColor: $isActive ? 'var(--purple)' : bgColorMap[$size],
  color: $isActive ? 'var(--white)' : textColorMap[$size],
  boxShadow: $isActive || $size === 'big' ? undefined : '0 0 15px 0 rgba(0, 0, 0, 0.1)',
  cursor: $isActive ? 'default' : 'pointer',
  '-webkit-tap-highlight-color': 'transparent',
  transition: 'all 0.8s',
  ...styleSizeMap[$size],
}));

const IconContainer = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

const TabText = styled.span`
  font-weight: 500;
  line-height: 19px;
`;

const styleSizeMap: Record<TabButtonSize, CSSObject> = {
  big: {
    padding: '20px 30px',
    margin: '0 10px',
    verticalAlign: 'bottom',
    height: 60,
    borderRadius: 30,
    fontSize: 19,
  },
  medium: {
    padding: '10px 25px 10px 15px',
    margin: '0 5px 15px',
    verticalAlign: 'bottom',
    height: 45,
    borderRadius: 23,
    fontSize: 15,
  },
};

const textColorMap: Record<TabButtonSize, string> = {
  big: 'var(--purple)',
  medium: 'var(--purple)',
};

const bgColorMap: Record<TabButtonSize, string> = {
  big: 'var(--gray7)',
  medium: 'var(--gray7)',
};
