import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { keyframes } from 'styled-components';

import {
  backgroundImageMap,
  borderColorMap,
  borderedDisabledStylesMap,
  boxShadowMap,
  colorsMap,
  hoverColorsMap,
  hoverTextColorsMap,
  sizeStylesMap,
  textColorsMap,
} from './styleMaps';

export type ButtonColor = 'purple' | 'purple-opacity' | 'purple-gradient' | 'red' | 'white' | 'white-text' | 'gray';
export type ButtonSize = 'big' | 'medium' | 'small';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean;
  width?: string;
  bordered?: boolean;
  hasBottomRadius?: boolean;
  color?: ButtonColor;
  hasShadow?: boolean;
  hasShadowHover?: boolean;
  size?: ButtonSize;
  fontSize?: string;
  hasPaddingHorizontal?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  rightIcon,
  leftIcon,
  fontSize,
  loading,
  children,
  onClick,
  disabled,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!disabled) {
      if (onClick) onClick(e);
    }
  };
  return (
    <ButtonContainerWithAnimation {...props} onClick={handleClick} disabled={disabled} $loading={loading}>
      {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
      <ButtonText $fontSize={fontSize}>{children}</ButtonText>
      {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
    </ButtonContainerWithAnimation>
  );
};

const IconContainer = styled.div`
  margin: 0 5px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const ButtonText = styled.span<{ $fontSize?: string }>`
  font-size: ${({ $fontSize }) => $fontSize || '15px'};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0 5px;
  /* align-self: flex-end; */
`;

const ButtonContainer = styled.button<ButtonProps>(
  ({
    block,
    width,
    bordered,
    color = 'purple',
    size = 'medium',
    hasShadow,
    hasShadowHover,
    hasBottomRadius = true,
    hasPaddingHorizontal = true,
    disabled,
    // loading,
  }) => {
    const baseColor = colorsMap[color];
    const baseHoverColor = hoverColorsMap[color];

    return {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: block ? '100%' : width,
      color: bordered ? baseColor : textColorsMap[color],
      borderBottomRightRadius: hasBottomRadius ? 4 : undefined,
      backgroundColor: bordered ? 'transparent' : baseColor,
      backgroundImage: backgroundImageMap[color],
      boxShadow: hasShadow ? boxShadowMap[color] : undefined,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: bordered ? borderColorMap[color] : 'transparent',
      opacity: disabled ? 0.2 : 1,
      outline: 'none',
      cursor: disabled ? 'default' : 'pointer',
      '-webkit-tap-highlight-color': 'transparent',
      textDecoration: 'none',
      transition: 'all 0.2s',
      userSelect: 'none',
      ...sizeStylesMap[size],
      ...(bordered && disabled && borderedDisabledStylesMap[color]),
      ...(!hasPaddingHorizontal && {
        paddingLeft: 0,
        paddingRight: 0,
      }),

      ...(!disabled && {
        '&:hover': {
          backgroundColor: baseHoverColor,
          borderColor: bordered ? baseHoverColor : 'transparent',
          color: hoverTextColorsMap[color],
          boxShadow: hasShadow || hasShadowHover ? boxShadowMap[color] : undefined,
        },
      }),
    };
  },
);

const loadingAnimation = keyframes`
0% {
  left: 0;
  transform: translate(-100%, 0);
}
25%, 100% {
  left: 100%;
  transform: translate(0);
}
`;

const ButtonContainerWithAnimation = styled(ButtonContainer)<{ $loading?: boolean }>`
  overflow: hidden;
  &::after {
    display: ${({ $loading }) => ($loading ? 'block' : 'none')};
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    opacity: 0.15;
    background-color: var(--white);
    animation: ${loadingAnimation} 1.2s linear infinite;
    transform: translate(-100%);
  }
`;
