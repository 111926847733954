import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ChatRoom, Consultation, ConsultationStatusEnum, Message, UpdateLastReadMessageDto, User } from 'shared/api';
import { ChatRoomExtended, LoadingStatus } from 'shared/common/types';

import {
  addChatRoom,
  addChatRoomIfNotExist,
  addMember,
  addUnreadMark,
  changeConsultationStatus,
  changeLastReadMessage,
  createChatRoom,
  createTempChatRoom,
  getChatRooms,
  getOneChatRoom,
  getOnlineMembers,
  removeMember,
  replaceTempToRealChatRoom,
  resetState,
  resetUnreadMark,
  setActiveRoom,
  setCompanionWritten,
  toggleChatNotification,
  updateLastMessage,
  updateLastReadMessageAsync,
} from './actions';

const initialState = {
  currentChatRoomId: null,
  chatRooms: [],
  consultationsStatuses: [],
  unreadRoomIds: [],
  onlineMembersIds: [],
  deletingChatRoomStatus: 'idle',
  fetchingChatRoomsStatus: 'idle',
  creatingChatRoomStatus: 'idle',
} as {
  currentChatRoomId: number | null;
  chatRooms: ChatRoomExtended[];
  consultationsStatuses: {
    roomId: number;
    consultations: { id: number; status: ConsultationStatusEnum }[];
  }[];
  unreadRoomIds: number[];
  onlineMembersIds: number[];
  fetchingChatRoomsStatus: LoadingStatus;
  deletingChatRoomStatus: LoadingStatus;
  creatingChatRoomStatus: LoadingStatus;
};

const chatRoomSlice = createSlice({
  name: 'chatRooms',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    [updateLastReadMessageAsync.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ id: number; dto: UpdateLastReadMessageDto }>,
    ) => {
      const chatRoomId = state.chatRooms.findIndex((chat) => chat.id === payload.id);
      if (chatRoomId !== -1) {
        state.chatRooms[chatRoomId] = {
          ...state.chatRooms[chatRoomId],
          clientLastReadMessageId: payload.dto.messageId,
          expertLastReadMessageId: payload.dto.messageId,
        };
      }
    },
    // *** chatRooms flow
    [createTempChatRoom.type]: (
      state,
      { payload }: PayloadAction<{ client: User; expert: User; tempRoomId: number }>,
    ) => {
      const tempChatRoom: ChatRoomExtended = {
        id: payload.tempRoomId,
        client: payload.client,
        clientId: payload.client.id,
        expert: payload.expert,
        expertId: payload.expert.id,
        createdAt: dayjs().toNow(),
        updatedAt: dayjs().toNow(),
        messages: [],
        consultations: [],
        chatConsultationOffers: [],
        deletedAt: '',
        isExpertMuteChat: false,
        isClientMuteChat: false,
        isTempRoom: true,
      };
      state.chatRooms.unshift(tempChatRoom);
    },
    [replaceTempToRealChatRoom.pending.type]: (state) => {
      state.creatingChatRoomStatus = 'pending';
    },
    [replaceTempToRealChatRoom.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ room: ChatRoom; expert: User; client: User; tempRoomId: number }>,
    ) => {
      state.creatingChatRoomStatus = 'fulfilled';
      const tempChatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.tempRoomId);
      if (tempChatRoomIndex !== -1) {
        const newRoom = { ...payload.room, client: payload.client, expert: payload.expert };
        state.chatRooms.splice(tempChatRoomIndex, 1);
        state.chatRooms.unshift(newRoom);
      }
    },
    [replaceTempToRealChatRoom.rejected.type]: (state) => {
      state.creatingChatRoomStatus = 'rejected';
    },
    [getChatRooms.pending.type]: (state) => {
      state.fetchingChatRoomsStatus = 'pending';
    },
    [getChatRooms.fulfilled.type]: (state, { payload }: PayloadAction<{ rooms: ChatRoom[]; user: User }>) => {
      const sortedChatRooms = [...payload.rooms].sort((a, b) => {
        if (dayjs(a.lastMessage?.createdAt || a.updatedAt).diff(dayjs(b.lastMessage?.createdAt || b.updatedAt)) > 0)
          return -1;
        if (dayjs(a.lastMessage?.createdAt || a.updatedAt).diff(dayjs(b.lastMessage?.createdAt || b.updatedAt)) === 0)
          return 0;
        return 1;
      });
      state.chatRooms = sortedChatRooms;
      state.unreadRoomIds = sortedChatRooms
        .filter(
          (room) =>
            (room.lastMessageId &&
              room.lastMessageId !==
                (payload.user.id === room.expertId ? room.expertLastReadMessageId : room.clientLastReadMessageId)) ||
            (payload.user.id === room.expertId &&
              room.consultations.some((c) =>
                [ConsultationStatusEnum.PendingStart, ConsultationStatusEnum.Active].includes(c.status),
              )),
        )
        .map((room) => room.id);

      state.consultationsStatuses = payload.rooms.map((room) => ({
        roomId: room.id,
        consultations: room.consultations.map((c) => ({ id: c.id, status: c.status })),
      }));
      state.fetchingChatRoomsStatus = 'fulfilled';
    },
    [getChatRooms.rejected.type]: (state) => {
      state.fetchingChatRoomsStatus = 'rejected';
    },
    [getOneChatRoom.fulfilled.type]: (state, { payload }: PayloadAction<ChatRoom>) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.id);
      if (chatRoomIndex !== -1) {
        state.chatRooms[chatRoomIndex] = { lastMessage: state.chatRooms[chatRoomIndex].lastMessage, ...payload };
      }
    },
    [setActiveRoom.type]: (state, { payload }: PayloadAction<{ roomId: number | null }>) => {
      state.currentChatRoomId = payload.roomId;
    },
    [addChatRoom.type]: (state, { payload }: PayloadAction<ChatRoom>) => {
      if (payload.id) {
        const isExist = !!state.chatRooms.find((v) => v.id === payload.id);
        if (!isExist) {
          state.chatRooms.unshift(payload);
          state.unreadRoomIds.push(payload.id);
        }
      }
    },
    [updateLastMessage.type]: (
      state,
      { payload: { message, user } }: PayloadAction<{ message: Message; user: User }>,
    ) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === message.chatRoomId);
      if (chatRoomIndex !== -1) {
        const newRoom: ChatRoomExtended = {
          ...state.chatRooms[chatRoomIndex],
          lastMessage: message,
          lastMessageId: message.id,
        };

        if (message.chatRoomId === state.currentChatRoomId) {
          const isExpertInRoom = newRoom.expertId === user.id;
          isExpertInRoom
            ? (newRoom.expertLastReadMessageId = message.id)
            : (newRoom.clientLastReadMessageId = message.id);
        }

        state.chatRooms.splice(chatRoomIndex, 1);
        state.chatRooms.unshift(newRoom);
      }

      if (!state.unreadRoomIds.includes(message.chatRoomId) && message.chatRoomId !== state.currentChatRoomId) {
        state.unreadRoomIds.push(message.chatRoomId);
      }
    },
    [addUnreadMark.type]: (state, { payload }: PayloadAction<{ roomId: number }>) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.roomId);
      if (chatRoomIndex !== -1) {
        const currentRoom = { ...state.chatRooms[chatRoomIndex] };
        state.chatRooms.splice(chatRoomIndex, 1);
        state.chatRooms.unshift(currentRoom);
      }

      if (!state.unreadRoomIds.includes(payload.roomId) && payload.roomId !== state.currentChatRoomId) {
        state.unreadRoomIds.push(payload.roomId);
      }
    },
    [resetUnreadMark.type]: (state, { payload }: PayloadAction<{ chatRoomId: number }>) => {
      const unreadRoomIdIndex = state.unreadRoomIds.findIndex((id) => id === payload.chatRoomId);
      if (unreadRoomIdIndex !== -1) {
        state.unreadRoomIds.splice(unreadRoomIdIndex, 1);
      }
    },
    [changeLastReadMessage.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        chatRoomId: number;
        lastMessageId?: number;
        isExpertInRoom: boolean;
      }>,
    ) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.chatRoomId);
      if (chatRoomIndex !== -1) {
        if (payload.isExpertInRoom) {
          state.chatRooms[chatRoomIndex].expertLastReadMessageId = payload.lastMessageId;
        } else {
          state.chatRooms[chatRoomIndex].clientLastReadMessageId = payload.lastMessageId;
        }
      }
    },

    // *** online members flow
    [getOnlineMembers.type]: (state, { payload }: PayloadAction<number[]>) => {
      state.onlineMembersIds = payload;
    },
    [addMember.type]: (state, { payload }: PayloadAction<number>) => {
      !state.onlineMembersIds.includes(payload) && state.onlineMembersIds.push(payload);
    },
    [removeMember.type]: (state, { payload }: PayloadAction<number>) => {
      const memberIndex = state.onlineMembersIds.findIndex((id) => id === payload);
      if (memberIndex !== -1) {
        state.onlineMembersIds.splice(memberIndex, 1);
      }
      const chatRoomIndex = state.chatRooms.findIndex((room) => payload === room.clientId || payload === room.expertId);
      if (chatRoomIndex !== -1) {
        const isExpertInRoom = state.chatRooms[chatRoomIndex].expertId === payload;
        isExpertInRoom
          ? (state.chatRooms[chatRoomIndex].expert.lastExitTime = dayjs().format())
          : (state.chatRooms[chatRoomIndex].client.lastExitTime = dayjs().format());
      }
    },

    // *** consultation flow - addChatRoomIfNotExist
    [addChatRoomIfNotExist.type]: (state, { payload }: PayloadAction<Consultation>) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.chatRoomId);
      if (chatRoomIndex === -1) {
        state.chatRooms = [
          { ...payload.chatRoom, consultations: [payload], client: payload.client, expert: payload.expert },
          ...state.chatRooms,
        ];
      }

      if (!state.unreadRoomIds.includes(payload.chatRoomId) && state.currentChatRoomId !== payload.chatRoomId) {
        state.unreadRoomIds.push(payload.chatRoomId);
      }
    },
    [changeConsultationStatus.type]: (
      state,
      {
        payload,
      }: PayloadAction<{
        roomId: number;
        consultationId: number;
        status: ConsultationStatusEnum;
      }>,
    ) => {
      const chatRoomIndex = state.consultationsStatuses.findIndex((room) => room.roomId === payload.roomId);
      if (chatRoomIndex !== -1) {
        const consultationIndex = state.consultationsStatuses[chatRoomIndex].consultations.findIndex(
          (c) => c.id === payload.consultationId,
        );
        if (consultationIndex !== -1) {
          state.consultationsStatuses[chatRoomIndex].consultations[consultationIndex].status = payload.status;
        } else {
          state.consultationsStatuses[chatRoomIndex].consultations.push({
            id: payload.consultationId,
            status: payload.status,
          });
        }
      }
    },

    // *** other
    [toggleChatNotification.type]: (
      state,
      { payload }: PayloadAction<{ roomId: number; isExpertInRoom: boolean; isMuted: boolean }>,
    ) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.roomId);
      if (chatRoomIndex !== -1) {
        payload.isExpertInRoom
          ? (state.chatRooms[chatRoomIndex].isExpertMuteChat = payload.isMuted)
          : (state.chatRooms[chatRoomIndex].isClientMuteChat = payload.isMuted);
      }
    },
    [setCompanionWritten.type]: (state, { payload }: PayloadAction<{ chatRoomId: number; isWritten: boolean }>) => {
      const chatRoomIndex = state.chatRooms.findIndex((room) => room.id === payload.chatRoomId);
      if (chatRoomIndex !== -1) {
        state.chatRooms[chatRoomIndex].isCompanionWritten = payload.isWritten;
      }
    },
    [resetState.type]: () => initialState,
  },
});

export const { reducer } = chatRoomSlice;
export const actions = {
  ...chatRoomSlice.actions,
  getChatRooms,
  getOneChatRoom,
  setActiveRoom,
  createChatRoom,
  createTempChatRoom,
  addChatRoom,
  updateLastMessage,
  replaceTempToRealChatRoom,
  getOnlineMembers,
  addMember,
  removeMember,
  addUnreadMark,
  resetUnreadMark,
  changeLastReadMessage,
  toggleChatNotification,
  setCompanionWritten,
  addChatRoomIfNotExist,
  changeConsultationStatus,
  resetState,
  updateLastReadMessageAsync,
};
