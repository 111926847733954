import * as React from 'react';

function SvgCheckOkOpacity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" opacity=".2">
        <circle fill="#11C15A" fillRule="nonzero" cx="15" cy="15" r="15" />
        <path
          stroke="#FFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.286 15 3.571 5 7.857-10"
        />
      </g>
    </svg>
  );
}

export default SvgCheckOkOpacity;
