import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { Header } from './components';
import ru from 'date-fns/locale/ru';

type DatePickerProps = {
  minDate?: Date;
  onChange?: (date: Date | null) => void;
  selected: Date | null | undefined;
  startDate?: Date | null;
} & Omit<ReactDatePickerProps, 'onChange'>;

export const DatePicker = ({
  minDate = new Date(),
  selected,
  onChange,
  startDate: initialStartDate = null,
  ...props
}: DatePickerProps) => {
  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => <Header isMinDate {...props} />;

  const handleChange = (dates: Date | null) => {
    onChange?.(dates);
  };

  registerLocale('ru', ru);

  return (
    <ReactDatePicker
      inline
      locale="ru"
      minDate={minDate}
      selected={selected}
      onChange={handleChange}
      renderCustomHeader={renderCustomHeader}
      {...props}
    />
  );
};
