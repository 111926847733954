import * as React from 'react';

function Vkontakte(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M18.5944,1.4056 C19.8759765,2.68790588 19.9890567,4.6818173 19.9990344,8.45394059 L19.9999642,9.20651991 C20,9.33574602 20,9.46689412 20,9.6 L20,10.4 L19.9999642,10.7934801 L19.9999642,10.7934801 L19.9990344,11.5460594 C19.9890567,15.3181827 19.8759765,17.3120941 18.5944,18.5944 C17.3120941,19.8759765 15.3181827,19.9890567 11.5460594,19.9990344 L10.7934801,19.9999642 C10.664254,20 10.5331059,20 10.4,20 L9.6,20 L9.20651991,19.9999642 L9.20651991,19.9999642 L8.45394059,19.9990344 C4.6818173,19.9890567 2.68790588,19.8759765 1.4056,18.5944 C0.124023529,17.3120941 0.0109432526,15.3181827 0.000965581111,11.5460594 L0.000965581111,8.45394059 C0.0109432526,4.6818173 0.124023529,2.68790588 1.4056,1.4056 C2.68790588,0.124023529 4.6818173,0.0109432526 8.45394059,0.000965581111 L11.5460594,0.000965581111 C15.3181827,0.0109432526 17.3120941,0.124023529 18.5944,1.4056 Z M6.0546758,6.00096558 L4.00096558,6.00096558 C4.09840474,10.8509921 6.43693712,13.7656715 10.5368772,13.7656715 L10.7692696,13.7656715 L10.7692696,10.9908967 C12.275829,11.1463463 13.4150427,12.2889006 13.8722572,13.7656715 L16.0009656,13.7656715 C15.4163306,11.5582876 13.879715,10.3380085 12.9203141,9.8716598 C13.879715,9.29649641 15.2288727,7.8974503 15.5511714,6.00096558 L13.6173414,6.00096558 C13.1976034,7.5399163 11.9535302,8.9389624 10.7692696,9.07109453 L10.7692696,6.00096558 L8.83540208,6.00096558 L8.83540208,11.3795206 C7.63615086,11.0686215 6.12213368,9.56076067 6.0546758,6.00096558 Z"
          id="Combined-Shape"
          fill="#111111"
        />
      </g>
    </svg>
  );
}

export default Vkontakte;
