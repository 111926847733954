import { CheckWhite } from 'app/assets/svg';
import React, { FC } from 'react';
import styled from 'styled-components';

type CheckboxProps = {
  checked: boolean;
  onChange: (nextValue: boolean) => void;
  children?: React.ReactNode;
  className?: string;
};

export const Checkbox: FC<CheckboxProps> = ({ checked, onChange, children, className }) => {
  const onInputChange = () => {
    onChange(!checked);
  };

  return (
    <Container className={className}>
      <StyledInput type="checkbox" checked={checked} onChange={onInputChange} />
      <StyledBox $checked={checked}>
        {checked && (
          <CheckImgContainer>
            <CheckWhite width={11} height={11} />
          </CheckImgContainer>
        )}
      </StyledBox>
      <StyledText>{children}</StyledText>
    </Container>
  );
};

const Container = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledBox = styled.div<{ $checked: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--gray3);
  background-color: ${({ $checked }) => ($checked ? 'var(--purple)' : 'var(--white)')};
  border-width: ${({ $checked }) => ($checked ? 0 : 1)};
`;

const CheckImgContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

const StyledText = styled.div`
  flex: 1;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
  color: 'var(--text)';
`;
