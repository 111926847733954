import { ArrowRightOpacityIcon } from 'app/assets/svg';
import Link from 'next/link';
import React, { FC } from 'react';
import { buildRoute } from 'shared/helpers/buildRoute';
import styled from 'styled-components';

export type BreadcrumbType = {
  name: string;
  id?: number;
};

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbType[];
  rootRoute?: string;
  routeTemplate: string;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, routeTemplate, rootRoute }) => (
  <Container>
    {breadcrumbs.map((item, index) => {
      if (item.id) {
        return (
          <React.Fragment key={item.id}>
            <Link href={buildRoute(routeTemplate, { id: item.id })} passHref>
              <BreadcrumbItem>{item.name}</BreadcrumbItem>
            </Link>
            {index !== breadcrumbs.length - 1 && <ArrowIcon />}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={item.name}>
          <Link href={rootRoute || '/'} passHref>
            <BreadcrumbItem>{item.name}</BreadcrumbItem>
          </Link>
          {index !== breadcrumbs.length - 1 && <ArrowIcon />}
        </React.Fragment>
      );
    })}
  </Container>
);

const Container = styled.div``;
const BreadcrumbItem = styled.a`
  font-size: 14px;
  color: var(--white);
  opacity: 0.7;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  &:hover {
    opacity: 0.9;
  }
`;
const ArrowIcon = styled(ArrowRightOpacityIcon)`
  margin: 0 8px;
`;
